import { createIcon } from '../createIcon';

export const ShortJourneyIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path d="M14 17H8V16H14V17Z" fill="currentColor" />
      <path d="M17 11H8V10H17V11Z" fill="currentColor" />
      <path d="M17 8H8V7H17V8Z" fill="currentColor" />
      <path d="M17 13H8V14H17V13Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4625 19.5375C5.77083 19.8458 6.15513 20 6.61538 20H18.3846C18.8449 20 19.2292 19.8458 19.5375 19.5375C19.8458 19.2292 20 18.8449 20 18.3846V5.61537C20 5.15512 19.8458 4.77083 19.5375 4.4625C19.2292 4.15417 18.8449 4 18.3846 4L6.61538 4C6.15513 4 5.77083 4.15417 5.4625 4.4625C5.15417 4.77083 5 5.15512 5 5.61537V18.3846C5 18.8449 5.15417 19.2292 5.4625 19.5375ZM18.3846 19H6.61538C6.46154 19 6.32052 18.9359 6.1923 18.8077C6.0641 18.6795 6 18.5385 6 18.3846V5.61537C6 5.46154 6.0641 5.32052 6.1923 5.1923C6.32052 5.0641 6.46154 5 6.61538 5H18.3846C18.5385 5 18.6795 5.0641 18.8077 5.1923C18.9359 5.32052 19 5.46154 19 5.61537V18.3846C19 18.5385 18.9359 18.6795 18.8077 18.8077C18.6795 18.9359 18.5385 19 18.3846 19Z"
        fill="currentColor"
      />
    </g>
  ),
  fontSize: 'medium',
  viewBox: '0 0 24 24',
});
