import axios from 'axios';
import { getToken } from 'src/auth';
import { MARKET_UPDATES_LAMBDA_URL } from './lambda-routes';

export const publishMarketUpdate = async (
  marketUpdateId: string,
  published: boolean,
) => {
  const options = {
    headers: {
      Authorization: await getToken(),
      'Content-Type': 'application/json',
    },
  };

  const response = await axios
    .put(
      `${MARKET_UPDATES_LAMBDA_URL}/marketUpdate/${marketUpdateId}`,
      { published: published.toString() },
      options,
    )
    .catch((error) => {
      throw new Error(error.response?.data?.error ?? 'Error');
    });

  return response.data;
};
