import { FC } from 'react';

import { SvgImage } from 'src/components/shared';

export const Disclaimer: FC = () => (
  <SvgImage
    image={
      <svg
        width="132"
        height="180"
        viewBox="0 0 132 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.457265"
          y="0.457265"
          width="130.71"
          height="179.085"
          fill="white"
          stroke="#D4D4D4"
          strokeWidth="0.91453"
        />
        <path
          d="M108.355 23.1501C106.58 23.4779 104.989 24.3261 103.744 25.5304L109.217 30.4828L108.355 23.1501Z"
          fill="#D9D9D9"
        />
        <path
          d="M102.938 26.4198C101.724 27.9537 101 29.8922 101 32C101 36.9706 105.03 41 110 41C114.277 41 117.857 38.0167 118.773 34.0176L109.729 32.5642L109.598 32.4449L102.938 26.4198Z"
          fill="#D9D9D9"
        />
        <path
          d="M118.962 32.8326C118.987 32.5584 119 32.2807 119 32C119 27.0294 114.97 23 110 23C109.848 23 109.697 23.0038 109.547 23.0112L110.543 31.4795L118.962 32.8326Z"
          fill="#D9D9D9"
        />
        <path
          d="M1.125 1.125H104.625L96.75 25.875H1.125V1.125Z"
          fill="#D4D4D4"
        />
        <circle cx="117" cy="13.5" r="4.5" fill="#D4D4D4" />
        <rect x="9" y="30" width="77" height="2.25" fill="#D4D4D4" />
        <rect x="9" y="34.5" width="77" height="2.25" fill="#D4D4D4" />
        <rect x="9" y="39" width="77" height="2.25" fill="#D4D4D4" />
        <rect x="2" y="134" width="127" height="44" fill="#F6F6F6" />
        <rect x="54" y="140" width="30" height="6" fill="#D4D4D4" />
        <rect x="54" y="150.125" width="68.7702" height="2.25" fill="#D4D4D4" />
        <rect x="54" y="154.625" width="68.7702" height="2.25" fill="#D4D4D4" />
        <rect x="54" y="159.125" width="68.7702" height="2.25" fill="#D4D4D4" />
        <rect x="54" y="163.625" width="68.7702" height="2.25" fill="#D4D4D4" />
        <rect x="54" y="168" width="41.7081" height="2" fill="#D4D4D4" />
        <g style={{ mixBlendMode: 'multiply' }}>
          <rect
            x="125"
            y="174"
            width="76"
            height="27"
            transform="rotate(180 125 174)"
            fill="#EFF7F6"
          />
          <rect
            x="125"
            y="174"
            width="76"
            height="27"
            transform="rotate(180 125 174)"
            stroke="#1F5E4C"
            strokeWidth="2"
          />
        </g>
        <rect x="9" y="48" width="23" height="7" fill="#D9D9D9" />
        <rect x="107" y="48" width="15" height="7" fill="#D9D9D9" />
        <rect x="34" y="48" width="71" height="7" fill="#D9D9D9" />
        <rect x="9" y="57" width="23" height="7" fill="#D9D9D9" />
        <rect x="107" y="57" width="15" height="7" fill="#D9D9D9" />
        <rect x="34" y="57" width="71" height="7" fill="#D9D9D9" />
        <rect x="9" y="66" width="23" height="7" fill="#D9D9D9" />
        <rect x="107" y="66" width="15" height="7" fill="#D9D9D9" />
        <rect x="34" y="66" width="71" height="7" fill="#D9D9D9" />
        <rect x="9" y="75" width="23" height="7" fill="#D9D9D9" />
        <rect x="107" y="75" width="15" height="7" fill="#D9D9D9" />
        <rect x="34" y="75" width="71" height="7" fill="#D9D9D9" />
        <rect x="9" y="84" width="23" height="7" fill="#D9D9D9" />
        <rect x="107" y="84" width="15" height="7" fill="#D9D9D9" />
        <rect x="34" y="84" width="71" height="7" fill="#D9D9D9" />
        <rect x="9" y="93" width="23" height="7" fill="#D9D9D9" />
        <rect x="107" y="93" width="15" height="7" fill="#D9D9D9" />
        <rect x="34" y="93" width="71" height="7" fill="#D9D9D9" />
        <rect x="9" y="102" width="23" height="7" fill="#D9D9D9" />
        <rect x="107" y="102" width="15" height="7" fill="#D9D9D9" />
        <rect x="34" y="102" width="71" height="7" fill="#D9D9D9" />
        <rect x="9" y="111" width="23" height="7" fill="#D9D9D9" />
        <rect x="107" y="111" width="15" height="7" fill="#D9D9D9" />
        <rect x="34" y="111" width="71" height="7" fill="#D9D9D9" />
        <rect x="9" y="120" width="23" height="7" fill="#D9D9D9" />
        <rect x="107" y="120" width="15" height="7" fill="#D9D9D9" />
        <rect x="34" y="120" width="71" height="7" fill="#D9D9D9" />
      </svg>
    }
  />
);
