import { Enum_RiskName, History, Maybe } from 'src/apollo';
import { Theme, lighten } from '@mui/material';
import { WorldEvent } from '../worldEvents';

export const areDatesEqual = (
  date1: Date | null,
  date2: Maybe<string> | undefined,
) => {
  if (!date1 || !date2) return false;
  const dt2 = new Date(date2);
  return (
    date1.getFullYear() === dt2.getFullYear() &&
    date1.getMonth() === dt2.getMonth()
  );
};

export const calculateLabelCoords = (
  event: WorldEvent,
  history?: History[],
): [string, number] | null => {
  if (!history) return null;

  const middlePoint = history.find(({ date }) => {
    return areDatesEqual(event.month, date);
  });
  const margin = Number(history?.[0]?.medium) * 2;
  const x = new Date(middlePoint?.date ?? '').getTime().toString();
  const y = ((middlePoint?.high ?? 0) + margin) * event.offset;
  return [x, y];
};

export const getSeriesColor = (i: number, theme: Theme) => {
  const palette = [
    lighten(theme.palette.primary.main, 0.3),
    lighten('#000', 0.4),
    theme.palette.grey[400],
  ];
  return palette[i] ?? 'black';
};

export const getRiskLabel = (risk: string) => {
  const lookup: Record<string, string> = {
    [Enum_RiskName.Low]: 'Low',
    [Enum_RiskName.Lower]: 'Low-Medium',
    [Enum_RiskName.Medium]: 'Medium',
    [Enum_RiskName.Higher]: 'Medium-High',
    [Enum_RiskName.High]: 'High',
  };

  return lookup[risk] ?? '';
};

export const coercePositive = (val?: number, fallbackVal = 1) => {
  return val && val !== 0 ? val : fallbackVal;
};
