import { createIcon } from '../createIcon';

export const CoreIcon = createIcon({
  svg: ({ palette }) => (
    <>
      <circle
        cx="36"
        cy="36.867"
        r="35.5"
        fill={palette.common.white}
        stroke="color-mix(in srgb, currentColor 20%, transparent)"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.37 37.592c.252 0 .478-.172.532-.423l1.006-4.799c.194-.91 1.023-1.57 1.973-1.57h19.235c.95 0 1.779.66 1.973 1.57l2.361 11.12c.054.251.28.424.532.424.036 0 .074-.004.111-.012.294-.06.483-.341.421-.629l-2.361-11.12c-.298-1.4-1.576-2.416-3.037-2.416h-3.561l-.693-2.013c-.175-.508-.66-.849-1.208-.849h-.058c.067-.293.102-.593.102-.899 0-2.265-1.884-4.109-4.2-4.109-2.315 0-4.199 1.844-4.199 4.11 0 .305.035.605.102.898h-.058c-.548 0-1.034.341-1.208.849l-.693 2.013H26.88c-1.462 0-2.74 1.016-3.037 2.417l-1.007 4.798c-.06.287.128.569.422.629.037.007.074.011.111.011zm12.764-7.855h-4.545l.576-1.673c.026-.075.097-.125.178-.125h8.311c.08 0 .152.05.178.125l.576 1.673h-5.274zm-.555-3.76c0-.497.413-.9.92-.9.506 0 .918.403.918.9 0 .492-.408.894-.911.898h-.016c-.503-.004-.91-.406-.91-.899zm.919-3.046c-1.716 0-3.112 1.366-3.112 3.045 0 .309.046.61.138.899h1.192c-.143-.27-.224-.575-.224-.899 0-1.082.9-1.963 2.006-1.963 1.106 0 2.006.88 2.006 1.963 0 .324-.081.63-.224.899h1.192c.091-.29.138-.59.138-.899 0-1.68-1.396-3.045-3.112-3.045z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M54.975 50.307l-.809-4.157c-.118-.607-.638-1.047-1.237-1.047h-1.073l-.423-2.097c-.061-.303-.348-.496-.642-.434-.293.063-.48.36-.42.662l.377 1.869H22.253l1.929-9.562c.061-.303-.127-.599-.42-.662-.293-.063-.58.131-.641.434l-1.976 9.79h-1.073c-.599 0-1.12.44-1.237 1.047l-.809 4.157c-.075.385.02.78.261 1.083.24.303.597.477.976.477h6.14c.299 0 .541-.25.541-.56 0-.308-.242-.559-.542-.559h-6.139c-.073 0-.117-.042-.137-.067-.02-.025-.052-.079-.037-.153l.808-4.158c.017-.086.09-.148.175-.148H52.93c.084 0 .158.062.175.148l.808 4.158c.015.074-.017.128-.036.153-.02.025-.065.067-.138.067H24.842c-.3 0-.543.25-.543.56 0 .309.243.56.543.56h28.895c.38 0 .736-.175.977-.478.24-.303.336-.698.261-1.083z"
      />
      <path
        fill="currentColor"
        d="M36.5 34.742c.688 0 1.25.563 1.25 1.25 0 .375.25.625.625.625s.625-.25.625-.625c0-1.187-.813-2.125-1.875-2.437v-.063c0-.375-.25-.625-.625-.625s-.625.25-.625.625v.063c-1.063.312-1.875 1.25-1.875 2.437 0 1.375 1.125 2.5 2.5 2.5.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25-.688 0-1.25-.562-1.25-1.25 0-.375-.25-.625-.625-.625s-.625.25-.625.625c0 1.188.813 2.125 1.875 2.438v.062c0 .375.25.625.625.625s.625-.25.625-.625v-.062C38.188 41.93 39 40.93 39 39.742c0-1.375-1.125-2.5-2.5-2.5-.688 0-1.25-.562-1.25-1.25 0-.687.563-1.25 1.25-1.25z"
      />
    </>
  ),
  viewBox: '0 0 72 73',
});
