import { createIcon } from '../createIcon';

export const SatelliteIcon = createIcon({
  svg: ({ palette }) => (
    <g stroke="none" fill={palette.primary.main}>
      <path d="M35.5998 20.8C35.5998 29.3605 28.6602 36.3 20.0998 36.3C11.5394 36.3 4.59981 29.3605 4.59981 20.8C4.59981 18.4951 5.10292 16.3077 6.00533 14.3415C5.54093 14.127 5.11502 13.8433 4.7408 13.5037C3.68865 15.7146 3.09981 18.1886 3.09981 20.8C3.09981 30.1889 10.711 37.8 20.0998 37.8C29.4886 37.8 37.0998 30.1889 37.0998 20.8C37.0998 11.4112 29.4886 3.80005 20.0998 3.80005C16.9616 3.80005 14.0221 4.65036 11.4989 6.13316C11.8703 6.47761 12.1893 6.8778 12.4426 7.32042C14.7014 6.03455 17.3148 5.30005 20.0998 5.30005C28.6602 5.30005 35.5998 12.2396 35.5998 20.8Z" />
      <circle cx="20.0998" cy="20.8" r="8" />
      <circle cx="8.09981" cy="9.80005" r="4" />
    </g>
  ),
  fontSize: 'small',
  viewBox: '0 0 41 41',
});
