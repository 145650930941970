import { createIcon } from '../createIcon';

export const RotateIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.3"
        d="M24.105 11.389V2.662c0-.914-.748-1.662-1.662-1.662h-2.909-5.942c-2.286 0-2.286 1.33-2.286 2.286v2.908"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.3"
        d="M28.634 7.233l-4.529 4.571-4.571-4.57M15.958 21.245v5.27h-3.277M6.816 26.515h-3.74V8.23h12.882v7.694"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.3"
        d="M27.923 31V18.118H9.64V31h18.284z"
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
