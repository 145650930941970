import { FC, MouseEvent as RMouseEvent, useCallback } from 'react';

import { useGlobalDrag } from 'src/hooks/useGlobalDrag';

import { useStyles } from './styles';

interface Props {
  hue: number;
  onChange: (hue: number) => void;
}

export const ColorSlider: FC<Props> = ({ hue, onChange }) => {
  const { classes } = useStyles();
  const handleMouseMove = useCallback(
    (moveEv: MouseEvent, el: HTMLDivElement) => {
      moveEv.preventDefault();
      const { x, width } = el.getBoundingClientRect();
      onChange?.(
        Math.min(Math.max(((moveEv.clientX as number) - x) / width, 0), 1),
      );
    },
    [onChange],
  );
  const startDrag = useGlobalDrag<HTMLDivElement>(handleMouseMove);
  const handleMouseDown = useCallback(
    (e: RMouseEvent<HTMLDivElement>) => {
      startDrag(e.currentTarget);
      handleMouseMove(e.nativeEvent, e.currentTarget);
    },
    [startDrag, handleMouseMove],
  );
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={classes.root} onMouseDown={handleMouseDown}>
      <svg
        className={classes.slider}
        viewBox="0 0 1 1"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="hue" x1="100%" y1="0%" x2="0%" y2="0%">
            <stop offset="0%" stopColor="#f00" />
            <stop offset="16.666%" stopColor="#f0f" />
            <stop offset="33.333%" stopColor="#00f" />
            <stop offset="50%" stopColor="#0ff" />
            <stop offset="66.666%" stopColor="#0f0" />
            <stop offset="83.333%" stopColor="#ff0" />
            <stop offset="100%" stopColor="#f00" />
          </linearGradient>
        </defs>
        <rect x="0" y="0" width="1" height="1" fill="url(#hue)" />
      </svg>
      <div className={classes.dragger} style={{ left: `${100 * hue}%` }} />
    </div>
  );
};
