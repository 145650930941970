import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import { BellIcon, CloseIcon } from 'src/components/ui';
import { useStyles } from './styles';

interface Props {
  isOpen?: boolean;
  hasUnreadNotifications?: boolean;
  onClick?: () => void;
}

export const NotificationBell: FC<Props> = ({
  isOpen,
  hasUnreadNotifications,
  onClick,
}) => {
  const { classes } = useStyles();

  return (
    <IconButton
      disableRipple
      sx={{ paddingRight: 0, width: 36 }}
      onClick={onClick}
    >
      {!isOpen ? (
        <>
          <BellIcon fontSize="medium" />
          {hasUnreadNotifications && (
            <Box className={classes.notificationDot} />
          )}
        </>
      ) : (
        <CloseIcon fontSize="small" />
      )}
    </IconButton>
  );
};
