import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: '8px 12px',
    height: 53,
    width: 'auto',
    alignSelf: 'stretch',
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[400]}`,
    transition: '0.1s',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid black',
    },
  },
  defaultLabel: {
    margin: theme.spacing(0, 2.5),
    cursor: 'inherit',
  },
}));
