import { createIcon } from '../createIcon';

export const EmailIcon = createIcon({
  svg: () => (
    <path
      d="M4.68809 18.8615C4.25863 18.8615 3.89555 18.7132 3.59887 18.4165C3.3022 18.1198 3.15387 17.7566 3.15387 17.3269V6.6731C3.15387 6.24334 3.3022 5.88012 3.59887 5.58345C3.89555 5.28677 4.25863 5.13843 4.68809 5.13843H19.3119C19.7414 5.13843 20.1045 5.28677 20.4012 5.58345C20.6978 5.88012 20.8462 6.24334 20.8462 6.6731V17.3269C20.8462 17.7566 20.6978 18.1198 20.4012 18.4165C20.1045 18.7132 19.7414 18.8615 19.3119 18.8615H4.68809ZM12 12.0567L4.07694 6.86923V17.3231C4.07694 17.5025 4.13464 17.65 4.25002 17.7654C4.3654 17.8807 4.51284 17.9384 4.69232 17.9384H19.3077C19.4872 17.9384 19.6346 17.8807 19.75 17.7654C19.8654 17.65 19.9231 17.5025 19.9231 17.3231V6.86923L12 12.0567ZM12 11.0154L19.6 6.06153H4.40002L12 11.0154ZM4.07694 6.86923V6.06153V17.3231C4.07694 17.5025 4.13464 17.65 4.25002 17.7654C4.3654 17.8807 4.51284 17.9384 4.69232 17.9384H4.07694V6.86923Z"
      fill="currentColor"
    />
  ),
  fontSize: 'large',
  viewBox: '0 0 24 24',
});
