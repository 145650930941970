import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    alignSelf: 'stretch',
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(4),
    },
  },
  label: {
    margin: '0px 0px 8px 8px',
    lineHeight: 1.75,
    color: theme.palette.primary.main,
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));
