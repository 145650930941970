import { ClientProfile } from 'src/components/pages/Customers/types';
import { Questionnaire } from 'src/components/pages/Questionnaire/types';
import { Routes } from 'src/routing';
import { UseDialog } from 'src/hooks';
import { confirmModal } from './confirmModal';

const testEnvs = ['localhost', 'develop', 'test', 'staging'];

const getUrl = () => {
  if (testEnvs.some((e) => window.location.hostname.startsWith(e))) {
    return window.location.origin;
  }
  return 'https://app.aspenadvisers.com';
};

export const contact =
  'mailto:support@aspenadvisers.com?body=For%20attention%20of%20the%20Aspen%20team%3A';

const questionnaireMailTemplate = `Dear,

Alongside understanding your financial goals, it is important for us to consider your attitude to risk. To help us with this, we have prepared a short questionnaire that we would be grateful if you could take the time to complete (answering all the questions). We expect this to take 5 - 10 minutes. It is important that this is completed honestly - there is no right or wrong answer.

The answers will help guide us to the appropriate level of risk for your investments, and will be considered alongside your wider circumstances and needs.

Please click on the link below (or copy it to your browser).

[link]

To access your questionnaire please type in the following passcode: [code]

If you leave the questionnaire partway through and wish to come back you will be prompted to supply the passcode again. Any progress made will be saved automatically.

If you have any questions, please do not hesitate to contact me.

Kind regards,

[adviser]`;

export const generateQuestionnaireMail = (
  client: ClientProfile,
  adviser: string,
  questionnaire: Questionnaire,
) => {
  const link = `${Routes.Questionnaire}/${questionnaire.questionnaireId}`;
  const prepopulatedMail = questionnaireMailTemplate
    .replace('[adviser]', adviser)
    .replace('[link]', `${getUrl()}${link}`)
    .replace('[code]', questionnaire.code);
  const body = encodeURIComponent(prepopulatedMail);
  return `mailto:${client.email}?body=${body}`;
};

export const handleContact = (dialog: UseDialog) => {
  confirmModal(dialog, {
    confirmLabel: 'Proceed',
    content: (
      <>
        <p>
          We will redirect you to your primary email application, for
          correspondence. We aim to respond to all queries within 24 hours.
        </p>
        <p>
          For urgent matters, please call us on: <strong>0131 380 4944</strong>.
        </p>
      </>
    ),
    onConfirm: () => window.open(contact),
  });
};
