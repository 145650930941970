import { createIcon } from '../createIcon';

export const PortfolioPositioningIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4.5V28H3.5C3.22386 28 3 27.7761 3 27.5V11.5C3 11.2239 3.22386 11 3.5 11H9.5C9.77614 11 10 10.7761 10 10.5C10 10.2239 9.77614 10 9.5 10H3.5C2.67157 10 2 10.6716 2 11.5V27.5C2 28.3284 2.67157 29 3.5 29H28.5C29.3284 29 30 28.3284 30 27.5V19.5C30 18.6716 29.3284 18 28.5 18H22.5C22.2239 18 22 18.2239 22 18.5C22 18.7761 22.2239 19 22.5 19H28.5C28.7761 19 29 19.2239 29 19.5V27.5C29 27.7761 28.7761 28 28.5 28H21V4.5C21 3.67157 20.3284 3 19.5 3H12.5C11.6716 3 11 3.67157 11 4.5ZM20 4.5C20 4.22386 19.7761 4 19.5 4H12.5C12.2239 4 12 4.22386 12 4.5V28H20V4.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6.5C16.2761 6.5 16.5 6.72386 16.5 7V13C16.5 13.2761 16.2761 13.5 16 13.5C15.7239 13.5 15.5 13.2761 15.5 13V7C15.5 6.72386 15.7239 6.5 16 6.5Z"
        fill="currentColor"
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
