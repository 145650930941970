import { createIcon } from '../createIcon';

export const ArrowFall = createIcon({
  svg: () => (
    <path
      d="M8.55363 0L8.55363 13.8685L15.2249 7.19723L16 8L8 16L0 8L0.775086 7.19723L7.44637 13.8685L7.44637 0H8.55363Z"
      fill="currentColor"
    />
  ),
  fontSize: 'inherit',
  viewBox: '0 0 16 16',
});
