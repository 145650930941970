import { Image, Text, View } from '@react-pdf/renderer';

import { lighten, Theme } from '@mui/material';
import { FC } from 'react';
import { Slant1 } from './Slant1';
import { Slant3 } from './Slant3';

interface Props {
  logo: string;
  theme: Theme;
  title: string;
}

export const Header: FC<Props> = ({ logo, theme, title }) => {
  const primary = theme.palette.primary.main;
  const bgColor = lighten(primary, 0.9);
  return (
    <View
      style={{
        height: 50,
        flexDirection: 'row',
        backgroundColor: 'white',
        transform: 'translate(-100px, 0px)',
      }}
    >
      <View
        style={{
          color: primary,
          paddingLeft: 125,
          height: '100%',
          flexGrow: 1,
          fontSize: 20,
          fontWeight: 500,
          fontFamily: 'Poppins',
          backgroundColor: bgColor,
          justifyContent: 'center',
        }}
      >
        <Text>{title}</Text>
      </View>
      <View>
        <View
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <Slant1 color={bgColor} opacity="1" />
        </View>
        <View
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            transform: 'translate(-126px, 0px)',
          }}
        >
          <Slant3 color={primary} />
        </View>
      </View>
      <View
        style={{
          width: 38,
          height: 27,
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          transform: 'translate(40px, 0px)',
        }}
      >
        <Image src={logo} style={{ objectFit: 'contain' }} />
      </View>
    </View>
  );
};
