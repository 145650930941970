import { PageTitle, trackDocumentDownload } from './tracking';

export const download = (
  src: string,
  docTitle: string,
  pageTitle?: PageTitle,
) => {
  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.href = src;
  downloadLink.target = '_self';
  downloadLink.download = docTitle;
  downloadLink.setAttribute('data-testid', 'download-link');
  downloadLink.click();
  if (pageTitle) {
    trackDocumentDownload(docTitle, pageTitle);
  }
  setTimeout(() => {
    downloadLink.remove();
  }, 1000);
};
