import { ReactNode } from 'react';

export const uncheckedRadio: ReactNode = (
  <div
    style={{
      width: 24,
      height: 24,
      borderRadius: 12,
      border: '2px solid currentColor',
      backgroundColor: 'white',
    }}
  />
);

export const checkedRadio: ReactNode = (
  <div
    style={{
      width: 24,
      height: 24,
      borderRadius: 12,
      background: 'currentColor',
      boxShadow:
        '0px 2px 4px 0px color-mix(in srgb, currentColor 40%, transparent)',
    }}
  />
);

export const uncheckedBox: ReactNode = (
  <div
    style={{
      width: 24,
      height: 24,
      borderRadius: 4,
      border: '2px solid currentColor',
      backgroundColor: 'white',
    }}
  />
);

export const checkedBox: ReactNode = (
  <div
    style={{
      width: 24,
      height: 24,
      borderRadius: 4,
      background: 'currentColor',
    }}
  />
);
