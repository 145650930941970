import { createIcon } from '../createIcon';

export const ResendEmailIcon = createIcon({
  svg: () => (
    <svg width="24" height="17" fill="none">
      <g clipPath="url(#clip0_5411_4701)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.6091 6.46402C21.5858 6.46402 24 8.82309 24 11.7319C24 14.6409 21.5858 17 18.6091 17C15.6323 17 13.2182 14.6409 13.2182 11.7319C13.2182 8.82309 15.6323 6.46402 18.6091 6.46402ZM1.34986 0H19.5966C20.334 0 20.9331 0.589653 20.9323 1.30678L20.9278 5.69256C20.569 5.56077 20.1989 5.46013 19.822 5.3918L19.8255 1.86268L13.9278 6.3921L14.3553 6.80174C14.0457 7.05704 13.7606 7.33954 13.5037 7.64571L12.9958 7.15824L11.0206 8.80115C10.8097 8.97388 10.4833 8.99463 10.2469 8.80687L8.1735 7.1533L1.84936 12.9488H12.1268C12.1988 13.3173 12.3039 13.6789 12.4409 14.0293L1.33589 14.0297C0.597375 14.0297 0 13.44 0 12.7226L0.0115313 1.30642C0.0125625 0.588279 0.611719 0 1.34986 0ZM1.10719 11.9959L7.22123 6.40397L1.11689 1.90931L1.10719 11.9959ZM1.99959 1.0811L10.6223 7.57915L18.782 1.0811H1.99959ZM20.0898 10.4166C20.0511 10.3844 20.0118 10.3529 19.9719 10.3222C19.7838 10.1769 19.5881 10.0504 19.4279 9.97299C18.9304 9.73269 18.3796 9.71213 17.8918 9.87299C17.4061 10.0333 16.9822 10.3753 16.7363 10.8608C16.49 11.347 16.4685 11.8853 16.6329 12.3615C16.7172 12.6057 16.8499 12.8313 17.0236 13.0252C17.1972 13.2192 17.4084 13.3778 17.6449 13.4918C18.095 13.7105 18.6128 13.7558 19.0955 13.6187C19.5656 13.4853 19.985 13.1859 20.2532 12.7543C20.3828 12.5451 20.6617 12.4781 20.8758 12.6047C21.09 12.7314 21.1585 13.0039 21.0289 13.2132C20.6382 13.8422 20.0295 14.2775 19.3482 14.4708C18.6716 14.6625 17.9226 14.617 17.2429 14.2882C16.5209 13.9386 16.0111 13.3353 15.7719 12.6433C15.5333 11.9527 15.5642 11.1725 15.9213 10.468C16.2787 9.76274 16.8956 9.26516 17.6034 9.03155C18.31 8.7984 19.1088 8.82822 19.8299 9.17661C20.0416 9.27917 20.295 9.44178 20.5341 9.62656L20.6299 9.70223L20.6099 9.34793C20.5964 9.1031 20.7883 8.89345 21.0388 8.88021C21.2894 8.86697 21.504 9.0545 21.5175 9.29933L21.5994 10.745C21.619 10.9899 21.4318 11.2041 21.1812 11.2232L19.6404 11.3444C19.3899 11.3635 19.1707 11.1806 19.1511 10.9357C19.1315 10.6909 19.3187 10.4767 19.5693 10.4575L20.0898 10.4166Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5411_4701">
          <rect width="24" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  fontSize: 'small',
  viewBox: '0 0 24 17',
});
