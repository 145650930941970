import { Typography } from '@mui/material';
import { SnackType } from 'src/hooks/Snack/types';
import { copyToClipboard } from '../../../utils';
import { UseDialog, UseSnack } from '../../../hooks';
import { confirmModal } from '../../../utils/confirmModal';
import { TemporaryPasswordDialog } from './TemporaryPasswordDialog/TemporaryPasswordDialog';

const handleCopyPassword = async (snack: UseSnack, password: string) => {
  await copyToClipboard(password);
  snack.show({
    type: SnackType.Success,
    message: 'Password copied to clipboard',
  });
};

export const showTemporaryPasswordDialog = (props: {
  dialog: UseDialog;
  snack: UseSnack;
  name: string;
  password: string;
}) => {
  const { dialog, snack, name, password } = props;
  dialog.show({
    title: 'Reset password',
    content: (
      <TemporaryPasswordDialog
        password={password}
        name={name}
        onSubmit={async () => {
          await handleCopyPassword(snack, password);
          dialog.close();
        }}
      />
    ),
  });
};

export const showPasswordResetConfirmation = (props: {
  name: string;
  userType: 'company' | 'adviser' | 'admin';
  dialog: UseDialog;
}): Promise<boolean> => {
  const { dialog, name, userType } = props;
  return new Promise((resolve) => {
    confirmModal(dialog, {
      title: 'Reset Password',
      content: (
        <>
          <Typography>
            You are about to initiate a temporary password for the <b>{name}</b>{' '}
            {userType}, which will result in the reset of the existing password
            for this user.
          </Typography>
          <Typography sx={{ marginTop: 4 }}>
            Are you sure you want to proceed?
          </Typography>
        </>
      ),
      confirmLabel: 'Yes, proceed',
      onConfirm: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });
};
