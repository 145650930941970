import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ fullPage?: boolean }>()(
  (_, { fullPage }) => ({
    root: {
      position: fullPage ? 'fixed' : 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: fullPage ? 5000 : 10,
    },
  }),
);
