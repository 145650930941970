import { createIcon } from '../createIcon';

export const DownloadIcon = createIcon({
  svg: () => (
    <path
      d="M6.625 19.35C6.15833 19.35 5.771 19.196 5.463 18.888C5.15433 18.5793 5 18.1917 5 17.725V15.35H6V17.725C6 17.8917 6.06267 18.0373 6.188 18.162C6.31267 18.2873 6.45833 18.35 6.625 18.35H17.375C17.5417 18.35 17.6873 18.2873 17.812 18.162C17.9373 18.0373 18 17.8917 18 17.725V15.35H19V17.725C19 18.1917 18.846 18.5793 18.538 18.888C18.2293 19.196 17.8417 19.35 17.375 19.35H6.625ZM12 15.575L8.45 12.05L9.175 11.325L11.5 13.65V5H12.5V13.65L14.825 11.325L15.55 12.05L12 15.575Z"
      fill="currentColor"
    />
  ),
  fontSize: 'large',
  viewBox: '0 0 24 24',
});
