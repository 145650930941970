/* eslint-disable react/jsx-props-no-spreading */
import { FC, memo, PropsWithChildren } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

import { DragIcon } from 'src/components/ui/icons';

import { useStyles } from './styles';

interface Props extends PropsWithChildren {
  id: string;
  index: number;
}

export const DragableRow: FC<Props> = memo(({ id, index, children }) => {
  const { classes } = useStyles();

  return (
    <Draggable draggableId={id} index={index}>
      {(p: DraggableProvided) => (
        <li
          data-testid={`${id}-dnd`}
          className={classes.itemContent}
          ref={p.innerRef}
          {...p.draggableProps}
        >
          <div {...p.dragHandleProps}>
            <DragIcon />
          </div>
          {children}
        </li>
      )}
    </Draggable>
  );
});
