import { createIcon } from '../createIcon';

export const InProgressIcon = createIcon({
  svg: () => (
    <path
      d="M10.0081 16.5833C9.09466 16.5833 8.23877 16.4106 7.44045 16.0652C6.64212 15.7198 5.94596 15.2501 5.35199 14.6561C4.75801 14.0621 4.28831 13.366 3.94291 12.5677C3.59751 11.7693 3.4248 10.9135 3.4248 10C3.4248 9.08654 3.59751 8.23065 3.94291 7.43233C4.28831 6.63401 4.75801 5.93786 5.35199 5.34387C5.94596 4.7499 6.64212 4.28021 7.44045 3.93481C8.23877 3.58939 9.09466 3.41668 10.0081 3.41668C11.0423 3.41668 11.9963 3.63704 12.8703 4.07775C13.7442 4.51845 14.4846 5.11007 15.0914 5.85258V3.1282H16.1747V7.35893H11.944V6.27562H14.061C13.5569 5.72284 12.9587 5.28871 12.2663 4.97323C11.5739 4.65774 10.8211 4.5 10.0081 4.5C8.49423 4.5 7.19909 5.03819 6.1227 6.11458C5.04631 7.19097 4.50812 8.48611 4.50812 10C4.50812 11.5139 5.04631 12.809 6.1227 13.8854C7.19909 14.9618 8.49423 15.5 10.0081 15.5C11.522 15.5 12.8145 14.9605 13.8855 13.8814C14.9566 12.8023 15.5001 11.5085 15.5161 10H16.5834C16.5887 11.8322 15.9523 13.3875 14.674 14.6659C13.3956 15.9442 11.8404 16.5833 10.0081 16.5833ZM12.2357 12.6891L9.46645 9.91987V6H10.5498V9.45512L13.0097 11.9151L12.2357 12.6891Z"
      fill="currentColor"
    />
  ),
  fontSize: 'small',
  viewBox: '0 0 20 20',
});
