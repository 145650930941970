import axios from 'axios';
import { getToken } from '../auth';
import { USEFUL_RESOURCES_LAMBDA_URL } from './lambda-routes';
import { UsefulResource } from '../components/pages/UsefulResourcesPage/model/UsefulResource';

interface GeneralArgs {
  pageSize?: number;
  page?: number;
  reverse?: 'true' | 'false';
}

interface SearchArgs {
  searchPhrase: string;
  searchBy: 'title';
}

export type GetUsefulResourcesArgs = GeneralArgs | (GeneralArgs & SearchArgs);

export const getUsefulResources = async (args?: GetUsefulResourcesArgs) => {
  const options = {
    headers: {
      Authorization: await getToken(),
    },
  };

  const query = new URLSearchParams(args as Record<string, string>).toString();
  const response = await axios
    .get(`${USEFUL_RESOURCES_LAMBDA_URL}/useful-resources?${query}`, options)
    .catch((error) => {
      throw new Error(
        error.response?.data?.error ?? 'Could not get useful resources',
      );
    });

  return response.data as {
    data: UsefulResource[];
    pageCount: number;
    currentPage: number;
  };
};
