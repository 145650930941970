import { DragEvent, FC, ReactElement, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './styles';

interface Props {
  multiple?: boolean;
  onFileDropped?: (files: FileList) => void;
  children?: ReactElement;
}

export const FileDropArea: FC<Props> = ({
  multiple,
  onFileDropped,
  children,
}) => {
  const dragPlaceholder = useRef(null);
  const [dragWithinDrop, setDragWithinDrop] = useState(false);
  const { classes } = useStyles(dragWithinDrop);

  const cancelEventDefaults = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragChange = (e: DragEvent) => {
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragWithinDrop(true);
    } else if (e.type === 'dragleave') {
      setDragWithinDrop(false);
    }
  };

  const onDragOver = (e: DragEvent) => {
    cancelEventDefaults(e);
    dragChange(e);
  };

  const onDrop = (e: DragEvent) => {
    cancelEventDefaults(e);
    setDragWithinDrop(false);
    const { files } = e.dataTransfer;
    if (!multiple && files?.length > 1) return;
    if (files?.length) {
      onFileDropped?.(files);
    }
  };

  const onDragEnter = (e: DragEvent) => {
    cancelEventDefaults(e);
    dragChange(e);
  };

  const onDragLeave = (e: DragEvent) => {
    cancelEventDefaults(e);
    dragChange(e);
  };

  return (
    <Box
      className={classes.root}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
    >
      {dragWithinDrop ? (
        <Box ref={dragPlaceholder} className={classes.dragCommunicate}>
          <Typography variant="h3" color="primary">
            <strong>Drop file here</strong>
          </Typography>
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};
