import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const tableWidth = 800;
const textHeight = 24.5;
const arrowHeight = 42;

export const useStyles = makeStyles()((theme) => ({
  root: {
    width: tableWidth,
    transition: '0.3s',
    border: `1px solid ${theme.palette.primary.main}`,
    margin: 'auto',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    padding: '10px 8px 10px 16px',
    '& .MuiTableRow-root td': {
      borderBottom: 0,
    },
    '&.unfolded': {
      boxShadow: `0px 7px 10px 0px ${lighten(theme.palette.primary.main, 0.8)}`,
    },
    '& .hidden': {
      overflow: 'hidden',
      display: 'block',
      transition: '0.3s',
      height: 0,
    },
    '& .MuiTableCell-root.firstRow': {
      transition: 'height 0.3s',
      height: `${64 - textHeight}px !important`,
    },
    '& .arrowWrapper': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 0,
      overflow: 'hidden',
      width: '100%',
      cursor: 'pointer',
      transition: '0.3s',
    },
    '&:hover, &.unfolded': {
      padding: `10px 8px ${arrowHeight}px 16px`,
      '& .arrowWrapper': {
        height: arrowHeight,
      },
      '& .hidden': {
        height: textHeight,
      },
      '& .MuiTableCell-root.firstRow': {
        height: '64px !important',
      },
    },
    '&.disableFold': {
      padding: `10px 8px 10px 16px`,
      '& .hidden': {
        height: textHeight,
      },
      '& .MuiTableCell-root.firstRow': {
        height: '64px !important',
      },
    },
  },
  arrowIcon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 4,
  },
  dashedDivider: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: tableWidth - 40,
    height: 0,
    zIndex: 100,
    borderBottom: `2px dashed ${lighten(theme.palette.primary.main, 0.5)}`,
  },
  tHeadRow: {
    '& .divider, & .marker': {
      height: '50%',
      width: '100%',
      position: 'absolute',
      bottom: 0,
      zIndex: 1,
    },
    '& > th': {
      height: 'auto',
      borderBottomWidth: 0,
      '& .divider': {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
      '&:nth-of-type(2) .divider': {
        width: '50%',
        right: 0,
      },
      '&:last-of-type .divider': {
        width: '50%',
        left: 0,
      },
    },
    '& .marker': {
      bottom: -1,
      zIndex: 10,
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
    },
  },
  radioTCell: {
    position: 'relative',
    '&.checked': {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      borderRadius: '4px 4px 0px 0px',
    },
  },
  radio: {
    zIndex: 100,
  },
  tcell: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    color: theme.palette.text.primary,
    height: theme.spacing(9),
    fontWeight: 400,
    position: 'relative',
    '&.title': {
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.primary,
    },
    '&.checked': {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      fontWeight: 500,
      color: theme.palette.text.primary,
      borderRadius: '0px 0px 4px 4px',
      '&.unfolded': {
        borderRadius: 0,
      },
    },
  },
}));
