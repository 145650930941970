import axios from 'axios';
import { getToken } from 'src/auth';
import { MarketUpdate } from 'src/components/pages/MarketUpdatesPage/MarketUpdate';
import { MARKET_UPDATES_LAMBDA_URL } from './lambda-routes';

export const postMarketUpdate = async (marketUpdate: MarketUpdate) => {
  const options = {
    headers: {
      Authorization: await getToken(),
      'Content-Type': 'application/json',
    },
  };

  const response = await axios
    .post(
      `${MARKET_UPDATES_LAMBDA_URL}/marketUpdate`,
      { marketUpdate },
      options,
    )
    .catch((error) => {
      throw new Error(error.response?.data?.error ?? 'Error');
    });

  return response.data;
};
