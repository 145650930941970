import {
  Box,
  Button,
  Container,
  DialogActions,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useStyles } from './styles';

interface Props {
  name: string;
  password: string;
  onSubmit?: () => void;
}

export const TemporaryPasswordDialog: FC<Props> = ({
  name,
  password,
  onSubmit,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <Box py={10} px="80px">
        <Typography>
          You’ve just reset the password for <b>{name}</b>. The temporary
          password for this account is:
        </Typography>
        <Container className={classes.passwordContainer}>
          <Typography className={classes.password}>{password}</Typography>
        </Container>
        <Typography>
          You can now copy the password and securely relay the information to
          the user, so that they can login again and setup their new password.
        </Typography>
      </Box>
      <DialogActions>
        <Container>
          <Button
            size="small"
            onClick={() => {
              onSubmit?.();
            }}
          >
            Copy password & close
          </Button>
        </Container>
      </DialogActions>
    </>
  );
};
