import { FocusEvent, MouseEvent } from 'react';

const globalMouseUp = (ev: Event) => {
  ev.preventDefault();
  window.removeEventListener('mouseup', globalMouseUp, { capture: true });
};

const handleFocus = (ev: FocusEvent<HTMLInputElement>): void => {
  const target = ev.currentTarget;
  Promise.resolve().then(() => target.select());
};

const handleMouseDown = (ev: MouseEvent<HTMLInputElement>): void => {
  if (ev.currentTarget !== document.activeElement) {
    window.addEventListener('mouseup', globalMouseUp, { capture: true });
  }
};

export const selectOnFocus = {
  onFocus: handleFocus,
  onMouseDown: handleMouseDown,
  pattern: '[0-9]*',
};
