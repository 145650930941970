import { createIcon } from '../createIcon';

export const ArrowRise = createIcon({
  svg: () => (
    <path
      d="M7.44637 16V2.13149L0.775086 8.80277L0 8L8 0L16 8L15.2249 8.80277L8.55363 2.13149V16H7.44637Z"
      fill="currentColor"
    />
  ),
  fontSize: 'inherit',
  viewBox: '0 0 16 16',
});
