import { createIcon } from '../createIcon';

export const JourneyIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path d="M11 17H5V16H11V17Z" fill="currentColor" />
      <path d="M14 11H5V10H14V11Z" fill="currentColor" />
      <path d="M14 8H5V7H14V8Z" fill="currentColor" />
      <path d="M14 13H5V14H14V13Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4625 19.5375C2.77083 19.8458 3.15513 20 3.61538 20H15.3846C15.8449 20 16.2292 19.8458 16.5375 19.5375C16.8458 19.2292 17 18.8449 17 18.3846V5.61537C17 5.15512 16.8458 4.77083 16.5375 4.4625C16.2292 4.15417 15.8449 4 15.3846 4L3.61538 4C3.15513 4 2.77083 4.15417 2.4625 4.4625C2.15417 4.77083 2 5.15512 2 5.61537V18.3846C2 18.8449 2.15417 19.2292 2.4625 19.5375ZM15.3846 19H3.61538C3.46154 19 3.32052 18.9359 3.1923 18.8077C3.0641 18.6795 3 18.5385 3 18.3846V5.61537C3 5.46154 3.0641 5.32052 3.1923 5.1923C3.32052 5.0641 3.46154 5 3.61538 5H15.3846C15.5385 5 15.6795 5.0641 15.8077 5.1923C15.9359 5.32052 16 5.46154 16 5.61537V18.3846C16 18.5385 15.9359 18.6795 15.8077 18.8077C15.6795 18.9359 15.5385 19 15.3846 19Z"
        fill="currentColor"
      />
      <path
        d="M16 19H17.3846C17.8449 19 18.2292 18.8458 18.5375 18.5375C18.8458 18.2292 19 17.8449 19 17.3846V6.61537C19 6.15512 18.8458 5.77083 18.5375 5.4625C18.2292 5.15417 17.8449 5 17.3846 5H16V6H17.3846C17.5385 6 17.6795 6.0641 17.8077 6.1923C17.9359 6.32052 18 6.46154 18 6.61537V17.3846C18 17.5385 17.9359 17.6795 17.8077 17.8077C17.6795 17.9359 17.5385 18 17.3846 18H16V19Z"
        fill="currentColor"
      />
      <path
        d="M18 18H19.3846C19.8449 18 20.2292 17.8458 20.5375 17.5375C20.8458 17.2292 21 16.8449 21 16.3846V7.61537C21 7.15512 20.8458 6.77083 20.5375 6.4625C20.2292 6.15417 19.8449 6 19.3846 6H18V7H19.3846C19.5385 7 19.6795 7.0641 19.8077 7.1923C19.9359 7.32052 20 7.46154 20 7.61537V16.3846C20 16.5385 19.9359 16.6795 19.8077 16.8077C19.6795 16.9359 19.5385 17 19.3846 17H18V18Z"
        fill="currentColor"
      />
    </g>
  ),
  fontSize: 'medium',
  viewBox: '0 0 24 24',
});
