import { ChangeEvent, FC } from 'react';
import { TextField, Typography } from '@mui/material';

import { useStyles } from './styles';

interface Props {
  name: string;
  label: string;
  value: string;
  rows: number;
  maxCharLength: number;
  charLimit?: number;
  tip?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  multiline?: boolean;
  width?: string;
}

export const Textarea: FC<Props> = ({
  name,
  label,
  value,
  rows,
  maxCharLength,
  charLimit,
  tip,
  disabled,
  error,
  helperText,
  width,
  onChange,
  onBlur,
  multiline = true,
}) => {
  const { classes } = useStyles();

  const currCharLength = value.length;

  const handleNoteChange = (ev: ChangeEvent<HTMLInputElement>) => {
    onChange(ev);
  };
  const exceedMaxCharLimit = currCharLength > maxCharLength;

  return (
    <>
      <div className={classes.textWrapper} style={{ width }}>
        <Typography align="left" variant="h1">
          {label}
        </Typography>
        <Typography
          align="right"
          color={exceedMaxCharLimit ? 'error' : 'textPrimary'}
          variant="body2"
          className={classes.charCounter}
        >
          {tip && `${tip}, `}
          {currCharLength}/{maxCharLength}
        </Typography>
      </div>
      <TextField
        name={name}
        className={classes.textarea}
        value={value}
        minRows={rows}
        maxRows={rows}
        onChange={handleNoteChange}
        onBlur={onBlur}
        disabled={disabled}
        error={error}
        helperText={helperText}
        inputProps={{
          maxLength: charLimit,
          style: {
            padding: multiline ? 0 : 24,
          },
        }}
        InputProps={{ style: { width } }}
        multiline={multiline}
        fullWidth={!width}
      />
    </>
  );
};
