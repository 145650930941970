import { SvgIcon } from '@mui/material';
import { FC } from 'react';

export const CancelIcon: FC = () => (
  <SvgIcon viewBox="0 0 16 16">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.26647 12.2L3.7998 11.7334L7.53314 8.00005L3.7998 4.26672L4.26647 3.80005L7.9998 7.53338L11.7331 3.80005L12.1998 4.26672L8.46647 8.00005L12.1998 11.7334L11.7331 12.2L7.9998 8.46672L4.26647 12.2Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
