import { createIcon } from '../createIcon';

export const AnalyticsIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        d="M11.5 8.5H3.5C2.39543 8.5 1.5 9.39543 1.5 10.5V27.5C1.5 28.6046 2.39543 29.5 3.5 29.5H21.5C22.6046 29.5 23.5 28.6046 23.5 27.5V16.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 6.5C13.5 6.22386 13.7239 6 14 6H19.5C21.433 6 23 7.567 23 9.5V13.5C23 13.7761 22.7761 14 22.5 14C22.2239 14 22 13.7761 22 13.5V9.5C22 8.11929 20.8807 7 19.5 7H14C13.7239 7 13.5 6.77614 13.5 6.5Z"
        fill="currentColor"
      />
      <circle cx="5.5" cy="17.5" r="1" stroke="currentColor" />
      <circle cx="19.5" cy="23.5" r="1" stroke="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 8.5C25 11.5376 22.5376 14 19.5 14C16.4624 14 14 11.5376 14 8.5C14 5.46243 16.4624 3 19.5 3C22.5376 3 25 5.46243 25 8.5ZM23.162 13.871C22.1192 14.5834 20.8582 15 19.5 15C15.9101 15 13 12.0899 13 8.5C13 4.91015 15.9101 2 19.5 2C23.0899 2 26 4.91015 26 8.5C26 10.3699 25.2104 12.0554 23.9464 13.2412L29.0587 18.3536C29.1525 18.4473 29.2797 18.5 29.4123 18.5H29.4981C29.7742 18.5 29.9981 18.2761 29.9981 18V17.9142C29.9981 17.7816 29.9454 17.6544 29.8516 17.5607L25.6445 13.3536C25.4493 13.1583 25.4493 12.8417 25.6445 12.6464C25.8398 12.4512 26.1564 12.4512 26.3516 12.6464L30.5587 16.8536C30.84 17.1349 30.9981 17.5164 30.9981 17.9142V18C30.9981 18.8284 30.3265 19.5 29.4981 19.5H29.4123C29.0145 19.5 28.6329 19.342 28.3516 19.0607L23.162 13.871Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85355 12.5605C9.65829 12.3652 9.34171 12.3652 9.14645 12.5605C9.05268 12.6543 9 12.7815 9 12.9141V16.4998C9 17.3283 8.32843 17.9998 7.5 17.9998H6.5V16.9998H7.5C7.77614 16.9998 8 16.776 8 16.4998V12.9141C8 12.5162 8.15804 12.1347 8.43934 11.8534C9.02513 11.2676 9.97487 11.2676 10.5607 11.8534C10.842 12.1347 11 12.5162 11 12.9141V26.0856C11 26.2182 11.0527 26.3454 11.1464 26.4392C11.3417 26.6345 11.6583 26.6345 11.8536 26.4392C11.9473 26.3454 12 26.2182 12 26.0856V17.9141C12 17.5162 12.158 17.1347 12.4393 16.8534C13.0251 16.2676 13.9749 16.2676 14.5607 16.8534C14.842 17.1347 15 17.5162 15 17.9141V22.4998C15 22.776 15.2239 22.9998 15.5 22.9998H18.5V23.9998H15.5C14.6716 23.9998 14 23.3283 14 22.4998V17.9141C14 17.7815 13.9473 17.6543 13.8536 17.5605C13.6583 17.3652 13.3417 17.3652 13.1464 17.5605C13.0527 17.6543 13 17.7815 13 17.9141V26.0856C13 26.4835 12.842 26.865 12.5607 27.1463C11.9749 27.7321 11.0251 27.7321 10.4393 27.1463C10.158 26.865 10 26.4835 10 26.0856V12.9141C10 12.7815 9.94732 12.6543 9.85355 12.5605Z"
        fill="currentColor"
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
