import { createIcon } from '../createIcon';

export const NotesIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="1.3"
        d="M25.273 13.41V4c0-1.657-1.343-3-3-3H4C2.343 1 1 2.343 1 4v23.128c0 1.657 1.343 3 3 3h9.137"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.3"
        d="M5.854 9.088L20.418 9.088M5.854 13.943L20.418 13.943M5.854 18.798L13.622 18.798M30.48 19.341L20.257 29.565l-3.795.595.595-3.795L27.28 16.14c.884-.883 2.317-.883 3.2 0 .884.884.884 2.317 0 3.2zM26.212 17.664L28.958 20.41"
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 33 33',
});
