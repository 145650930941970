import { makeVar, useReactiveVar } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { useMemo } from 'react';

import { CognitoGroup } from 'src/apollo';

import { identifyUser, trackSignOut } from 'src/utils';
import {
  clearUnreadNotificationsIndicator,
  destroyCompany,
  initCompany,
  resetLogo,
} from 'src/hooks';
import { Challenge } from './Challenge';
import { User } from './User';

export interface Attributes {
  groups: CognitoGroup[];
  name: string;
  firstname?: string;
  lastname?: string;
  marketsEmailsOptIn?: boolean;
  id?: string;
}

export const getCognitoGroups = (user: User) => {
  const session = user.getSignInUserSession();
  const token = session?.getAccessToken();
  return (token?.payload['cognito:groups'] ?? []) as CognitoGroup[];
};

const isUserLoggedIn = (user?: User | null): user is User => {
  return !!user && !user.challengeParam;
};

/*
 undefined = not determined
 null = no user authenticated
 */
const userVar = makeVar<User | null | undefined>(undefined);
let timer: number;

const changeName = async (firstname?: string, lastname?: string) => {
  const currentUser = userVar();
  if (!isUserLoggedIn(currentUser)) return;
  const newAttributes = {
    'custom:firstname': firstname,
    'custom:lastname': lastname,
  };
  await Auth.updateUserAttributes(currentUser, newAttributes);
  Object.assign(currentUser, {
    attributes: { ...currentUser.attributes, ...newAttributes },
  });
  userVar(currentUser);
};

const signOut = async () => {
  await Auth.signOut().catch(() => {});
  trackSignOut(); // TODO: It actually signs out 4 times, figure out why
  userVar(null);
  destroyCompany();
  resetLogo();
  clearUnreadNotificationsIndicator();
  clearTimeout(timer);
};

const checkUserSession = async (callback: (user: User | null) => void) => {
  try {
    await Auth.currentSession();
    const user = await Auth.currentAuthenticatedUser();
    callback(user);
  } catch (e) {
    await signOut();
  }
};

const runSessionTimeout = async (user: User | null) => {
  if (user?.signInUserSession) {
    const expTime = user.signInUserSession.accessToken.payload.exp;
    const time = expTime * 1000 - new Date().getTime();
    clearTimeout(timer);
    timer = window.setTimeout(() => {
      checkUserSession(runSessionTimeout);
    }, time);
  }
};

const init = async (userObj?: User) => {
  let user: User | null;
  try {
    user = await Auth.currentAuthenticatedUser();
  } catch {
    user = userObj ?? null;
  }
  userVar(user);
  if (user) {
    identifyUser(user);
    runSessionTimeout(user);
  }
};

const signIn = async (username: string, password: string) => {
  const user: User = await Auth.signIn({ username, password });

  userVar(user);
  identifyUser(user);
  runSessionTimeout(user);
  await initCompany();
  return user;
};

const confirmSignInWithMFA = async (code: string) => {
  try {
    await Auth.confirmSignIn(userVar(), code);
    await init();
    await initCompany();
  } catch (e) {
    if ((e as { code?: string })?.code === 'NotAuthorizedException') {
      userVar(null);
      destroyCompany();
    }
    throw e;
  }
};

const forgotPassword = async (username: string) => {
  await Auth.forgotPassword(username);
};

const forgotPasswordSubmit = async (
  username: string,
  code: string,
  password: string,
) => {
  await Auth.forgotPasswordSubmit(username, code, password);
  await signIn(username, password).catch(() => {});
  await initCompany();
};

const setFirstPassword = async (password: string) => {
  let user: User;
  try {
    user = await Auth.completeNewPassword(userVar(), password);
    await init(user);
    await initCompany();
  } catch (e) {
    if ((e as { code?: string })?.code === 'NotAuthorizedException') {
      userVar(null);
    }
    throw e;
  }
  return user;
};

const updateMarketsEmailsOptInValue = async (shouldReceive: boolean) => {
  const user = await Auth.currentAuthenticatedUser();
  user.attributes['custom:marketsEmailsOptIn'] = shouldReceive.toString();
  await Auth.updateUserAttributes(user, user.attributes);
};

interface UseAuth {
  changeName: (firstname?: string, lastname?: string) => Promise<void>;
  confirmSignInWithMFA: (code: string) => Promise<void>;
  forgotPassword: (username: string) => Promise<void>;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    password: string,
  ) => Promise<void>;
  init: () => Promise<void>;
  signIn: (username: string, password: string) => Promise<User>;
  signOut: () => Promise<void>;
  setFirstPassword: (password: string) => Promise<User>;
  attributes?: Attributes;
  isLoggedIn: boolean;
  maskedPhoneNumber?: string;
  newPasswordRequired: boolean;
  tfaRequired: boolean;
  updateMarketsEmailsOptInValue: (shouldReceive: boolean) => Promise<void>;
}

export const getCurrentUser = () => {
  return userVar();
};

export const useAuth = (): UseAuth => {
  const currentUser = useReactiveVar(userVar);

  const newPasswordRequired =
    currentUser?.challengeName === Challenge.NewPasswordRequired;
  const tfaRequired = currentUser?.challengeName === Challenge.SmsMfa;
  const isLoggedIn = isUserLoggedIn(currentUser);
  const maskedPhoneNumber =
    currentUser?.challengeParam?.CODE_DELIVERY_DESTINATION?.replace('+', '');

  const username = useMemo(() => {
    const { attributes } = currentUser ?? {};
    if (
      attributes?.['custom:firstname'] ||
      attributes?.['custom:name'] ||
      attributes?.['custom:lastname']
    ) {
      return [
        attributes['custom:firstname'],
        attributes['custom:name'],
        attributes['custom:lastname'],
      ]
        .filter((val): val is string => !!val)
        .join(' ');
    }
    return '';
  }, [currentUser]);

  const attributes: Attributes | undefined = useMemo(
    () =>
      isUserLoggedIn(currentUser)
        ? {
            id: currentUser.attributes.sub,
            email: currentUser.attributes.email,
            groups: getCognitoGroups(currentUser),
            name: username,
            firstname: currentUser.attributes['custom:firstname'],
            lastname: currentUser.attributes['custom:lastname'],
            marketsEmailsOptIn:
              currentUser.attributes['custom:marketsEmailsOptIn'] === 'true',
          }
        : undefined,
    [currentUser, username],
  );

  return {
    attributes,
    changeName,
    confirmSignInWithMFA,
    forgotPassword,
    forgotPasswordSubmit,
    isLoggedIn,
    init,
    maskedPhoneNumber,
    newPasswordRequired,
    tfaRequired,
    setFirstPassword,
    signIn,
    signOut,
    updateMarketsEmailsOptInValue,
  };
};

export const getToken = async (soft?: boolean) => {
  if (!userVar()) {
    return '';
  }

  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (e) {
    if (soft) {
      console.log(e);
      return '';
    }
    await signOut();
    throw e;
  }
};
