interface PasswordValidationDetails {
  lowercase: boolean;
  uppercase: boolean;
  numerical: boolean;
  symbol: boolean;
  minLength: boolean;
}

export interface PasswordValidation {
  details: PasswordValidationDetails;
  isPasswordValid: boolean;
}

// password must contain at least 8 characters!
const passLengthValidate = (password: string): boolean => {
  return password?.length >= 8;
};

// password must contain at least one number (0-9)!
const passwordNumericValidate = (password: string): boolean => {
  const re = /[0-9]/;

  return re.test(password);
};

// password must contain at least one lowercase letter (a-z)!
const passLowercaseValidate = (password: string): boolean => {
  const re = /[a-z]/;

  return re.test(password);
};

// password must contain at least one uppercase letter (A-Z)!
const passUpperCaseValidate = (password: string): boolean => {
  const re = /[A-Z]/;

  return re.test(password);
};

// password must contain at least one special character
const passSymbolValidate = (password: string): boolean => {
  const re = /[-!$%^&*()_+|~=#\\`{}@[\]:";'<>?,./]/;

  return re.test(password);
};

export function validatePassword(password: string): PasswordValidation {
  const lowercase = passLowercaseValidate(password);
  const uppercase = passUpperCaseValidate(password);
  const numerical = passwordNumericValidate(password);
  const symbol = passSymbolValidate(password);
  const minLength = passLengthValidate(password);

  const isPasswordValid =
    !password || (lowercase && uppercase && numerical && symbol && minLength);

  return {
    details: {
      lowercase,
      uppercase,
      numerical,
      symbol,
      minLength,
    },
    isPasswordValid,
  };
}

export function validateEmail(email: string): string | null {
  if (!email) {
    return '';
  }
  return /^\S+@\S+\.\S+$/.test(email) ? null : 'Invalid e-mail';
}

export function validateRequired(value: string = ''): string | null {
  return value ? null : '';
}
