import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

export const useStyles = makeStyles()((theme) => ({
  passwordContainer: {
    background: alpha(theme.palette.primary.main, 0.1),
    padding: '10px 0',
    marginTop: 24,
    marginBottom: 24,
    maxWidth: '180px !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 8,
  },
  password: {
    fontWeight: 600,
    fontSize: 16,
  },
}));
