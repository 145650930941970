import { ElementType, FC, ReactElement } from 'react';

import {
  Enum_ConfidenceComposure,
  Enum_Income_WithdrawalType,
  Enum_KnowledgeExperience_Duration,
  Enum_KnowledgeExperience_TypeAsset,
  Enum_KnowledgeExperience_TypeService,
  Enum_PastExperience,
  Enum_PortfolioCost,
  Enum_PortfolioSelection,
  Enum_PortfolioStyle,
  Enum_PortfolioType,
  Enum_ReasonsInvesting_RetirementPlanning,
  Enum_ReasonsInvesting_SaveAndInvest,
  Enum_ReasonsInvesting_SelectedName,
  Enum_ReasonsInvesting_SupplementaryIncome,
  Enum_ReasonsInvesting_TargetInvesting,
  Enum_ResponsibleInvesting_Question1,
  Enum_ResponsibleInvesting_Question2,
  Enum_RiskCapacity_Question1,
  Enum_RiskCapacity_Question2,
} from 'src/apollo';

/**
 * I know, the imports can be shortened, but if one does that,
 * the app crushes due to undefined (icon not found) error...
 * and I don't know why.
 *
 * Exemplary error:
 * Uncaught TypeError: Cannot read properties of undefined (reading 'CapitalIcon')
 */
import { AdHocIcon } from '../components/ui/icons/journey/AdHocIcon';
import { CapitalIcon } from '../components/ui/icons/journey/CapitalIcon';
import { GrowthIcon } from '../components/ui/icons/journey/GrowthIcon';
import { ImpactIcon } from '../components/ui/icons/journey/ImpactIcon';
import { IncomeIcon } from '../components/ui/icons/journey/IncomeIcon';
import { PassiveIcon } from '../components/ui/icons/journey/PassiveIcon';
import { SatelliteCircledIcon } from '../components/ui/icons/journey/SatelliteCircledIcon';

export const portfolioTypeOptions: Record<
  Enum_PortfolioType,
  { title: string; description: string }
> = {
  [Enum_PortfolioType.IndividualAccount]: {
    title: 'Individual Account',
    description: 'For general investment accounts (GIA) or joint accounts',
  },
  [Enum_PortfolioType.JointAccount]: {
    title: 'Pension',
    description: 'For SIPPs and other Personal Pension accounts',
  },
  [Enum_PortfolioType.Trust]: {
    title: 'Trust',
    description: 'For a range of Trust structures or equivalent',
  },
  [Enum_PortfolioType.Company]: {
    title: 'Company',
    description: 'For corporate accounts, partnerships or equivalent',
  },
  [Enum_PortfolioType.Isa]: {
    title: 'ISA',
    description: 'For tax wrapped savings accounts',
  },
  [Enum_PortfolioType.Other]: {
    title: 'Other',
    description: 'E.g. Offshore bond',
  },
};

export const reasonsInvestingOptions: Record<
  Enum_ReasonsInvesting_SelectedName,
  {
    title: string;
    description: string;
    options: Record<
      string,
      {
        title: string;
        description: string;
        icon: ElementType<{ className?: string }>;
      }
    >;
  }
> = {
  [Enum_ReasonsInvesting_SelectedName.SaveAndInvest]: {
    title: 'Grow Wealth',
    description: 'Investing for the long-term to grow the real value of wealth',
    options: {
      [Enum_ReasonsInvesting_SaveAndInvest.CapitalGrowth]: {
        title: 'Capital Growth',
        description: 'Investing for long-term capital growth.',
        icon: CapitalIcon,
      },
      [Enum_ReasonsInvesting_SaveAndInvest.IncomeAndGrowth]: {
        title: 'Income & Growth',
        description:
          'Achieving a blend of income generation and capital growth.',
        icon: GrowthIcon,
      },
      [Enum_ReasonsInvesting_SaveAndInvest.Incomeprovision]: {
        title: 'Income Provision',
        description: 'Investing for a reliable income stream.',
        icon: IncomeIcon,
      },
    },
  },
  [Enum_ReasonsInvesting_SelectedName.RetirementPlanning]: {
    title: 'Retirement Planning',
    description:
      'Investing for future retirement or in drawdown/decumulation phase',
    options: {
      [Enum_ReasonsInvesting_RetirementPlanning.AccumulationPhase]: {
        title: 'Accumulation Phase',
        description: 'Investing to grow pension pot over the long-term.',
        icon: CapitalIcon,
      },
      [Enum_ReasonsInvesting_RetirementPlanning.LifestylePhase]: {
        title: 'Life-style Phase',
        description: 'Progressive de-risking until retirement.',
        icon: GrowthIcon,
      },
      [Enum_ReasonsInvesting_RetirementPlanning.DrawdownPhase]: {
        title: 'Drawdown/Decumulation Phase',
        description: 'Drawing down income or capital from pension pot.',
        icon: IncomeIcon,
      },
    },
  },
  [Enum_ReasonsInvesting_SelectedName.TargetInvesting]: {
    title: 'Targeted Investing',
    description:
      'Investing for a future spend (e.g. house purchase, school fees)',
    options: {
      [Enum_ReasonsInvesting_TargetInvesting.AccumulationPhase]: {
        title: 'Accumulation',
        description: 'Investing to grow assets for a future goal.',
        icon: CapitalIcon,
      },
      [Enum_ReasonsInvesting_TargetInvesting.Preservation]: {
        title: 'Preservation',
        description: 'Safeguarding assets, whilst still generating a return.',
        icon: GrowthIcon,
      },
      [Enum_ReasonsInvesting_TargetInvesting.IncomeWithdrawal]: {
        title: 'Income/Withdrawal',
        description: 'Regular or fixed withdrawals are required.',
        icon: IncomeIcon,
      },
    },
  },
  [Enum_ReasonsInvesting_SelectedName.SupplementaryIncome]: {
    title: 'Supplementary Income',
    description: 'Investing for a reliable income stream',
    options: {
      [Enum_ReasonsInvesting_SupplementaryIncome.AdHocIncome]: {
        title: 'Ad hoc Income',
        description:
          'Income/withdrawals from portfolio are likely to be required on an ad hoc basis.',
        icon: AdHocIcon,
      },
      [Enum_ReasonsInvesting_SupplementaryIncome.IncomeAndGrowth]: {
        title: 'Income & Growth',
        description:
          'A balance of income provision and capital growth is preferred.',
        icon: GrowthIcon,
      },
      [Enum_ReasonsInvesting_SupplementaryIncome.RegularIncome]: {
        title: 'Regular Income',
        description:
          'Regular income/withdrawals are a key requirement from the portfolio.',
        icon: IncomeIcon,
      },
    },
  },
};

export const incomeRequirementOptions: Record<
  Enum_Income_WithdrawalType,
  {
    title: string;
    description: string;
  }
> = {
  [Enum_Income_WithdrawalType.Capital]: {
    title: 'Sell down Capital',
    description: 'Periodically sell capital, as required.',
  },
  [Enum_Income_WithdrawalType.Income]: {
    title: 'Natural Income',
    description: 'Receive income (e.g. dividends) from portfolio.',
  },
};

export const portfolioCostOptions: Record<
  Enum_PortfolioCost,
  {
    title: string;
    description: string;
  }
> = {
  [Enum_PortfolioCost.CostConsidered]: {
    title: 'Cost Considered',
    description:
      'Having the right blend of funds to meet requirements is more important than focusing on the lowest cost investments.',
  },
  [Enum_PortfolioCost.CostFocus]: {
    title: 'Cost Focus',
    description:
      'A focus on low cost/passive funds is important for this investment portfolio.',
  },
};

export const serviceOptions: Record<
  Enum_KnowledgeExperience_TypeService,
  string
> = {
  [Enum_KnowledgeExperience_TypeService.DiscretionaryManagment]:
    'Discretionary Management',
  [Enum_KnowledgeExperience_TypeService.Advisory]: 'Advisory',
  [Enum_KnowledgeExperience_TypeService.ExecutionOnlySelfTrade]:
    'Execution Only/Self Trade',
};
export const assetOptions: Record<
  Enum_KnowledgeExperience_TypeAsset,
  { title: string; description?: string }
> = {
  [Enum_KnowledgeExperience_TypeAsset.SingleStocksBonds]: {
    title: 'Single stocks/bonds',
    description: '(e.g. Barclays, Amazon)',
  },
  [Enum_KnowledgeExperience_TypeAsset.Commodities]: {
    title: 'Commodities',
    description: '(e.g. Gold, Oil)',
  },
  [Enum_KnowledgeExperience_TypeAsset.CollectiveFunds]: {
    title: 'Collective funds',
    description: '(e.g. ETF, OEIC, Investment Trust)',
  },
  [Enum_KnowledgeExperience_TypeAsset.StructuredProducts]: {
    title: 'Structured Products',
  },
  [Enum_KnowledgeExperience_TypeAsset.AbsoluteReturnHedgeFund]: {
    title: 'Absolute return/hedge fund',
  },
  [Enum_KnowledgeExperience_TypeAsset.PrivateEquity]: {
    title: 'Private Equity',
  },
};
export const durationOptions: Record<
  Enum_KnowledgeExperience_Duration,
  { title: string; description: string }
> = {
  [Enum_KnowledgeExperience_Duration.Short]: {
    title: 'Short',
    description: '(0-6 Years)',
  },
  [Enum_KnowledgeExperience_Duration.Med]: {
    title: 'Med',
    description: '(6-10 Years)',
  },
  [Enum_KnowledgeExperience_Duration.Long]: {
    title: 'Long',
    description: '(10+ Years)',
  },
};
export const noInvestmentText =
  'No investment service or asset/product experience.';

export const responsibleInvestingOptions: {
  description: string;
  question1: {
    description: string;
    options: Record<Enum_ResponsibleInvesting_Question1, string>;
  };
  question2: {
    description: string;
    options: Record<Enum_ResponsibleInvesting_Question2, string>;
  };
} = {
  description:
    'In recent years there has been a growing desire from investors to integrate environmental, social, and governance (ESG) factors into their investment portfolio.',
  question1: {
    description:
      'Would you like to take ESG and sustainability factors into account within your investment portfolio?',
    options: {
      [Enum_ResponsibleInvesting_Question1.NotImportant]:
        'No, these factors are not important for my investments.',
      [Enum_ResponsibleInvesting_Question1.SomeConsideration]:
        'I would like some consideration given to these factors in this investment portfolio.',
      [Enum_ResponsibleInvesting_Question1.FactorsCoreElement]:
        'I would like these factors to be a core element of this investment portfolio.',
    },
  },
  question2: {
    description:
      'Investing with exclusions or specific requirements can carry with it limitations and reduce portfolio diversification. Which of the following best reflects your views?',
    options: {
      [Enum_ResponsibleInvesting_Question2.NoSpecificRequirements]:
        'I have no specific requirements with regard to responsible investing.',
      [Enum_ResponsibleInvesting_Question2.InvestmentFlexibility]:
        'I am happy to forgo some investment flexibility, in order to have a more responsible portfolio.',
      [Enum_ResponsibleInvesting_Question2.ResponsibleInvesting]:
        'Investing responsibly is important to me, even if it means having limitations on the investable universe available.',
    },
  },
};

export const riskProfileOptions = {
  q1: {
    question:
      'Where do you see yourself with regard to taking investment risk?',
  },
  q2: {
    question:
      'What is your tolerance for capital loss in the short-term (12 months)?',
  },
  q3: {
    question:
      'You would be prepared to take higher investment risks, if there is a chance of making better long-term returns.',
  },
  q4: {
    question:
      'You would accept potential losses on this investment portfolio in order to pursue long-term growth.',
  },
  q5: {
    description:
      'Imagine that your investments have suffered substantial losses in the current year, like we saw in early 2020 (coronavirus pandemic) or 2008 (financial crisis).',
    question: 'What would you do?',
    options: {
      [Enum_ConfidenceComposure.ImmediatelySwitch]:
        'Immediately switch into a more conservative strategy.',
      [Enum_ConfidenceComposure.WaitSixMonth]:
        'Wait six months and, if markets continue to fall, switch to a more conservative strategy.',
      [Enum_ConfidenceComposure.Stay]:
        'Stay with the current strategy (or even switch into a more aggressive one).',
    },
  },
  q6: {
    question:
      'How did the experience of financial losses affect you in the past?',
    options: {
      [Enum_PastExperience.UncomfortableWithLoseEverything]:
        'I felt uncomfortable with the losses and was afraid that I might lose everything.',
      [Enum_PastExperience.UncomfortableWithoutFeelRisk]:
        'I felt uncomfortable with the losses, but I did not feel that all my investments were at risk.',
      [Enum_PastExperience.NotEmotionally]:
        'I was not emotionally affected by the losses.',
      [Enum_PastExperience.None]:
        'None of the above/I have no past experiences.',
    },
  },
  q7: {
    question:
      'How much of this investment could you stand to lose without having a significant impact on your future standard of living?',
    options: {
      [Enum_RiskCapacity_Question1.None]: 'None/Very limited losses (0-5%)*.',
      [Enum_RiskCapacity_Question1.Small]:
        'Small/Medium losses (up to 20%)* could be tolerated.',
      [Enum_RiskCapacity_Question1.Large]:
        'Large losses would have a low impact on your future standard of living.',
    },
  },
  q8: {
    question:
      'If you needed sudden access to a lump sum, how likely is it that you would need to encash this investment?',
    options: {
      [Enum_RiskCapacity_Question2.AlmostCertainly]:
        'I would almost certainly need access to this investment.',
      [Enum_RiskCapacity_Question2.Maybe]:
        'I may need this investment, if I needed access to a significant amount of money quickly.',
      [Enum_RiskCapacity_Question2.OtherSavings]:
        'I have other savings and investments, which I can use for most needs.',
    },
  },
};

export const agreeOptions = [
  { value: 1, label: <>Disagree</> },
  {
    value: 2,
    label: (
      <>
        Somewhat
        <br />
        disagree
      </>
    ),
  },
  {
    value: 3,
    label: (
      <>
        Neither agree or
        <br />
        disagree
      </>
    ),
  },
  { value: 4, label: <>Somewhat agree</> },
  { value: 5, label: <>Agree</> },
];

export const riskNumber: Record<Enum_PortfolioSelection, number> = {
  [Enum_PortfolioSelection.Low]: 1,
  [Enum_PortfolioSelection.Lower]: 2,
  [Enum_PortfolioSelection.Medium]: 3,
  [Enum_PortfolioSelection.Higher]: 4,
  [Enum_PortfolioSelection.High]: 5,
};

export const riskEnum: Record<number, Enum_PortfolioSelection> = {
  1: Enum_PortfolioSelection.Low,
  2: Enum_PortfolioSelection.Lower,
  3: Enum_PortfolioSelection.Medium,
  4: Enum_PortfolioSelection.Higher,
  5: Enum_PortfolioSelection.High,
};

export const riskRange: Record<Enum_PortfolioSelection, string> = {
  [Enum_PortfolioSelection.Low]: '10% - 30%',
  [Enum_PortfolioSelection.Lower]: '30% - 50%',
  [Enum_PortfolioSelection.Medium]: '50% - 70%',
  [Enum_PortfolioSelection.Higher]: '70% - 90%',
  [Enum_PortfolioSelection.High]: '90% - 100%',
};

export const riskOptions: {
  value: Enum_PortfolioSelection;
  label: ReactElement;
}[] = [
  {
    value: Enum_PortfolioSelection.Low,
    label: (
      <>
        <b>Low</b>
        <br />
        {'(20%\xa0equity)'}
      </>
    ),
  },
  {
    value: Enum_PortfolioSelection.Lower,
    label: (
      <>
        <b>Low-Medium</b>
        <br />
        {'(40%\xa0equity)'}
      </>
    ),
  },
  {
    value: Enum_PortfolioSelection.Medium,
    label: (
      <>
        <b>Medium</b>
        <br />
        {'(60%\xa0equity)'}
      </>
    ),
  },
  {
    value: Enum_PortfolioSelection.Higher,
    label: (
      <>
        <b>Medium-High</b>
        <br />
        {'(80%\xa0equity)'}
      </>
    ),
  },
  {
    value: Enum_PortfolioSelection.High,
    label: (
      <>
        <b>High</b>
        <br />
        {'(100%\xa0equity)'}
      </>
    ),
  },
];

export type StyleEntry = {
  title: string;
  description: string;
  icon: FC;
};

export const portfolioStyleOptions: Record<Enum_PortfolioStyle, StyleEntry> = {
  [Enum_PortfolioStyle.Core]: {
    title: 'Core',
    description:
      'The flagship range of portfolios, offering greatest flexibility in underlying funds and investment style.',
    icon: CapitalIcon,
  },
  [Enum_PortfolioStyle.Income]: {
    title: 'Income',
    description:
      'Higher yielding portfolios, where income is paid out and not reinvested. Expected yield is between 3.5% - 4.5%.',
    icon: IncomeIcon,
  },
  [Enum_PortfolioStyle.ImpactAndInnovation]: {
    title: 'Sustainable',
    description:
      'Responsible range of portfolios for investors with strong sustainability requirements, sitting alongside financial goals.',
    icon: ImpactIcon,
  },
  [Enum_PortfolioStyle.Passive]: {
    title: 'Passive Plus',
    description:
      'Low cost range, with a focus on mostly passive funds, seeking to deliver index like returns.',
    icon: PassiveIcon,
  },
  [Enum_PortfolioStyle.Satellite]: {
    title: 'Satellite',
    description:
      'Curated portfolios, that focus in on specific themes that advisers and/or their clients often wish to tilt towards.',
    icon: SatelliteCircledIcon,
  },
};

export const smallContentWidth = 725;
export const contentWidth = 992;
