import axios from 'axios';
import { getToken } from 'src/auth';
import { Notification } from 'src/components/shared/NotificationForm/types';
import { NOTIFICATIONS_LAMBDA_URL } from './lambda-routes';

export async function getIfaNotifications() {
  const options = {
    headers: {
      Authorization: await getToken(),
    },
  };
  const response = await axios
    .get(`${NOTIFICATIONS_LAMBDA_URL}/notifications`, options)
    .catch((error) => {
      throw new Error(
        error.response?.data?.error ??
          'Unexpected error while fetching notifications.',
      );
    });

  return response.data.data.notifications as Notification[];
}
