import axios from 'axios';
import { getToken } from 'src/auth';
import {
  MarketUpdate,
  MarketUpdateIFA,
} from 'src/components/pages/MarketUpdatesPage/MarketUpdate';
import { MARKET_UPDATES_LAMBDA_URL } from './lambda-routes';

interface GetMarketUpdatesResponse {
  items: Partial<MarketUpdate | MarketUpdateIFA>[];
  lastEvaluatedKey?: IFAKey | AdminKey;
}

export interface IFAKey {
  fixedPublishDate: string;
  marketUpdateId: string;
  published: string;
}

export interface AdminKey {
  date: string;
  marketUpdateId: string;
  type: string;
}

async function getMarketUpdates(
  itemsPerPage: number,
  startKeyParams?: Record<string, string>,
  tag?: string,
): Promise<{
  items: Partial<MarketUpdate | MarketUpdateIFA>[];
  lastItem?: Partial<MarketUpdate | MarketUpdateIFA>;
}> {
  let params = {
    size: itemsPerPage + 1, // +1 to check if there are more items
    type: tag,
  };

  if (startKeyParams)
    params = {
      ...params,
      ...startKeyParams,
    };

  const options = {
    headers: {
      Authorization: await getToken(),
    },
    params,
  };
  const response = await axios
    .get(`${MARKET_UPDATES_LAMBDA_URL}/marketUpdate`, options)
    .catch((error) => {
      throw new Error(error.response?.data?.error ?? 'Error');
    });

  const resp = response.data;
  if (!resp) {
    return { items: [] };
  }

  if (resp.items.length > itemsPerPage) {
    resp.items.pop();
    return {
      items: resp.items as Partial<MarketUpdate | MarketUpdateIFA>[],
      lastItem: resp.items[resp.items.length - 1],
    };
  }

  return {
    items: resp.items as Partial<MarketUpdate | MarketUpdateIFA>[],
  };
}

export async function getMarketUpdatesIFA(
  itemsPerPage: number,
  startKey: IFAKey | null,
): Promise<GetMarketUpdatesResponse> {
  const startKeyParams = startKey
    ? {
        startKeyFixedPublishDate: startKey.fixedPublishDate,
        startKeyId: startKey.marketUpdateId,
        startKeyPublished: startKey.published,
      }
    : undefined;

  const { items, lastItem } = await getMarketUpdates(
    itemsPerPage,
    startKeyParams,
  );

  const lastEvaluatedKey: IFAKey | undefined = lastItem && {
    fixedPublishDate: lastItem.fixedPublishDate!,
    marketUpdateId: lastItem.marketUpdateId!,
    published: lastItem.published!,
  };

  return {
    items,
    lastEvaluatedKey,
  };
}

export async function getMarketUpdatesAdmin(
  itemsPerPage: number,
  tag: string,
  startKey: AdminKey | null,
): Promise<GetMarketUpdatesResponse> {
  const startKeyParams = startKey
    ? {
        startKeyDate: startKey.date,
        startKeyId: startKey.marketUpdateId,
        startKeyType: startKey.type,
      }
    : undefined;

  const { items, lastItem } = await getMarketUpdates(
    itemsPerPage,
    startKeyParams,
    tag,
  );

  const lastEvaluatedKey: AdminKey | undefined = lastItem && {
    date: lastItem.date!,
    marketUpdateId: lastItem.marketUpdateId!,
    type: lastItem.type!,
  };

  return {
    items,
    lastEvaluatedKey,
  };
}
