import { createIcon } from '../createIcon';

export const ChartIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        d="M2.5 2C2.22386 2 2 2.22386 2 2.5V28.5C2 29.3284 2.67157 30 3.5 30H29.5C29.7761 30 30 29.7761 30 29.5C30 29.2239 29.7761 29 29.5 29H3.5C3.22386 29 3 28.7761 3 28.5V2.5C3 2.22386 2.77614 2 2.5 2Z"
        fill="currentColor"
      />
      <path
        d="M28.4926 4.91433C28.4453 4.64227 28.1864 4.46008 27.9143 4.50739L23.4809 5.27843C23.2088 5.32574 23.0266 5.58465 23.0739 5.85671C23.1213 6.12876 23.3802 6.31096 23.6522 6.26364L26.8964 5.69943L18.3971 17.7774L13.4035 13.7825L5.09563 25.2059C4.93321 25.4292 4.98259 25.7419 5.20591 25.9044C5.42924 26.0668 5.74195 26.0174 5.90437 25.7941L13.5965 15.2175L18.6029 19.2226L27.7142 6.27493L28.2784 9.51912C28.3257 9.79118 28.5846 9.97337 28.8567 9.92606C29.1288 9.87874 29.311 9.61984 29.2636 9.34778L28.4926 4.91433Z"
        fill="currentColor"
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
