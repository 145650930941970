export const presetColors = [
  '#b953f6',
  '#f1c447',
  '#eb4f81',
  '#71ccde',
  '#281e24',
  '#6be7b8',
  '#2c36cd',
  '#dc5c4c',
  '#ff9f9f',
  '#afff9f',
  '#fff69f',
  '#d0baf3',
  '#4d7c7d',
  '#caf6ae',
  '#f3d8ba',
];
