import { ApolloLink, Operation } from '@apollo/client';
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { getToken } from 'src/auth';

const cleanTypeName = new ApolloLink((operation: Operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: unknown) =>
      key === '__typename' ? undefined : value;
    // eslint-disable-next-line no-param-reassign
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    );
  }

  return forward(operation);
});

export const createApolloLink = (): ApolloLink => {
  const config = {
    url: process.env.REACT_APP_AWS_GRAPHQL_API || '',
    region: process.env.REACT_APP_AWS_REGION || '',
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: () => getToken(),
    } as AuthOptions,
  };

  return ApolloLink.from([
    cleanTypeName,
    createAuthLink(config),
    createSubscriptionHandshakeLink(config),
  ]);
};
