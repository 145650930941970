import { FC, forwardRef, ReactNode, useEffect, useState } from 'react';
import { Box, Button, IconButton, Paper } from '@mui/material';

import { SnackType } from 'src/hooks/Snack/types';
import { useStyles } from './styles';
import { CloseIcon, ErrorIcon, InfoIcon } from '../icons';

interface Props {
  className?: string;
  icon?: ReactNode;
  onClose?: () => void;
  onTryAgain?: () => void;
  children?: ReactNode;
  timeout?: number;
  type?: SnackType;
  isSnackbar?: boolean;
}

export const ErrorBox: FC<Props> = forwardRef<HTMLDivElement | null, Props>(
  (
    {
      children,
      className,
      icon,
      type,
      timeout,
      isSnackbar,
      onClose,
      onTryAgain,
    },
    ref,
  ) => {
    const { classes, cx } = useStyles();
    const [barWidth, setBarWidth] = useState(0);

    useEffect(() => setBarWidth(100), []);
    const isError = type === undefined || type === SnackType.Error;

    const color = cx({
      [classes.successIcon]: type === SnackType.Success,
      [classes.warningIcon]: type === SnackType.Warning,
      [classes.neutralIcon]: type === SnackType.Neutral,
    });
    return (
      <Paper
        ref={ref}
        className={cx(classes.root, className, {
          [classes.shadow]: !!isSnackbar,
        })}
      >
        <div className={cx(classes.icon, color, { shadow: !!isSnackbar })}>
          {icon ?? (isError ? <ErrorIcon /> : <InfoIcon />)}
        </div>
        <div className={classes.text}>
          {children}
          {(onClose || onTryAgain) && (
            <Box className={classes.actionButtons}>
              {onTryAgain && (
                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                  onClick={onTryAgain}
                >
                  Try again
                </Button>
              )}
              {onClose && (
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  size="large"
                  sx={{ color: 'black' }}
                >
                  <CloseIcon color="inherit" />
                </IconButton>
              )}
            </Box>
          )}
          {timeout && (
            <Box className={classes.timeoutWrap}>
              <div
                className={cx(classes.timeoutBar, color)}
                style={{ width: `${barWidth}%`, transition: `${timeout}ms` }}
              />
            </Box>
          )}
        </div>
      </Paper>
    );
  },
);
