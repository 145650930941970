import {
  CSSProperties,
  ChangeEvent,
  FC,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { DatePicker } from 'src/components/ui';
import {
  formatCurrency,
  parseInputNumber,
  selectOnFocus,
  trackChartAction,
} from 'src/utils';

import { addYears, subYears } from 'date-fns';
import { useStyles } from './styles';
import { coercePositive } from '../Chart/utils';
import { Dropdown, DropdownOption } from './Dropdown';

interface Props {
  startingValue?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  minDate: Date;
  maxDate: Date;
  initialStart?: Date;
  initialEnd?: Date;
  justifyContent?: string;
  disableFuture?: boolean;
  dropdownOptions?: DropdownOption[];
  onValueChange?: (value: number) => void;
  setStartDate?: (date: Date) => void;
  setEndDate?: (date: Date) => void;
}

const defaultRange = 'own-range';

export const Filters: FC<Props> = ({
  startingValue,
  minDate,
  maxDate,
  initialStart,
  initialEnd,
  startDate,
  endDate,
  justifyContent = 'space-between',
  disableFuture,
  dropdownOptions = [],
  onValueChange,
  setStartDate,
  setEndDate,
}) => {
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const timeoutRef = useRef<number>();
  const [range, setRange] = useState(defaultRange);
  const [startValue, setStartValue] = useState(
    formatCurrency(coercePositive(startingValue)),
  );

  const handleValueBlur = useCallback(() => {
    setStartValue((val) =>
      formatCurrency(coercePositive(parseInputNumber(val)[1] as number)),
    );
    trackChartAction('Starting Value change');
  }, []);

  const handleValueChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const [val, number] = parseInputNumber(ev.currentTarget.value);
    setStartValue(val);
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      onValueChange?.(coercePositive(number));
    }, 500);
  };

  const resetDates = () => {
    setStartDate?.(new Date(initialStart ?? minDate));
    setEndDate?.(new Date(initialEnd ?? maxDate));
  };

  const handleReset = () => {
    resetDates();
    setRange(defaultRange);
    trackChartAction('Reset');
  };
  const handleStartDateChange = (date: Date) => {
    setStartDate?.(date);
    trackChartAction('From Date change');
  };
  const handleEndDateChange = (date: Date) => {
    setEndDate?.(date);
    trackChartAction('To Date change');
  };

  const handleRangeChange = (newRange: string) => {
    setRange(newRange);
    if (newRange === defaultRange) {
      resetDates();
      return;
    }
    if (disableFuture) {
      setStartDate?.(subYears(maxDate, +newRange));
      setEndDate?.(new Date(maxDate));
    } else {
      const now = new Date();
      setStartDate?.(now);
      setEndDate?.(addYears(now, +newRange));
    }
  };

  const textLabelStyles = {
    style: {
      color: theme.palette.primary.main,
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 12,
      transform: 'none',
    } as CSSProperties,
  };

  const minStartDate = new Date(minDate.getFullYear(), minDate.getMonth());
  const today = new Date();
  const maxStartDate = endDate
    ? new Date(endDate.getFullYear(), endDate.getMonth() - 1)
    : new Date(today.getFullYear(), today.getMonth() - 1);
  const minEndDate = startDate
    ? new Date(startDate.getFullYear(), startDate.getMonth() + 1)
    : new Date(minStartDate.getFullYear(), minStartDate.getMonth() + 1);

  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const predefinedRange = range !== defaultRange;
  const hideDateInputs = predefinedRange && disableFuture;
  const hiddenClass = cx({ [classes.hidden]: hideDateInputs });
  const hasRange = startDate && endDate;

  return (
    <Box className={classes.root} sx={{ justifyContent }}>
      {startingValue && (
        <>
          {!isSmall && (
            <Typography className={classes.title}>Starting Value</Typography>
          )}
          <TextField
            className={classes.startingValueInput}
            id="starting-value"
            label={isSmall ? 'Starting Value:' : ''}
            autoComplete="off"
            variant="standard"
            value={startValue}
            onChange={handleValueChange}
            onBlur={handleValueBlur}
            inputProps={{
              ...selectOnFocus,
              style: {
                textAlign: 'left',
                fontSize: 16,
                fontWeight: 400,
                paddingBottom: 8,
                fontFamily: 'Montserrat',
              },
            }}
            InputLabelProps={textLabelStyles}
          />
        </>
      )}
      {hasRange && (
        <Dropdown
          value={range}
          options={[
            { value: defaultRange, name: 'Own Range' },
            ...dropdownOptions,
          ]}
          onChange={handleRangeChange}
        />
      )}
      {startDate && (
        <>
          {!isSmall && (
            <Typography className={cx(classes.title, hiddenClass)}>
              from
            </Typography>
          )}
          <DatePicker
            value={startDate}
            label={isSmall ? 'From' : ''}
            outlined
            fullWidth
            onDateChange={handleStartDateChange}
            minDate={minStartDate}
            maxDate={maxStartDate}
            takeFirstDayOfMonth
            disabled={hideDateInputs}
            className={hiddenClass}
          />
        </>
      )}
      {endDate && (
        <>
          {!isSmall && (
            <Typography className={cx(classes.title, hiddenClass)}>
              to
            </Typography>
          )}
          <DatePicker
            value={endDate}
            label={isSmall ? 'To' : ''}
            outlined
            fullWidth
            onDateChange={handleEndDateChange}
            minDate={minEndDate}
            maxDate={maxDate}
            disabled={hideDateInputs}
            disableFuture={disableFuture}
            takeLastDayOfMonth
            className={hiddenClass}
          />
        </>
      )}
      {hasRange && (
        <Button className={classes.resetButton} onClick={handleReset}>
          Reset
        </Button>
      )}
    </Box>
  );
};
