import axios from 'axios';
import { getToken } from 'src/auth';
import { Satelite } from 'src/components/pages/Factsheets/SateliteList/Satelite';
import { MULTI_ASSETS_LAMBDA_URL } from './lambda-routes';

export const getMultiAssetById = async (multiAssetId: string) => {
  const options = {
    headers: {
      Authorization: await getToken(),
    },
  };

  const response = await axios
    .get(`${MULTI_ASSETS_LAMBDA_URL}/multiAssets/${multiAssetId}`, options)
    .catch((error) => {
      throw new Error(error.response?.data?.error ?? 'Error');
    });

  return response.data as Satelite;
};
