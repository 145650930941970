import { Storage } from '@aws-amplify/storage';

import { storageImagesConfig } from '../config';

export const getLogo = async (key: string): Promise<string> => {
  Storage.configure(storageImagesConfig);
  const res = (await Storage.get(key, {
    cacheControl: 'no-cache',
    customPrefix: { public: '' },
    download: true,
  })) as { Body: Blob };
  return URL.createObjectURL(res.Body);
};
