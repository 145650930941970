import { FC, ReactNode } from 'react';

import { ErrorBox, RotateIcon } from 'src/components/ui';

import { Routes } from 'src/routing';
import { SnackType } from 'src/hooks/Snack/types';
import { useStyles } from './styles';

interface Props {
  children?: ReactNode;
}

export const RotationGuard: FC<Props> = ({ children }) => {
  const { classes } = useStyles();
  const location = window.location.pathname;
  if (location.startsWith(Routes.Questionnaire)) {
    return <div>{children}</div>;
  }
  return (
    <>
      <div className={classes.root}>{children}</div>
      <div className={classes.snack}>
        <ErrorBox type={SnackType.Neutral} icon={<RotateIcon />}>
          Hold the screen horizontally to display the content.
        </ErrorBox>
      </div>
    </>
  );
};
