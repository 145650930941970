import { FC } from 'react';
import { useBlocker } from 'react-router-dom';

interface Props {
  when: boolean;
  message: string;
}

const Prompt: FC<Props> = ({ when, message }) => {
  useBlocker(() => {
    // eslint-disable-next-line no-alert
    return when ? !window.confirm(message) : false;
  });

  return null;
};

export default Prompt;
