import { Box, Drawer, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import {
  DialogSize,
  clearUnreadNotificationsIndicator,
  useDialog,
} from 'src/hooks';
import { getIfaNotifications } from 'src/restApi/getIfaNotifications';
import { Notification } from 'src/components/shared/NotificationForm/types';
import { confirmation } from 'src/utils/confirmModal';
import { deleteIfaNotifications } from 'src/restApi/deleteIfaNotifications';
import { readIfaNotifications } from 'src/restApi/readIfaNotifications';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'src/routing';
import { strHeaderHeight } from 'src/utils';
import { useStyles } from './styles';
import { NotificationCard } from './NotificationCard';
import { NotificationModal } from './NotificationModal';

interface Props {
  open?: boolean;
  onClose?: () => void;
}

export const NotificationsDrawer: FC<Props> = ({ open, onClose }) => {
  const { classes } = useStyles({ headerHeight: strHeaderHeight });
  const dialog = useDialog();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<Notification[]>();

  const notificationIds = notifications?.map((n) => n.notificationId);

  const clearIndicator = (notifs?: Notification[]) => {
    const unreads = notifs?.filter?.((n) => n.unread)?.length ?? 0;
    if (unreads > 0 || !notifs) return;
    clearUnreadNotificationsIndicator();
  };

  useEffect(() => {
    clearIndicator(notifications);
  }, [notifications]);

  const fetchNotifications = async () => {
    try {
      const res = await getIfaNotifications();
      setNotifications(res);
    } catch (e) {
      console.log('Could not fetch notifications', e);
    }
  };

  const handleClearNotifications = async () => {
    if (!notificationIds?.length) return;
    const confirmed = await confirmation(dialog, {
      title: 'Clear Notifications',
      content: <>Are you sure?</>,
    });
    if (!confirmed) return;
    await deleteIfaNotifications(notificationIds);
    await fetchNotifications();
  };

  const handleClearNotification = async (id: string) => {
    await deleteIfaNotifications([id]);
    await fetchNotifications();
  };

  const handleOpenNotification = async (notification: Notification) => {
    let newNotifications: Notification[] | undefined;
    if (notification.unread) {
      readIfaNotifications([notification.notificationId]);
      newNotifications = notifications?.map((n) => {
        if (n.notificationId !== notification.notificationId) {
          return n;
        }
        return { ...n, unread: false };
      });
      setNotifications(newNotifications);
    }

    const navigateWrap = (to: string) => {
      clearIndicator(newNotifications);
      navigate(to);
    };

    if (notification.type === 'auto') {
      if (notification.targetResource === 'clients') {
        navigateWrap(Routes.Customers);
      } else if (notification.targetResource === 'marketUpdates') {
        navigateWrap(Routes.MarketUpdates);
      } else if (notification.targetResource === 'usefulResources') {
        navigateWrap(Routes.UsefulResources);
      } else if (notification.targetResource === 'portfolioPositioning') {
        navigateWrap(Routes.PortfolioPositioning);
      } else if (notification.targetResource === 'portfolioChanges') {
        navigateWrap(Routes.PortfolioChanges);
      } else if (notification.targetResource === 'self') {
        await handleClearNotification(notification.notificationId);
      }
      return;
    }

    dialog.show({
      title: 'Notification',
      content: <NotificationModal notification={notification} />,
      size: DialogSize.Big,
      backdropClose: true,
    });
    onClose?.();
  };

  useEffect(() => {
    if (!open) return;
    fetchNotifications();
  }, [open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className={classes.drawer}
    >
      <Box className={classes.container}>
        <Box className={classes.topBar}>
          <Typography fontWeight={500} fontSize={16}>
            Notifications
          </Typography>
          {!!notifications?.length && (
            <Box
              className={classes.clearAll}
              onClick={handleClearNotifications}
            >
              Clear All
            </Box>
          )}
        </Box>
        <Box className={classes.notifications}>
          {notifications?.map((notification) => {
            return (
              <NotificationCard
                key={notification.notificationId}
                title={notification.title}
                message={notification.rawMessage}
                unread={notification.unread}
                auto={notification.type === 'auto'}
                onClick={() => handleOpenNotification(notification)}
                onClear={() => {
                  handleClearNotification(notification.notificationId);
                }}
              />
            );
          })}
          {!notifications?.length && (
            <Typography textAlign="center">No notifications</Typography>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};
