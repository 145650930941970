import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  button: {
    width: 80,
    height: 80,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '100%',
    '& svg': {
      color: theme.palette.primary.main,
      width: 32,
      height: 32,
    },
  },
}));
