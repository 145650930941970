import { createIcon } from '../createIcon';

export const PortfolioSelectionIcon = createIcon({
  svg: () => (
    <g>
      <path
        d="M18.8307 24.0115H6.9269V22.4731H18.8307V24.0115Z"
        fill="currentColor"
      />
      <path
        d="M25.0731 13.7693H6.9269V12.2308H25.0731V13.7693Z"
        fill="currentColor"
      />
      <path
        d="M25.0731 8.52695H6.9269V6.98845H25.0731V8.52695Z"
        fill="currentColor"
      />
      <path
        d="M25.0732 18.7692H6.927V17.2307H25.0732V18.7692Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.80815 31.1919C1.34695 31.7306 2.00063 32 2.7692 32H29.2308C29.9994 32 30.653 31.7306 31.1918 31.1919C31.7306 30.6531 32 29.9994 32 29.2308V2.7692C32 2.00063 31.7306 1.34695 31.1918 0.808151C30.653 0.269385 29.9994 0 29.2308 0H2.7692C2.00063 0 1.34695 0.269385 0.80815 0.808151C0.269383 1.34695 0 2.00063 0 2.7692V29.2308C0 29.9994 0.269383 30.6531 0.80815 31.1919ZM29.2308 30.4616H2.7692C2.46153 30.4616 2.17948 30.3333 1.92305 30.077C1.66665 29.8205 1.53845 29.5385 1.53845 29.2308V2.7692C1.53845 2.46153 1.66665 2.17948 1.92305 1.92305C2.17948 1.66665 2.46153 1.53845 2.7692 1.53845H29.2308C29.5385 1.53845 29.8205 1.66665 30.077 1.92305C30.3333 2.17948 30.4616 2.46153 30.4616 2.7692V29.2308C30.4616 29.5385 30.3333 29.8205 30.077 30.077C29.8205 30.3333 29.5385 30.4616 29.2308 30.4616Z"
        fill="currentColor"
      />
      )
    </g>
  ),
  fontSize: 'inherit',
  viewBox: '0 0 32 32',
});
