import { Box, SxProps, Theme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useStyles } from './styles';

const ID = () => Math.random();

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  size?: number;
  invertColor?: boolean;
}

export const LoadingBox: FC<Props> = ({ className, sx, size, invertColor }) => {
  const { classes, cx } = useStyles({ invertColor });

  const heights = [30, 50, 70, 90];

  const [ids, setIds] = useState([ID(), ID(), ID(), ID()]);

  useEffect(() => {
    const interval = setInterval(() => {
      // reset chart bars
      setIds([ID(), ID(), ID(), ID()]);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      className={cx(classes.root, className)}
      sx={{ ...sx, width: `${size ?? 75}px`, height: `${size ?? 75}px` }}
    >
      <Box className={classes.topWrap}>
        {ids.map((id, i) => {
          return (
            <Box
              key={id.toString()}
              className={classes.barWrap}
              sx={{ height: `${heights[i]}% !important` }}
            >
              <Box
                className={classes.bar}
                sx={{ animationDelay: `${(1 * i) / 2}s !important` }}
              />
            </Box>
          );
        })}
      </Box>
      <Box className={classes.divider} />
      <Box className={classes.bottomWrap}>
        {ids.map((id, i) => {
          return (
            <Box key={id.toString()} className={classes.bottomBarWrap}>
              <Box
                className={cx(classes.bar, classes.bottomBar)}
                sx={{ animationDelay: `${(1 * i) / 2}s !important` }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
