import { FC } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { Storage } from '@aws-amplify/storage';

import {
  DialogController,
  OverlayController,
  SnackController,
} from 'src/hooks';

import { useApolloClient } from './apollo/useApolloClient';
import { RotationGuard } from './components/shared';
import { authConfig, storageImagesConfig } from './config';
import { Routing } from './routing';
import { ThemeController } from './themes';

Auth.configure(authConfig);
Storage.configure(storageImagesConfig);

export const App: FC = () => {
  const client = useApolloClient();
  return (
    <ThemeController>
      <DialogController>
        <SnackController>
          <OverlayController>
            <ApolloProvider client={client}>
              <RotationGuard>
                <Routing />
              </RotationGuard>
            </ApolloProvider>
          </OverlayController>
        </SnackController>
      </DialogController>
    </ThemeController>
  );
};
