import { FC, ReactNode } from 'react';
import { usePageTracking } from 'src/hooks/usePageTracking';

interface Props {
  children: ReactNode;
  title: string;
}

export const TrackPage: FC<Props> = ({ children, title }) => {
  usePageTracking(title);
  return <>{children}</>;
};
