import { createIcon } from '../createIcon';

export const SatelliteCircledIcon = createIcon({
  svg: ({ palette }) => (
    <>
      <circle cx="42" cy="42" r="42" fill="white" />
      <circle
        cx="42"
        cy="42"
        r="41.5"
        stroke={palette.primary.main}
        strokeOpacity="0.15"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 22.5C39.623 22.5 37.3452 22.9253 35.2388 23.704C35.0128 23.2428 34.7409 22.8082 34.4289 22.4062C36.7778 21.498 39.3307 21 42 21C53.598 21 63 30.402 63 42C63 53.598 53.598 63 42 63C30.402 63 21 53.598 21 42C21 37.9889 22.1246 34.2405 24.0756 31.0528C23.0971 29.9852 22.5 28.5623 22.5 27C22.5 23.6863 25.1863 21 28.5 21C31.8137 21 34.5 23.6863 34.5 27C34.5 30.3137 31.8137 33 28.5 33C27.2959 33 26.1747 32.6453 25.2351 32.0348C23.4979 34.9511 22.5 38.359 22.5 42C22.5 52.7696 31.2304 61.5 42 61.5C52.7696 61.5 61.5 52.7696 61.5 42C61.5 31.2304 52.7696 22.5 42 22.5ZM28.5 31.5C30.9853 31.5 33 29.4853 33 27C33 24.5147 30.9853 22.5 28.5 22.5C26.0147 22.5 24 24.5147 24 27C24 29.4853 26.0147 31.5 28.5 31.5Z"
        fill={palette.primary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.5 42C52.5 47.799 47.799 52.5 42 52.5C36.201 52.5 31.5 47.799 31.5 42C31.5 36.201 36.201 31.5 42 31.5C47.799 31.5 52.5 36.201 52.5 42Z"
        fill={palette.primary.main}
      />
    </>
  ),
  fontSize: 'inherit',
  viewBox: '0 0 84 84',
});
