import { createIcon } from '../createIcon';

export const ActiveEquityIcon = createIcon({
  svg: () => (
    <>
      <path d="M2 4V37.0001H38V35.5H3.5L3.5 4H2Z" fill="currentColor" />
      <path
        d="M5.666 27L6.666 28L16.791 17.916L23.541 24.666L35.583 12.583V18.333H37V10.166H28.833V11.541H34.708L23.541 22.583L16.791 15.833L5.666 27Z"
        fill="currentColor"
      />
    </>
  ),
  fontSize: 'inherit',
  viewBox: '0 0 40 40',
});
