import { useCallback } from 'react';

export const useGlobalDrag = <T>(
  fn: (moveEv: MouseEvent, startEv: T) => void,
): ((ev: T) => void) => {
  return useCallback(
    (ev: T) => {
      const handleMouseMove = (e: MouseEvent) => fn(e, ev);
      const handleMouseUp = () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    },
    [fn],
  );
};
