import { FC, memo } from 'react';
import { alpha, Box, CircularProgress, useTheme } from '@mui/material';

import { LoadingBox } from 'src/components/ui/LoadingBox';
import { useStyles } from './styles';

type CoverProps = {
  cover?: boolean;
  overlay?: never;
  fullPage?: never;
};

type OverlayProps = {
  cover?: never;
  overlay?: boolean;
  fullPage?: never;
};

type FullPageProps = {
  cover?: boolean;
  overlay?: never;
  fullPage?: boolean;
};

type FullLoadingProps = {
  size?: number;
  type?: 'spinner' | 'chart';
} & (CoverProps | OverlayProps | FullPageProps);

export const FullLoading: FC<FullLoadingProps> = memo(
  ({ cover, overlay, fullPage, size = 92, type = 'chart' }) => {
    const { classes } = useStyles({ fullPage: overlay || fullPage });
    const theme = useTheme();

    const bgColor = () => {
      if (overlay) return alpha(theme.palette.primary.main, 0.3);
      return cover ? 'white' : 'transparent';
    };

    return (
      <Box
        className={classes.root}
        sx={{ backgroundColor: bgColor() }}
        data-testid={overlay ? 'overlay' : 'full-loading'}
      >
        {type === 'chart' ? (
          <LoadingBox size={size} />
        ) : (
          <CircularProgress size={size} thickness={1} />
        )}
      </Box>
    );
  },
);
