import { createIcon } from '../createIcon';

export const SendIcon = createIcon({
  svg: () => (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M1 14.2L31 1 18 31l-6.5-10.5L1 14.2zM11.4 20.6L31 1"
    />
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
