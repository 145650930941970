import axios from 'axios';
import { getToken } from 'src/auth';
import { IfaCompany } from 'src/components/pages/CompanyPage/types';
import { IFA_COMPANY_LAMBDA_URL } from './lambda-routes';

export async function getIfaCompanyInfo() {
  const token = await getToken(true);
  if (!token) {
    throw new Error('Not signed in yet');
  }
  const options = {
    headers: { Authorization: token },
  };
  const response = await axios
    .get(`${IFA_COMPANY_LAMBDA_URL}/ifaCompanyInfo`, options)
    .catch((error) => {
      throw new Error(error.response?.data?.error ?? 'Error');
    });

  return response.data.data as IfaCompany;
}
