import { FC, ReactNode, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
  TextField,
  Tooltip,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { useStyles } from './styles';

interface Props {
  id: string;
  label?: string;
  upperLabel?: ReactNode;
  onChange?: (value: string) => void;
  value: string;
  onBlur?: () => void;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  error?: string | null;
  type?: string;
  className?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  inputProps?: InputBaseComponentProps;
  variant?: 'outlined' | 'standard' | 'filled';
}

export const SimpleInput: FC<Props> = ({
  autoComplete,
  autoFocus,
  disabled,
  error,
  id,
  label,
  upperLabel,
  onChange,
  onBlur,
  type,
  value,
  className,
  fullWidth,
  multiline,
  rows,
  inputProps,
  variant,
}) => {
  const { classes, cx } = useStyles();
  const [dirty, setDirty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const currentType = showPassword ? 'text' : type;
  const toggleLabel = `${showPassword ? 'Hide' : 'Show'} password`;
  const endAdornment =
    type === 'password' ? (
      <InputAdornment position="end">
        <Tooltip title={toggleLabel}>
          <IconButton
            aria-label={toggleLabel}
            onClick={() => setShowPassword((val) => !val)}
            size="large"
          >
            {showPassword ? (
              <Visibility color="primary" />
            ) : (
              <VisibilityOff color="primary" />
            )}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    ) : null;

  const displayedError = error != null && (value || dirty) ? error : null;

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
    setDirty(true);
  };

  return (
    <Box className={classes.wrapper}>
      {upperLabel && (
        <label htmlFor={id} className={classes.label}>
          {upperLabel}
        </label>
      )}
      <TextField
        autoFocus={autoFocus}
        label={label}
        id={id}
        name={id}
        autoComplete={autoComplete}
        type={currentType}
        disabled={disabled}
        error={displayedError !== null}
        value={value}
        helperText={displayedError}
        className={cx(classes.root, className)}
        onBlur={handleBlur}
        onChange={(e) => onChange?.(e.currentTarget.value)}
        InputProps={{ endAdornment, inputProps }}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        variant={variant ?? 'outlined'}
      />
    </Box>
  );
};
