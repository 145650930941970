export type WorldEvent = {
  name: string;
  month: Date;
  offset: number;
};

export const worldEvents: WorldEvent[] = [
  { name: 'Black Wednesday', month: new Date('1992-09-15'), offset: 1.3 },
  { name: 'EU Formed', month: new Date('1993-11-15'), offset: 0.75 },
  {
    name: 'Asian Financial Crisis',
    month: new Date('1997-07-15'),
    offset: 0.95,
  },
  {
    name: 'Russian Financial Crisis',
    month: new Date('1998-08-15'),
    offset: 1.35,
  },
  { name: 'Euro Introduced', month: new Date('1999-01-15'), offset: 1.7 },
  { name: 'Dotcom Bubble', month: new Date('2000-08-15'), offset: 1.325 },
  { name: '9/11', month: new Date('2001-09-15'), offset: 0.95 },
  { name: 'Invasion of Iraq', month: new Date('2003-03-15'), offset: 1.6 },
  { name: 'SARS Outbreak', month: new Date('2003-05-15'), offset: 1.8 },
  { name: 'London Bombings', month: new Date('2005-07-15'), offset: 1 },
  { name: 'iPhone Introduced', month: new Date('2007-06-15'), offset: 1.5 },
  {
    name: 'Global Financial Crisis (GFC)',
    month: new Date('2008-10-15'),
    offset: 1.5,
  },
  { name: 'BP Oil Spill', month: new Date('2010-04-15'), offset: 0.95 },
  { name: 'Facebook IPO', month: new Date('2012-05-15'), offset: 1.45 },
  {
    name: '"Whatever it takes" ECB Speech',
    month: new Date('2012-07-15'),
    offset: 1.6,
  },
  { name: 'Ebola Outbreak', month: new Date('2014-03-15'), offset: 1 },
  { name: 'Brexit', month: new Date('2016-06-15'), offset: 1 },
  { name: 'Trump Inauguration', month: new Date('2017-01-15'), offset: 1.1 },
  { name: 'COVID-19 Pandemic', month: new Date('2020-03-15'), offset: 1.05 },
  {
    name: 'Russia Invades Ukraine',
    month: new Date('2022-02-15'),
    offset: 0.95,
  },
  {
    name: 'UK Inflation Hits 10%',
    month: new Date('2022-09-15'),
    offset: 1.075,
  },
];
