import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CSSProperties } from 'react';

export const useStyles = makeStyles()((theme) => {
  const numberInput = {
    width: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
    fontSize: 12,
    padding: '2px 6px',
    backgroundColor: 'white',
    fontWeight: theme.typography.fontWeightMedium,
    outline: 'none',
    boxSizing: 'border-box' as CSSProperties['boxSizing'],
  };
  const numberInputRoot = {
    overflow: 'visible',
    border: 0,
    borderRadius: 0,
    height: 22,
  };
  return {
    summary: {
      margin: theme.spacing(3, 0, 2),
      width: 735,
      display: 'flex',
      justifyContent: 'space-between',
      '& h3': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    columnNames: {
      margin: theme.spacing(2, 0, 6, 8),
      display: 'flex',
      justifyContent: 'space-between',
    },
    error: {
      color: theme.palette.error.main,
    },
    deleteIcon: {
      fontSize: 20,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    exposuresWrap: {
      display: 'flex',
      gap: 2,
      flexDirection: 'column',
    },
    exposuresHeader: {
      display: 'flex',
      gap: 2,
      width: '100%',
      '& > div': {
        backgroundColor: lighten(theme.palette.primary.main, 0.95),
        color: 'black',
        borderRadius: 4,
        padding: '2px 8px',
        fontSize: 12,
        boxSizing: 'border-box',
      },
    },
    exposureEntry: {
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.grey[100],
      borderRadius: 4,
      boxSizing: 'border-box',
      gap: 2,
      '& input': numberInput,
      '& .MuiFilledInput-root': numberInputRoot,
      '& .MuiFilledInput-input': {
        height: 22,
      },
      '& > div': {
        padding: 8,
      },
      '& .color-wrap': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingInline: 40,
        '& .dot': {
          width: 20,
          height: 20,
          borderRadius: '50%',
          cursor: 'pointer',
          backgroundColor: theme.palette.primary.main,
          '&.selected': {
            outline: `2px solid ${theme.palette.primary.main}`,
            outlineOffset: 2,
          },
          '&:nth-child(2)': {
            backgroundColor: lighten(theme.palette.primary.main, 0.4),
          },
          '&:nth-child(3)': {
            backgroundColor: lighten(theme.palette.primary.main, 0.7),
          },
          '&:nth-child(4)': {
            backgroundColor: '#727272',
          },
          '&:nth-child(5)': {
            backgroundColor: '#AAAAAA',
          },
          '&:nth-child(6)': {
            backgroundColor: '#D5D5D5',
          },
        },
      },
    },
    fundsWrap: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },
    fundEntry: {
      padding: 12,
      paddingRight: 0,
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.grey[100],
      borderRadius: 4,
      boxSizing: `border-box`,
    },
    fundInputs: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 50px)',
      '& textarea': {
        ...numberInput,
        resize: 'none',
        height: 100,
      },
      '& select': {
        ...numberInput,
        paddingLeft: 20,
        width: '100%',
        height: '100%',
        verticalAlign: 'top',
      },
    },
    fundLabels: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > div': {
        width: '14%',
      },
    },
    fundInfo: {
      display: 'flex',
      gap: 4,
      marginBottom: 10,
      height: 22,
      '& input': numberInput,
      '& .MuiFilledInput-root': numberInputRoot,
      '& .MuiFilledInput-input': {
        height: '100%',
      },
      '& .MuiTextField-root': {
        width: '15%',
      },
    },
    selectionDot: {
      position: 'absolute',
      transform: 'translateY(-50%)',
      left: 8,
      top: '50%',
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: 'black',
    },
    fundDeleteWrap: {
      width: 50,
      display: 'flex',
      justifyContent: 'center',
      marginTop: 52,
    },
    addItem: {
      border: `1px solid ${theme.palette.grey[300]}`,
      color: 'black',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 14,
      height: 'auto',
      padding: '12px 20px',
      minWidth: 'unset',
    },
    entriesCounter: {
      color: theme.palette.primary.main,
    },
    buttonWrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 20,
    },
  };
});
