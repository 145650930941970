export const getErrorMessage = (err: unknown): string => {
  const { code } = err as { code: string };
  return (
    {
      CodeMismatchException: `Incorrect code. Check the code in the text message we've sent and try again.`,
      ExpiredCodeException: `Code expired. Please start the reset password procedure again.`,
      InternalErrorException: `Internal server error. Please try again.`,
      InvalidLambdaResponseException: `Internal server error. Please try again.`,
      InvalidParameterException: `Incorrect value in the form. Check highlighted field, correct and try again.`,
      InvalidPasswordException: `Login failed. Your email and/or password do not match. Check and try again.`,
      LimitExceededException: `Change password failed, due to time out. Please start the reset password procedure again.`,
      NotAuthorizedException: `Login failed. Your email and/or password do not match. Check and try again. If you have trouble logging in, please contact your administator.`,
      ResourceNotFoundException: `Internal server error. Please try again.`,
      TooManyFailedAttemptsException: `Change password failed. Exceeded retry attempts. Please start the procedure again.`,
      TooManyRequestsException: `Change password failed. Exceeded retry attempts. Please start the procedure again.`,
      UnexpectedLambdaException: `Internal server error. Please try again.`,
      UserLambdaValidationException: `Internal server error. Please try again.`,
      UserNotConfirmedException: `Internal server error. Please contact your administrator.`,
      UserNotFoundException: `Login failed. Your email and/or password do not match. Check and try again. If you have trouble logging in, please contact your administator.`,
    }[code] ?? 'Unexpected error'
  );
};

export const isErrorCode = <T extends string>(
  err: unknown,
  code: T,
): boolean => {
  const { code: errCode } = err as { code: T };
  return code === errCode;
};

export const hasFormErrors = (errors: (string | null)[]): boolean =>
  errors.some((err) => err !== null);
