import { Box, Button, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';

import { JourneyStep, UpdateJourneyInput } from 'src/apollo';

import { useStyles } from './styles';

const maxCharLength = 750;

const getTitle = (step: JourneyStep | 'portfolioSelection') =>
  ({
    [JourneyStep.BasicInformation]: 'Basic Information',
    [JourneyStep.CapacityLoss]: 'Capacity for Loss',
    [JourneyStep.ConfidenceComposure]: 'Confidence & Composure',
    [JourneyStep.FeelAboutRisk]: 'How do you feel about taking risk?',
    [JourneyStep.Income]: 'Regular Withdrawals',
    [JourneyStep.KnowledgeExperience]: 'Knowledge & Experience',
    [JourneyStep.MapThirdPart]: 'Risk Profile Mapping',
    [JourneyStep.PastExperience]: 'Past Experiences',
    [JourneyStep.PortfolioCost]: 'Portfolio Cost',
    [JourneyStep.PortfolioStyle]: 'Portfolio Style',
    [JourneyStep.SatelliteSelection]: 'Portfolio Style',
    [JourneyStep.PortfolioType]: 'Portfolio Type',
    [JourneyStep.ReasonsInvesting]: 'Reasons for Investing',
    [JourneyStep.ReasonsInvestingSelected]: 'Reasons for Investing',
    [JourneyStep.ReportFactsheet]: 'Report & Factsheet',
    [JourneyStep.ResponsibleInvesting]: 'Responsible Investing',
    [JourneyStep.RiskProfile]: 'Risk Profile',
    [JourneyStep.RiskProfileSelection]: 'Portfolio Style Selection',
    [JourneyStep.RiskTolerance]: 'Attitude to Risk',
    [JourneyStep.SuggestedPortfolio]: 'Suggested Portfolio',
    [JourneyStep.SatellitePortfolios]: 'Satellite Portfolios',
    [JourneyStep.TaxWrapper]: 'Portfolio Selection',
    [JourneyStep.TimeHorizon]: 'Time Horizon',
    [JourneyStep.ToleranceCapitalLoss]: 'Tolerance for Capital Loss',
    portfolioSelection: 'Portfolio Style Selection',
  })[step];

type JourneyObjects = Pick<
  UpdateJourneyInput,
  | 'income'
  | 'knowledgeExperience'
  | 'portfolioSelection'
  | 'portfolioType'
  | 'reasonsInvesting'
  | 'incomeCostSustainability'
  | 'responsibleInvesting'
  | 'taxWrapper'
  | 'riskProfile'
  | 'timeHorizon'
>;

interface Props {
  value: Partial<UpdateJourneyInput>;
  onClose: () => void;
  onSave: (journey: Partial<UpdateJourneyInput>) => void;
}

export const JourneyNotes: FC<Props> = ({ value, onClose, onSave }) => {
  const { classes } = useStyles();

  let { step } = value;
  if (value.step === JourneyStep.ReasonsInvestingSelected) {
    step = JourneyStep.ReasonsInvesting;
  } else if (
    value.step === JourneyStep.RiskTolerance ||
    value.step === JourneyStep.ToleranceCapitalLoss ||
    value.step === JourneyStep.FeelAboutRisk ||
    value.step === JourneyStep.ConfidenceComposure ||
    value.step === JourneyStep.PastExperience ||
    value.step === JourneyStep.CapacityLoss ||
    value.step === JourneyStep.MapThirdPart ||
    value.step === JourneyStep.RiskProfileSelection
  ) {
    step = JourneyStep.RiskProfile;
  } else if (value.step === JourneyStep.PortfolioStyle) {
    step = 'portfolioSelection' as JourneyStep;
  }

  const isGeneralNotes =
    step === JourneyStep.BasicInformation ||
    step === JourneyStep.SuggestedPortfolio ||
    step === JourneyStep.ReportFactsheet ||
    !!value.isRiskProfile;

  const journeyPart = step as keyof JourneyObjects;
  const initialNote = isGeneralNotes
    ? value.notes
    : value?.[journeyPart]?.notes;
  const [notes, setNotes] = useState(initialNote ?? '');

  const handleSave = () => {
    onSave(
      isGeneralNotes
        ? { notes }
        : {
            [journeyPart]: {
              ...(value[journeyPart] ?? {}),
              notes,
            },
          },
    );
    onClose();
  };

  const currCharLength = notes.length;

  const handleNoteChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setNotes(ev.target.value);
  };

  const exceedMaxCharLimit = currCharLength > maxCharLength;

  return (
    <>
      <Box py={10} px="80px" className={classes.root}>
        <div className={classes.textWrapper}>
          <Typography variant="body1">
            {getTitle(step as JourneyStep)}
          </Typography>
          <Typography
            color={exceedMaxCharLimit ? 'error' : 'textPrimary'}
            variant="body2"
            className={classes.charCounter}
          >
            {currCharLength}/{maxCharLength}
          </Typography>
        </div>
        <TextField
          className={classes.textarea}
          value={notes}
          rows={15}
          maxRows={15}
          onChange={handleNoteChange}
          multiline
          fullWidth
        />
      </Box>
      <Box className={classes.actions}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={exceedMaxCharLimit} onClick={handleSave}>
          Save
        </Button>
      </Box>
    </>
  );
};
