import { alpha, AppBar, Box, Typography, useTheme } from '@mui/material';
import { CSSProperties, FC, memo, ReactNode } from 'react';

import { BackButton } from 'src/components/ui';
import { Routes } from 'src/routing';

import { strHeaderHeight } from 'src/utils';
import { defaultContentWidth } from 'src/utils/contentWidth';
import { useStyles } from './styles';

interface Props {
  children: NonNullable<ReactNode>;
  title: string;
  middleTitle?: ReactNode;
  subTitle?: string | ReactNode;
  endActions?: ReactNode;
  backTo?: Routes | string;
  style?: CSSProperties;
  headerSyle?: CSSProperties;
  gradient?: string;
  width?: number;
  headerGradient?: boolean;
  contentWidthHeader?: boolean;
  onBackClick?: () => void;
  scrollable?: boolean;
}

export const FullPage: FC<Props> = memo(
  ({
    children,
    endActions,
    title,
    middleTitle,
    subTitle,
    backTo,
    style,
    gradient,
    headerGradient,
    onBackClick,
    contentWidthHeader,
    headerSyle,
    width = defaultContentWidth,
    scrollable,
  }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    const actions = endActions && (
      <Box display="flex" marginLeft="auto" height="100%">
        {endActions}
      </Box>
    );
    return (
      <Box
        className={classes.root}
        sx={{
          background: gradient
            ? `linear-gradient(0deg, ${alpha(
                theme.palette.primary.main,
                0,
              )} calc(100% - ${gradient} - 128px), ${alpha(
                theme.palette.primary.main,
                0.08,
              )} calc(100% - ${strHeaderHeight}), white calc(100% - 128px), white 100%)`
            : undefined,
          overflowY: scrollable ? 'auto' : 'hidden',
          overflowX: 'hidden',
        }}
      >
        <AppBar
          className={classes.appBar}
          style={headerSyle}
          sx={{
            padding: contentWidthHeader ? '0px 8px' : undefined,
            boxShadow: gradient
              ? undefined
              : 'rgb(238, 238, 238) 0px 0px 4px 0px',
            background: headerGradient
              ? `linear-gradient(to right, ${alpha(
                  theme.palette.primary.main,
                  0.05,
                )} 20%, white 60%)`
              : undefined,
          }}
        >
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            flexDirection="row"
            margin="auto"
            maxWidth={contentWidthHeader ? width : '100%'}
          >
            {(backTo || onBackClick) && (
              <Box pr={3}>
                <BackButton
                  sx={{ transform: 'translateX(-12px)' }}
                  to={backTo}
                  onClick={onBackClick}
                />
              </Box>
            )}
            <Box
              className={
                subTitle !== undefined ? classes.multilineTitleContainer : ''
              }
            >
              <Typography
                variant="h2"
                fontSize={headerGradient ? 22 : 28}
                color={headerGradient ? 'primary' : undefined}
              >
                {title}
              </Typography>
              <Typography variant="h3" color="primary">
                {subTitle}
              </Typography>
              {middleTitle}
            </Box>
            {actions}
            {headerGradient && (
              <Box
                position="absolute"
                left="0px"
                bottom="0px"
                width="100%"
                height="1px"
                sx={{
                  background: `linear-gradient(to right, ${alpha(
                    theme.palette.primary.main,
                    0.5,
                  )} 10%, white 80%)`,
                }}
              />
            )}
          </Box>
        </AppBar>
        <Box className={classes.content} component="main" style={style}>
          {children}
        </Box>
      </Box>
    );
  },
);
