import { Path, Svg } from '@react-pdf/renderer';
import { FC } from 'react';

interface Props {
  color: string;
}

export const Slant3: FC<Props> = ({ color }) => {
  return (
    <Svg width="271" height="260" viewBox="0 0 271 260">
      <Path
        opacity="0.15"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.205811 260L271 0L164 260H0.205811Z"
        fill={color}
      />
    </Svg>
  );
};
