import { alpha, Theme } from '@mui/material';
import { Styles, Text, View } from '@react-pdf/renderer';
import { FC } from 'react';

interface Props {
  theme: Theme;
  style?: Styles;
  selected?: boolean;
  wide?: boolean;
  label: string;
  subLabel?: string;
  center?: boolean;
}

export const Cell: FC<Props> = ({
  theme,
  style,
  selected,
  center,
  wide,
  label,
  subLabel,
}) => {
  const boldLabel = selected || (wide && subLabel);

  return (
    <View
      style={{
        width: wide ? 100 : 70,
        color: selected ? theme.palette.primary.main : 'black',
        fontFamily: 'Montserrat',
        padding: '4px 0px',
        alignItems: center ? 'center' : 'flex-start',
        justifyContent: 'center',
        position: 'relative',
        ...style,
      }}
    >
      {selected && (
        <View
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: 100,
            width: '100%',
            borderRadius: 4,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          }}
        />
      )}
      <Text
        style={{
          fontWeight: boldLabel ? 700 : 500,
          fontSize: 7,
        }}
      >
        {label}
      </Text>
      {subLabel && (
        <Text
          style={{
            fontWeight: selected ? 600 : 500,
            fontSize: 7,
            fontStyle: 'italic',
          }}
        >
          {subLabel}
        </Text>
      )}
    </View>
  );
};
