import axios from 'axios';
import { getToken } from 'src/auth';
import { Satelite } from 'src/components/pages/Factsheets/SateliteList/Satelite';
import { SATELLITE_LAMBDA_URL } from './lambda-routes';

export const getSateliteById = async (sateliteId: string) => {
  const options = {
    headers: {
      Authorization: await getToken(),
    },
  };

  const response = await axios
    .get(`${SATELLITE_LAMBDA_URL}/satelite/${sateliteId}`, options)
    .catch((error) => {
      throw new Error(error.response?.data?.error ?? 'Error');
    });

  return response.data as Satelite;
};
