import axios from 'axios';
import { getToken } from 'src/auth';
import { QueryGetJourneysArgs } from 'src/apollo';
import { ClientProfile } from 'src/components/pages/Customers/types';
import { CLIENT_PROFILE_LAMBDA_URL } from './lambda-routes';

type Args = QueryGetJourneysArgs & { all?: boolean };
type Data = {
  data: ClientProfile[];
  pageCount: number;
};

export async function getClients(queryArgs?: Args, userId?: string) {
  const options = {
    headers: {
      Authorization: await getToken(),
    },
  };

  const response = await axios
    .post(
      `${CLIENT_PROFILE_LAMBDA_URL}/profiles`,
      { queryArgs, userId },
      options,
    )
    .catch((error) => {
      throw new Error(error.response?.data?.error ?? 'Error');
    });

  return response.data as Data;
}
