import { FC, ReactElement, useMemo } from 'react';
import { SvgIcon, SvgIconTypeMap, Theme, useTheme } from '@mui/material';

interface Props {
  className?: string;
  color?: SvgIconTypeMap['props']['color'];
  fontSize?: SvgIconTypeMap['props']['fontSize'];
}

interface Params {
  svg: (theme: Theme) => ReactElement;
  fontSize?: SvgIconTypeMap['props']['fontSize'];
  viewBox?: SvgIconTypeMap['props']['viewBox'];
}

export const createIcon = ({ svg, fontSize, viewBox }: Params): FC<Props> => {
  return ({ className, color, fontSize: fontSizeProps }) => {
    const theme = useTheme();
    const svgEl = useMemo(() => svg(theme), [theme]);
    return (
      <SvgIcon
        className={className}
        fontSize={fontSizeProps ?? fontSize}
        viewBox={viewBox}
        color={color}
      >
        {svgEl}
      </SvgIcon>
    );
  };
};
