import { forwardRef, Ref } from 'react';
import { Slide, SlideProps } from '@mui/material';

const slideTimeout = 300;

export const Transition = forwardRef(function Transition(
  props: SlideProps,
  ref: Ref<unknown>,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} timeout={slideTimeout} />;
});
