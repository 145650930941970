import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, SxProps, Theme } from '@mui/material';

import { Routes } from 'src/routing';

import { ArrowLeftIcon } from '../icons';

interface Props {
  to?: Routes | string;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

export const BackButton: FC<Props> = ({ to, sx, onClick }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (to) {
      navigate(to);
    }
  };
  return (
    <IconButton sx={sx} onClick={handleClick} size="large">
      <ArrowLeftIcon />
    </IconButton>
  );
};
