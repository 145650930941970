import { createIcon } from '../createIcon';

export const ArrowRightIcon = createIcon({
  svg: () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M12.0788 6.5L6.28652 0.7077L7 0L14 7L7 14L6.28652 13.2923L12.0788 7.5L0 7.5L0 6.5L12.0788 6.5Z"
        fill="currentColor"
      />
    </svg>
  ),
  fontSize: 'small',
  viewBox: '0 0 14 14',
});
