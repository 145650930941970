import { createIcon } from '../createIcon';

export const FactsheetsIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0607 2.35348C20.1157 1.40853 18.5 2.07779 18.5 3.41414V6.99993C18.5 7.82836 19.1716 8.49993 20 8.49993H23.5858C24.9221 8.49993 25.5914 6.88422 24.6464 5.93927L21.0607 2.35348ZM19.5 3.41414C19.5 2.96869 20.0386 2.74561 20.3536 3.06059L23.9393 6.64637C24.2543 6.96136 24.0312 7.49993 23.5858 7.49993H20C19.7239 7.49993 19.5 7.27607 19.5 6.99993V3.41414Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 2C7.67157 2 7 2.67157 7 3.5V12.5H4.69243C3.96703 12.5 3.34557 13.0191 3.2164 13.7329L2.02397 20.3227C2.00802 20.4108 2 20.5002 2 20.5897V28.5C2 29.3284 2.67157 30 3.5 30H28.5C29.3284 30 30 29.3284 30 28.5V20.5897C30 20.5002 29.992 20.4108 29.976 20.3227L28.7836 13.7329C28.6544 13.0191 28.033 12.5 27.3076 12.5H25V9.5C25 9.22386 24.7761 9 24.5 9C24.2239 9 24 9.22386 24 9.5V18.5C24 18.7761 24.2239 19 24.5 19C24.7761 19 25 18.7761 25 18.5V13.5H27.3076C27.5494 13.5 27.7565 13.673 27.7996 13.911L28.9118 20.0572C28.7809 20.02 28.6428 20 28.5 20H22.5C21.6716 20 21 20.6716 21 21.5V23.5C21 23.7761 20.7761 24 20.5 24H11.5C11.2239 24 11 23.7761 11 23.5V21.5C11 20.6716 10.3284 20 9.5 20H3.5C3.35721 20 3.21908 20.02 3.08824 20.0572L4.20042 13.911C4.24348 13.673 4.45063 13.5 4.69243 13.5H7V18.5C7 18.7761 7.22386 19 7.5 19C7.77614 19 8 18.7761 8 18.5V3.5C8 3.22386 8.22386 3 8.5 3H17.5C17.7761 3 18 2.77614 18 2.5C18 2.22386 17.7761 2 17.5 2H8.5ZM29 21.5V28.5C29 28.7761 28.7761 29 28.5 29H3.5C3.22386 29 3 28.7761 3 28.5V21.5C3 21.2239 3.22386 21 3.5 21H9.5C9.77614 21 10 21.2239 10 21.5V23.5C10 24.3284 10.6716 25 11.5 25H20.5C21.3284 25 22 24.3284 22 23.5V21.5C22 21.2239 22.2239 21 22.5 21H28.5C28.7761 21 29 21.2239 29 21.5Z"
        fill="currentColor"
      />
      <path
        d="M11.5 6C11.2239 6 11 6.22386 11 6.5C11 6.77614 11.2239 7 11.5 7H15.5C15.7761 7 16 6.77614 16 6.5C16 6.22386 15.7761 6 15.5 6H11.5Z"
        fill="currentColor"
      />
      <path
        d="M11.5 9C11.2239 9 11 9.22386 11 9.5C11 9.77614 11.2239 10 11.5 10H15.5C15.7761 10 16 9.77614 16 9.5C16 9.22386 15.7761 9 15.5 9H11.5Z"
        fill="currentColor"
      />
      <path
        d="M11.5 12C11.2239 12 11 12.2239 11 12.5C11 12.7761 11.2239 13 11.5 13H20.5C20.7761 13 21 12.7761 21 12.5C21 12.2239 20.7761 12 20.5 12H11.5Z"
        fill="currentColor"
      />
      <path
        d="M11.5 15C11.2239 15 11 15.2239 11 15.5C11 15.7761 11.2239 16 11.5 16H20.5C20.7761 16 21 15.7761 21 15.5C21 15.2239 20.7761 15 20.5 15H11.5Z"
        fill="currentColor"
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
