import { Storage } from '@aws-amplify/storage';

import { storagePDFConfig } from '../config';

export const getPdf = async (
  filename: string,
  download = false,
): Promise<string> => {
  Storage.configure(storagePDFConfig);
  try {
    const response: any = await Storage.get(`${filename}`, {
      customPrefix: { public: '' },
      download,
      contentType: download ? undefined : 'application/pdf',
    });
    return download
      ? URL.createObjectURL((response as { Body: Blob }).Body)
      : response.toString();
  } catch (e) {
    return '';
  }
};
