import { createIcon } from '../createIcon';

export const FolderIcon = createIcon({
  svg: () => (
    <g fill="none">
      <rect
        x="2.5"
        y="9.5"
        width="27"
        height="19"
        rx="2.5"
        stroke="currentColor"
      />
      <path
        d="M2.5 16V11.7426C2.5 10.947 2.81607 10.1839 3.37868 9.62132L5.91421 7.08579C6.28929 6.71071 6.79799 6.5 7.32843 6.5H9"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M29.5 16V11.7426C29.5 10.947 29.1839 10.1839 28.6213 9.62132L26.0858 7.08579C25.7107 6.71071 25.202 6.5 24.6716 6.5H23"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <rect
        x="5.5"
        y="12.5"
        width="9"
        height="5"
        rx="0.5"
        stroke="currentColor"
      />
      <path
        d="M11 7.5V4.5C11 3.94772 11.4477 3.5 12 3.5H20C20.5523 3.5 21 3.94772 21 4.5V7.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
