import { makeStyles } from 'tss-react/mui';

const inputHeight = 48;

export const useStyles = makeStyles()((theme) => ({
  fundSection: {
    marginBottom: theme.spacing(15),
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(8),
    fontStyle: 'italic',
  },
  row: {
    backgroundColor: '#ccc',
  },
  foundList: {
    padding: 0,
  },
  itemContent: {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 0, 2),
    '& > svg': {
      width: 16,
    },
  },
  foundNameInput: {
    height: inputHeight,
    margin: theme.spacing(0, 4, 0, 6),
    width: '57%',
  },
  buttonsWrap: {
    height: 23,
    width: '28%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  allocationInput: {
    height: inputHeight,
    margin: theme.spacing(0, 2, 0, 2),
    width: '15%',
  },
  addLineButton: {
    height: inputHeight,
    width: 168,
    marginLeft: theme.spacing(10),
    '& svg': {
      marginRight: theme.spacing(2),
    },
  },
  rowIcon: {
    borderRadius: 4,
    width: 35,
    border: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    position: 'relative',
    fontWeight: theme.typography.fontWeightMedium,
    '&.selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    '& svg': {
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
}));
