import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  wrapper: {
    display: 'inline-block',
    paddingTop: 55,
    position: 'absolute',
    bottom: 0,
    left: '50%',
  },
}));
