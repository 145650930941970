import { createIcon } from '../createIcon';

export const ResourceIcon = createIcon({
  svg: (theme) => (
    <svg width="260" height="160" viewBox="0 0 260 160" fill="none">
      <rect
        width="260"
        height="160"
        rx="4"
        fill={theme.palette.primary.main}
        fillOpacity="0.1"
      />
      <mask
        id="mask0_3662_4539"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="260"
        height="160"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H256C258.209 0 260 1.79086 260 4V160H0V4Z"
          fill="url(#paint0_linear_3662_4539)"
        />
      </mask>
      <g mask="url(#mask0_3662_4539)">
        <path d="M28 182L210 0" stroke="white" strokeWidth="2" />
        <path d="M36 182L218 0" stroke="white" strokeWidth="2" />
        <path d="M44 182L226 0" stroke="white" strokeWidth="2" />
        <path d="M52 182L233 0" stroke="white" strokeWidth="2" />
        <path d="M59 182L240 0" stroke="white" strokeWidth="2" />
        <path d="M66 182L247 0" stroke="white" strokeWidth="2" />
        <path d="M73 182L254 0" stroke="white" strokeWidth="2" />
        <path d="M80 182L261 0" stroke="white" strokeWidth="2" />
        <path d="M87 182L269 0" stroke="white" strokeWidth="2" />
        <path d="M95 182L277 0" stroke="white" strokeWidth="2" />
        <path d="M103 182L285 0" stroke="white" strokeWidth="2" />
        <path d="M111 182L293 0" stroke="white" strokeWidth="2" />
        <path d="M119 182L300 0" stroke="white" strokeWidth="2" />
        <path d="M126 182L307 0" stroke="white" strokeWidth="2" />
        <path d="M133 182L314 0" stroke="white" strokeWidth="2" />
        <path d="M140 182L321 0" stroke="white" strokeWidth="2" />
        <path d="M147 182L329 0" stroke="white" strokeWidth="2" />
        <path d="M155 182L337 0" stroke="white" strokeWidth="2" />
        <path d="M163 182L345 0" stroke="white" strokeWidth="2" />
        <path d="M171 182L353 0" stroke="white" strokeWidth="2" />
        <path d="M179 182L361 0" stroke="white" strokeWidth="2" />
        <path d="M187 182L368 0" stroke="white" strokeWidth="2" />
        <path d="M194 182L375 0" stroke="white" strokeWidth="2" />
        <path d="M201 182L382 0" stroke="white" strokeWidth="2" />
        <path d="M208 182L389 0" stroke="white" strokeWidth="2" />
        <path d="M215 182L397 0" stroke="white" strokeWidth="2" />
        <path d="M223 182L405 0" stroke="white" strokeWidth="2" />
        <path d="M231 182L413 0" stroke="white" strokeWidth="2" />
        <path d="M239 182L421 0" stroke="white" strokeWidth="2" />
        <path d="M247 182L428 0" stroke="white" strokeWidth="2" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3662_4539"
          x1="153.041"
          y1="80.0001"
          x2="260"
          y2="80.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.palette.primary.main} />
          <stop
            offset="1"
            stopColor={theme.palette.primary.main}
            stopOpacity="0"
          />
        </linearGradient>
      </defs>
    </svg>
  ),
  viewBox: '0 0 260 160',
});
