import { createIcon } from '../createIcon';

export const RealAssetsIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 2C2.22386 2 2 2.22386 2 2.5C2 2.77614 2.22386 3 2.5 3C5.14227 3 7.25645 3.83856 8.70895 5.29105C9.44695 6.02906 10.0265 6.93788 10.418 8H9.5C8.67157 8 8 8.67157 8 9.5V17.0858C8 17.4836 8.15804 17.8651 8.43934 18.1464L20.4393 30.1464C21.0251 30.7322 21.9749 30.7322 22.5607 30.1464L30.1464 22.5607C30.7322 21.9749 30.7322 21.0251 30.1464 20.4393L18.1464 8.43934C17.8651 8.15804 17.4836 8 17.0858 8H13C12.7239 8 12.5 8.22386 12.5 8.5C12.5 8.77614 12.7239 9 13 9H17.0858C17.2184 9 17.3456 9.05268 17.4393 9.14645L29.4393 21.1464C29.6346 21.3417 29.6346 21.6583 29.4393 21.8536L21.8536 29.4393C21.6583 29.6346 21.3417 29.6346 21.1464 29.4393L9.14645 17.4393C9.05268 17.3456 9 17.2184 9 17.0858V9.5C9 9.22386 9.22386 9 9.5 9H10.7196C10.8053 9.35814 10.872 9.73058 10.9188 10.1168C10.379 10.3439 10 10.8777 10 11.5C10 12.3284 10.6716 13 11.5 13C12.3284 13 13 12.3284 13 11.5C13 10.8169 12.5434 10.2405 11.9188 10.0592C11.6616 7.83744 10.799 5.96686 9.41605 4.58395C7.74355 2.91144 5.35773 2 2.5 2Z"
        fill="currentColor"
      />
    </g>
  ),
  fontSize: 'inherit',
  viewBox: '0 0 32 32',
});
