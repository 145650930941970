import { createIcon } from '../createIcon';

export const ErrorIcon = createIcon({
  svg: () => (
    <g fill="currentColor" fillRule="nonzero">
      <g>
        <path
          d="M4.626 7.676c6.51-4.901 15.477-4.901 21.986 0 .097.073.164.178.189.295.275 1.296.405 2.619.386 3.936.006 7.446-4.505 14.15-11.405 16.95-.12.049-.255.049-.376 0C8.503 26.062 3.99 19.354 4 11.909c-.005-1.328.142-2.652.44-3.946.026-.114.092-.216.186-.287zm21.07.567c-6.125-4.4-14.42-4.323-20.468.232l.033-.026.115-.084-.073.347c-.172.876-.27 1.765-.296 2.659L5 11.908c-.01 7.04 4.256 13.38 10.781 16.022l-.188-.079.148-.061c6.222-2.677 10.307-8.748 10.443-15.526l.003-.364c.018-1.25-.104-2.498-.364-3.72l.035.182zM12.217 12.35l.07.058 3.332 3.332 3.333-3.332c.196-.196.512-.196.707 0 .174.173.193.443.058.638l-.058.069-3.333 3.332 3.333 3.334c.196.195.196.511 0 .707-.173.173-.443.193-.637.058l-.07-.058-3.333-3.334-3.332 3.334c-.196.195-.512.195-.708 0-.173-.174-.192-.443-.057-.638l.057-.07 3.333-3.333-3.333-3.332c-.195-.195-.195-.512 0-.707.174-.174.443-.193.638-.058z"
          transform="translate(-378 -300) translate(378 300)"
        />
      </g>
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
