import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(8),
    height: 206,
    flexShrink: 0,
  },
  box: {
    display: 'block',
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
  dragger: {
    position: 'absolute',
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(5),
    transform: 'translate(-50%, -50%)',
    border: '1px solid',
    borderColor: 'rgba(71, 71, 71, 0.52)',
    background: alpha(theme.palette.common.white, 0.26),
    cursor: 'pointer',
  },
}));
