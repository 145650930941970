export type Config = {
  message: string;
  onTryAgain?: () => void;
  type?: SnackType;
  timeout?: number;
  multiline?: boolean;
};

export type SnackValue = (config: Config) => () => void;

export enum SnackType {
  Success,
  Error,
  Warning,
  Neutral,
}

export type SnackState = Config & {
  id: number;
  open: boolean;
};
