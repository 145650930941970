export const storageImagesConfig = {
  AWSS3: {
    bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    region: process.env.REACT_APP_AWS_REGION,
  },
};

export const storagePDFConfig = {
  AWSS3: {
    bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME_PDF,
    region: process.env.REACT_APP_AWS_REGION,
  },
};

export const storageResourcesConfig = {
  AWSS3: {
    bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME_RESOURCES,
    region: process.env.REACT_APP_AWS_REGION,
  },
};

export const authConfig = {
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
  identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
};
