import { createIcon } from '../createIcon';

export const BackArrowIcon = createIcon({
  svg: () => (
    <g transform="translate(-40 -962) translate(40 962)">
      <circle
        cx="16"
        cy="16"
        r="15.5"
        stroke="#BDBDBD"
        fill="none"
        opacity=".5"
      />
      <g stroke="#18263F" strokeWidth=".5">
        <path
          d="M15.902 5.098l-5-5c-.13-.13-.34-.13-.471 0-.13.13-.13.34 0 .471L14.862 5H.333C.15 5 0 5.15 0 5.333c0 .185.15.334.333.334h14.529l-4.431 4.43c-.13.13-.13.342 0 .472.065.065.15.098.236.098.085 0 .17-.033.235-.098l5-5c.13-.13.13-.341 0-.471z"
          transform="matrix(-1 0 0 1 24 11)"
        />
      </g>
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
