import { Navigate } from 'react-router-dom';
import { Routes } from '../routes';

interface Params {
  block?: boolean;
  navigateTo?: string;
  children?: any;
}

export const GuardedRoute = ({
  block,
  children,
  navigateTo = Routes.Dashboard,
}: Params) => (block ? <Navigate to={navigateTo} /> : children);
