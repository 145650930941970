import { useEffect, useState } from 'react';

export const useDetectChanges = () => {
  const state = useState(false);
  const [hasChanges] = state;
  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      /* eslint-disable no-param-reassign */
      event.returnValue = '';
    };
    if (hasChanges) {
      window.addEventListener('beforeunload', handler);
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
    return () => {};
  }, [hasChanges]);

  return state;
};
