import { useContext, useEffect, useMemo, useRef } from 'react';

import { SnackContext } from './SnackController';
import { Config } from './types';

export interface UseSnack {
  close: () => void;
  show: (options: Config) => void;
}

export const useSnack = (): UseSnack => {
  const closeRef = useRef<() => void>();
  const showSnack = useContext(SnackContext);
  useEffect(() => () => closeRef.current?.(), []);
  const ref: UseSnack = useMemo(
    () => ({
      close: () => {
        closeRef.current?.();
        closeRef.current = undefined;
      },
      show: (options) => {
        closeRef.current?.();
        closeRef.current = showSnack(options);
      },
    }),
    [showSnack],
  );
  return ref;
};
