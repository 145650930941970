import { useContext, useEffect, useMemo, useRef } from 'react';
import { OverlayContext } from './OverlayController';

export interface UseOverlay {
  show: () => void;
  close: () => void;
}

export const useOverlay = (): UseOverlay => {
  const closeRef = useRef<() => void>();
  const showOverlay = useContext(OverlayContext);

  useEffect(() => {
    return () => closeRef.current?.();
  }, []);

  return useMemo(
    () => ({
      close: () => {
        closeRef.current?.();
        closeRef.current = undefined;
      },
      show: () => {
        closeRef.current?.();
        const { close } = showOverlay();
        closeRef.current = close;
      },
    }),
    [showOverlay],
  );
};
