import { Feature } from 'src/components/pages/CompanyPage/types';
import { useCompany } from './useCompany';

export const useFeatures = () => {
  const ifaCompany = useCompany();

  const hasFeature = (f: Feature) => {
    return !ifaCompany?.disabledFeatures?.includes(f);
  };

  return {
    hasPortfolioChanges: hasFeature(Feature.PortfolioChanges),
    hasMultiAssets: hasFeature(Feature.MultiAssets),
  };
};
