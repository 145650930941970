import { lighten, Theme } from '@mui/material';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { formatDate } from 'src/utils';

interface Props {
  theme: Theme;
  name?: string;
}

export const Footer: FC<Props> = ({ theme, name }) => {
  const today = formatDate(Date.now(), false, true, {
    month: 'short',
  });

  return (
    <View
      style={{
        justifyContent: 'space-between',
        flexDirection: 'row',
        fontFamily: 'Lato',
        marginTop: 'auto',
        paddingBottom: 10,
        paddingLeft: 30,
        marginRight: 95,
      }}
    >
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          width: 200,
          color: theme.palette.grey[500],
        }}
      >
        {name ? (
          <View>
            <Text style={{ marginBottom: 4, fontSize: 10 }}>
              Prepared by{' '}
              <Text style={{ fontWeight: 600, fontFamily: 'Lato' }}>
                {name}
              </Text>
            </Text>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <Text>on </Text>
              <Text style={{ fontWeight: 600 }}>{today}</Text>
            </View>
          </View>
        ) : (
          <View style={{ fontSize: 10 }}>
            <Text>Prepared on </Text>
            <Text style={{ fontWeight: 600 }}>{today}</Text>
          </View>
        )}
      </View>
      <View
        style={{
          height: '100%',
          width: 1.5,
          backgroundColor: lighten(theme.palette.primary.main, 0.7),
        }}
      />
      <View
        style={{
          padding: '5px 0px 5px 10px',
          color: lighten('#262626', 0.5),
          width: 'calc(100% - 150px)',
        }}
      >
        <Text
          style={{ fontSize: 7, fontStyle: 'italic', textAlign: 'justify' }}
        >
          Source: Dimensional Returns, Aspen. The composite indices are indices
          are made up of equity, bond and cash allocations, corresponding to
          risk profile. For more information on index construction, please
          contact your financial adviser. Advisers - please contact Aspen. All
          returns are total returns, net of 0.75% annual fee. Past performance
          is not a guide to future returns.{' '}
          <Text style={{ fontWeight: 600, fontSize: 7, fontStyle: 'normal' }}>
            Performance summary statistics and projections are for information
            only. Losses may exceed those indicated.{' '}
          </Text>
          Any projections are for illustrative purposes only, based off historic
          data. Returns in the future may be higher or lower than those shown.
          {'\n\n'}
        </Text>
        <Text
          style={{ fontSize: 7, fontStyle: 'italic', textAlign: 'justify' }}
        >
          The value of investments and the income from them can go down as well
          as up and investors may not recover the amount of their original
          investment. The information in this illustration is believed to be
          correct but cannot be guaranteed. No representation or warranty
          (express or otherwise) is given as to the accuracy or completeness of
          the information contained in this illustration. This document is for
          illustrative purposes only and does not constitute advice. It does not
          constitute an offer to sell or a solicitation of an offer to purchase
          any security or any other investment or product. Aspen will not accept
          any liability for the consequences of acting or not acting upon the
          information contained in this publication. Opinions expressed are
          solely the opinions of Aspen. ‘Aspen’ is a trading name of Aspen
          Advisers Limited, a company authorised and regulated by the FCA with
          FRN: 1007296, registered in England and Wales with company number
          14115281.
        </Text>
      </View>
    </View>
  );
};
