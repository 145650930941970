import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { validatePassword } from 'src/utils';

import { useStyles } from './styles';

interface Pair {
  label: string;
  key: string;
}

const passwordCheckList: Pair[] = [
  {
    label: '8 characters',
    key: 'minLength',
  },
  {
    label: 'One upper case letter',
    key: 'uppercase',
  },
  {
    label: 'One lower case letter',
    key: 'lowercase',
  },
  {
    label: 'One number',
    key: 'numerical',
  },
  {
    label: 'One special character (!@#$$%^&*, etc)',
    key: 'symbol',
  },
  {
    label: 'Both passwords need to match',
    key: 'passwordsEqual',
  },
];

interface Props {
  password: string;
  secondPassword: string;
}

export const PasswordValidationBox: FC<Props> = ({
  password,
  secondPassword,
}) => {
  const { classes } = useStyles();
  const { details } = validatePassword(password);

  const validationResult: { [key: string]: boolean } = {
    ...details,
    passwordsEqual: !!password && password === secondPassword,
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Typography className={classes.headerText}>
          Your password needs to contain:
        </Typography>
        {passwordCheckList.map(({ label, key }) => {
          return (
            <Box className={classes.requirementWrapper} key={key}>
              <Box>
                <CheckIcon
                  fontSize="small"
                  color={validationResult[key] ? 'primary' : 'inherit'}
                />
              </Box>
              <Typography variant="body2">{label}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
