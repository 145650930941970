import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(6),
    },
    '&.Mui-disabled': {
      opacity: 0.5,
      cursor: 'default',
      textDecoration: 'none',
    },
  },
  preview: {
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    height: 166,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 8,
    transition: '0.1s',
    padding: '20px 10px 60px 10px',
    '&:hover': {
      border: '1px solid black',
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  actionsWrap: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 20,
    display: 'flex',
    columnGap: 8,
    '& > div': {
      cursor: 'pointer',
      padding: '2px 12px',
      borderRadius: 16,
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
    },
  },
  iconWrapper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
