import { createIcon } from '../createIcon';

export const NotificationsIcon = createIcon({
  svg: ({ palette }) => (
    <g fill="none">
      <path
        d="M11.5365 27.9453L19.6679 15.7481C20.0638 15.1543 20.9362 15.1543 21.3321 15.7481L29.4635 27.9453C29.9066 28.6099 29.4302 29.5 28.6315 29.5H20.5H12.3685C11.5698 29.5 11.0934 28.6099 11.5365 27.9453Z"
        stroke={palette.primary.main}
      />
      <path
        d="M20.5 18.5V23.5M20.5 25.5V26.5"
        stroke={palette.primary.main}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.74423 7.90012C0.419695 7.09077 0.977374 5 2.51779 5H27.4822C29.0226 5 29.5803 7.09077 28.2558 7.90012L15 16L1.74423 7.90012ZM2.51779 6.03955C2.00432 6.03955 1.81843 6.73647 2.25994 7.00626L15 14.791L27.7401 7.00626C28.1816 6.73647 27.9957 6.03955 27.4822 6.03955H2.51779Z"
        fill={palette.primary.main}
      />
      <path
        d="M1.5 8.5C1.77614 8.5 2 8.72386 2 9V25C2 25.8284 2.67157 26.5 3.5 26.5H10.8043L10.1522 27.5H3.5C2.11929 27.5 1 26.3807 1 25V9C1 8.72386 1.22386 8.5 1.5 8.5Z"
        fill={palette.primary.main}
      />
      <path
        d="M29 24.875V9C29 8.72386 28.7761 8.5 28.5 8.5C28.2239 8.5 28 8.72386 28 9V23.4583L29 24.875Z"
        fill={palette.primary.main}
      />
    </g>
  ),
  fontSize: 'small',
  viewBox: '0 0 32 32',
});
