import { DocumentNode, gql } from '@apollo/client';

import {
  CHART_PDF_FIELDS,
  FACTSHEET_FIELDS,
  JOURNEY_FIELDS,
  STATISTICS_FIELDS,
} from './fragments';
import { Mutation } from './types';

export interface MutationData<T extends keyof Mutation> {
  mutation: DocumentNode;
  name: T;
}

export const REMOVE_IFA: MutationData<'removeIFA'> = {
  mutation: gql`
    mutation removeIFA($id: ID!) {
      removeIFA(id: $id)
    }
  `,
  name: 'removeIFA',
};

export const SET_IFA_STATUS: MutationData<'setIFAStatus'> = {
  mutation: gql`
    mutation setIFAStatus($id: ID!, $enabled: Boolean!) {
      setIFAStatus(id: $id, enabled: $enabled) {
        id
        enabled
      }
    }
  `,
  name: 'setIFAStatus',
};

export const SET_IFA_COMPANY_STATUS: MutationData<'setIFACompanyStatus'> = {
  mutation: gql`
    mutation setIFACompanyStatus($id: ID!, $enabled: Boolean!) {
      setIFACompanyStatus(id: $id, enabled: $enabled) {
        id
        enabled
      }
    }
  `,
  name: 'setIFACompanyStatus',
};

export const CREATE_JOURNEY: MutationData<'createJourney'> = {
  mutation: gql`
    mutation createJourney($journey: JourneyInput!) {
      createJourney(journey: $journey) {
        journeyId
        complete
        isShort
        isRiskProfile
        createdAt
        updatedAt
      }
    }
  `,
  name: 'createJourney',
};

export const UPDATE_JOURNEY: MutationData<'updateJourney'> = {
  mutation: gql`
    ${JOURNEY_FIELDS}
    mutation updateJourney($journey: UpdateJourneyInput!) {
      updateJourney(journey: $journey) {
        ...JourneyFields
      }
    }
  `,
  name: 'updateJourney',
};

export const REFRESH_REPORT: MutationData<'refreshReport'> = {
  mutation: gql`
    ${JOURNEY_FIELDS}
    mutation refreshReport($journeyId: ID!) {
      refreshReport(journeyId: $journeyId) {
        ...JourneyFields
      }
    }
  `,
  name: 'refreshReport',
};

export const CREATE_PORTFOLIO: MutationData<'createPortfolio'> = {
  mutation: gql`
    mutation createPortfolio($portfolio: PortfolioInput!) {
      createPortfolio(portfolio: $portfolio) {
        portfolioId
      }
    }
  `,
  name: 'createPortfolio',
};

export const UPLOAD_RETURNS: MutationData<'uploadReturns'> = {
  mutation: gql`
    mutation uploadReturns($returns: UploadReturnsInput!) {
      uploadReturns(returns: $returns) {
        returnsId
      }
    }
  `,
  name: 'uploadReturns',
};

export const DELETE_JOURNEY: MutationData<'deleteJourney'> = {
  mutation: gql`
    mutation deleteJourney($journeyId: ID!) {
      deleteJourney(journeyId: $journeyId)
    }
  `,
  name: 'deleteJourney',
};

export const CREATE_LABEL: MutationData<'createLabel'> = {
  mutation: gql`
    mutation createLabel($label: LabelInput!) {
      createLabel(label: $label) {
        labelId
      }
    }
  `,
  name: 'createLabel',
};

export const UPDATE_STATISTICS: MutationData<'updateStatistics'> = {
  mutation: gql`
    ${STATISTICS_FIELDS}
    mutation updateStatistics($statistics: UpdateStatisticsInput!) {
      updateStatistics(statistics: $statistics) {
        low {
          ...StatisticsFields
        }
        lower {
          ...StatisticsFields
        }
        medium {
          ...StatisticsFields
        }
        higher {
          ...StatisticsFields
        }
        high {
          ...StatisticsFields
        }
      }
    }
  `,
  name: 'updateStatistics',
};

export const UPDATE_CHART_PDF: MutationData<'updatePdfChart'> = {
  mutation: gql`
    ${CHART_PDF_FIELDS}
    mutation updatePdfChart($pdfChart: UpdatePdfChartInput!) {
      updatePdfChart(pdfChart: $pdfChart) {
        ...ChartFields
      }
    }
  `,
  name: 'updatePdfChart',
};

export const UPDATE_FACTSHEET: MutationData<'updateFactSheet'> = {
  mutation: gql`
    ${FACTSHEET_FIELDS}
    mutation updateFactSheet($factSheet: UpdateFactSheetInput!) {
      updateFactSheet(factSheet: $factSheet) {
        ...FactsheetFields
      }
    }
  `,
  name: 'updateFactSheet',
};

export const RESET_USER_PASSWORD: MutationData<'resetUserPassword'> = {
  mutation: gql`
    mutation resetUserPassword($id: ID!) {
      resetUserPassword(id: $id) {
        temporaryPassword
        username
      }
    }
  `,
  name: 'resetUserPassword',
};

export const MARK_ALL_MARKET_UPDATES_AS_READ: MutationData<'markAllMarketUpdatesAsRead'> =
  {
    mutation: gql`
      mutation markAllMarketUpdatesAsRead {
        markAllMarketUpdatesAsRead
      }
    `,
    name: 'markAllMarketUpdatesAsRead',
  };
