import { Box, Theme } from '@mui/material';
import { ReactNode } from 'react';

export type Downturn = {
  start: Date;
  end: Date;
  label: string;
  offset: number;
  position: 'top' | 'bottom';
  description: (theme: Theme) => ReactNode;
};

export const downturns: Downturn[] = [
  {
    start: new Date('2000-08-31'),
    end: new Date('2003-02-28'),
    label: '{b|Dot-Com Bubble}\n{c|Click for more}',
    offset: 115,
    position: 'top',
    description: (theme) => (
      <>
        <Box color={theme.palette.primary.main} fontWeight="bold" fontSize={18}>
          31/8/2000-28/02/2003
        </Box>
        <br />
        <b>Intro:</b> The early 2000 global bear market was set off by a
        confluence of factors, prominently including corporate scandals,
        economic sluggishness, and geopolitical tensions.
        <br />
        <br />
        <b>Duration:</b> The bear market began in August 2000 and ended in
        February 2003. A peak to trough lasting a total of 31 months, with
        global equities falling 49.7% over the period.
        <br />
        <br />
        <b>Context:</b> Notable corporate wrongdoings, exemplified by the Enron
        and WorldCom scandals, shook investor confidence, and cast doubts on the
        integrity of corporate practices and financial reporting. Concurrently,
        the aftermath of the dot-com bubble burst in the early 2000s
        precipitated a downturn in the technology sector, spreading its effects
        across industries and contributing to a broader market decline. Economic
        growth was further hampered by the fallout from the September 11, 2001
        terrorist attacks, which cast a shadow of uncertainty over global
        markets. Geopolitical tensions, particularly concerning conflicts in the
        Middle East and fears of terrorism, exacerbated market unease. The
        looming prospect of war with Iraq and subsequent military action in 2003
        added to concerns about energy prices and geopolitical stability.
        Lasting for much of the early 2000s, the bear market saw significant
        losses across major stock indices worldwide, underlining the
        vulnerability of financial markets to external shocks and emphasising
        the importance of transparency and risk management.
      </>
    ),
  },
  {
    start: new Date('2007-10-31'),
    end: new Date('2009-02-28'),
    label: '{b|Global\nFinancial Crisis}\n{c|Click for more}',
    offset: 115,
    position: 'top',
    description: (theme) => (
      <>
        <Box color={theme.palette.primary.main} fontWeight="bold" fontSize={18}>
          31/10/2007-28/02/2009
        </Box>
        <br />
        <b>Intro:</b> The 2008 global bear market stands as one of the most
        profound financial crises in modern history, characterised by a
        widespread collapse in stock markets and financial systems worldwide.
        <br />
        <br />
        <b>Duration:</b> The bear market began in October 2007 and ended in
        February 2009. A peak to trough lasting a total of 17 months, with
        global equities falling 54.8% over the period.
        <br />
        <br />
        <b>Context:</b> Its origins lie in the bursting of the housing bubble in
        the United States, fuelled by the proliferation of risky subprime
        mortgages. This collapse triggered a chain reaction, exposing the
        fragility of complex financial instruments like mortgage-backed
        securities and collateralised debt obligations and causing a liquidity
        crisis as banks became reluctant to lend. The crisis swiftly spread
        beyond the housing market, plunging the global economy into recession
        with soaring unemployment rates and plummeting consumer spending.
        Lasting over a year, from late 2007 through early 2009, major stock
        indices experienced staggering losses, leading to profound regulatory
        reforms aimed at averting similar crises in the future.
      </>
    ),
  },
  {
    start: new Date('2019-12-01'),
    end: new Date('2020-03-31'),
    label: '{b|COVID-19 Outbreak}\n{c|Click for more}',
    offset: 110,
    position: 'top',
    description: (theme) => (
      <>
        <Box color={theme.palette.primary.main} fontWeight="bold" fontSize={18}>
          01/12/2019-30/03/2020
        </Box>
        <br />
        <b>Intro:</b> In early 2020, in the wake of global COVID-19 outbreaks,
        financial markets experienced one of the shortest and sharpest sell offs
        in living memory.
        <br />
        <br />
        <b>Duration:</b> The bear market began in December 2019 and ended in
        March 2020. A peak to trough lasting a total of 4 months, with global
        equities falling 32.1% over the period.
        <br />
        <br />
        <b>Context:</b> This bear market began due to great uncertainty among
        investors of what impact the unprecedented COVID-19 lockdowns would have
        on economies around the world. Unlike the previous two major bear
        markets, since the turn of the century, the COVID-19 downturn bottomed
        in a matter of weeks as investors sold assets across the board to sit in
        cash, or what they deemed to be safe haven assets. Again, unlike prior
        sell-offs, the recovery was rapid - with the US retracing all losses by
        August of the same year. Remarkably, global equity markets closed the
        year in positive territory.
      </>
    ),
  },
];
