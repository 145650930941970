import { FC, useEffect, useState } from 'react';
import { Box, Typography, alpha, lighten, useTheme } from '@mui/material';

import { Enum_RiskName, Estimation, History } from 'src/apollo';
import { EChart, EChartProps } from 'src/components/shared';
import {
  formatCurrency,
  formatCurrencyInMillions,
  formatPercent,
} from 'src/utils';

import { graphic } from 'echarts';
import { useStyles } from './styles';
import {
  areDatesEqual,
  calculateLabelCoords,
  getRiskLabel,
  getSeriesColor,
} from './utils';
import { Downturn } from '../downturns';
import { worldEvents } from '../worldEvents';
import { riskLevels } from '../utils';

interface Props {
  data?: Estimation;
  selectedRisk: Enum_RiskName;
  showWorldEvents?: boolean;
  showGrid?: boolean;
  showDownturns?: boolean;
  downturns?: Downturn[];
  additionalSeries?: string[];
  className?: string;
  printing?: boolean;
  renderFlag?: number;
  setSelectedRisk: (risk: Enum_RiskName) => void;
  onDownturnSelect?: (downturn: Downturn) => void;
  onMonthsShownChange?: (shown: boolean) => void;
  onChartRender?: (url: string) => void;
}

export const Chart: FC<Props> = ({
  data,
  selectedRisk,
  showWorldEvents,
  showGrid,
  showDownturns,
  additionalSeries,
  downturns,
  className,
  printing,
  renderFlag,
  setSelectedRisk,
  onDownturnSelect,
  onMonthsShownChange,
  onChartRender,
}) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const [resizeFlag, setResizeFlag] = useState(false);
  const [lineHover, setLineHover] = useState(false);
  const [hoveredSeriesIdx, setHoveredSeriesIdx] = useState<number>();
  const [showTooltipLine, setShowTooltipLine] = useState(false);
  const historyData = data?.history ?? [];
  const increaseData = data?.increase ?? {};
  const dateStart = new Date(historyData[0]?.date ?? '');
  const dateEnd = new Date(historyData[historyData.length - 1]?.date ?? '');
  const hasAdditionalSeries = !!additionalSeries?.length;
  const transparent = alpha('#000', 0).replaceAll(' ', '');
  const showMonths = dateEnd.getFullYear() - dateStart.getFullYear() <= 5;

  const generateLabels = () => {
    if (!showWorldEvents) {
      return [];
    }

    const labels = worldEvents.map((event) => ({
      name: event?.name,
      coord: calculateLabelCoords(event, historyData as History[]),
      label: {
        backgroundColor: 'white',
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        borderRadius: 30,
        color: theme.palette.primary.main,
        padding: [6, 12, 6, 12],
        shadowColor: theme.palette.grey[400],
        shadowBlur: 5,
        formatter: event?.name,
      },
    }));

    return labels;
  };

  const seriesAttr = (risk: Enum_RiskName, additionalColor?: string) => {
    const percentValue = increaseData?.procents?.[risk] ?? 0;
    const amountValue = increaseData?.amounts?.[risk] ?? 0;
    let startingVal = historyData?.[0]?.[risk] ?? 0;
    if (additionalColor) {
      startingVal =
        historyData?.[0]?.additionalData?.find((d) => {
          return d?.name === risk;
        })?.value ?? 0;
    }
    const formattedPercent = `${percentValue > 0 ? '+ ' : ''}${formatPercent(
      percentValue / 100,
      0,
      0,
    )}`;
    const formattedAmount = formatCurrency(amountValue + startingVal, 0);

    const isRiskSelected = selectedRisk === risk;
    const baseFontStyle = {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.main,
    };

    let lineColor = theme.palette.divider;
    let lineWidth = 2;
    if (isRiskSelected) {
      lineColor = theme.palette.primary.main;
      lineWidth = 4;
    } else if (hasAdditionalSeries) {
      if (additionalColor) {
        lineColor = additionalColor;
      } else {
        lineColor = 'transparent';
      }
    }

    return {
      lineStyle: {
        color: lineColor,
        width: lineWidth,
      },
      animation: true,
      animationDuration: 1000,
      animationEasing: 'cubicOut',
      zlevel: isRiskSelected ? 1 : 0,
      showSymbol: true,
      silent: false,
      triggerLineEvent: true,
      type: 'line',
      labelLayout: {
        moveOverlap: 'shiftY',
      },
      smooth: 0.1,
      smoothMonotone: 'x',
      symbolSize: 10,
      symbol: isRiskSelected ? 'circle' : 'none',
      itemStyle: {
        color: theme.palette.primary.main,
      },
      markLine: {
        silent: true,
      },
      select: {
        selectedMode: false,
      },
      label: {
        show: false,
      },
      endLabel: {
        silent: true,
        show: isRiskSelected,
        formatter: `{a|${formattedPercent}}\n{b|(${formattedAmount})}`,
        rich: {
          a: {
            ...baseFontStyle,
            fontSize: theme.typography.fontSize * 1.25,
            lineHeight: 24,
          },
          b: {
            ...baseFontStyle,
            fontSize: theme.typography.fontSize * 0.75,
          },
        },
      },
    };
  };

  const getValues = (risk: Enum_RiskName, isAdditional?: boolean) => {
    if (isAdditional) {
      return historyData.map((row) => {
        return row?.additionalData?.find((d) => d?.name === risk)?.value;
      });
    }
    return historyData.map((row) => row?.[risk]);
  };

  const getDates = () => {
    return historyData?.map((row) => new Date(row?.date ?? ''));
  };

  const getXAxisLabels = () => {
    return getDates().map((d) => d.getTime().toString());
  };

  const prepareSeriesData = (risk: Enum_RiskName, additionalColor?: string) => {
    return {
      name: risk,
      data: getValues(risk, !!additionalColor),
      ...seriesAttr(risk, additionalColor),
    };
  };

  const prepareAdditionalData = (name: string, color: string) => {
    return prepareSeriesData(name as Enum_RiskName, color);
  };

  const defaultMarkAreaColor = new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: alpha(theme.palette.primary.main, 0.08),
    },
    {
      offset: 1,
      color: alpha(theme.palette.primary.main, 0.02),
    },
  ]);

  const highlightMarkAreaColor = new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: alpha(theme.palette.primary.main, 0.2),
    },
    {
      offset: 1,
      color: alpha(theme.palette.primary.main, 0.04),
    },
  ]);

  const generatedLabels = generateLabels();
  const dates = getDates();

  const additionalData =
    historyData[0]?.additionalData?.map((d) => {
      const isVisible = additionalSeries?.includes(d?.name ?? '');
      const colorIdx = additionalSeries?.findIndex((s) => s === d?.name) ?? 0;
      const color = getSeriesColor(colorIdx === -1 ? 0 : colorIdx, theme);
      const series = prepareAdditionalData(d?.name ?? '', color);

      const firstElement = d?.value ?? 0;
      const lastElement =
        historyData[historyData.length - 1]?.additionalData?.find((add) => {
          return add?.name === d?.name;
        })?.value ?? 0;

      const amount = lastElement - firstElement;
      const percent = firstElement
        ? ((lastElement - firstElement) / firstElement) * 100
        : 0;

      const formattedPercent = `${percent > 0 ? '+ ' : ''}${formatPercent(
        percent / 100,
        0,
        0,
      )}`;
      const formattedAmount = formatCurrency(amount + firstElement, 0);

      return {
        ...series,
        silent: false,
        lineStyle: {
          ...series.lineStyle,
          width: 2,
          color: isVisible ? series.lineStyle.color : 'transparent',
        },
        endLabel: {
          ...series.endLabel,
          show: isVisible,
          formatter: `{a|${formattedPercent}}\n{b|(${formattedAmount})}`,
          rich: {
            ...series.endLabel.rich,
            a: {
              ...series.endLabel.rich.a,
              color,
            },
            b: {
              ...series.endLabel.rich.b,
              color,
            },
          },
        },
      };
    }) ?? [];

  const option = dates.length
    ? {
        animationDuration: 0,
        tooltip: {
          trigger: 'axis',
          backgroundColor: theme.palette.primary.main,
          borderColor: 'white',
          borderWidth: '2',
          borderRadius: 12,
          textStyle: {
            color: 'white',
            fontFamily: theme.typography.fontFamily,
          },
          shadowBlur: 8,
          axisPointer: {
            type: 'line',
            axis: 'x',
            z: 0,
            lineStyle: {
              color: showTooltipLine
                ? theme.palette.primary.main
                : 'transparent',
              type: 'solid',
            },
          },
          formatter: (params: any) => {
            setShowTooltipLine(false);
            if (!lineHover || hoveredSeriesIdx === undefined) return '';
            const param = params[hoveredSeriesIdx];
            const { value, seriesName } = param;
            if (
              seriesName !== selectedRisk &&
              !additionalSeries?.includes(seriesName)
            ) {
              return;
            }
            if (!value) return '';
            const val = formatCurrencyInMillions(value, 2);
            setShowTooltipLine(true);
            return val;
          },
          position: (point: [number, number]) => {
            return [point[0] + 10, point[1] - 25 - 15];
          },
        },
        title: {
          show: false,
        },
        grid: {
          top: '20px',
          right: '105px',
          bottom: '20px',
          left: '20px',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          nameLocation: 'middle',
          data: getXAxisLabels(),
          axisTick: {
            show: false,
          },
          splitLine: {
            show: showGrid,
            lineStyle: { type: 'dashed' },
          },
          verticalAlign: 'middle',
          axisLabel: {
            rotate: 90,
            margin: 16,
            fontSize: printing ? 16 : undefined,
            interval: (_: number, value: string) => {
              return showMonths || new Date(+value).getMonth() === 0;
            },
            formatter: (value: string) => {
              const date = new Date(+value);
              if (showMonths) {
                return date.toLocaleString('default', {
                  year: 'numeric',
                  month: 'short',
                });
              }
              return date.getFullYear();
            },
          },
          scale: true,
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
          },
          boundaryGap: ['0%', '10%'],
          axisLabel: {
            margin: 24,
            fontSize: printing ? 16 : undefined,
            formatter: (value: number) => {
              return value ? formatCurrencyInMillions(value) : '';
            },
          },
          splitArea: {
            show: false,
          },
          splitLine: {
            show: showGrid,
            lineStyle: { type: 'dashed' },
          },
          scale: true,
          splitNumber: 6,
        },
        visualMap: {
          show: false,
          dimension: 0,
          pieces: downturns
            ?.map((downturn) => {
              const startPoint = historyData.findIndex((point) => {
                return areDatesEqual(downturn.start, point?.date);
              });
              const endPoint = historyData.findIndex((point) => {
                return areDatesEqual(downturn.end, point?.date);
              });
              const color = showDownturns
                ? theme.palette.primary.main
                : 'transparent';
              return [
                {
                  gte: startPoint,
                  lte: startPoint,
                  symbolSize: 11,
                  color,
                },
                {
                  gte: endPoint,
                  lte: endPoint,
                  symbolSize: 12,
                  color,
                },
              ];
            })
            .flat(),
        },
        series: [
          {
            ...prepareSeriesData(Enum_RiskName.Low),
            silent: false,
            markPoint: {
              symbol: 'circle',
              symbolSize: 75,
              itemStyle: {
                color: 'transparent',
                cursor: 'pointer',
              },
              label: {
                color: theme.palette.text.secondary,
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightLight,
                cursor: 'pointer',
              },
              data: generatedLabels,
            },
            markLine: {
              lineStyle: {
                width: 1,
                color: lighten(theme.palette.primary.main, 0.25),
                type: 'solid',
              },
              emphasis: {
                lineStyle: {
                  width: 1,
                  color: lighten(theme.palette.primary.main, 0.25),
                },
              },
              data: generatedLabels.map((label) => {
                return [
                  { coord: label.coord, symbol: 'none' },
                  {
                    coord: [label.coord?.[0] ?? '0', 0],
                    symbol: 'circle',
                    symbolSize: 6,
                  },
                ];
              }),
            },
            markArea: {
              itemStyle: {
                color: defaultMarkAreaColor,
              },
              emphasis: {
                itemStyle: {
                  color: highlightMarkAreaColor,
                },
              },
              data: showDownturns
                ? downturns
                    ?.filter((downturn) => {
                      return (
                        downturn.start > dateStart && downturn.end < dateEnd
                      );
                    })
                    .map((downturn) => {
                      const moveUp =
                        downturn.label.includes('Covid') &&
                        dateStart.getFullYear() >= 2008;
                      const position = moveUp ? 'top' : downturn.position;
                      const startPoint = historyData.find((point) => {
                        return areDatesEqual(downturn.start, point?.date);
                      });
                      const endPoint = historyData.find((point) => {
                        return areDatesEqual(downturn.end, point?.date);
                      });
                      const xStart = new Date(startPoint?.date ?? '');
                      const xEnd = new Date(endPoint?.date ?? '');
                      return [
                        {
                          xAxis: xStart.getTime().toString(),
                          name: downturn.label,
                          emphasis: {
                            label: { position },
                          },
                          label: {
                            show: true,
                            color: theme.palette.primary.main,
                            fontFamily: theme.typography.fontFamily,
                            align: 'center',
                            verticalAlign: 'middle',
                            position,
                            formatter: downturn.label,
                            distance: -downturn.offset,
                            rich: {
                              b: {
                                fontSize: 16,
                                fontWeight: 'bold',
                              },
                              c: {
                                height: 20,
                              },
                            },
                          },
                        },
                        {
                          xAxis: xEnd.getTime().toString(),
                        },
                      ];
                    })
                : [],
            },
          },
          prepareSeriesData(Enum_RiskName.Lower),
          prepareSeriesData(Enum_RiskName.Medium),
          prepareSeriesData(Enum_RiskName.Higher),
          prepareSeriesData(Enum_RiskName.High),
          ...additionalData,
        ],
      }
    : {};

  const isOptionInvalid = option.series?.some((s) => !s.data.length);
  const notEnoughData = historyData.length <= 1;
  const isDataInvalid = isOptionInvalid || notEnoughData;

  const handleSeriesClick = (series: string) => {
    const risk = series as Enum_RiskName;
    if (!riskLevels.includes(risk)) return;
    setSelectedRisk(risk);
  };

  const downturnEvents: EChartProps['downturnEvents'] = {};

  downturns?.forEach((downturn) => {
    downturnEvents[downturn.label] = {
      onClick: () => onDownturnSelect?.(downturn),
    };
  });

  useEffect(() => {
    setResizeFlag((old) => !old);
  }, [className]);

  useEffect(() => {
    onMonthsShownChange?.(showMonths);
  }, [showMonths]);

  return (
    <div
      data-testid="interactive-chart"
      id="interactive-chart"
      className={cx(classes.root, className)}
    >
      {isDataInvalid ? (
        <Typography variant="h3">No data available</Typography>
      ) : (
        <>
          {hasAdditionalSeries && (
            <Box className={classes.legend}>
              <Box className={classes.legendItem}>
                <span className={classes.dot} />
                {getRiskLabel(selectedRisk)}
              </Box>
              {additionalSeries.map((series, i) => {
                const color = getSeriesColor(i, theme);
                return (
                  <Box
                    className={classes.legendItem}
                    sx={{ color }}
                    key={color}
                  >
                    <span
                      className={classes.dot}
                      style={{ backgroundColor: color }}
                    />
                    {series}
                  </Box>
                );
              })}
            </Box>
          )}
          <EChart
            config={option}
            downturnEvents={downturnEvents}
            resizeFlag={resizeFlag}
            renderFlag={renderFlag}
            onSeriesClick={handleSeriesClick}
            onLineEnter={(color, series) => {
              if (color === transparent || color === 'transparent') return;
              setHoveredSeriesIdx(series);
              setLineHover(true);
            }}
            onLineLeave={() => setLineHover(false)}
            onChartRender={onChartRender}
          />
        </>
      )}
    </div>
  );
};
