import { FC } from 'react';
import { IconButton } from '@mui/material';

import { CloseIcon } from '../icons';

interface Props {
  onClose: () => void;
}

export const DialogClose: FC<Props> = ({ onClose }) => {
  return (
    <IconButton
      color="primary"
      aria-label="close"
      onClick={onClose}
      size="large"
    >
      <CloseIcon />
    </IconButton>
  );
};
