import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ color: string; size: number }>()(
  (theme, { color, size }) => ({
    root: {
      background: color,
      width: size,
      height: size,
      borderRadius: size / 2,
      flexShrink: 0,
      overflow: 'hidden',
    },
    selected: {
      boxShadow: `0 0 0 4px ${theme.palette.divider}`,
    },
  }),
);
