import { makeStyles } from 'tss-react/mui';

const indicatorColor = '#5D5FEF';

export const useStyles = makeStyles()((theme) => ({
  root: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    height: 50,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  logo: {
    marginLeft: theme.spacing(3),
    display: 'block',
    maxHeight: '100%',
  },
  attributeText: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '28px',
    fontFamily: theme.typography.body1.fontFamily,
  },
  notificationDot: {
    position: 'absolute',
    top: 15,
    right: 0,
    width: 11,
    height: 11,
    borderRadius: '50%',
    backgroundColor: indicatorColor,
    border: '2px solid white',
    pointerEvents: 'none',
  },
}));
