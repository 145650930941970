import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  footer: {
    padding: '40px 76px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  footerSectionTitle: {
    fontFamily: ['Poppins', 'sans-serif'].join(),
    fontWeight: 300,
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  footerBodyText: {
    color: '#002C1E',
    font: '300 16px Poppins, sans-serif',
    lineHeight: 1.5,
  },
  googleLink: {
    font: '400 20px Poppins, sans-serif',
    color: theme.palette.primary.main,
    lineHeight: 1.65,
  },
}));
