import {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FullLoading } from '../../components/layout';

type OverlayValue = () => {
  close: () => void;
};

export const OverlayContext = createContext<OverlayValue>(() => ({
  close: () => {},
}));

interface Props {
  children?: ReactNode;
}

export const OverlayController: FC<Props> = memo(({ children }) => {
  const overlays = useRef(new Set<string>());
  const [reRenderFlag, setReRenderFlag] = useState(false);

  const genId = () => {
    let id = '';
    do {
      id = Math.floor(Math.random() * Date.now()).toString(36);
    } while (overlays.current.has(id));
    return id;
  };

  const close = (id: string) => {
    setReRenderFlag((v) => !v);
    overlays.current.delete(id);
  };

  const show = useCallback(() => {
    const id = genId();
    setReRenderFlag((v) => !v);
    overlays.current.add(id);
    return { close: () => close(id) };
  }, [close, overlays]);

  const overlayElement = useMemo(() => {
    if (overlays.current.size === 0) {
      return null;
    }
    return <FullLoading overlay />;
  }, [reRenderFlag]);

  return (
    <OverlayContext.Provider value={show}>
      {children}
      {overlayElement}
    </OverlayContext.Provider>
  );
});
