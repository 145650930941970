import { FC, memo } from 'react';

import { useStyles } from './styles';

interface Props {
  side: 'top' | 'bottom';
  color?: string;
}

export const FadeScroll: FC<Props> = memo(({ color, side }) => {
  const { classes, cx } = useStyles({ color });
  const className = cx(classes.root, classes[side]);
  return <div className={className} />;
});
