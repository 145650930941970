import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Status } from 'src/enums';

interface UseStatusState<T> {
  state: Status;
  response?: T;
  error?: unknown;
}

export interface UseStatus<T> extends UseStatusState<T> {
  execute: (promise: Promise<T>) => void;
  isIdle: boolean;
  isPending: boolean;
  isSuccess: boolean;
  isFailed: boolean;
}

export const useStatus = <T>(
  initial?: Partial<UseStatusState<T>>,
): UseStatus<T> => {
  const [state, setState] = useState<UseStatusState<T>>({
    ...initial,
    state: initial?.state ?? Status.Idle,
  });
  const cancel = useRef(() => {});
  useEffect(() => cancel.current, []);
  const execute = useCallback((promise: Promise<T>) => {
    cancel.current();
    let isActive = true;
    cancel.current = () => {
      isActive = false;
    };
    setState((oldState) => ({
      ...oldState,
      state: Status.Pending,
    }));
    promise.then(
      (val) => {
        if (isActive) {
          setState({ state: Status.Success, response: val });
        }
      },
      (err: unknown) => {
        if (isActive) {
          setState({ state: Status.Failed, error: err });
        }
      },
    );
  }, []);
  return useMemo(
    () => ({
      ...state,
      execute,
      isIdle: state.state === Status.Idle,
      isPending: state.state === Status.Pending,
      isSuccess: state.state === Status.Success,
      isFailed: state.state === Status.Failed,
    }),
    [state, execute],
  );
};
