import { makeStyles } from 'tss-react/mui';

const indicatorColor = '#5D5FEF';

export const useStyles = makeStyles()(() => ({
  notificationDot: {
    position: 'absolute',
    top: 15,
    right: 0,
    width: 11,
    height: 11,
    borderRadius: '50%',
    backgroundColor: indicatorColor,
    border: '2px solid white',
    pointerEvents: 'none',
  },
}));
