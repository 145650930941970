import { FC, useEffect, useMemo, useState } from 'react';
import { Box, IconButton, lighten, useTheme } from '@mui/material';

import { Enum_RiskName } from 'src/apollo';
import { EChart } from 'src/components/shared';

import { InfoIcon } from 'src/components/ui';
import { DialogSize, useDialog } from 'src/hooks';
import { confirmModal } from 'src/utils/confirmModal';
import {
  formatCurrency,
  formatCurrencyInMillions,
  formatDate,
} from 'src/utils';
import { eachMonthOfInterval, isSameMonth } from 'date-fns';
import { useStyles } from './styles';
import { cornishFisherExpansion, objectToStyles, riskParams } from './utils';
import { DepositFrequency } from '../Filters/ValueFilters';
import { ChartEvent } from '../Filters/EventFilters';

type SeriesOptions = {
  stack?: string;
  showLabels?: boolean;
  itemColor?: string;
  firstLabel?: boolean;
};

interface Props {
  selectedRisk: Enum_RiskName;
  dateStart: Date;
  dateEnd: Date;
  startingValue: number;
  contribution: number;
  events: ChartEvent[];
  contributionFreq: DepositFrequency;
  showGrid?: boolean;
  className?: string;
  printing?: boolean;
  renderFlag?: number;
  onChartRender?: (url: string) => void;
}

export const ProjectionsChart: FC<Props> = ({
  selectedRisk,
  dateStart,
  dateEnd,
  startingValue,
  contribution,
  events,
  contributionFreq,
  showGrid,
  className,
  printing,
  renderFlag,
  onChartRender,
}) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const dialog = useDialog();
  const [resizeFlag, setResizeFlag] = useState(false);
  const [tooltipShown, setTooltipShown] = useState(false);
  const [mean, setMean] = useState(riskParams[selectedRisk].mean);
  const [stdDev, setStdDev] = useState(riskParams[selectedRisk].stdDev);
  const [skew, setSkew] = useState(riskParams[selectedRisk].skew);
  const [kurt, setKurt] = useState(riskParams[selectedRisk].kurt);

  useEffect(() => {
    setMean(riskParams[selectedRisk].mean);
    setStdDev(riskParams[selectedRisk].stdDev);
    setSkew(riskParams[selectedRisk].skew);
    setKurt(riskParams[selectedRisk].kurt);
  }, [selectedRisk]);

  const datesArray = eachMonthOfInterval({ start: dateStart, end: dateEnd });
  const lastDate = datesArray[datesArray.length - 1]?.toString();
  const months = datesArray.length;
  const hasContributions = contribution !== 0;
  const contributionsText = contribution < 0 ? 'Withdrawals' : 'Contributions';
  const monthlyContribution = contributionFreq === DepositFrequency.Month;
  const contributionMonthlyShare = monthlyContribution ? 1 : 12;
  const monthlyContributions = datesArray.map(() => {
    return contribution / contributionMonthlyShare;
  });

  const getEventContribution = (m: number) => {
    const currentMonth = datesArray[m]!;
    const currentEvent = events.find((ev) => {
      return isSameMonth(ev.date, currentMonth);
    });
    return currentEvent?.contribution ?? 0;
  };

  const monteCarloSimulation = (
    sims: number,
    mn: number,
    dev: number,
    skewness: number,
    kurtosis: number,
  ) => {
    const results = [];

    for (let i = 0; i < sims; i += 1) {
      let savings = startingValue;
      const simulationResult = [savings];

      for (let month = 0; month < months; month += 1) {
        const returnRate = cornishFisherExpansion(
          Math.random(),
          mn,
          dev,
          skewness,
          kurtosis,
        );
        const eventContribution = getEventContribution(month);
        savings =
          savings * (1 + returnRate) +
          monthlyContributions[month]! +
          eventContribution;
        simulationResult.push(savings);
      }

      results.push(simulationResult);
    }

    return results;
  };

  const results = useMemo(() => {
    return monteCarloSimulation(2000, mean, stdDev, skew, kurt);
  }, [
    mean,
    stdDev,
    startingValue,
    contribution,
    events,
    contributionFreq,
    dateStart,
    dateEnd,
  ]);

  const calculatePercentiles = (d: number[], percentiles: number[]) => {
    const percentileValues = percentiles.map((p) => {
      const index = Math.floor(p * d.length);
      return d[index]!;
    });
    return percentileValues;
  };

  const highestResult = useMemo(() => {
    const param = riskParams[Enum_RiskName.High];
    const result = monteCarloSimulation(
      500,
      param.mean,
      param.stdDev,
      param.skew,
      param.kurt,
    );
    const values = [];
    for (let month = 0; month < months; month += 1) {
      const monthlyData = result.map((simulation) => simulation[month]!);
      monthlyData.sort((a, b) => a - b);
      const [percentileValue] = calculatePercentiles(monthlyData, [0.97]);
      values.push(percentileValue!);
    }
    return Math.max(values[0]! + startingValue, values[values.length - 1]!);
  }, [
    startingValue,
    contribution,
    contributionFreq,
    dateStart,
    dateEnd,
    events,
  ]);

  const percentiles = [0.05, 0.25, 0.5, 0.75, 0.95];
  const monthlyPercentiles = [];
  const contributions = hasContributions ? [startingValue] : [];

  for (let month = 0; month < months; month += 1) {
    const monthlyData = results.map((simulation) => simulation[month]!);
    monthlyData.sort((a, b) => a - b);
    const percentileValues = calculatePercentiles(monthlyData, percentiles);
    monthlyPercentiles.push(percentileValues);
    const evContribution = getEventContribution(month);
    const previousContribution = contributions[contributions.length - 1]!;
    const currentContribution = monthlyContributions[month]! + evContribution;
    if (currentContribution) {
      contributions.push(previousContribution + currentContribution);
    }
  }

  const data5th = monthlyPercentiles.map((p) => p[0]!);
  const data25th = monthlyPercentiles.map((p) => p[1]!);
  const data50th = monthlyPercentiles.map((p) => p[2]!);
  const data75th = monthlyPercentiles.map((p) => p[3]!);
  const data95th = monthlyPercentiles.map((p) => p[4]!);

  const { legendBreakpoint } = riskParams[selectedRisk];
  const projectionStart = data50th[0]!;
  const projectionEnd = data50th[data50th.length - 1]!;
  const diff = projectionEnd - projectionStart;
  const diffPercent = (diff / projectionStart) * 100;
  const moveLegend = diffPercent < legendBreakpoint;

  const topArea = data95th.map((v, i) => data75th[i]! - v);
  const bottomArea = data25th.map((v, i) => data5th[i]! - v);
  const middleTopArea = data75th.map((v, i) => data50th[i]! - v);
  const middleBottomArea = data50th.map((v, i) => data25th[i]! - v);

  const moreLikelyColor = lighten(theme.palette.primary.main, 0.5);
  const lessLikelyColor = theme.palette.grey[400];

  const eventLabels = events.map((ev) => {
    const evContribution = formatCurrency(ev.contribution);
    const eventMonth = datesArray.findIndex((d) => {
      return isSameMonth(d, ev.date);
    });
    const x = datesArray[eventMonth]?.toString();
    const y = data50th[eventMonth];
    return {
      name: evContribution,
      coord: [x, y],
      label: {
        backgroundColor: 'white',
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        borderRadius: 30,
        color: theme.palette.primary.main,
        padding: [6, 12, 6, 12],
        shadowColor: theme.palette.grey[400],
        shadowBlur: 5,
        formatter: evContribution,
        offset: [0, -50],
      },
    };
  });

  const prepareSeries = (
    name: string,
    data: number[],
    { stack, showLabels, itemColor, firstLabel }: SeriesOptions = {},
  ) => {
    const lastValue = data[data.length - 1] ?? 0;
    const maxDigits = lastValue >= 1000000000 ? 0 : 1;
    const target = formatCurrencyInMillions(lastValue, maxDigits);
    const elementColor = itemColor ?? theme.palette.primary.main;
    const showTarget = showLabels && !tooltipShown;
    const offsetLabel = Number(!!itemColor);
    const offsetSign = firstLabel ? -1 : 1;

    return {
      name,
      data,
      stack,
      type: 'line',
      showSymbol: false,
      tooltip: {
        show: true,
      },
      lineStyle: {
        width: 0,
      },
      smooth: 0.001,
      smoothMonotone: 'x',
      symbolSize: 10,
      symbol: 'circle',
      itemStyle: {
        color: elementColor,
      },
      endLabel: showTarget && {
        silent: true,
        show: true,
        formatter: `{a|${target}}`,
        distance: 10,
        offset: [0, 20 * offsetLabel * offsetSign],
        rich: {
          a: {
            fontFamily: theme.typography.fontFamily,
            backgroundColor: elementColor,
            color: 'white',
            borderColor: 'white',
            fontSize: 14,
            borderRadius: 12,
            borderWidth: 2,
            padding: [8, 12, 4, 12],
            shadowColor: theme.palette.grey[400],
            shadowBlur: 5,
            align: 'center',
          },
        },
      },
      markPoint: showTarget && {
        symbol: 'circle',
        symbolSize: 10,
        itemStyle: {
          color: elementColor,
        },
        data: [{ coord: [lastDate, lastValue] }],
      },
    };
  };

  const prepareAreaSeries = (data: number[], stack: string, color: string) => {
    return {
      data,
      type: 'line',
      stack,
      stackStrategy: 'all',
      lineStyle: { opacity: 0 },
      silent: true,
      symbol: 'none',
      areaStyle: {
        color: lighten(color, 0.25),
      },
      tooltip: {
        show: false,
      },
    };
  };

  const option = {
    title: {
      show: false,
    },
    grid: {
      top: '20px',
      right: '105px',
      bottom: '20px',
      left: '20px',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      textStyle: {
        fontFamily: theme.typography.fontFamily,
      },
      axisPointer: {
        type: 'line',
        axis: 'x',
        z: 0,
        lineStyle: {
          color: theme.palette.grey[400],
          type: 'solid',
        },
      },
      formatter: (params: any[]) => {
        if (!params[0]?.axisValue) return;
        const formattedDate = formatDate(params[0].axisValue, false, true, {
          month: 'short',
          day: undefined,
        });
        const itemStyle = objectToStyles({
          width: '300px',
          display: 'flex',
          'justify-content': 'space-between',
        });
        const values = params
          .map((p) => {
            const name = p.seriesName;
            const isPercentile = name.includes('percentile');
            const isOuter = name === '5th percentile' || name.includes('95th');
            const isContribution = name.includes('Contributions');
            const percentileColor = isOuter ? lessLikelyColor : moreLikelyColor;
            const symbolColor = isPercentile
              ? percentileColor
              : theme.palette.primary.main;
            const symbolStyle = objectToStyles({
              width: '10px',
              height: '10px',
              display: 'inline-block',
              border: `2px solid ${symbolColor}`,
              'margin-right': '7px',
              'border-radius': '50%',
              'background-color': isContribution ? 'white' : symbolColor,
            });
            const symbol = `<div style="${symbolStyle}"></div>`;
            const v = formatCurrencyInMillions(p.value, 1);
            return `<div style="${itemStyle}"><div>${symbol}${p.seriesName}</div><div>${v}</div></div>`;
          })
          .join('');
        return `${formattedDate}<br /><br />${values}`;
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      nameLocation: 'middle',
      data: datesArray.map((d) => d.toString()),
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.palette.grey[400],
          type: 'solid',
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: showGrid,
        lineStyle: { type: 'dashed' },
      },
      verticalAlign: 'middle',
      axisLabel: {
        rotate: 90,
        margin: 16,
        fontSize: printing ? 16 : undefined,
        interval: (_: number, value: string) => {
          return new Date(value).getMonth() === 0;
        },
        formatter: (value: string) => {
          return new Date(value).getFullYear();
        },
      },
    },
    yAxis: {
      type: 'value',
      max: highestResult,
      axisLine: {
        show: false,
        onZero: false,
      },
      boundaryGap: ['10%', '10%'],
      axisLabel: {
        margin: 24,
        fontSize: printing ? 16 : undefined,
        formatter: (value: number) => {
          if (value.toString().includes('.')) return '';
          return formatCurrencyInMillions(value, 1);
        },
      },
      splitArea: {
        show: false,
      },
      splitLine: {
        show: showGrid,
        lineStyle: { type: 'dashed' },
      },
      scale: true,
      splitNumber: 6,
    },
    series: [
      {
        ...prepareSeries('95th percentile', data95th, {
          stack: 'top',
          itemColor: lessLikelyColor,
        }),
        markLine: !tooltipShown && {
          silent: true,
          symbol: 'none',
          lineStyle: {
            width: 1,
            color: theme.palette.primary.main,
            type: 'solid',
          },
          label: {
            formatter: 'Target',
            position: 'start',
            rotate: 90,
            color: theme.palette.primary.main,
            fontSize: 14,
            distance: -30,
            lineHeight: 30,
          },
          data: [
            {
              xAxis: lastDate,
            },
          ],
        },
      },
      prepareAreaSeries(topArea, 'top', lessLikelyColor),
      prepareSeries('75th percentile', data75th, {
        itemColor: moreLikelyColor,
        stack: 'middle-top',
        showLabels: true,
        firstLabel: true,
      }),
      prepareAreaSeries(middleTopArea, 'middle-top', moreLikelyColor),
      {
        ...prepareSeries('Projected', data50th, {
          stack: 'middle-bottom',
          showLabels: true,
        }),
        lineStyle: {
          width: 2,
          color: theme.palette.primary.main,
        },
      },
      prepareAreaSeries(middleBottomArea, 'middle-bottom', moreLikelyColor),
      prepareSeries('25th percentile', data25th, {
        itemColor: moreLikelyColor,
        stack: 'bottom',
        showLabels: true,
      }),
      prepareAreaSeries(bottomArea, 'bottom', lessLikelyColor),
      {
        ...prepareSeries('5th percentile', data5th, {
          itemColor: lessLikelyColor,
        }),
        markPoint: {
          symbol: 'circle',
          symbolSize: 75,
          itemStyle: {
            color: 'transparent',
            cursor: 'pointer',
          },
          data: eventLabels,
        },
      },
      {
        name: contributionsText,
        data: contributions,
        type: 'line',
        showSymbol: false,
        symbol: 'emptyCircle',
        itemStyle: {
          color: theme.palette.primary.main,
        },
        lineStyle: {
          width: 2,
          type: 'dashed',
          color: theme.palette.primary.main,
          opacity: hasContributions ? 1 : 0,
        },
        smooth: 0.001,
        smoothMonotone: 'x',
        markPoint: {
          symbol: 'emptyCircle',
          symbolSize: 10,
          itemStyle: {
            color: theme.palette.primary.main,
          },
          data: events.map((ev) => {
            const eventMonth = datesArray.findIndex((d) => {
              return isSameMonth(d, ev.date);
            });
            const x = datesArray[eventMonth]?.toString();
            const y = data50th[eventMonth];
            return { coord: [x, y] };
          }),
        },
      },
    ],
  };

  const contributionsWithdrawals = (
    <>
      <Box className={cx(classes.line, 'dashed')} />
      {contributionsText}
    </>
  );
  const projected = (
    <>
      <Box className={classes.line} />
      Projected
    </>
  );
  const moreLikely = (
    <>
      <Box
        className={classes.dot}
        sx={{
          backgroundColor: moreLikelyColor,
        }}
      />
      Return range 50% of the time
    </>
  );
  const lessLikely = (
    <>
      <Box className={classes.dot} sx={{ backgroundColor: lessLikelyColor }} />
      Return range 90% of the time
    </>
  );

  const handleLegendInfoClick = () => {
    confirmModal(dialog, {
      title: 'More Info',
      confirmLabel: 'Ok',
      hideCancel: true,
      size: DialogSize.Big,
      content: (
        <>
          {hasContributions && (
            <>
              <Box mt={4} mb={3} color={theme.palette.primary.main}>
                {contributionsWithdrawals}
              </Box>
              The dotted line represents the amount invested over the time
              period if it hadn&apos;t been allocated to the portfolio,
              highlighting how much your investment would have grown just by
              saving it, without any exposure to market returns. It provides a
              baseline for comparing the potential benefits of investing in the
              stock market versus not investing at all.
            </>
          )}
          <Box mt={4} mb={3} color={theme.palette.primary.main}>
            {projected}
          </Box>
          The solid line represents the median return, which is the middle point
          of all the projected returns. This means that half of the projected
          returns are expected to be above this line and half below it.
          It&apos;s a useful indicator of a typical return you might expect from
          the portfolio.
          <Box mt={4} mb={3} color={moreLikelyColor}>
            {moreLikely}
          </Box>
          This shaded area represents 50% of possible returns, with returns
          expected to fall in this range 50% of the time.
          <Box mt={4} mb={3} color={lessLikelyColor}>
            {lessLikely}
          </Box>
          This shaded area combined with the shaded area described above
          represents 90% of possible returns, with returns expected to fall in
          this range 90% of the time. Therefore, 10% of the time returns will be
          outside of this range, 5% of the time returns could be lower and 5% of
          the time they could be higher.
        </>
      ),
    });
  };

  useEffect(() => {
    setResizeFlag((old) => !old);
  }, [className]);

  return (
    <div
      data-testid="interactive-chart"
      id="interactive-chart"
      className={cx(classes.root, className)}
    >
      <Box className={cx(classes.legend, { bottom: moveLegend })}>
        {hasContributions && (
          <Box className={classes.legendItem}>{contributionsWithdrawals}</Box>
        )}
        <Box className={classes.legendItem}>{projected}</Box>
        <Box className={classes.legendItem} sx={{ color: moreLikelyColor }}>
          {moreLikely}
        </Box>
        <Box className={classes.legendItem} sx={{ color: lessLikelyColor }}>
          {lessLikely}
        </Box>
        <IconButton
          className={classes.infoIcon}
          onClick={handleLegendInfoClick}
        >
          <InfoIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <EChart
        config={option}
        resizeFlag={resizeFlag}
        renderFlag={renderFlag}
        onChartEnter={() => setTooltipShown(true)}
        onChartLeave={() => setTooltipShown(false)}
        onChartRender={onChartRender}
      />
    </div>
  );
};
