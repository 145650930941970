import { Dict } from 'mixpanel-browser';
import { useEffect } from 'react';
import { trackPage } from 'src/utils';

export const usePageTracking = (
  pageTitle: string,
  skip?: boolean,
  properties?: Dict,
) => {
  useEffect(() => {
    if (skip) return;
    trackPage(pageTitle, properties);
  }, [skip, pageTitle]);
};
