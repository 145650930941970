import { makeStyles } from 'tss-react/mui';

import { fullScreenHeight } from 'src/utils';

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: fullScreenHeight,
  },
  appBar: {
    border: 'none',
  },
  multilineTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    marginBottom: theme.spacing(3),
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: theme.spacing(11, 0),
    position: 'relative',
  },
}));
