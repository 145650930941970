import { keyframes } from '@emotion/react';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const bounce = keyframes`
  0% {
    height: 2%;
  }

  4% {
    height: 100%;
  }

  100% {
    height: 100%;
  }
`;

interface Props {
  invertColor?: boolean;
}

export const useStyles = makeStyles<Props>()((theme, { invertColor }) => {
  const color = invertColor
    ? theme.palette.primary.contrastText
    : theme.palette.primary.main;
  const alphaVal = alpha(color, 0.75);
  return {
    root: {
      position: 'relative',
    },
    topWrap: {
      height: '70%',
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'flex-end',
      gap: '3%',
      padding: '0px 5%',
    },
    bottomWrap: {
      height: '28%',
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'flex-end',
      gap: '3%',
      padding: '0px 5%',
      position: 'relative',
    },
    barWrap: {
      width: '25%',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    bottomBarWrap: {
      width: '25%',
      height: '50%',
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: 'auto',
    },
    bar: {
      width: '100%',
      height: 2,
      backgroundColor: color,
      animation: `${bounce} 2.1s`,
    },
    bottomBar: {
      background: `linear-gradient(0deg, rgba(255,255,255,0) 12%, ${alphaVal} 100%)`,
    },
    divider: {
      width: '100%',
      height: '2%',
      borderRadius: 10,
      backgroundColor: color,
      margin: '1% 0px',
    },
  };
});
