import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  return {
    root: {
      width: '100%',
      height: 600,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    legend: {
      position: 'absolute',
      left: 100,
      top: 50,
      border: '1px solid lightgray',
      borderRadius: 8,
      padding: 10,
      zIndex: 100,
      backgroundColor: 'white',
      minWidth: 280,
      transition: '0.5s',
      '&.bottom': {
        top: 380,
      },
    },
    legendItem: {
      fontWeight: 'bold',
      '&:not(:last-child)': {
        marginBottom: 5,
      },
    },
    dot: {
      display: 'inline-block',
      width: 8,
      height: 8,
      marginRight: 7,
      borderRadius: '50%',
      verticalAlign: 'middle',
    },
    line: {
      display: 'inline-block',
      width: 12,
      marginRight: 7,
      borderRadius: 2,
      verticalAlign: 'middle',
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      '&.dashed': {
        borderBottom: `3px dotted ${theme.palette.primary.main}`,
        borderRadius: 0,
      },
    },
    infoIcon: {
      position: 'absolute',
      bottom: 6,
      right: 6,
      width: 36,
      height: 36,
    },
  };
});
