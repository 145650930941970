import { createIcon } from '../createIcon';

export const CloseIcon = createIcon({
  svg: () => (
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="M610 29v11h11v2h-11v11h-2V42h-11v-2h11V29h2z"
        transform="translate(-599 -31) rotate(45 609 41)"
      />
    </g>
  ),
  viewBox: '-2 -2 24 24',
});
