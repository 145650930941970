import { DocumentNode, gql } from '@apollo/client';

import {
  FACTSHEET_FIELDS,
  JOURNEY_FIELDS,
  STATISTICS_FIELDS,
} from './fragments';
import {
  CognitoGroup,
  Query,
  QueryCalculateRiskJourneyArgs,
  QueryGetFactSheetByArgumentsArgs,
  QueryGetHistoryArgs,
  QueryGetJourneyByIdArgs,
  QueryGetJourneysArgs,
  QueryGetReturnsFileArgs,
  QueryGetStyleRecommendationArgs,
  QueryGetUserByIdArgs,
  QueryGetUserLogoStatusArgs,
  QueryGetUsersByGroupNameArgs,
  QueryPreviewFactsheetArgs,
  QueryRenderFactSheetArgs,
} from './types';

export interface QueryData<T extends keyof Query, V> {
  query: DocumentNode;
  name: T;
  variables?: V;
}

const GET_USERS: (
  variables: QueryGetUsersByGroupNameArgs,
) => QueryData<'getUsersByGroupName', QueryGetUsersByGroupNameArgs> = (
  variables,
) => ({
  query: gql`
    query getUsers($groupName: CognitoGroup!) {
      getUsersByGroupName(groupName: $groupName) {
        id
        email
        enabled
        firstname
        lastname
      }
    }
  `,
  name: 'getUsersByGroupName',
  variables,
});

export const GET_USER: QueryData<'getUserById', QueryGetUserByIdArgs> = {
  query: gql`
    query getUserById($id: ID!) {
      getUserById(id: $id) {
        id
        email
        enabled
        firstname
        lastname
      }
    }
  `,
  name: 'getUserById',
};

export const GET_USER_LOGO_STATUS: QueryData<
  'getUserLogoStatus',
  QueryGetUserLogoStatusArgs
> = {
  query: gql`
    query getUserLogoStatus($id: ID!) {
      getUserLogoStatus(id: $id) {
        id
        logo
      }
    }
  `,
  name: 'getUserLogoStatus',
};

export const GET_JOURNEYS: QueryData<'getJourneys', QueryGetJourneysArgs> = {
  query: gql`
    ${JOURNEY_FIELDS}
    query getJourneys(
      $page: Int
      $sortBy: SortByTypes
      $reverse: Boolean
      $search: String
      $portfolioType: [Enum_PortfolioType]
      $status: [StatusTypes]
      $userId: String
    ) {
      getJourneys(
        page: $page
        sortBy: $sortBy
        reverse: $reverse
        search: $search
        portfolioType: $portfolioType
        status: $status
        userId: $userId
      ) {
        availablePages
        data {
          ...JourneyFields
        }
      }
    }
  `,
  name: 'getJourneys',
};

export const GET_IFA_COMPANIES = GET_USERS({
  groupName: CognitoGroup.IfaCompanyAdmin,
});

export const GET_IFAS = (): QueryData<
  'getUsersByGroupName',
  QueryGetUsersByGroupNameArgs
> =>
  GET_USERS({
    groupName: CognitoGroup.Ifa,
  });

export const GET_JOURNEY_BY_ID: (
  variables?: QueryGetJourneyByIdArgs,
) => QueryData<'getJourneyById', QueryGetJourneyByIdArgs> = (variables) => ({
  query: gql`
    ${JOURNEY_FIELDS}
    query getJourneyById($journeyId: ID!, $userId: String) {
      getJourneyById(journeyId: $journeyId, userId: $userId) {
        ...JourneyFields
      }
    }
  `,
  name: 'getJourneyById',
  variables,
});

export const CALCULATE_RISK: (
  variables: QueryCalculateRiskJourneyArgs,
) => QueryData<'calculateRiskJourney', QueryCalculateRiskJourneyArgs> = (
  variables,
) => ({
  query: gql`
    query calculateRisk($journeyId: ID!, $userId: String) {
      calculateRiskJourney(journeyId: $journeyId, userId: $userId) {
        calculatedRisk
        messageAlert {
          question1
          question2
          question3
          question4
          question5
          question6
          question7
          question8
          timeHorizon
        }
      }
    }
  `,
  name: 'calculateRiskJourney',
  variables,
});

export const GET_HISTORY: QueryData<'getHistory', QueryGetHistoryArgs> = {
  query: gql`
    query getHistory($value: Float!, $startDate: String, $endDate: String) {
      getHistory(value: $value, startDate: $startDate, endDate: $endDate) {
        minSearchDate
        maxSearchDate
        history {
          date
          high
          higher
          low
          lower
          medium
          additionalData {
            name
            value
          }
        }
        increase {
          amounts {
            low
            lower
            medium
            higher
            high
          }
          procents {
            low
            lower
            medium
            higher
            high
          }
        }
      }
    }
  `,
  name: 'getHistory',
};

export const GET_RISKS: QueryData<'getRisks', unknown> = {
  query: gql`
    query {
      getRisks {
        annualReturn
        bestOneYear
        name
        riskId
        riskLevel
        volatility
        worstOneYear
      }
    }
  `,
  name: 'getRisks',
};

export const GET_CHART_LABELS: QueryData<'getLabels', unknown> = {
  query: gql`
    query {
      getLabels {
        boom
        end
        labelId
        name
        start
      }
    }
  `,
  name: 'getLabels',
};

export const GET_STATISTICS: QueryData<'getStatistics', unknown> = {
  query: gql`
    ${STATISTICS_FIELDS}
    query getStatistics {
      getStatistics {
        low {
          ...StatisticsFields
        }
        lower {
          ...StatisticsFields
        }
        medium {
          ...StatisticsFields
        }
        higher {
          ...StatisticsFields
        }
        high {
          ...StatisticsFields
        }
      }
    }
  `,
  name: 'getStatistics',
};

export const GET_FACTSHEETS: QueryData<'getFactSheets', unknown> = {
  query: gql`
    ${FACTSHEET_FIELDS}
    query {
      getFactSheets {
        ...FactsheetFields
      }
    }
  `,
  name: 'getFactSheets',
};

export const PREVIEW_FACTSHEET: QueryData<
  'previewFactsheet',
  QueryPreviewFactsheetArgs
> = {
  query: gql`
    query (
      $brandColor: String!
      $companyAddress: String!
      $companyName: String!
      $phoneNumber: String!
      $logo: String!
    ) {
      previewFactsheet(
        brandColor: $brandColor
        companyAddress: $companyAddress
        companyName: $companyName
        phoneNumber: $phoneNumber
        logo: $logo
      )
    }
  `,
  name: 'previewFactsheet',
};

export const GET_FACTSHEET: (
  variables: QueryGetFactSheetByArgumentsArgs,
) => QueryData<'getFactSheetByArguments', QueryGetFactSheetByArgumentsArgs> = (
  variables,
) => ({
  query: gql`
    ${FACTSHEET_FIELDS}
    query (
      $portfolioStyle: Enum_PortfolioStyle!
      $risk: Enum_PortfolioSelection
      $ownedBy: String
    ) {
      getFactSheetByArguments(
        portfolioStyle: $portfolioStyle
        risk: $risk
        ownedBy: $ownedBy
      ) {
        ...FactsheetFields
      }
    }
  `,
  name: 'getFactSheetByArguments',
  variables,
});

export const RENDER_FACTSHEET: (
  variables: QueryRenderFactSheetArgs,
) => QueryData<'renderFactSheet', QueryRenderFactSheetArgs> = (variables) => ({
  query: gql`
    ${FACTSHEET_FIELDS}
    query ($factsheetId: String!) {
      renderFactSheet(factsheetId: $factsheetId) {
        ...FactsheetFields
      }
    }
  `,
  name: 'renderFactSheet',
  variables,
});

export const GET_STYLE_RECOMMENDATIONS: (
  variables: QueryGetStyleRecommendationArgs,
) => QueryData<'getStyleRecommendation', QueryGetStyleRecommendationArgs> = (
  variables,
) => ({
  query: gql`
    query ($journeyId: ID!) {
      getStyleRecommendation(journeyId: $journeyId) {
        recommendation
        number
      }
    }
  `,
  name: 'getStyleRecommendation',
  variables,
});

export const GET_RETURNS: QueryData<'getReturns', unknown> = {
  query: gql`
    query {
      getReturns {
        returnsId
        fileName
        createdAt
      }
    }
  `,
  name: 'getReturns',
};

export const GET_RETURNS_FILE = (variables: QueryGetReturnsFileArgs) => {
  return {
    query: gql`
      query ($returnsId: ID!) {
        getReturnsFile(returnsId: $returnsId)
      }
    `,
    name: 'getReturnsFile',
    variables,
  } as QueryData<'getReturnsFile', QueryGetReturnsFileArgs>;
};
