import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ color?: string }>()(
  (theme, { color }) => ({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      height: 0,
      pointerEvents: 'none',
      '&::after': {
        display: 'block',
        content: '""',
        height: theme.spacing(8),
      },
    },
    top: {
      bottom: 0,
      '&::after': {
        background: `linear-gradient(${alpha(
          color ?? theme.palette.common.white,
          1,
        )}, ${alpha(color ?? theme.palette.common.white, 0)})`,
      },
    },
    bottom: {
      top: 0,
      '&::after': {
        marginTop: `-${theme.spacing(8)}`,
        background: `linear-gradient(${alpha(
          color ?? theme.palette.common.white,
          0,
        )}, ${alpha(color ?? theme.palette.common.white, 1)})`,
      },
    },
  }),
);
