import { FC, memo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';

import { useAuth } from 'src/auth';
import { useLogo } from 'src/hooks/useLogo';
import { Routes } from 'src/routing';

import { useHasUnreadNotifications, useUserGroup } from 'src/hooks';
import { FullLoading } from 'src/components/layout';
import { useStyles } from './styles';
import { NotificationsDrawer } from './NotificationsDrawer';
import { NotificationBell } from './NotificationBell';

interface Props {
  disableLink?: boolean;
  useDefaultLogo?: boolean;
}

export const Header: FC<Props> = memo(({ disableLink, useDefaultLogo }) => {
  const { classes } = useStyles();
  const { attributes } = useAuth();
  const { isMasterAdmin, isIfa } = useUserGroup();
  const { src, companyLogoLoaded } = useLogo(useDefaultLogo);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const hasUnreadNotifications = useHasUnreadNotifications({ skip: !isIfa });

  if (isIfa && !companyLogoLoaded) {
    return <FullLoading fullPage cover />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <Typography
          variant="h3"
          color="primary"
          className={classes.attributeText}
        >
          {isMasterAdmin || !attributes ? 'Aspen' : attributes?.name}
        </Typography>
        {attributes && (
          <Link
            variant="button"
            component={NavLink}
            to={Routes.Logout}
            color="primary"
            underline="hover"
            fontWeight={300}
          >
            Logout
          </Link>
        )}
      </div>
      {src && (
        <Link
          to={Routes.Dashboard}
          component={NavLink}
          height="100%"
          onClick={(e) => {
            if (!disableLink) return;
            e.preventDefault();
          }}
          sx={{ cursor: disableLink ? 'default' : 'pointer' }}
        >
          <img className={classes.logo} src={src} alt="logo" />
        </Link>
      )}
      {isIfa && (
        <>
          <NotificationBell
            isOpen={drawerOpen}
            onClick={() => setDrawerOpen(!drawerOpen)}
            hasUnreadNotifications={!!hasUnreadNotifications}
          />
          <NotificationsDrawer
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          />
        </>
      )}
    </div>
  );
});
