import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { largeCanvasH, largeCanvasW } from './utils';

export const useStyles = makeStyles()((theme) => ({
  root: {
    '& .MuiPaper-root': {
      padding: 0,
      maxWidth: '100%',
      borderRadius: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexShrink: 0,
    height: 40,
    borderBottom: `1px solid ${lighten(theme.palette.primary.main, 0.5)}`,
    paddingRight: theme.spacing(3),
  },
  headerBtn: {
    borderRadius: 0,
    width: 240,
    height: '100%',
    fontSize: 16,
    fontWeight: 600,
    '&.MuiButton-outlined': {
      border: 0,
      fontWeight: 400,
    },
  },
  closeBtn: {
    color: theme.palette.primary.main,
    zIndex: 1,
    padding: 5,
    marginRight: 10,
  },
  container: {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(2),
    overflowY: 'auto',
  },
  footerText: {
    fontSize: 12,
    marginTop: 40,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentWrapper: {
    flexDirection: 'column',
  },
  filtersWrapper: {
    paddingLeft: 70,
    paddingRight: 100,
    margin: '10px 0px',
    width: '100%',
  },
  togglesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'space-around',
    paddingLeft: 80,
    paddingRight: 95,
    margin: '10px 0px',
    width: '100%',
  },
  toggleWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  exitZoom: {
    position: 'absolute',
    right: 115,
    top: 30,
    zIndex: 100,
    fontSize: 12,
    minWidth: 'unset',
    height: 'unset',
    padding: '6px 28px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  printBtn: {
    position: 'absolute',
    right: 115,
    bottom: 70,
    zIndex: 100,
    fontSize: 12,
    minWidth: 'unset',
    height: 'unset',
    padding: '6px 28px',
    '&.higher': {
      bottom: 100,
    },
  },
  largeChart: {
    width: largeCanvasW,
    height: largeCanvasH,
  },
  eventsBadgeWrap: {
    minWidth: '50%',
    maxWidth: 700,
    width: '100%',
    height: 2,
    position: 'relative',
    margin: '0px auto 12px auto',
    backgroundColor: theme.palette.primary.main,
  },
  eventsBadge: {
    position: 'absolute',
    padding: '2px 8px',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 'bold',
  },
  eventsDisclaimer: {
    color: theme.palette.primary.main,
    fontSize: 12,
    textAlign: 'center',
    margin: '12px 0px',
  },
}));
