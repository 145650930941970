import { createIcon } from '../createIcon';

export const AdHocIcon = createIcon({
  svg: ({ palette }) => (
    <>
      <circle
        cx="36"
        cy="36"
        r="35.5"
        fill={palette.common.white}
        stroke="color-mix(in srgb, currentColor 20%, transparent)"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M50.827 51H21V21"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M35.74 29.67h-4.336V47.88h4.336V29.67zM49.613 21h-4.335v26.879h4.335V21z"
      />
    </>
  ),
  viewBox: '0 0 72 72',
});
