import { createIcon } from '../createIcon';

export const UsefulResourcesIcon = createIcon({
  svg: () => (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5C3 3.61929 4.11929 2.5 5.5 2.5H12.5C13.2869 2.5 14.0279 2.87049 14.5 3.5L15.7 5.1C16.361 5.98132 17.3983 6.5 18.5 6.5H23C24.3807 6.5 25.5 7.61929 25.5 9V10C25.5 10.2761 25.7239 10.5 26 10.5C26.2761 10.5 26.5 10.2761 26.5 10V9C26.5 7.067 24.933 5.5 23 5.5H18.5C17.7131 5.5 16.9721 5.12951 16.5 4.5L15.3 2.9C14.639 2.01868 13.6017 1.5 12.5 1.5H5.5C3.567 1.5 2 3.067 2 5V26.5C2 28.433 3.567 30 5.5 30H27C28.6569 30 30 28.6569 30 27V14C30 12.3431 28.6569 11 27 11H11C9.34315 11 8 12.3431 8 14V26.5C8 27.8807 6.88071 29 5.5 29C4.11929 29 3 27.8807 3 26.5V5ZM11 12H27C28.1046 12 29 12.8954 29 14V27C29 28.1046 28.1046 29 27 29H7.94949C8.59777 28.3647 9 27.4793 9 26.5V14C9 12.8954 9.89543 12 11 12Z"
        fill="currentColor"
      />
    </g>
  ),
  fontSize: 'inherit',
  viewBox: '0 0 32 32',
});
