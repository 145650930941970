import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useStateMessage = (): string | undefined => {
  const navigate = useNavigate();
  const location = useLocation();
  const [initialMessage] = useState(location?.state?.message);

  useEffect(() => {
    if (location?.state?.message) {
      delete location?.state.message;
      navigate('', { replace: true, state: location?.state });
    }
  });

  return initialMessage;
};
