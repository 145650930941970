import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { useCompany, useUserGroup } from 'src/hooks';
import { getQuestionnaireTheme } from 'src/restApi/getQuestionnaireTheme';
import { Routes } from 'src/routing';
import { companyTheme } from './companyTheme';
import { defaultTheme as getDefaultTheme } from './defaultTheme';

interface Props {
  children?: ReactNode;
}

export const ThemeController: FC<Props> = ({ children }) => {
  const ifaCompany = useCompany();
  const { isMasterAdmin } = useUserGroup();
  const [ifaColor, setIfaColor] = useState<string>();

  const defaultTheme = getDefaultTheme();

  const theme = useMemo(() => {
    if (ifaCompany?.brandColor) {
      return companyTheme({ main: ifaCompany?.brandColor });
    }
    if (ifaColor) {
      return companyTheme({ main: ifaColor });
    }

    if (
      window.location.pathname.startsWith(Routes.Index) ||
      window.location.pathname.startsWith(Routes.Login) ||
      window.location.pathname.startsWith(Routes.Logout) ||
      isMasterAdmin
    ) {
      return defaultTheme;
    }

    return companyTheme({ main: '#FFFFFF' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ifaColor, ifaCompany, window.location]);

  useEffect(() => {
    if (window.location.pathname.startsWith(Routes.Questionnaire)) {
      const id = window.location.pathname.split('/')[2];
      if (id) {
        getQuestionnaireTheme(id)
          .then((questionnaireTheme) => {
            if (!questionnaireTheme.color) {
              setIfaColor(defaultTheme.palette.primary.main);
              return;
            }
            setIfaColor(questionnaireTheme.color);
          })
          .catch((error: Error) => {
            setIfaColor(defaultTheme.palette.primary.main);
            console.log('Could not get questionnaire theme', error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
