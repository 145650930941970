import axios, { AxiosError } from 'axios';
import { PUBLIC_QUESTIONNAIRE_LAMBDA_URL } from './lambda-routes';

export async function getQuestionnaireTheme(id: string) {
  const res = await axios
    .post(`${PUBLIC_QUESTIONNAIRE_LAMBDA_URL}/questionnaire-theme`, { id })
    .catch((reason: AxiosError) => {
      const { response } = reason;
      const { error = 'Error' } = response?.data as Record<string, string>;
      const status = response?.status;
      throw { error, status };
    });

  return res.data.data;
}
