import { FC, memo, MouseEvent as RMouseEvent, useCallback } from 'react';

import { useGlobalDrag } from 'src/hooks/useGlobalDrag';

import { useStyles } from './styles';

interface Props {
  h: number;
  s: number;
  v: number;
  onChange: (s: number, v: number) => void;
}

export const ColorBox: FC<Props> = memo(({ h, s, v, onChange }) => {
  const { classes } = useStyles();
  const handleMouseMove = useCallback(
    (moveEv: MouseEvent, el: HTMLDivElement) => {
      moveEv.preventDefault();
      const { x, y, width, height } = el.getBoundingClientRect();
      onChange?.(
        Math.min(Math.max(((moveEv.clientX as number) - x) / width, 0), 1),
        Math.min(Math.max(1 - ((moveEv.clientY as number) - y) / height, 0), 1),
      );
    },
    [onChange],
  );
  const startDrag = useGlobalDrag<HTMLDivElement>(handleMouseMove);
  const handleMouseDown = useCallback(
    (e: RMouseEvent<HTMLDivElement>) => {
      startDrag(e.currentTarget);
      handleMouseMove(e.nativeEvent, e.currentTarget);
    },
    [startDrag, handleMouseMove],
  );
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={classes.root} onMouseDown={handleMouseDown}>
      <svg
        color={`hsl(${h * 360},100%,50%)`}
        className={classes.box}
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="saturation" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#fff" />
            <stop offset="100%" stopColor="currentColor" />
          </linearGradient>
          <linearGradient id="brightness" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgba(0,0,0,0)" />
            <stop offset="100%" stopColor="#000" />
          </linearGradient>
          <pattern id="pattern_config" width="100%" height="100%">
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill="url(#saturation)"
            />
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill="url(#brightness)"
            />
          </pattern>
        </defs>
        <rect
          x="0"
          y="0"
          width="100"
          height="100"
          fill="url(#pattern_config)"
        />
      </svg>
      <div
        className={classes.dragger}
        style={{ top: `${100 * (1 - v)}%`, left: `${100 * s}%` }}
      />
    </div>
  );
});
