import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  inputSection: {
    width: '100%',
    marginTop: theme.spacing(9),
    display: 'flex',
  },
  inputWrapper: {
    width: '100%',
    marginLeft: theme.spacing(10),
    '& h1': {
      marginBottom: theme.spacing(7),
      fontSize: 20,
    },
  },
}));
