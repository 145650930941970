import { FC, memo, useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Button, DialogActions, Switch } from '@mui/material';

import { Mutation, SET_IFA_STATUS } from 'src/apollo';
import { useDialog, useSnack } from 'src/hooks';

interface Props {
  id: string;
  name: string;
  value: boolean;
  userGroupName: string;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  onCompleted?: (value: boolean) => void;
}

export const ActivationSwitch: FC<Props> = memo(
  ({ id, name, value, userGroupName, disabled, onChange, onCompleted }) => {
    const [setStatusCall, { error, loading }] = useMutation<Mutation>(
      SET_IFA_STATUS.mutation,
      {
        refetchQueries: [],
        onCompleted: () => {
          onCompleted?.(!value);
        },
      },
    );
    const dialog = useDialog();
    const snackError = useSnack();

    const setStatus = useCallback(
      () => setStatusCall({ variables: { id, enabled: !value } }),
      [id, value, setStatusCall],
    );

    const handleChangeStatus = useCallback(() => {
      if (onChange) {
        onChange(!value);
        return;
      }
      // TODO: in the future, the onChange prop should also
      // be used for changing IFA status
      snackError.close();
      dialog.show({
        content: (
          <>
            <Box py={10} px="80px">
              <Box>{`Are you sure you want to ${
                value ? 'deactivate' : 'activate'
              } ${name} ${userGroupName} profile?`}</Box>
            </Box>
            <DialogActions>
              <Button size="small" variant="outlined" onClick={dialog.close}>
                Cancel
              </Button>
              <Button
                size="small"
                onClick={() => {
                  dialog.close();
                  setStatus();
                }}
              >
                {value ? 'Deactivate' : 'Activate'}
              </Button>
            </DialogActions>
          </>
        ),
        title: `${value ? 'Deactivate ' : 'Activate '} ${userGroupName}`,
      });
    }, [dialog, name, value, userGroupName, snackError, setStatus]);

    useEffect(() => {
      if (error) {
        snackError.show({
          message: `${value ? 'Dea' : 'A'}ctivating ${name} failed`,
          onTryAgain: setStatus,
        });
      } else {
        snackError.close();
      }
    }, [error, snackError, handleChangeStatus, value, name, setStatus]);

    return (
      <Switch
        checked={value}
        disabled={loading || disabled}
        onChange={handleChangeStatus}
        edge="end"
      />
    );
  },
);
