import { CognitoGroup } from 'src/apollo';
import { useAuth } from 'src/auth';

export const getGroupsMap = (groups?: CognitoGroup[]) => {
  return {
    isIfa: !!groups?.includes(CognitoGroup.Ifa),
    isIfaAdmin: !!groups?.includes(CognitoGroup.IfaCompanyAdmin),
    isMasterAdmin: !!groups?.includes(CognitoGroup.MasterAdmin),
  };
};

export const getRoleName = (groups?: CognitoGroup[]) => {
  const { isIfaAdmin, isMasterAdmin } = getGroupsMap(groups);
  if (isMasterAdmin) return CognitoGroup.MasterAdmin;
  if (isIfaAdmin) return CognitoGroup.IfaCompanyAdmin;
  return CognitoGroup.Ifa;
};

export const useUserGroup = () => {
  const { attributes } = useAuth();

  return getGroupsMap(attributes?.groups);
};
