import { FC, FormEvent, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Button, Container } from '@mui/material';

import { useStyles } from './styles';
import { ErrorBox } from '../ErrorBox';
import { SuccessMessage } from '../SuccessMessage';
import { LoadingBox } from '../LoadingBox';

interface Props {
  buttonLabel: string;
  isDisabled: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  belowButton?: ReactNode;
  error?: string;
  message?: ReactNode;
  onCancelRedirectTo?: string;
  className?: string;
  children?: ReactNode;
  additionalButtons?: ReactNode[];
}

export const SimpleForm: FC<Props> = ({
  belowButton,
  buttonLabel,
  children,
  error,
  isDisabled,
  isLoading,
  message,
  onSubmit,
  onCancelRedirectTo,
  additionalButtons,
  className,
}) => {
  const { classes, cx } = useStyles();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isLoading) {
      onSubmit();
    }
  };

  const buttonContent = isLoading ? (
    <LoadingBox invertColor size={30} />
  ) : (
    buttonLabel
  );

  const errorBox = error && (
    <Box mb={4} width="100%">
      <ErrorBox>{error}</ErrorBox>
    </Box>
  );
  const messageBox = message && (
    <SuccessMessage style={{ marginBottom: 16, marginTop: errorBox ? 16 : 0 }}>
      {message}
    </SuccessMessage>
  );

  return (
    <Container
      component="form"
      onSubmit={handleSubmit}
      className={className}
      disableGutters
    >
      {errorBox}
      {messageBox}
      {children}
      <Box
        mt={9}
        display={additionalButtons?.length ? 'flex' : 'block'}
        gap={3}
        className={cx(
          { [classes.withCancel]: !!onCancelRedirectTo },
          classes.submitContainer,
        )}
      >
        {additionalButtons}
        {onCancelRedirectTo && (
          <Button
            variant="outlined"
            disabled={isLoading}
            component={NavLink}
            to={onCancelRedirectTo}
          >
            Cancel
          </Button>
        )}
        <Button type="submit" disabled={isDisabled} className={classes.submit}>
          {buttonContent}
        </Button>
      </Box>

      {belowButton}
    </Container>
  );
};
