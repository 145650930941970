import { MenuItem, Select, SxProps } from '@mui/material';
import { FC } from 'react';
import { Theme } from '@emotion/react';
import { useStyles } from './styles';

export type DropdownOption = {
  value: string;
  name: string;
};

interface Props {
  onChange?: (v: string) => void;
  value: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  options: DropdownOption[];
}

export const Dropdown: FC<Props> = ({
  value,
  options,
  disabled,
  sx = { width: 200 },
  onChange,
}) => {
  const { classes } = useStyles();
  return (
    <Select
      className={classes.root}
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      disabled={disabled}
      sx={sx}
    >
      {options.map((opt) => {
        return (
          <MenuItem value={opt.value} key={opt.value}>
            {opt.name}
          </MenuItem>
        );
      })}
    </Select>
  );
};
