import { FC } from 'react';
import { useTheme } from '@mui/material';

export const ImagePlaceholder: FC = () => {
  const theme = useTheme();
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none">
      <path
        d="M28 13C28.5523 13 29 13.4477 29 14V27H42C42.5523 27 43 27.4477 43 28C43 28.5523 42.5523 29 42 29H29V42C29 42.5523 28.5523 43 28 43C27.4477 43 27 42.5523 27 42V29H14C13.4477 29 13 28.5523 13 28C13 27.4477 13.4477 27 14 27H27V14C27 13.4477 27.4477 13 28 13Z"
        fill={theme.palette.primary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56ZM28 54C42.3594 54 54 42.3594 54 28C54 13.6406 42.3594 2 28 2C13.6406 2 2 13.6406 2 28C2 42.3594 13.6406 54 28 54Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
