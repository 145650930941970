import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.spacing(16),
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(19),
    flexShrink: 0,
    alignSelf: 'stretch',
    color: theme.palette.error.contrastText,
    background: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px 0px 0px 8px',
    '&.shadow': {
      boxShadow: `1px 1px 20px 0px ${lighten(theme.palette.error.main, 0.2)}`,
    },
  },
  successIcon: {
    background: '#59E388',
    '&.shadow': {
      boxShadow: `1px 1px 20px 0px ${lighten('#59E388', 0.2)}`,
    },
  },
  warningIcon: {
    background: '#E3A359',
    '&.shadow': {
      boxShadow: `1px 1px 20px 0px ${lighten('#E3A359', 0.2)}`,
    },
  },
  neutralIcon: {
    background: '#A6A6A6',
    '&.shadow': {
      boxShadow: `1px 1px 20px 0px ${lighten('#A6A6A6', 0.2)}`,
    },
  },
  text: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  },
  actionButtons: {
    flexShrink: 0,
    marginLeft: theme.spacing(1),
  },
  timeoutWrap: {
    position: 'absolute',
    bottom: 0,
    left: 4,
    width: 'calc(100% - 7px)',
    borderRadius: '0px 0px 16px 0px',
    height: 3,
    backgroundColor: 'white',
  },
  timeoutBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 0,
    height: '100%',
    background: theme.palette.error.main,
    transition: 'linear',
  },
  shadow: {
    boxShadow: '#e0e0e0 0px 4px 12px',
  },
}));
