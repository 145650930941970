import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  return {
    root: {
      '& p': {
        color: theme.palette.primary.main,
      },
    },
    message: {
      '& .ql-container': {
        border: '0px !important',
      },
      '& .ql-toolbar': {
        display: 'none',
      },
      '& .ql-editor': {
        padding: 0,
        fontSize: 16,
        maxHeight: 'calc(60vh - 100px)',
      },
    },
  };
});
