import { createIcon } from '../createIcon';

export const FiltersClearIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.3"
        d="M8 4.68H2c.059 2.473.766 4.767 1.914 6.763 1.914 3.336 8.98 7.536 8.98 11.14V30l6.183-2.085v-5.332c0-3.604 7.037-7.745 8.95-11.051C29.234 9.506 29.912 7.183 30 4.68h-5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.3"
        d="M0.65 -0.65L13.212 -0.65"
        transform="matrix(.69253 .72139 -.7479 .66381 11 3)"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.3"
        d="M11.8 12.23L20.6 3"
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
