import { createIcon } from '../createIcon';

export const SecondIcon = createIcon({
  svg: ({ palette }) => (
    <g fill="none" fillRule="evenodd">
      <circle
        cx="22"
        cy="22"
        r="21"
        fill={palette.primary.main}
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M17.992 25.888V28H9.528V26.32L13.848 22.24C14.3067 21.8027 14.616 21.4293 14.776 21.12C14.936 20.8 15.016 20.4853 15.016 20.176C15.016 19.728 14.8613 19.3867 14.552 19.152C14.2533 18.9067 13.8107 18.784 13.224 18.784C12.7333 18.784 12.2907 18.88 11.896 19.072C11.5013 19.2533 11.1707 19.5307 10.904 19.904L9.016 18.688C9.45333 18.0373 10.056 17.5307 10.824 17.168C11.592 16.7947 12.472 16.608 13.464 16.608C14.296 16.608 15.0213 16.7467 15.64 17.024C16.2693 17.2907 16.7547 17.6747 17.096 18.176C17.448 18.6667 17.624 19.248 17.624 19.92C17.624 20.528 17.496 21.0987 17.24 21.632C16.984 22.1653 16.488 22.7733 15.752 23.456L13.176 25.888H17.992Z"
        fill={palette.primary.contrastText}
      />
      <path
        d="M23.788 17.448C24.588 17.448 25.232 17.688 25.72 18.168C26.216 18.648 26.464 19.36 26.464 20.304V24H24.592V20.592C24.592 20.08 24.48 19.7 24.256 19.452C24.032 19.196 23.708 19.068 23.284 19.068C22.812 19.068 22.436 19.216 22.156 19.512C21.876 19.8 21.736 20.232 21.736 20.808V24H19.864V17.544H21.652V18.3C21.9 18.028 22.208 17.82 22.576 17.676C22.944 17.524 23.348 17.448 23.788 17.448ZM34.8072 15.096V24H33.0192V23.256C32.5552 23.816 31.8832 24.096 31.0032 24.096C30.3952 24.096 29.8432 23.96 29.3472 23.688C28.8592 23.416 28.4752 23.028 28.1952 22.524C27.9152 22.02 27.7752 21.436 27.7752 20.772C27.7752 20.108 27.9152 19.524 28.1952 19.02C28.4752 18.516 28.8592 18.128 29.3472 17.856C29.8432 17.584 30.3952 17.448 31.0032 17.448C31.8272 17.448 32.4712 17.708 32.9352 18.228V15.096H34.8072ZM31.3272 22.56C31.7992 22.56 32.1912 22.4 32.5032 22.08C32.8152 21.752 32.9712 21.316 32.9712 20.772C32.9712 20.228 32.8152 19.796 32.5032 19.476C32.1912 19.148 31.7992 18.984 31.3272 18.984C30.8472 18.984 30.4512 19.148 30.1392 19.476C29.8272 19.796 29.6712 20.228 29.6712 20.772C29.6712 21.316 29.8272 21.752 30.1392 22.08C30.4512 22.4 30.8472 22.56 31.3272 22.56Z"
        fill={palette.primary.contrastText}
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 44 44',
});
