import axios from 'axios';
import { getToken } from 'src/auth';
import { MULTI_ASSETS_LAMBDA_URL } from './lambda-routes';

export const updateMultiAsset = async (
  multiAssetId: string,
  multiAsset: any,
) => {
  const options = {
    headers: {
      Authorization: await getToken(),
    },
  };

  const response = await axios
    .put(
      `${MULTI_ASSETS_LAMBDA_URL}/multiAssets/${multiAssetId}`,
      multiAsset,
      options,
    )
    .catch((error) => {
      throw new Error(error.response?.data?.error ?? 'Error');
    });

  return response.data;
};
