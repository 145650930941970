import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import { CloseIcon } from 'src/components/ui';
import { useStyles } from './styles';

interface Props {
  title: string;
  message: string;
  unread?: boolean;
  auto?: boolean;
  onClick?: () => void;
  onClear?: () => void;
}

export const NotificationCard: FC<Props> = ({
  title,
  message,
  unread,
  auto,
  onClick,
  onClear,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(classes.root, { unread, auto })} onClick={onClick}>
      <IconButton
        className={classes.clearIcon}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClear?.();
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
      <Box className={classes.title}>
        <Box className={classes.unreadDot} />
        {title}
      </Box>
      <Box className={classes.message}>{message}</Box>
    </Box>
  );
};
