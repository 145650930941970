import { Box, Button, Dialog, IconButton } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { Transition } from 'src/components/shared';
import { CloseIcon } from 'src/components/ui';
import { trackChartAction } from 'src/utils';

import { useStyles } from './styles';
import { HistoricReturnsChart } from './HistoricReturnsChart';
import { ProjectedReturnsChart } from './ProjectedReturnsChart';

enum ChartTab {
  Historic,
  Projected,
}

interface Props {
  open: boolean;
  initialValue?: number;
  onClose: () => void;
}

export const InteractiveChart: FC<Props> = ({
  open,
  initialValue,
  onClose,
}) => {
  const { classes } = useStyles();
  const [closeFlag, setCloseFlag] = useState(false);
  const [selectedTab, setSelectedTab] = useState(ChartTab.Historic);

  useEffect(() => {
    if (!open) return;
    trackChartAction('Opened');
  }, [open]);

  const handleClose = () => {
    setCloseFlag(!closeFlag);
    onClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      className={classes.root}
    >
      <Box className={classes.header}>
        <Box height="100%">
          <Button
            className={classes.headerBtn}
            variant={selectedTab === ChartTab.Historic ? undefined : 'outlined'}
            onClick={() => setSelectedTab(ChartTab.Historic)}
          >
            Historic Returns
          </Button>
          <Button
            className={classes.headerBtn}
            variant={
              selectedTab === ChartTab.Projected ? undefined : 'outlined'
            }
            onClick={() => setSelectedTab(ChartTab.Projected)}
          >
            Projected Returns
          </Button>
        </Box>
        <IconButton
          data-testid="interactive-close-btn"
          className={classes.closeBtn}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {selectedTab === ChartTab.Historic ? (
        <HistoricReturnsChart
          closeFlag={closeFlag}
          initialValue={initialValue}
          open={open}
        />
      ) : (
        <ProjectedReturnsChart
          closeFlag={closeFlag}
          initialValue={initialValue}
        />
      )}
    </Dialog>
  );
};
