import { FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ color?: string }>()((theme, { color }) => ({
  root: {
    color: color ?? theme.palette.primary.main,
  },
}));

interface Props {
  image: ReactNode;
  className?: string;
  color?: string;
}

export const SvgImage: FC<Props> = ({ image, className, color }) => {
  const { classes, cx } = useStyles({ color });
  return <div className={cx(classes.root, className)}>{image}</div>;
};
