import { createIcon } from '../createIcon';

export const EditIcon = createIcon({
  svg: () => (
    <path
      d="M5.29999 19H6.37499L16.275 9.1L15.2 8.025L5.29999 17.925V19ZM18.425 8.375L15.925 5.9L17.125 4.7C17.325 4.48333 17.5667 4.375 17.85 4.375C18.1333 4.375 18.375 4.48333 18.575 4.7L19.625 5.725C19.825 5.925 19.925 6.16667 19.925 6.45C19.925 6.73333 19.825 6.975 19.625 7.175L18.425 8.375ZM17.7 9.1L6.79999 20H4.29999V17.5L15.2 6.6L17.7 9.1ZM15.725 8.55L15.2 8.025L16.275 9.1L15.725 8.55Z"
      fill="currentColor"
    />
  ),
  fontSize: 'small',
  viewBox: '0 0 24 24',
});
