import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';

interface Props {
  color: string;
  name: string;
  type?: 'solid' | 'dotted' | 'point';
}

export const LegendItem: FC<Props> = ({ color, name, type = 'point' }) => {
  const isLine = type !== 'point';

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <View
        style={{
          backgroundColor: isLine ? undefined : color,
          width: isLine ? 10 : 6,
          height: isLine ? 0 : 6,
          borderBottom: isLine ? `1.5px ${type} ${color}` : undefined,
          borderRadius: isLine ? 0 : '50%',
          marginRight: 6,
        }}
      />
      <Text style={{ color, fontSize: 8, fontFamily: 'Poppins' }}>{name}</Text>
    </View>
  );
};
