import { FC } from 'react';
import { ButtonBase, Tooltip } from '@mui/material';

import { useStyles } from './styles';

interface Props {
  color: string;
  size: number;
  onClick?: () => void;
  selected?: boolean;
}

export const ColorDot: FC<Props> = ({ color, onClick, selected, size }) => {
  const { classes, cx } = useStyles({ color, size });
  const className = cx(classes.root, { [classes.selected]: selected });
  const component = onClick ? (
    <ButtonBase
      className={className}
      onClick={onClick}
      aria-current={selected}
    />
  ) : (
    <div className={className} aria-current={selected} />
  );
  return <Tooltip title={color}>{component}</Tooltip>;
};
