import { createIcon } from '../createIcon';

export const OverrideIcon = createIcon({
  svg: () => (
    <>
      <path
        d="M19 9.0001H18V4.61548C18 4.46165 17.9359 4.32062 17.8077 4.1924C17.6795 4.0642 17.5385 4.0001 17.3846 4.0001H15V6.23088H7V4.0001H4.61538C4.46154 4.0001 4.32052 4.0642 4.1923 4.1924C4.0641 4.32062 4 4.46165 4 4.61548V17.3847C4 17.5386 4.0641 17.6796 4.1923 17.8078C4.32052 17.936 4.46154 18.0001 4.61538 18.0001H10V19.0001H4.61538C4.16794 19.0001 3.78685 18.8427 3.4721 18.528C3.15737 18.2133 3 17.8322 3 17.3847V4.61548C3 4.16805 3.15737 3.78695 3.4721 3.4722C3.78685 3.15747 4.16794 3.0001 4.61538 3.0001H9.25192C9.34551 2.60907 9.5532 2.28055 9.875 2.01453C10.1968 1.7485 10.5718 1.61548 11 1.61548C11.4359 1.61548 11.8138 1.7485 12.1337 2.01453C12.4535 2.28055 12.6603 2.60907 12.7538 3.0001H17.3846C17.8321 3.0001 18.2132 3.15747 18.5279 3.4722C18.8426 3.78695 19 4.16805 19 4.61548V9.0001ZM11 4.23088C11.232 4.23088 11.4247 4.15427 11.5779 4.00105C11.7311 3.84785 11.8077 3.65523 11.8077 3.42318C11.8077 3.19113 11.7311 2.9985 11.5779 2.8453C11.4247 2.69209 11.232 2.61548 11 2.61548C10.768 2.61548 10.5753 2.69209 10.4221 2.8453C10.2689 2.9985 10.1923 3.19113 10.1923 3.42318C10.1923 3.65523 10.2689 3.84785 10.4221 4.00105C10.5753 4.15427 10.768 4.23088 11 4.23088Z"
        fill="currentColor"
      />
      <path
        d="M6.5 15.2692H12.5V14.2692H6.5V15.2692ZM6.5 12.4999H15.5V11.4999H6.5V12.4999ZM6.5 9.73071H15.5V8.73071H6.5V9.73071Z"
        fill="currentColor"
      />
      <path
        d="M17.8 23V15.125L14.7 18.2L14 17.5L18.3 13.2L22.6 17.5L21.9 18.2L18.8 15.125V23H17.8Z"
        fill="currentColor"
      />
    </>
  ),
  fontSize: 'large',
  viewBox: '0 0 24 24',
});
