import { ChangeEvent, FC, ReactNode, useCallback } from 'react';
import { Box, Link, useTheme } from '@mui/material';

import { ImagePlaceholder } from './ImagePlaceholder';
import { useStyles } from './styles';

interface Props {
  id: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  value?: string | null;
  additionalControls?: ReactNode[];
  accept?: string;
}

export const ImageInput: FC<Props> = ({
  disabled,
  id,
  value,
  additionalControls,
  onChange,
  accept = 'image/*',
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const file = ev.currentTarget.files?.[0];
      if (!file) return;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        onChange(reader.result as string);
      };
    },
    [onChange],
  );

  const image = value ? (
    <img src={value} alt="uploaded logo" />
  ) : (
    <Box className={classes.iconWrapper}>
      <ImagePlaceholder />
    </Box>
  );

  return (
    <>
      <Link
        component="label"
        htmlFor={id}
        variant="body2"
        className={classes.root}
        underline="none"
      >
        <input
          id={id}
          name={id}
          type="file"
          onChange={handleChange}
          disabled={disabled}
          accept={accept}
          hidden
        />
        <Box mb={2} ml={2} sx={{ color: theme.palette.primary.main }}>
          <b>Add logo</b> (Suggested image aspect ratio 16:10)
        </Box>
        <div className={classes.preview}>
          {image}
          <Box className={classes.actionsWrap}>{additionalControls}</Box>
        </div>
      </Link>
    </>
  );
};
