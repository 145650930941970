import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface Props {
  headerHeight: string;
}

export const useStyles = makeStyles<Props>()((theme, { headerHeight }) => {
  return {
    drawer: {
      '& .MuiDrawer-paper': {
        boxShadow: 'none',
      },
      '&, & .MuiBackdrop-root, & .MuiDrawer-paper': {
        top: headerHeight,
        height: `calc(100% - ${headerHeight})`,
      },
    },
    container: {
      width: 400,
      padding: theme.spacing(0, 4, 4, 4),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      borderTop: `1px solid ${lighten(theme.palette.primary.main, 0.9)}`,
    },
    clearAll: {
      color: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      padding: '4px 14px',
      borderRadius: 14,
      cursor: 'pointer',
    },
    topBar: {
      padding: 16,
      paddingBottom: 8,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    notifications: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      flexGrow: 1,
      minHeight: 0,
      overflow: 'auto',
    },
  };
});
