import { createIcon } from '../createIcon';

export const CalendarIcon = createIcon({
  svg: () => (
    <g>
      <path fill="currentColor" d="M0 6H16V6.5H0z" />
      <circle cx="4.75" cy="9.25" r=".75" fill="currentColor" />
      <circle cx="8.25" cy="9.25" r=".75" fill="currentColor" />
      <circle cx="4.75" cy="12.75" r=".75" fill="currentColor" />
      <circle cx="8.25" cy="12.75" r=".75" fill="currentColor" />
      <circle cx="11.75" cy="9.25" r=".75" fill="currentColor" />
      <path
        fill="#979797"
        fillRule="evenodd"
        d="M3.75 1.25h2V2.5h4.5V1.25h2V2.5H15c.552 0 1 .448 1 1V15c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V3.5c0-.552.448-1 1-1h2.75V1.25zm0 1.75H1c-.256 0-.468.193-.497.442L.5 3.5V15c0 .256.193.468.442.497L1 15.5h14c.256 0 .468-.193.497-.442L15.5 15V3.5c0-.256-.193-.468-.442-.497L15 3h-2.75v1.75h-2V3h-4.5v1.75h-2V3zm.5 1.25h1v-2.5h-1v2.5zm6.5 0h1v-2.5h-1v2.5z"
        clipRule="evenodd"
      />
      <mask
        id="9evimzx19a"
        width="16"
        height="15"
        x="0"
        y="1"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M3.75 1.25h2V2.5h4.5V1.25h2V2.5H15c.552 0 1 .448 1 1V15c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V3.5c0-.552.448-1 1-1h2.75V1.25zm0 1.75H1c-.256 0-.468.193-.497.442L.5 3.5V15c0 .256.193.468.442.497L1 15.5h14c.256 0 .468-.193.497-.442L15.5 15V3.5c0-.256-.193-.468-.442-.497L15 3h-2.75v1.75h-2V3h-4.5v1.75h-2V3zm.5 1.25h1v-2.5h-1v2.5zm6.5 0h1v-2.5h-1v2.5z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#9evimzx19a)">
        <path fill="currentColor" d="M0 0H16V16H0z" />
      </g>
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
