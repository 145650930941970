import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  top: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: 1,
    width: '100%',
    position: 'relative',
  },
  saveButton: {
    position: 'absolute',
    right: 0,
  },
  pageButton: {
    height: 60,
    width: 120,
    padding: 0,
    textAlign: 'center',
    fontSize: theme.typography.fontSize * 1.25,
    fontWeight: theme.typography.fontWeightLight,
    border: 'none',
    transform: 'translateY(1px)',
    transition: '0s',
    borderRadius: 0,
    '&.selected': {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover': {
      border: 'none',
      '&.selected': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
  },
}));
