/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useLazyQuery } from '@apollo/client';
import { Box, Button, Switch, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';

import {
  Enum_RiskName,
  GET_HISTORY,
  Query,
  QueryGetHistoryArgs,
} from 'src/apollo';
import { FullLoading } from 'src/components/layout';
import { PageTitle, trackChartAction } from 'src/utils';
import { usePDF } from '@react-pdf/renderer';

import { useLogo } from 'src/hooks';
import { useAuth } from 'src/auth';
import { download } from 'src/utils/download';
import { Chart } from './Chart';
import { areDatesEqual, coercePositive } from './Chart/utils';
import { Filters } from './Filters';
import { RiskSummary } from './RiskSummary';
import { useStyles } from './styles';
import { Downturn, downturns } from './downturns';
import { RiskTable, riskLevels } from './utils';
import { HistoricReturnsPdf } from './ChartPdf/HistoricReturnsPdf';

const defaultValue = 500000;
const defaultMinDate = '1993-12-31';

type PrintOptions = {
  cleanLabels?: boolean;
  labelsCleaned?: boolean;
  chartResized?: boolean;
  pdfUpdated?: boolean;
};

interface Props {
  open: boolean;
  initialValue?: number;
  closeFlag?: boolean;
}

export const HistoricReturnsChart: FC<Props> = ({
  open,
  initialValue,
  closeFlag,
}) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const { attributes } = useAuth();
  const { src } = useLogo();
  const [instance, updateInstance] = usePDF({ document: <></> });
  const [renderFlag, setRenderFlag] = useState(0);
  const [lastUrl, setLastUrl] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showGrid, setShowGrid] = useState(true);
  const [showEvents, setShowEvents] = useState(false);
  const [isWholeRange, setIsWholeRange] = useState(true);
  const [showDownturns, setShowDownturns] = useState(false);
  const [monthsShown, setMonthsShown] = useState(false);
  const [additionalSeries, setAdditionalSeries] = useState<string[]>([]);
  const [currentDownturn, setCurrentDownturn] = useState<Downturn>();
  const [selectedRisk, setSelectedRisk] = useState<Enum_RiskName>(
    Enum_RiskName.Medium,
  );
  const [downturnsList, setDownturnsList] = useState(downturns);
  const [printing, setPrinting] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [startingValue, setStartingValue] = useState(
    coercePositive(initialValue, defaultValue),
  );

  const { firstname, lastname } = attributes ?? {};

  const [fetchHistory, { data: historyData, loading: historyLoading }] =
    useLazyQuery<Query, QueryGetHistoryArgs>(GET_HISTORY.query, {
      fetchPolicy: 'network-only',
    });
  const data = historyData?.[GET_HISTORY.name] ?? undefined;
  const refetch = useRef<boolean>(!data);

  const { minSearchDate, maxSearchDate } = data ?? {};

  const history = data?.history ?? [];
  const currentRange = history.filter((row) => {
    if (!startDate || !endDate) return false;
    const date = new Date(row?.date ?? '').getTime();
    return date >= startDate.getTime() && date <= endDate.getTime();
  });
  const historyLength = currentRange.length || 1;
  const additionalData = history[0]?.additionalData;
  const samples = (historyLength + 1) / 12;

  const downloadInstance = () => {
    if (!instance.url) return;
    download(
      instance.url,
      'Historic Returns Chart.pdf',
      PageTitle.HistoricReturns,
    );
    setPrinting(false);
    setShowLoading(false);
  };

  useEffect(downloadInstance, [instance.url]);

  const annualisedReturns = riskLevels.reduce(
    (obj, risk) => {
      if (!currentRange.length) {
        return { ...obj, [risk]: NaN };
      }
      const endingValue = currentRange[historyLength - 1]?.[risk] ?? 1;
      const returnRate = (endingValue / startingValue) ** (1 / samples) - 1;
      return { ...obj, [risk]: returnRate * 100 };
    },
    {} as RiskTable['annualisedReturns'],
  );
  const bestOneYear = riskLevels.reduce(
    (obj, risk) => {
      if (currentRange.length < 12) {
        return { ...obj, [risk]: NaN };
      }
      let bestChange = -100;
      for (let i = 12; i < currentRange.length; i += 1) {
        const yearStart = currentRange[i - 12]![risk]!;
        const yearEnd = currentRange[i]![risk]!;
        const change = yearEnd - yearStart;
        const changePercent = (change / yearStart) * 100;
        if (changePercent > bestChange) {
          bestChange = changePercent;
        }
      }
      return { ...obj, [risk]: bestChange };
    },
    {} as RiskTable['bestOneYear'],
  );
  const worstOneYear = riskLevels.reduce(
    (obj, risk) => {
      if (currentRange.length < 12) {
        return { ...obj, [risk]: NaN };
      }
      let worstChange = 100;
      for (let i = 12; i < currentRange.length; i += 1) {
        const yearStart = currentRange[i - 12]![risk]!;
        const yearEnd = currentRange[i]![risk]!;
        const change = yearEnd - yearStart;
        const changePercent = (change / yearStart) * 100;
        if (changePercent < worstChange) {
          worstChange = changePercent;
        }
      }
      return { ...obj, [risk]: worstChange };
    },
    {} as RiskTable['worstOneYear'],
  );
  const maxDrawdown = riskLevels.reduce(
    (obj, risk) => {
      let highestVal = -Infinity;
      let maxDd = -Infinity;

      for (let i = 0; i < currentRange.length; i += 1) {
        const val = currentRange[i]![risk]!;
        if (val > highestVal) highestVal = val;
        const dd = (highestVal - val) / highestVal;
        if (dd > maxDd) maxDd = dd;
      }

      if (maxDd === -Infinity) maxDd = NaN;
      return { ...obj, [risk]: maxDd * -100 };
    },
    {} as RiskTable['maxDrawdown'],
  );

  const riskTableData: RiskTable = {
    annualisedReturns,
    bestOneYear,
    worstOneYear,
    maxDrawdown,
  };

  useEffect(() => {
    refetch.current = true;
    setStartDate(null);
    setEndDate(null);
    setStartingValue(coercePositive(initialValue, defaultValue));
    setCurrentDownturn(undefined);
  }, [closeFlag]);

  useEffect(() => {
    if (typeof initialValue !== 'number') return;
    setStartingValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!startDate || !endDate || !maxSearchDate || !minSearchDate) return;
    const sameStart = areDatesEqual(startDate, minSearchDate);
    const sameEnd = areDatesEqual(endDate, maxSearchDate);
    setIsWholeRange(sameStart && sameEnd);
  }, [startDate, endDate, maxSearchDate, minSearchDate]);

  useEffect(() => {
    if (open) return;
    setShowGrid(true);
    setShowDownturns(false);
    setShowEvents(false);
    setAdditionalSeries([]);
  }, [open]);

  useEffect(() => {
    const startFrom = startDate || minSearchDate;
    const endAt = endDate || maxSearchDate;
    if (refetch.current && open) {
      fetchHistory({
        variables: {
          value: startingValue,
          startDate: startFrom ? new Date(startFrom).toISOString() : undefined,
          endDate: endAt ? new Date(endAt).toISOString() : undefined,
        },
      });
      refetch.current = false;
    }
  }, [fetchHistory, startingValue, startDate, endDate, open]);

  useEffect(() => {
    if (minSearchDate && !startDate) {
      setStartDate(new Date(minSearchDate));
    }
    if (maxSearchDate && !endDate) {
      setEndDate(new Date(maxSearchDate));
    }
  }, [minSearchDate, startDate, maxSearchDate, endDate]);

  const selectRisk = (risk: Enum_RiskName) => {
    setSelectedRisk(risk);
    trackChartAction('Risk change');
  };

  const handleStartingValueChange = (value: number) => {
    refetch.current = true;
    setStartingValue(coercePositive(value));
  };

  const handleStartDateChange = (date: Date) => {
    refetch.current = true;
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date) => {
    refetch.current = true;
    setEndDate(date);
  };

  const handleExitZoom = () => {
    handleStartDateChange(new Date(minSearchDate ?? ''));
    handleEndDateChange(new Date(maxSearchDate ?? ''));
    setCurrentDownturn(undefined);
  };

  const handleSelectDownturn = (downturn: Downturn) => {
    handleStartDateChange(downturn.start);
    handleEndDateChange(downturn.end);
    setCurrentDownturn(downturn);
  };

  const handlePrint = (opts?: PrintOptions) => {
    const { cleanLabels, labelsCleaned, chartResized } = opts ?? {};
    if (chartResized) {
      setShowLoading(false);
      return;
    }
    if (showDownturns && !cleanLabels && !labelsCleaned) {
      setDownturnsList(
        downturnsList.map((d) => {
          return {
            ...d,
            label: d.label.replace(`\n{c|Click for more}`, ''),
            offset: d.offset - 10,
          };
        }),
      );
      handlePrint({ cleanLabels: true });
      return;
    }
    if (cleanLabels && !labelsCleaned) {
      setTimeout(() => {
        handlePrint({ labelsCleaned: true });
      }, 500);
      return;
    }
    setRenderFlag(renderFlag + 1);
  };

  const onChartRender = (url: string) => {
    if (url === lastUrl) {
      downloadInstance();
      return;
    }
    updateInstance(
      <HistoricReturnsPdf
        url={url}
        logo={src ?? ''}
        theme={theme}
        startingValue={startingValue}
        startDate={startDate}
        endDate={endDate}
        selectedRisk={selectedRisk}
        riskTableData={riskTableData}
        riskLevels={riskLevels}
        name={firstname ? `${firstname} ${lastname}` : undefined}
        additionalSeries={additionalSeries}
      />,
    );
    setLastUrl(url);
  };

  const availableSeries = additionalData?.map((d) => {
    return d?.name ?? '';
  });

  return (
    <div className={classes.container} data-testid="historic-returns-chart">
      <div className={classes.contentWrapper}>
        <Box height={110} mt={4} zIndex={1000} position="relative">
          <RiskSummary
            data={riskTableData}
            selectedRisk={selectedRisk}
            onChange={selectRisk}
          />
        </Box>
        {historyLoading ? (
          <Box height="600px" width="100%">
            <FullLoading />
          </Box>
        ) : (
          <>
            <Box position="relative" overflow="hidden">
              {currentDownturn && (
                <Button
                  variant="outlined"
                  className={classes.exitZoom}
                  onClick={handleExitZoom}
                >
                  Exit Zoom
                </Button>
              )}
              <Chart
                data={data}
                selectedRisk={selectedRisk}
                setSelectedRisk={setSelectedRisk}
                onDownturnSelect={handleSelectDownturn}
                onMonthsShownChange={setMonthsShown}
                onChartRender={onChartRender}
                showWorldEvents={showEvents && isWholeRange}
                showGrid={showGrid}
                showDownturns={showDownturns}
                downturns={downturnsList}
                additionalSeries={additionalSeries}
                printing={printing}
                renderFlag={renderFlag}
                className={cx({ [classes.largeChart]: printing })}
              />
              <Button
                className={cx(classes.printBtn, { higher: monthsShown })}
                disabled={printing}
                onClick={() => {
                  setPrinting(true);
                  setShowLoading(true);
                  setTimeout(() => handlePrint(), 500);
                }}
              >
                Download PDF
              </Button>
              {showLoading && <FullLoading cover />}
            </Box>
          </>
        )}
        {currentDownturn ? (
          currentDownturn.description(theme)
        ) : (
          <>
            <Box className={classes.filtersWrapper}>
              <Filters
                minDate={new Date(minSearchDate ?? defaultMinDate)}
                maxDate={new Date(maxSearchDate ?? Date.now())}
                startingValue={startingValue}
                startDate={startDate}
                endDate={endDate}
                onValueChange={handleStartingValueChange}
                setStartDate={handleStartDateChange}
                setEndDate={handleEndDateChange}
                disableFuture
                dropdownOptions={[
                  { value: '1', name: '1 Year' },
                  { value: '2', name: '2 Years' },
                  { value: '5', name: '5 Years' },
                  { value: '10', name: '10 Years' },
                  { value: '15', name: '15 Years' },
                ]}
              />
            </Box>
            <Box className={classes.togglesWrapper}>
              {availableSeries?.map((series) => {
                const checked = additionalSeries.includes(series);
                return (
                  <Box className={classes.toggleWrap} key={series}>
                    <Switch
                      checked={checked}
                      onChange={() => {
                        localStorage[`showAdditional-${series}`] = checked
                          ? 'false'
                          : 'true';
                        if (checked) {
                          setAdditionalSeries(
                            additionalSeries.filter((s) => s !== series),
                          );
                        } else {
                          setAdditionalSeries([...additionalSeries, series]);
                        }
                      }}
                      edge="end"
                    />
                    <Box>{series}</Box>
                  </Box>
                );
              })}
              <Box className={classes.toggleWrap}>
                <Switch
                  checked={showEvents && isWholeRange}
                  onChange={() => {
                    localStorage.disableWorldEvents = showEvents
                      ? 'true'
                      : 'false';
                    setShowEvents(!showEvents);
                  }}
                  edge="end"
                  disabled={!isWholeRange}
                />
                <Box>Show world events</Box>
              </Box>
              <Box className={classes.toggleWrap}>
                <Switch
                  checked={showDownturns}
                  onChange={() => {
                    localStorage.disableMarketDownturns = showDownturns
                      ? 'true'
                      : 'false';
                    setShowDownturns(!showDownturns);
                  }}
                  edge="end"
                />
                <Box>Show Market Downturns</Box>
              </Box>
              <Box className={classes.toggleWrap}>
                <Switch
                  checked={showGrid}
                  onChange={() => {
                    localStorage.disableChartGrid = showGrid ? 'true' : 'false';
                    setShowGrid(!showGrid);
                  }}
                  edge="end"
                />
                <Box>Show grid</Box>
              </Box>
            </Box>
            <Typography variant="body2" className={classes.footerText}>
              Source: Dimensional Returns, Aspen. The composite indices are
              indices are made up of equity, bond and cash allocations,
              corresponding to risk profile. For more information on index
              construction, please contact your financial adviser. Advisers -
              please contact Aspen. All returns are total returns, net of 0.75%
              annual fee. Past performance is not a guide to future returns.{' '}
              <strong>
                Performance summary statistics are for information only. Losses
                may exceed those indicated.
              </strong>{' '}
              Any projections are for illustrative purposes only, based off
              historic data. Returns in the future may be lower or higher than
              those shown.
              <br />
              <br />
              The value of investments and the income from them can go down as
              well as up and investors may not recover the amount of their
              original investment. The information in this illustration is
              believed to be correct but cannot be guaranteed. No representation
              or warranty (express or otherwise) is given as to the accuracy or
              completeness of the information contained in this illustration.
              This document is for illustrative purposes only and does not
              constitute advice. It does not constitute an offer to sell or a
              solicitation of an offer to purchase any security or any other
              investment or product. Aspen will not accept any liability for the
              consequences of acting or not acting upon the information
              contained in this publication. Opinions expressed are solely the
              opinions of Aspen. ‘Aspen’ is a trading name of Aspen Advisers
              Limited, a company authorised and regulated by the FCA with FRN:
              1007296, registered in England and Wales with company number
              14115281.
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};
