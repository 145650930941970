import { createIcon } from '../createIcon';

export const MailIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51779 5C1.97737 5 1.4197 7.09077 2.74423 7.90012L16 16L29.2558 7.90012C30.5803 7.09077 30.0226 5 28.4822 5H3.51779ZM3.25994 7.00626C2.81843 6.73647 3.00432 6.03955 3.51779 6.03955H28.4822C28.9957 6.03955 29.1816 6.73647 28.7401 7.00626L16 14.791L3.25994 7.00626Z"
        fill="currentColor"
      />
      <path
        d="M3 9C3 8.72386 2.77614 8.5 2.5 8.5C2.22386 8.5 2 8.72386 2 9V25C2 26.3807 3.11929 27.5 4.5 27.5H27.5C28.8807 27.5 30 26.3807 30 25V9C30 8.72386 29.7761 8.5 29.5 8.5C29.2239 8.5 29 8.72386 29 9V25C29 25.8284 28.3284 26.5 27.5 26.5H4.5C3.67157 26.5 3 25.8284 3 25V9Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 30 33',
  fontSize: 'large',
});
