import { createIcon } from '../createIcon';

export const DragIcon = createIcon({
  svg: ({
    palette: {
      primary: { main },
    },
  }) => (
    <g fill="none" fillRule="evenodd">
      <circle cx="4" cy="8" r="2" fill={main} />
      <circle cx="4" cy="16" r="2" fill={main} />
      <circle cx="4" cy="24" r="2" fill={main} />
      <circle cx="12" cy="8" r="2" fill={main} />
      <circle cx="12" cy="16" r="2" fill={main} />
      <circle cx="12" cy="24" r="2" fill={main} />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 16 32',
});
