import { useEffect, useState } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

import { useAuth } from 'src/auth';

import { createApolloLink } from './createApolloLink';

const createClient = () =>
  new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        FactSheet: {
          keyFields: ['factSheetId'],
          fields: {
            fixedIncome: { merge: false },
            realReturn: { merge: false },
            absoluteReturn: { merge: false },
            equities: { merge: false },
            realAssets: { merge: false },
          },
        },
      },
    }),
  });

export const useApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const [client] = useState(() => createClient());
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn === false) {
      client.clearStore();
    }
  }, [client, isLoggedIn]);

  useEffect(() => {
    client.setLink(createApolloLink());
  }, [client]);

  return client;
};
