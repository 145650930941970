import { Maybe } from 'src/apollo';
import { getDecimalPlacesLength, parseInputNumber } from 'src/utils';

import { State } from './interfaces';
import { Satelite } from '../Factsheets/SateliteList/Satelite';

const percentToFloat = (x?: string | number | null) => {
  return (parseInputNumber(x?.toString() ?? '')[1] ?? 0) / 100;
};

const percentFormat = (value?: string | number, precision?: number) => {
  let startVal = value;
  if (typeof startVal === 'string') {
    startVal = percentToFloat(startVal);
  }
  const parsedVal = startVal ?? 0;
  const decimalPlacesLength = getDecimalPlacesLength(parsedVal);
  return `${(parsedVal * 100).toFixed(
    (precision ?? decimalPlacesLength > 3) ? 2 : 1,
  )}%`;
};

const performanceFields = [
  'top1m',
  'top3m',
  'top6m',
  'top1y',
  'top3y',
  'top5y',
  'topInception',
  'bottom1m',
  'bottom3m',
  'bottom6m',
  'bottom1y',
  'bottom3y',
  'bottom5y',
  'bottomInception',
] as const;

const cleanupPerformanceUpdate = (_update: any) => {
  const update = { ..._update };
  performanceFields.forEach((k) => {
    if (typeof update[k] !== 'string' || !update[k]) return;
    if (!Number.isNaN(parseFloat(update[k]))) return;
    update[k] = '';
  });
  return update;
};

const cleanupPercent = (field: string, arr?: any[] | null) => {
  return arr?.map((x: any) => {
    const cpy = { ...x };
    if (Number.isNaN(parseFloat(cpy[field]))) {
      cpy[field] = '';
    } else {
      cpy[field] = percentFormat(cpy[field]);
    }
    return cpy;
  });
};

export const parseInitialValues = (
  satellite: Maybe<Satelite> | undefined,
): State => {
  const totalPortfolioCost = satellite?.keyInformation?.totalPortfolioCost;
  const growAssets = satellite?.keyInformation?.growAssets;

  return {
    ...satellite,
    keyInformation: {
      historicYield: percentFormat(satellite?.keyInformation?.historicYield),
      numberOfHoldings: satellite?.keyInformation?.numberOfHoldings ?? '0',
      minimumInvestment:
        satellite?.keyInformation?.minimumInvestment ?? undefined,
      growAssets: growAssets ? percentFormat(growAssets) : '',
      totalPortfolioCost: totalPortfolioCost
        ? percentFormat(totalPortfolioCost)
        : '',
      ongoingCostOfFunds: percentFormat(
        satellite?.keyInformation?.ongoingCostOfFunds,
      ),
      portfolioManagementFee: satellite?.keyInformation?.portfolioManagementFee,
      inceptionDate: satellite?.keyInformation?.inceptionDate,
      availableShareClass: satellite?.keyInformation?.availableShareClass,
    },
    performanceUpdate: cleanupPerformanceUpdate(satellite?.performanceUpdate),
    chosenExposures: cleanupPercent('share', satellite?.chosenExposures),
    fundEntries: cleanupPercent('allocation', satellite?.fundEntries),
  };
};

export const parseToUpdateInput = (state: State): any => {
  const totalPortfolioCost = state?.keyInformation?.totalPortfolioCost;
  const growAssets = state.keyInformation?.growAssets;

  return {
    ...state,
    keyInformation: {
      historicYield: percentFormat(state.keyInformation?.historicYield),
      numberOfHoldings: state.keyInformation?.numberOfHoldings ?? '0',
      growAssets: growAssets ? percentFormat(growAssets) : '',
      minimumInvestment: state.keyInformation?.minimumInvestment,
      totalPortfolioCost: totalPortfolioCost
        ? percentFormat(totalPortfolioCost)
        : '',
      ongoingCostOfFunds: percentFormat(
        state.keyInformation?.ongoingCostOfFunds,
      ),
      portfolioManagementFee: state.keyInformation?.portfolioManagementFee,
      inceptionDate: state.keyInformation?.inceptionDate,
      availableShareClass: state.keyInformation?.availableShareClass,
    },
    performanceUpdate: cleanupPerformanceUpdate(state.performanceUpdate),
    chosenExposures: cleanupPercent('share', state.chosenExposures),
    fundEntries: cleanupPercent('allocation', state.fundEntries),
  };
};
