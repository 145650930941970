import {
  Document,
  Page,
  View,
  Image,
  Font,
  Text,
  Styles,
} from '@react-pdf/renderer';
import { FC } from 'react';
import { lighten, Theme } from '@mui/material';
import { formatCurrency, shortDate } from 'src/utils';
import { Enum_RiskName } from 'src/apollo';
import { Header } from './Header';
import { Input } from './Input';
import { Footer } from './Footer';
import { Cell } from './Cell';
import { getRowTitle, theadCells } from '../RiskSummary/utils';
import { RiskTable } from '../utils';
import { LegendItem } from './LegendItem';
import { getRiskLabel, getSeriesColor } from '../Chart/utils';

const getFonts = (font: string, weights: number[], fontStyle?: string) => {
  return weights.map((w) => {
    return {
      // eslint-disable-next-line
      src: require(
        `./assets/fonts/${font.replace('-italic', '')}/${font}-${w}.ttf`,
      ),
      fontWeight: w,
      fontStyle,
    };
  });
};

Font.register({
  family: 'Poppins',
  fonts: getFonts('poppins', [200, 300, 500]),
});

Font.register({
  family: 'Lato',
  fonts: getFonts('lato', [400, 600, 700, 800]).concat(
    getFonts('lato-italic', [400], 'italic'),
  ),
});

Font.register({
  family: 'Montserrat',
  fonts: getFonts('montserrat', [400, 500, 600, 700]).concat(
    getFonts('montserrat-italic', [400, 500, 600, 700], 'italic'),
  ),
});

Font.registerHyphenationCallback((word) => [word]);

interface Props {
  theme: Theme;
  url: string;
  logo: string;
  startingValue: number;
  startDate: Date | null;
  selectedRisk: Enum_RiskName;
  riskLevels: Enum_RiskName[];
  riskTableData: RiskTable;
  endDate: Date | null;
  name?: string;
  additionalSeries?: string[];
}

export const HistoricReturnsPdf: FC<Props> = ({
  url,
  logo,
  theme,
  startingValue,
  startDate,
  endDate,
  selectedRisk,
  riskLevels,
  riskTableData,
  name,
  additionalSeries,
}) => {
  const startingVal = formatCurrency(startingValue, 0);
  const from = shortDate(startDate);
  const to = shortDate(endDate);

  const rowStyle = {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  } as Styles;

  const riskKeys = Object.keys(riskTableData) as (keyof RiskTable)[];
  const hasAdditionalSeries = !!additionalSeries?.length;

  const printRow = (risk: keyof RiskTable) => {
    const title = getRowTitle(risk);
    return (
      <View style={rowStyle} key={title}>
        <Cell theme={theme} label={title} wide />
        {riskLevels.map((level) => {
          const riskValue = riskTableData[risk][level];
          const isSelected = selectedRisk === level;
          if (hasAdditionalSeries && !isSelected) {
            return;
          }
          const formattedValue = Number.isNaN(riskValue)
            ? '-'
            : `${riskValue.toFixed(1)}%`;
          return (
            <Cell key={riskValue} theme={theme} label={formattedValue} center />
          );
        })}
      </View>
    );
  };

  const printLegendItem = (s: string, i: number) => {
    const color = getSeriesColor(i, theme);
    return <LegendItem key={s} color={color} name={s} />;
  };

  return (
    <Document>
      <Page orientation="landscape" size="B4">
        <Header logo={logo} theme={theme} title="Historic Returns" />
        <View
          style={{
            margin: '35px 0px 5px 30px',
            justifyContent: 'space-between',
            flexDirection: 'row',
            paddingRight: 100,
            width: '100%',
            height: 100,
          }}
        >
          <View
            style={{
              width: hasAdditionalSeries ? '100%' : 'calc(50% - 1px)',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                fontFamily: 'Poppins',
                fontWeight: 500,
                gap: 12,
              }}
            >
              {hasAdditionalSeries && (
                <View
                  style={{
                    marginRight: additionalSeries.length > 1 ? 20 : 50,
                    borderRadius: 6,
                    border: `1px solid ${theme.palette.grey[300]}`,
                    padding: '8px 10px',
                    minWidth: additionalSeries.length > 1 ? 175 : 125,
                    flexDirection: 'row',
                    gap: 20,
                  }}
                >
                  <View style={{ gap: 4 }}>
                    <LegendItem
                      color={theme.palette.primary.main}
                      name={getRiskLabel(selectedRisk)}
                    />
                    {additionalSeries.slice(0, 1).map(printLegendItem)}
                  </View>
                  {additionalSeries.length > 1 && (
                    <View style={{ gap: 4 }}>
                      {additionalSeries.slice(1).map(printLegendItem)}
                    </View>
                  )}
                </View>
              )}
              <Text style={{ fontSize: 8 }}>Starting Value</Text>
              <Input theme={theme} text={startingVal} />
              <Text style={{ fontSize: 8 }}>from</Text>
              <Input theme={theme} text={from} />
              <Text style={{ fontSize: 8 }}>to</Text>
              <Input theme={theme} text={to} />
            </View>
          </View>
          <View
            style={{
              height: '100%',
              width: 1.5,
              backgroundColor: lighten(theme.palette.primary.main, 0.7),
            }}
          />
          <View
            style={{
              width: 'calc(50% - 1px)',
              height: '100%',
              paddingLeft: 50,
              paddingRight: 25,
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <View style={{ width: hasAdditionalSeries ? 170 : '100%' }}>
              <View
                style={{
                  ...rowStyle,
                  paddingBottom: 3,
                  marginBottom: 3,
                  borderBottom: `1.5px dashed ${lighten(theme.palette.primary.main, 0.7)}`,
                }}
              >
                {theadCells.map((cell, i) => {
                  const subLabel = cell.textInBrackets
                    ? `(${cell.textInBrackets})`
                    : undefined;
                  const isFirst = i === 0;
                  const isSelected = selectedRisk === cell.riskLevel;
                  if (!isFirst && hasAdditionalSeries && !isSelected) {
                    return;
                  }
                  return (
                    <Cell
                      theme={theme}
                      key={cell.label}
                      label={cell.label}
                      subLabel={subLabel}
                      wide={isFirst}
                      center={!isFirst}
                      selected={isSelected}
                    />
                  );
                })}
              </View>
              {riskKeys.map(printRow)}
            </View>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            padding: '0px 20px',
          }}
        >
          <Image src={url} />
        </View>
        <Footer theme={theme} name={name} />
      </Page>
    </Document>
  );
};
