import { createIcon } from '../createIcon';

export const ImpactIcon = createIcon({
  svg: ({ palette }) => (
    <>
      <circle
        cx="36"
        cy="36"
        r="35.5"
        fill={palette.common.white}
        stroke="color-mix(in srgb, currentColor 20%, transparent)"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M49 51.734H19v-30"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M26.117 48.097l4.254-6.73 2.713 1.28 3.18-5.277 2.682 1.133s1.794-1.115 3.343-3.481c1.55-2.367 3.08-7.282 3.08-7.282"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M38.871 28.76c-1.058 2.853.421 6.013 3.273 7.07 2.887 1.072 6.056-.325 7.15-3.164 2.15-5.692-2.306-11.06-2.306-11.06s-5.961 1.344-8.117 7.154zM43.31 33.112l-1.277-2.848M46.775 30.7l-2.668.79"
      />
    </>
  ),
  viewBox: '0 0 72 73',
});
