import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  factsheetForm: {
    width: '100%',
  },
  inputSection: {
    width: '100%',
    maxWidth: 826,
    marginTop: theme.spacing(9),
    display: 'flex',
  },
  inputNumber: {
    width: 240,
    marginBottom: theme.spacing(6),
    display: 'block',
  },
  textField: {
    width: 520,
    display: 'block',
    marginBottom: theme.spacing(6),
  },
  button: {
    minWidth: 152,
    height: 48,
  },
  actionContainer: {
    marginTop: theme.spacing(5),
  },
}));
