import { createIcon } from '../createIcon';

export const TickIcon = createIcon({
  svg: () => (
    <svg width="11" height="10" viewBox="0 0 11 10" fill="none">
      <path
        d="M3.52083 9.0625L0 5.52083L1.04167 4.47917L3.52083 6.9375L9.54167 0.9375L10.5833 2L3.52083 9.0625Z"
        fill="currentColor"
      />
    </svg>
  ),
  fontSize: 'small',
  viewBox: '0 0 11 10',
});
