import { FC, ReactElement } from 'react';
import { ErrorMessage } from '../ErrorMessage';
import { FullLoading } from '../../layout';

interface Props {
  content: ReactElement;
  empty?: boolean;
  emptyContent?: ReactElement;
  error?: Error | null;
  errorContent?: ReactElement;
  onTryAgain?: () => void;
  loading?: boolean;
  loadingPlaceholder?: ReactElement;
}

export const StateSwitch: FC<Props> = ({
  content,
  empty,
  emptyContent,
  error,
  errorContent,
  loading,
  loadingPlaceholder,
  onTryAgain,
}) => {
  if (error) {
    return (
      errorContent ?? (
        <ErrorMessage
          message={error.message}
          onTryAgain={onTryAgain ?? (() => {})}
        />
      )
    );
  }
  if (loading) {
    return loadingPlaceholder ?? <FullLoading cover fullPage />;
  }
  if (empty) {
    return emptyContent ?? <></>;
  }

  return content;
};
