import { FC } from 'react';

import { UpdateJourneyInput } from 'src/apollo';
import { NotesIcon, SideButton } from 'src/components/ui';
import { useDialog } from 'src/hooks';

import { JourneyNotes } from './JourneyNotes';

interface Props {
  value: Partial<UpdateJourneyInput>;
  disabled?: boolean;
  onChange: (val: Partial<UpdateJourneyInput>) => void;
}

export const NotesButton: FC<Props> = ({ value, onChange, disabled }) => {
  const dialog = useDialog();

  const handleClick = () => {
    dialog.show({
      title: 'Notes',
      transition: true,
      content: (
        <JourneyNotes value={value} onClose={dialog.close} onSave={onChange} />
      ),
    });
  };

  return (
    <SideButton
      dataTestid="journey-notes-btn"
      side="bottom"
      disabled={disabled}
      onClick={handleClick}
    >
      <NotesIcon color={disabled ? 'inherit' : 'primary'} />
    </SideButton>
  );
};
