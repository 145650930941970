import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { defaultContentWidth } from 'src/utils/contentWidth';

interface Props {
  width?: string | number;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  className?: string;
}

const paddingSpacing = 2;
const defaultWidth = `${defaultContentWidth}px`;

export const Wrapper: FC<Props> = ({
  width = defaultWidth,
  sx,
  className,
  children,
}) => {
  const theme = useTheme();
  const maxWidth = typeof width === 'string' ? width : `${width}px`;
  return (
    <Box
      maxWidth={`calc(${maxWidth} + ${theme.spacing(paddingSpacing)})`}
      width="100%"
      margin="auto"
      marginTop="0px"
      px={paddingSpacing}
      sx={sx}
      className={className}
    >
      {children}
    </Box>
  );
};
