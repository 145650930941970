import { FC, ReactNode } from 'react';
import { ButtonBase } from '@mui/material';

import { useStyles } from './styles';

interface Props {
  side: 'top' | 'bottom' | 'left' | 'right';
  dataTestid?: string;
  disabled?: boolean;
  onClick?: () => void;
  children?: ReactNode;
}

export const SideButton: FC<Props> = ({
  children,
  onClick,
  side,
  dataTestid,
  disabled,
}) => {
  const { classes, cx } = useStyles();
  return (
    <ButtonBase
      data-testid={dataTestid}
      onClick={onClick}
      disabled={disabled}
      className={cx(classes.root, classes[side], {
        [classes.disabled]: disabled,
      })}
    >
      {children}
    </ButtonBase>
  );
};
