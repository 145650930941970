import { FC, useRef, useState } from 'react';
import {
  DatePicker as MuiPicker,
  DateView,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { ClickAwayListener } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useStyles } from './styles';
import { CalendarIcon } from '../icons';

interface Props {
  value: Date | null;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  takeFirstDayOfMonth?: boolean;
  takeLastDayOfMonth?: boolean;
  className?: string;
  format?: string;
  disabled?: boolean;
  showDays?: boolean;
  outlined?: boolean;
  fullWidth?: boolean;
  onDateChange: (date: Date) => void;
  onMonthChange?: () => void;
}

export const DatePicker: FC<Props> = ({
  value,
  minDate,
  maxDate,
  label,
  disableFuture,
  disablePast,
  takeFirstDayOfMonth,
  takeLastDayOfMonth,
  className,
  format = 'LLL yyyy',
  disabled,
  showDays,
  outlined,
  fullWidth,
  onDateChange,
  onMonthChange,
}) => {
  const { classes, cx } = useStyles();
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const views: DateView[] = showDays
    ? ['year', 'month', 'day']
    : ['year', 'month'];

  const handleDateChange = (date: Date | null) => {
    const dt = date ? new Date(date) : new Date();
    if (takeLastDayOfMonth) {
      const lastDayOfMth = new Date(
        dt.getFullYear(),
        dt.getMonth() + 1,
        0,
      ).getDate();
      onDateChange(new Date(dt.getFullYear(), dt.getMonth(), lastDayOfMth));
    } else if (takeFirstDayOfMonth) {
      onDateChange(new Date(dt.getFullYear(), dt.getMonth(), 1));
    } else {
      onDateChange(new Date(dt));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div
          className={cx(classes.datePickerWrapper, { fullWidth })}
          onClick={() => {
            if (disabled) return;
            setOpen(true);
          }}
          aria-hidden="true"
        >
          <MuiPicker
            openTo="year"
            open={open}
            views={views}
            format={format}
            label={label}
            value={value}
            onChange={handleDateChange}
            onMonthChange={onMonthChange}
            minDate={minDate}
            maxDate={maxDate}
            inputRef={inputRef}
            onClose={() => {
              // Next render frame is required to prevent onClick from the parent div to reopen the dialog
              setTimeout(() => {
                setOpen(false);
                if (inputRef?.current) {
                  inputRef.current.blur();
                }
              });
            }}
            selectedSections={null}
            className={cx(classes.datePicker, className, {
              outlined,
              fullWidth,
            })}
            disableFuture={disableFuture}
            disablePast={disablePast}
            disabled={disabled}
            slots={{ openPickerIcon: CalendarIcon }}
            slotProps={{
              openPickerButton: {
                disabled: true,
              },
              textField: { focused: false },
              field: { readOnly: true },
            }}
          />
        </div>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};
