import { createIcon } from '../createIcon';

export const FirstIcon = createIcon({
  svg: ({ palette }) => (
    <g fill="none" fillRule="evenodd">
      <circle
        cx="22"
        cy="22"
        r="21"
        fill={palette.primary.main}
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M16.944 16.8V28H14.352V18.88H12.112V16.8H16.944Z"
        fill={palette.primary.contrastText}
      />
      <path
        d="M22.036 24.096C21.5 24.096 20.976 24.032 20.464 23.904C19.952 23.768 19.544 23.6 19.24 23.4L19.864 22.056C20.152 22.24 20.5 22.392 20.908 22.512C21.316 22.624 21.716 22.68 22.108 22.68C22.9 22.68 23.296 22.484 23.296 22.092C23.296 21.908 23.188 21.776 22.972 21.696C22.756 21.616 22.424 21.548 21.976 21.492C21.448 21.412 21.012 21.32 20.668 21.216C20.324 21.112 20.024 20.928 19.768 20.664C19.52 20.4 19.396 20.024 19.396 19.536C19.396 19.128 19.512 18.768 19.744 18.456C19.984 18.136 20.328 17.888 20.776 17.712C21.232 17.536 21.768 17.448 22.384 17.448C22.84 17.448 23.292 17.5 23.74 17.604C24.196 17.7 24.572 17.836 24.868 18.012L24.244 19.344C23.676 19.024 23.056 18.864 22.384 18.864C21.984 18.864 21.684 18.92 21.484 19.032C21.284 19.144 21.184 19.288 21.184 19.464C21.184 19.664 21.292 19.804 21.508 19.884C21.724 19.964 22.068 20.04 22.54 20.112C23.068 20.2 23.5 20.296 23.836 20.4C24.172 20.496 24.464 20.676 24.712 20.94C24.96 21.204 25.084 21.572 25.084 22.044C25.084 22.444 24.964 22.8 24.724 23.112C24.484 23.424 24.132 23.668 23.668 23.844C23.212 24.012 22.668 24.096 22.036 24.096ZM30.403 23.688C30.219 23.824 29.991 23.928 29.719 24C29.455 24.064 29.175 24.096 28.879 24.096C28.111 24.096 27.515 23.9 27.091 23.508C26.675 23.116 26.467 22.54 26.467 21.78V19.128H25.471V17.688H26.467V16.116H28.339V17.688H29.947V19.128H28.339V21.756C28.339 22.028 28.407 22.24 28.543 22.392C28.687 22.536 28.887 22.608 29.143 22.608C29.439 22.608 29.691 22.528 29.899 22.368L30.403 23.688Z"
        fill={palette.primary.contrastText}
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 44 44',
});
