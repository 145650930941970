import { createIcon } from '../createIcon';

export const ReassignClientIcon = createIcon({
  svg: () => (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82433 6.00397C8.29484 6.69089 8.03009 7.48484 8.03009 8.38582C8.03009 9.28622 8.29451 10.0797 8.82334 10.7664C8.29889 11.1786 7.69112 11.3848 7 11.3848C6.175 11.3848 5.46875 11.091 4.88125 10.5035C4.29375 9.91604 4 9.20979 4 8.38479C4 7.55977 4.29375 6.85352 4.88125 6.26602C5.46875 5.67852 6.175 5.38477 7 5.38477C7.69155 5.38477 8.29966 5.59117 8.82433 6.00397ZM4 18.6156V17.048C4 16.5527 4.13736 16.0892 4.41209 15.6577C4.68681 15.2261 5.05641 14.8911 5.52089 14.6527C6.33507 14.2426 7.14968 13.91 7.9647 13.6548C7.64321 13.6286 7.32164 13.6155 7 13.6155C6.05385 13.6155 5.10833 13.7287 4.16345 13.955C3.21858 14.1812 2.27436 14.5207 1.33078 14.9732C0.924358 15.172 0.600958 15.4511 0.360575 15.8107C0.120192 16.1704 0 16.5566 0 16.9694V18.6156H4Z"
        fill="currentColor"
      />
      <path
        d="M12 11.3848C11.175 11.3848 10.4688 11.091 9.88125 10.5035C9.29375 9.91604 9 9.20979 9 8.38479C9 7.55977 9.29375 6.85352 9.88125 6.26602C10.4688 5.67852 11.175 5.38477 12 5.38477C12.825 5.38477 13.5313 5.67852 14.1188 6.26602C14.7063 6.85352 15 7.55977 15 8.38479C15 9.20979 14.7063 9.91604 14.1188 10.5035C13.5313 11.091 12.825 11.3848 12 11.3848ZM5 18.6156V16.9694C5 16.5566 5.12019 16.1704 5.36057 15.8107C5.60096 15.4511 5.92436 15.172 6.33078 14.9732C7.27436 14.5207 8.21858 14.1812 9.16345 13.955C10.1083 13.7287 11.0539 13.6155 12 13.6155C12.9461 13.6155 13.8917 13.7287 14.8366 13.955C15.7814 14.1812 16.7256 14.5207 17.6692 14.9732C18.0756 15.172 18.399 15.4511 18.6394 15.8107C18.8798 16.1704 19 16.5566 19 16.9694V18.6156H5ZM6 17.6155H18V16.9694C18 16.7476 17.9285 16.5393 17.7856 16.3444C17.6426 16.1495 17.4449 15.9848 17.1923 15.8502C16.3692 15.4514 15.5206 15.146 14.6464 14.9338C13.7722 14.7216 12.8901 14.6155 12 14.6155C11.1099 14.6155 10.2278 14.7216 9.3536 14.9338C8.4794 15.146 7.63077 15.4514 6.8077 15.8502C6.55513 15.9848 6.35737 16.1495 6.21442 16.3444C6.07148 16.5393 6 16.7476 6 16.9694V17.6155ZM12 10.3848C12.55 10.3848 13.0208 10.189 13.4125 9.79729C13.8042 9.40562 14 8.93479 14 8.38479C14 7.83479 13.8042 7.36396 13.4125 6.97229C13.0208 6.58062 12.55 6.38479 12 6.38479C11.45 6.38479 10.9792 6.58062 10.5875 6.97229C10.1958 7.36396 10 7.83479 10 8.38479C10 8.93479 10.1958 9.40562 10.5875 9.79729C10.9792 10.189 11.45 10.3848 12 10.3848Z"
        fill="currentColor"
      />
      <path
        d="M19.4231 14.4337L18.7615 13.7817L21.8712 10.6721H17V9.74902H21.8712L18.774 6.65193L19.4356 6L23.6385 10.2154L19.4231 14.4337Z"
        fill="currentColor"
      />
    </>
  ),
  fontSize: 'large',
  viewBox: '0 0 24 24',
});
