import { createIcon } from '../createIcon';

export const RiskProfileIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.162 24.871C22.1192 25.5834 20.8582 26 19.5 26C15.9101 26 13 23.0899 13 19.5C13 15.9101 15.9101 13 19.5 13C23.0899 13 26 15.9101 26 19.5C26 21.3699 25.2104 23.0554 23.9464 24.2412L29.0587 29.3536C29.1525 29.4473 29.2797 29.5 29.4123 29.5H29.4981C29.7742 29.5 29.9981 29.2761 29.9981 29V28.9142C29.9981 28.7816 29.9454 28.6544 29.8516 28.5607L25.6445 24.3536C25.4493 24.1583 25.4493 23.8417 25.6445 23.6464C25.8398 23.4512 26.1564 23.4512 26.3516 23.6464L30.5587 27.8536C30.84 28.1349 30.9981 28.5164 30.9981 28.9142V29C30.9981 29.8284 30.3265 30.5 29.4981 30.5H29.4123C29.0145 30.5 28.6329 30.342 28.3516 30.0607L23.162 24.871ZM25 19.5C25 22.5376 22.5376 25 19.5 25C16.4624 25 14 22.5376 14 19.5C14 16.4624 16.4624 14 19.5 14C22.5376 14 25 16.4624 25 19.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 13C15.5376 13 18 10.5376 18 7.5C18 4.46243 15.5376 2 12.5 2C9.46243 2 7 4.46243 7 7.5C7 10.5376 9.46243 13 12.5 13ZM12.5 14C16.0899 14 19 11.0899 19 7.5C19 3.91015 16.0899 1 12.5 1C8.91015 1 6 3.91015 6 7.5C6 11.0899 8.91015 14 12.5 14Z"
        fill="currentColor"
      />
      <path
        d="M15.3756 13.2349C14.6077 13.0808 13.8133 13 13 13C6.37258 13 1 18.3678 1 24.9894C1 31.0735 5.53577 31.0381 11.4131 30.9923C11.9323 30.9882 12.4621 30.9841 13 30.9841C13.5379 30.9841 14.0676 30.9882 14.5867 30.9923C19.4543 31.0302 23.4017 31.061 24.6145 27.6145L23.81 26.81C23.675 27.369 23.4756 27.8044 23.2362 28.1483C22.7901 28.7892 22.1269 29.225 21.1901 29.5144C20.2349 29.8096 19.0498 29.9352 17.6398 29.9791C16.6896 30.0087 15.688 30.0008 14.6203 29.9924C14.0967 29.9883 13.5571 29.9841 13 29.9841C12.4429 29.9841 11.9034 29.9883 11.3798 29.9924C10.3121 30.0008 9.31035 30.0087 8.36017 29.9791C6.95019 29.9352 5.76514 29.8096 4.80993 29.5144C3.87313 29.225 3.2099 28.7892 2.76377 28.1483C2.31019 27.4966 2 26.5165 2 24.9894C2 18.921 6.92403 14 13 14C13.446 14 13.8859 14.0265 14.318 14.0781C14.6439 13.7665 14.9978 13.4841 15.3756 13.2349Z"
        fill="currentColor"
      />
      <path d="M19.5 16V20" stroke="currentColor" strokeLinecap="round" />
      <path d="M19.5 22V22.5" stroke="currentColor" strokeLinecap="round" />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
