import { createIcon } from '../createIcon';

export const TrashIcon = createIcon({
  svg: () => (
    <path
      d="M7.625 20C7.175 20 6.79167 19.8416 6.475 19.525C6.15833 19.2083 6 18.825 6 18.375V5.99998H5V4.99998H9V4.22498H15V4.99998H19V5.99998H18V18.375C18 18.8416 17.846 19.2293 17.538 19.538C17.2293 19.846 16.8417 20 16.375 20H7.625ZM17 5.99998H7V18.375C7 18.5583 7.05833 18.7083 7.175 18.825C7.29167 18.9416 7.44167 19 7.625 19H16.375C16.5417 19 16.6873 18.9373 16.812 18.812C16.9373 18.6873 17 18.5416 17 18.375V5.99998ZM9.8 17H10.8V7.99998H9.8V17ZM13.2 17H14.2V7.99998H13.2V17ZM7 5.99998V19V18.375V5.99998Z"
      fill="currentColor"
    />
  ),
  fontSize: 'large',
  viewBox: '0 0 24 24',
});
