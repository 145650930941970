import { lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { CognitoGroup } from 'src/apollo';
import { useAuth } from '../../auth';
import { Routes } from '../routes';

interface Params {
  children: any;
  groups?: string[];
}

const NotFoundPage = lazy(() => import('src/components/pages/NotFoundPage'));

export const RequireGroup = ({ groups, children }: Params) => {
  const userGroups = useAuth().attributes?.groups;
  const location = useLocation();
  if (!userGroups?.length) {
    const params = new URLSearchParams({ page: location.pathname }).toString();
    return <Navigate to={`${Routes.Login}?${params}`} />;
  }
  if (groups && !groups.some((g) => userGroups.includes(g as CognitoGroup))) {
    return <NotFoundPage />;
  }
  return children;
};
