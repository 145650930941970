import { FC } from 'react';
import { Box, Divider, Link, Typography } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';
import { useStyles } from './styles';

export const WelcomeFooter: FC = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.footer}>
      <Box display="flex">
        <Box width="50%">
          <Typography className={classes.footerSectionTitle}>
            Find us
          </Typography>
        </Box>
        <Box width="50%">
          <Typography className={classes.footerSectionTitle}>
            Get in touch
          </Typography>
        </Box>
      </Box>

      <Box display="flex" mt={10}>
        <Box width="50%">
          <Typography className={classes.footerBodyText}>
            Aspen Advisers, 4 Albyn Place,
          </Typography>
          <Typography className={classes.footerBodyText}>
            Edinburgh, EH2 4NG
          </Typography>
        </Box>
        <Box width="50%">
          <Typography className={classes.footerBodyText}>
            +44 (0) 131 380 4944
          </Typography>
          <Link
            href="mailto:contact@aspenadvisers.com"
            target="_blank"
            style={{ textDecoration: 'underline' }}
            className={classes.footerBodyText}
            underline="hover"
          >
            contact@aspenadvisers.com
          </Link>
        </Box>
      </Box>

      <Box display="flex" mt={8}>
        <Box width="50%">
          <Link
            href="https://maps.app.goo.gl/M895FTxT6LvgrLNi8"
            className={classes.googleLink}
            underline="hover"
          >
            Open Google Maps
          </Link>
        </Box>
        <Box width="50%">
          <Link
            target="_blank"
            type="button"
            href="https://www.linkedin.com/company/aspenadvisers/"
            underline="hover"
          >
            <LinkedIn color="primary" fontSize="large" />
          </Link>
        </Box>
      </Box>

      <Box display="flex" mt={10}>
        <Typography className={classes.footerBodyText}>
          Not a financial adviser?{' '}
          <Link
            href="https://aspenadvisers.com/client"
            className={classes.footerBodyText}
            style={{ textDecoration: 'underline', fontWeight: 400 }}
            underline="hover"
          >
            Access the client website
          </Link>
        </Typography>
      </Box>

      <Box mt={6} mb={6}>
        <Divider />
      </Box>

      <Box display="flex" mb={2} maxWidth={950}>
        <Typography fontWeight={300}>
          Aspen is registered in England and Wales, with company number:
          14115281. Aspen, through North Capital Management Limited, is
          authorised and regulated by the Financial Conduct Authority with firm
          reference number 713442.
        </Typography>
      </Box>
    </Box>
  );
};
