import { Document, Page, View, Image, Font, Text } from '@react-pdf/renderer';
import { FC } from 'react';
import { lighten, Theme } from '@mui/material';
import { formatCurrency, shortDate } from 'src/utils';
import { Header } from './Header';
import { Input } from './Input';
import { Footer } from './Footer';
import { LegendItem } from './LegendItem';
import { DepositFrequency } from '../Filters/ValueFilters';
import { ChartEvent } from '../Filters/EventFilters';

const getFonts = (font: string, weights: number[], fontStyle?: string) => {
  return weights.map((w) => {
    return {
      // eslint-disable-next-line
      src: require(
        `./assets/fonts/${font.replace('-italic', '')}/${font}-${w}.ttf`,
      ),
      fontWeight: w,
      fontStyle,
    };
  });
};

Font.register({
  family: 'Poppins',
  fonts: getFonts('poppins', [200, 300, 500]),
});

Font.register({
  family: 'Lato',
  fonts: getFonts('lato', [400, 600, 700, 800]).concat(
    getFonts('lato-italic', [400], 'italic'),
  ),
});

Font.register({
  family: 'Montserrat',
  fonts: getFonts('montserrat', [400, 500, 600, 700]).concat(
    getFonts('montserrat-italic', [400, 500, 600, 700], 'italic'),
  ),
});

Font.registerHyphenationCallback((word) => [word]);

interface Props {
  theme: Theme;
  url: string;
  logo: string;
  startingValue: number;
  startDate: Date | null;
  endDate: Date | null;
  name?: string;
  contribution?: number;
  contributionFreq?: DepositFrequency;
  events?: ChartEvent[];
}

export const ProjectedReturnsPdf: FC<Props> = ({
  url,
  logo,
  theme,
  startingValue,
  startDate,
  endDate,
  name,
  contribution,
  contributionFreq,
  events,
}) => {
  const startingVal = formatCurrency(startingValue, 0);
  const from = shortDate(startDate);
  const to = shortDate(endDate);
  const moreLikelyColor = lighten(theme.palette.primary.main, 0.5);
  const lessLikelyColor = theme.palette.grey[400];
  const hasEvents = events?.length !== 0;
  const legendHeight = contribution ? 50 : 0;

  return (
    <Document>
      <Page orientation="landscape" size="B4">
        <Header logo={logo} theme={theme} title="Projected Returns" />
        <View
          style={{
            margin: `${hasEvents ? 10 : 20}px 0px 10px 35px`,
            justifyContent: 'space-between',
            flexDirection: 'row',
            paddingRight: 105,
            width: '100%',
            height: hasEvents ? 60 : 100 - legendHeight,
          }}
        >
          <View
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <View style={{ fontFamily: 'Poppins', marginBottom: 20 }}>
              <Text style={{ fontSize: 7 }}>
                Below is an indication of potential future returns, based off
                simulations run using historic data. It provides an illustration
                of expected returns, as well as a range of outcomes that are
                more or less likely. This illustration should be used as a guide
                only.
              </Text>
              <Text style={{ fontSize: 7 }}>
                Actual returns may vary considerably from those shown.
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                position: 'relative',
              }}
            >
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  gap: 12,
                }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    width: hasEvents ? 35 : undefined,
                  }}
                >
                  From
                </Text>
                <Input theme={theme} text={from} />
                <Text style={{ fontSize: 8 }}>to</Text>
                <Input theme={theme} text={to} />
                <Text style={{ fontSize: 8 }}>Starting Value</Text>
                <Input theme={theme} text={startingVal} />
                {contribution && (
                  <>
                    <Text style={{ fontSize: 8 }}>
                      {contribution > 0 ? 'contribute ' : 'withdraw '}
                      every{' '}
                      {contributionFreq === DepositFrequency.Month
                        ? 'month'
                        : 'year'}
                    </Text>
                    <Input
                      theme={theme}
                      text={formatCurrency(Math.abs(contribution), 0)}
                    />
                  </>
                )}
              </View>
              <View
                style={{
                  borderRadius: 6,
                  border: `1px solid ${theme.palette.grey[300]}`,
                  padding: contribution ? 8 : '4px 8px',
                  flexDirection: contribution ? 'column' : 'row',
                  marginRight: contribution ? 0 : 20,
                  gap: contribution ? 5 : 10,
                  position: contribution ? 'absolute' : undefined,
                  top: contribution ? 0 : undefined,
                  right: contribution ? 20 : undefined,
                  width: contribution ? 150 : undefined,
                }}
              >
                {contribution && (
                  <LegendItem
                    color={theme.palette.primary.main}
                    name={contribution > 0 ? 'Contributions' : 'Withdrawals'}
                    type="dotted"
                  />
                )}
                <LegendItem
                  color={theme.palette.primary.main}
                  name="Projected"
                  type="solid"
                />
                <LegendItem
                  color={moreLikelyColor}
                  name="Return range 50% of the time"
                />
                <LegendItem
                  color={lessLikelyColor}
                  name="Return range 90% of the time"
                />
              </View>
            </View>
          </View>
        </View>
        {events && (
          <View style={{ paddingLeft: 35, gap: 5, marginBottom: 10 }}>
            {events.map((e, i) => {
              const eventType = e.contribution > 0 ? 'Deposit' : 'Withdrawal';
              const amount = formatCurrency(Math.abs(e.contribution), 0);
              const eventDate = shortDate(e.date);
              const key = `key-${i}`;
              return (
                <View
                  key={key}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 12,
                  }}
                >
                  <Text style={{ fontSize: 8, width: 35 }}>{eventType}</Text>
                  <Input theme={theme} text={amount} />
                  <Text style={{ fontSize: 8 }}>on</Text>
                  <Input theme={theme} text={eventDate} />
                </View>
              );
            })}
          </View>
        )}
        <View
          style={{
            width: '100%',
            padding: '0px 20px',
            marginTop: !hasEvents ? legendHeight : undefined,
          }}
        >
          <Image src={url} />
        </View>
        <Footer theme={theme} name={name} />
      </Page>
    </Document>
  );
};
