import {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Snackbar } from '@mui/material';

import { ErrorBox } from 'src/components/ui';
import { Config, SnackState, SnackType, SnackValue } from './types';

export const SnackContext = createContext<SnackValue>(() => () => {});

let currentId = 0;
const defaultTimeout = 5000;

interface Props {
  children?: ReactNode;
}

export const SnackController: FC<Props> = memo(({ children }) => {
  const [snacks, setSnacks] = useState<SnackState[]>([]);

  const close = useCallback((id: number) => {
    setSnacks((v) => {
      const newV = v.slice();
      const i = v.findIndex((s) => s.id === id);
      const oldSnack = newV[i];
      if (oldSnack) {
        newV[i] = { ...oldSnack, open: false };
      }
      return newV;
    });
  }, []);

  const destroy = useCallback(
    (id: number) => setSnacks((v) => v.filter(({ id: vId }) => vId !== id)),
    [],
  );

  const show = useCallback(
    (config: Config): (() => void) => {
      const id = currentId;
      setSnacks((v) => v.concat({ ...config, id, open: true }));
      currentId += 1;
      if (config.type === SnackType.Success) {
        setTimeout(() => close(id), config.timeout ?? defaultTimeout);
      }
      return () => close(id);
    },
    [close],
  );

  const snackEls = useMemo(() => {
    return snacks.map((snack) => {
      const shouldTimeout = snack.type === SnackType.Success;
      const timeout = snack.timeout ?? defaultTimeout;
      return (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          key={snack.id}
          open={snack.open}
          style={snack.multiline ? { whiteSpace: 'pre-wrap' } : undefined}
          TransitionProps={{ onExited: () => destroy(snack.id) }}
        >
          <ErrorBox
            onClose={() => close(snack.id)}
            onTryAgain={snack.onTryAgain}
            type={snack.type}
            timeout={shouldTimeout ? timeout : undefined}
            isSnackbar
          >
            {snack.message}
          </ErrorBox>
        </Snackbar>
      );
    });
  }, [close, destroy, snacks]);

  return (
    <SnackContext.Provider value={show}>
      {children}
      {snackEls}
    </SnackContext.Provider>
  );
});
