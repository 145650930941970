import { Enum_RiskName } from 'src/apollo';

type RiskTableRow = {
  [key in Enum_RiskName]: number;
};

export type RiskTable = {
  annualisedReturns: RiskTableRow;
  bestOneYear: RiskTableRow;
  worstOneYear: RiskTableRow;
  maxDrawdown: RiskTableRow;
};

export const riskLevels = [
  Enum_RiskName.Low,
  Enum_RiskName.Lower,
  Enum_RiskName.Medium,
  Enum_RiskName.Higher,
  Enum_RiskName.High,
];

export const imageLoad = (img: HTMLImageElement) => {
  const image = img;
  return new Promise<void>((resolve, reject) => {
    image.onload = () => resolve();
    image.onerror = (e) => reject(e);
  });
};

export const getUrl = (resource: string) => {
  return new Promise<string>((resolve) => {
    fetch(resource)
      .then((res) => res.blob())
      .then((blob) => {
        resolve(URL.createObjectURL(blob));
      });
  });
};

export const largeCanvasW = 1400;
export const largeCanvasH = 600;
