import { createIcon } from '../createIcon';

export const InfoIcon = createIcon({
  svg: () => (
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.3"
        d="M25.872 6.123c5.504 5.498 5.504 14.35 0 19.754-5.505 5.497-14.368 5.497-19.779 0-5.41-5.498-5.504-14.35 0-19.754 5.505-5.497 14.274-5.497 19.779 0zM15.995 14.15v9.328M15.995 14.15H14.13M15.995 14.15H14.13M15.995 23.478H14.13M17.861 23.478H14.13"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.3"
        d="M16 10.522c.336 0 .608-.273.608-.609 0-.336-.272-.609-.608-.609-.336 0-.609.273-.609.609 0 .336.273.609.609.609z"
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
