import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: -theme.spacing(10),
  },
  textWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  charCounter: {
    fontSize: theme.typography.fontSize * 0.625,
  },
  textarea: {
    fontWeight: theme.typography.fontWeightLight,
    '& .MuiFilledInput-multiline': {
      padding: theme.spacing(5),
    },
  },
  actions: {
    display: 'flex',
    gap: 20,
    justifyContent: 'flex-end',
    margin: '30px 80px',
  },
}));
