import { Path, Svg } from '@react-pdf/renderer';
import { FC } from 'react';

interface Props {
  color: string;
  opacity?: string;
}

export const Slant1: FC<Props> = ({ color, opacity = '0.1' }) => {
  return (
    <Svg
      width="1185"
      height="1684"
      viewBox="0 0 1185 1684"
      style={{ width: '100%', height: '100%' }}
    >
      <Path opacity={opacity} d="M-2 0H1185L510.993 1684H-2V0Z" fill={color} />
    </Svg>
  );
};
