import { useEffect } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';

import defaultLogo from 'src/assets/logo.png';
import { getLogo } from 'src/utils';
import { useCompany } from './useCompany';

type LogoVar = {
  logo?: string;
  src?: string;
  companyLogoLoaded?: boolean;
};

const defaultLogoVar = {
  logo: '',
  src: undefined,
  companyLogoLoaded: false,
};
const logoVar = makeVar<LogoVar>(defaultLogoVar);

export const resetLogo = () => {
  logoVar(defaultLogoVar);
};

export const useLogo = (useDefault?: boolean) => {
  const ifaCompany = useCompany();
  const value = useReactiveVar(logoVar);

  useEffect(() => {
    if (value.logo === ifaCompany?.logo) {
      return;
    }
    if (ifaCompany && !ifaCompany.logo) {
      logoVar({ src: defaultLogo, companyLogoLoaded: true });
      return;
    }
    const logo = ifaCompany?.logo;
    if (logo) {
      getLogo(logo).then((src) => {
        return logoVar({ logo, src, companyLogoLoaded: true });
      });
    } else {
      logoVar({ src: defaultLogo, companyLogoLoaded: false });
    }
  }, [ifaCompany, value]);

  return useDefault ? ({ logo: '', src: defaultLogo } as LogoVar) : value;
};
