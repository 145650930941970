import { gql } from '@apollo/client';

export const JOURNEY_FIELDS = gql`
  fragment JourneyFields on Journey {
    journeyId
    version
    complete
    isShort
    isRiskProfile
    status
    date
    createdAt
    investorName
    portfolioName
    portfolioValue
    investorEmail
    step
    completedSteps
    notes
    updatedAt
    clientId
    portfolioSelection {
      notes
      riskProfileSelection
      portfolioStyle {
        selected
      }
    }
    satellitePortfolios {
      notes
      chosenSatellites {
        name
        percent
        risk
        sateliteId
      }
    }
    satelliteSelection
    taxWrapper {
      notes
      sources {
        id
        name
        value
        note
        portfolios {
          key
          percent
          cost
          risk
          cost
        }
      }
    }
    portfolioType {
      notes
      selected {
        note
        type
        value
      }
    }
    timeHorizon {
      notes
      selected
    }
    portfolioCost {
      notes
      selected
    }
    income {
      notes
      incomeAnnum
      yield
      selected
    }
    knowledgeExperience {
      notes
      duration
      noInvestment
      typeAsset
      typeService
    }
    reasonsInvesting {
      notes
      selectedName
      retirementPlanning
      saveAndInvest
      supplementaryIncome
      targetInvesting
    }
    responsibleInvesting {
      notes
      question1
      question2
    }
    riskProfile {
      notes
      selectedName
      completeRiskProfile {
        riskTolerance
        toleranceCapitalLoss
        confidenceComposure
        pastExperience
        feelAboutRisk {
          question1
          question2
        }
        riskCapacity {
          question1
          question2
        }
      }
    }
    pdf {
      report
      factsheet
    }
    reportOptions {
      excludedPages
    }
  }
`;

export const STATISTICS_FIELDS = gql`
  fragment StatisticsFields on Variables {
    annualReturn
    volatility
    bestOneYear
    worstOneYear
  }
`;

export const CHART_PDF_FIELDS = gql`
  fragment ChartFields on PdfChart {
    factsheetId
    date
    portfolioChange
    benchmarkChange
  }
`;

export const FACTSHEET_FIELDS = gql`
  fragment FactsheetFields on FactSheet {
    factSheetId
    portfolioStyle
    risk
    name
    subName
    ownedByDisplayName
    ownedBy
    objective
    marketPortfolioUpdate
    portfolioPositioningChanges
    company {
      name
      address
      phoneNumber
    }
    portfolioCharacteristics {
      growAssets
      numberOfHoldings
      ongoingCostOfFunds
      historicYield
      totalPortfolioCost
      portfolioManagementFee
      minimumInvestment
      availableShareClass
      disclaimer
    }
    timeStamp {
      topLine
      bottomLine
    }
    cumulativePerformance {
      top1m
      top1y
      top3m
      top3y
      top5y
      top6m
      topInception
      topTableLabel
      bottom1m
      bottom1y
      bottom3m
      bottom3y
      bottom5y
      bottom6m
      bottomInception
      bottomTableLabel
      assumedFees
    }
    fixedIncome {
      name
      value
      order
      change
    }
    realReturn {
      name
      value
      order
      change
    }
    absoluteReturn {
      name
      value
      order
      change
    }
    equities {
      name
      value
      order
      change
    }
    realAssets {
      name
      value
      order
      change
    }
    riskWarnings
    file
  }
`;
