import { Enum_RiskName } from 'src/apollo';
import { RiskTable } from '../utils';

export const theadCells = [
  {
    label: 'Risk',
    textInBrackets: 'equity content',
  },
  {
    label: 'Low',
    textInBrackets: '20% equity',
    riskLevel: Enum_RiskName.Low,
  },
  {
    label: 'Low-Medium',
    textInBrackets: '40% equity',
    riskLevel: Enum_RiskName.Lower,
  },
  {
    label: 'Medium',
    textInBrackets: '60% equity',
    riskLevel: Enum_RiskName.Medium,
  },
  {
    label: 'Medium-High',
    textInBrackets: '80% equity',
    riskLevel: Enum_RiskName.Higher,
  },
  {
    label: 'High',
    textInBrackets: '100% equity',
    riskLevel: Enum_RiskName.High,
  },
];

export const riskRadioOptions = [
  {
    value: Enum_RiskName.Low,
  },
  {
    value: Enum_RiskName.Lower,
  },
  {
    value: Enum_RiskName.Medium,
  },
  {
    value: Enum_RiskName.Higher,
  },
  {
    value: Enum_RiskName.High,
  },
];

export const getRowTitle = (riskName: keyof RiskTable): string => {
  switch (riskName) {
    case 'annualisedReturns':
      return 'Annualised Return';
    case 'bestOneYear':
      return 'Best 1 Year';
    case 'worstOneYear':
      return 'Worst 1 Year';
    default:
      return 'Maximum Drawdown';
  }
};
