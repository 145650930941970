import { alpha, Box } from '@mui/material';
import { CSSProperties, FC, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    minHeight: theme.spacing(24),
    alignSelf: 'stretch',
    background: alpha(theme.palette.primary.main, 0.1),
    borderRadius: theme.spacing(2),
    boxSizing: 'border-box',
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

interface Props {
  style?: CSSProperties;
  children?: ReactNode;
}

export const SuccessMessage: FC<Props> = ({ children, style }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.root} style={style}>
      {children}
    </Box>
  );
};
