export enum Routes {
  Advisers = '/advisers',
  Company = '/company',
  CompanyDemo = '/company-demo',
  Customers = '/clients',
  Dashboard = '/dashboard',
  Companies = '/companies',
  Ifa = '/ifa',
  Index = '/',
  Journey = '/journey',
  ShortJourney = '/short-journey',
  InvestorRiskProfile = '/investor-risk-profile',
  RiskProfile = '/risk-profile',
  Login = '/login',
  Logout = '/logout',
  ResetPassword = '/reset-password',
  HistoricReturns = '/historic-returns',
  Factsheets = '/factsheets',
  Satellites = '/satellites',
  MultiAssets = '/multi-assets',
  MarketUpdate = '/market-update',
  MarketUpdates = '/market-updates',
  Questionnaire = '/questionnaire',
  UsefulResources = '/useful-resources',
  NotificationsList = '/notifications-list',
  CreateNotification = '/add-notification',
  EditNotification = '/edit-notification',
  PortfolioPositioning = '/portfolio-positioning',
  PortfolioChanges = '/portfolio-changes',
  EditPortfolioChanges = '/edit-portfolio-changes',
}
