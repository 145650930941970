import { useCallback, useEffect, useMemo, useState } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';

import {
  Enum_PortfolioSelection,
  Enum_PortfolioStyle,
  FactSheet,
  GET_FACTSHEET,
  GET_FACTSHEETS,
  Maybe,
  Query,
  QueryGetFactSheetByArgumentsArgs,
} from 'src/apollo';

type PartQuery<T> = [
  { queryData: T } | undefined,
  {
    loading: boolean;
    reload: () => void;
    error?: ApolloError;
  },
];

const usePartQuery = <T>(
  getData: () => void,
  queryData: Maybe<T> | undefined,
  queryError?: ApolloError,
): PartQuery<T> => {
  const [error, setError] = useState<ApolloError>();
  const reload = useCallback(() => {
    getData();
    setError(undefined);
  }, [getData]);
  useEffect(() => reload(), [reload]);
  useEffect(() => setError((err) => err || queryError), [queryError]);
  const data = useMemo(() => {
    return (queryData && { queryData }) ?? undefined;
  }, [queryData]);
  const loading = !error && !data;
  return [data, { loading, reload, error }];
};

export const useFactsheetQuery = (
  portfolioStyle: Enum_PortfolioStyle,
  risk: Enum_PortfolioSelection,
): PartQuery<FactSheet> => {
  const query = useMemo(
    () => GET_FACTSHEET({ portfolioStyle, risk }),
    [portfolioStyle, risk],
  );
  const [getFactsheet, { data: factData, error: factError }] = useLazyQuery<
    Query,
    QueryGetFactSheetByArgumentsArgs
  >(query.query);
  const getQuery = useCallback(
    () => getFactsheet(query),
    [getFactsheet, query],
  );
  return usePartQuery(getQuery, factData?.[query.name], factError);
};

export const useFactsheetsQuery = (): PartQuery<FactSheet[]> => {
  const [getFactsheets, { data: factData, error: factError }] = useLazyQuery<
    Query,
    object
  >(GET_FACTSHEETS.query);
  return usePartQuery(
    getFactsheets,
    factData?.[GET_FACTSHEETS.name] as Maybe<FactSheet[]> | undefined,
    factError,
  );
};
