import { createIcon } from '../createIcon';

export const ClientArrowIcon = createIcon({
  svg: () => (
    <path
      d="M12 14.7019L6.69229 9.39422L7.39999 8.68652L12 13.2865L16.6 8.68652L17.3077 9.39422L12 14.7019Z"
      fill="currentColor"
    />
  ),
  fontSize: 'medium',
  viewBox: '0 0 24 24',
});
