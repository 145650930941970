import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: 0,
    maxWidth: 652,
    borderRadius: 6,
    '&.big': {
      maxWidth: 842,
    },
    '&.medium': {
      maxWidth: 752,
    },
    '& .MuiDialogContent-root': {
      margin: 0,
      padding: '40px 56px 32px 56px !important',
    },
    '& .MuiDialogActions-root': {
      padding: '0 56px 32px 56px',
    },
    '& .advanced-cropper': {
      background: 'transparent !important',
      border: '1px dashed lightgray',
    },
    '& .advanced-cropper-simple-handler': {
      border: '1px solid gray',
    },
    '& .MuiButton-root': {
      fontSize: 14,
    },
    '& .MuiButton-outlined': {
      border: `1px solid ${lighten(theme.palette.primary.main, 0.8)}`,
    },
  },
  dialogTitleContainer: {
    backgroundColor: `${theme.palette.primary.main}0d`,
    backgroundImage: 'url("/modal-bg-lines.svg")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    backgroundSize: 'cover',
    color: theme.palette.primary.main,
    padding: '16px 16px 16px 80px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    minHeight: 80,
  },
  dialogTitle: {
    fontSize: 22,
    color: theme.palette.primary.main,
  },
  dialogExit: {
    position: 'absolute',
    left: 15,
  },
}));
