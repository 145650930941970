import { createIcon } from '../createIcon';

export const PortfolioDiscoveryIcon = createIcon({
  svg: () => (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.80815 31.1919C1.34695 31.7306 2.00063 32 2.7692 32H27.2308C27.9994 32 28.653 31.7306 29.1918 31.1919C29.7306 30.6531 30 29.9994 30 29.2308V29H30.2308C30.9994 29 31.653 28.7306 32.1918 28.1919C32.7306 27.6531 33 26.9994 33 26.2308V26H33.2308C33.9994 26 34.653 25.7306 35.1918 25.1919C35.7306 24.6531 36 23.9994 36 23.2308V8.7692C36 8.00063 35.7306 7.34695 35.1918 6.80815C34.653 6.26938 33.9994 6 33.2308 6H33V5.7692C33 5.00063 32.7306 4.34695 32.1918 3.80815C31.653 3.26938 30.9994 3 30.2308 3H30V2.7692C30 2.00063 29.7306 1.34695 29.1918 0.808151C28.653 0.269385 27.9994 0 27.2308 0H2.7692C2.00063 0 1.34695 0.269385 0.80815 0.808151C0.269383 1.34695 0 2.00063 0 2.7692V29.2308C0 29.9994 0.269383 30.6531 0.80815 31.1919ZM33 7.53845V24.4616H33.2308C33.5385 24.4616 33.8205 24.3333 34.077 24.077C34.3334 23.8205 34.4616 23.5385 34.4616 23.2308V8.7692C34.4616 8.46153 34.3334 8.17948 34.077 7.92305C33.8205 7.66665 33.5385 7.53845 33.2308 7.53845H33ZM30.2308 27.4616H30V4.53845H30.2308C30.5385 4.53845 30.8205 4.66665 31.077 4.92305C31.3333 5.17948 31.4616 5.46153 31.4616 5.7692V26.2308C31.4616 26.5385 31.3333 26.8205 31.077 27.077C30.8205 27.3333 30.5385 27.4616 30.2308 27.4616ZM27.2308 30.4616H2.7692C2.46153 30.4616 2.17948 30.3333 1.92305 30.077C1.66665 29.8205 1.53845 29.5385 1.53845 29.2308V2.7692C1.53845 2.46153 1.66665 2.17948 1.92305 1.92305C2.17948 1.66665 2.46153 1.53845 2.7692 1.53845H27.2308C27.5385 1.53845 27.8205 1.66665 28.077 1.92305C28.3333 2.17948 28.4616 2.46153 28.4616 2.7692V29.2308C28.4616 29.5385 28.3333 29.8205 28.077 30.077C27.8205 30.3333 27.5385 30.4616 27.2308 30.4616ZM24.0731 8.52695H5.9269V6.98845H24.0731V8.52695ZM5.9269 13.7693H24.0731V12.2308H5.9269V13.7693ZM5.9269 24.0115H17.8307V22.4731H5.9269V24.0115ZM5.927 18.7692H24.0732V17.2307H5.927V18.7692Z"
      fill="currentColor"
    />
  ),
  fontSize: 'inherit',
  viewBox: '0 0 36 32',
});
