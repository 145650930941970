import { decomposeColor, recomposeColor, rgbToHex } from '@mui/material';

export function hexToHsv(rgb: string): [number, number, number] {
  const [r, g, b] = decomposeColor(rgb).values as [number, number, number];
  const rO = r / 255;
  const gO = g / 255;
  const bO = b / 255;
  const v = Math.max(rO, gO, bO);
  const c = v - Math.min(rO, gO, bO);
  const h =
    c &&
    // eslint-disable-next-line no-nested-ternary
    (v === rO
      ? (gO - bO) / c
      : v === gO
        ? 2 + (bO - rO) / c
        : 4 + (rO - gO) / c);
  return [(h < 0 ? h + 6 : h) / 6, v && c / v, v];
}

export function hsvToHex([h, s, v]: [number, number, number]): string {
  const f = (n: number, k = (n + h * 6) % 6) =>
    v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  return rgbToHex(
    recomposeColor({
      type: 'rgb',
      values: [f(5) * 255, f(3) * 255, f(1) * 255],
    }),
  );
}

export function getColorWithOpacity(color: string, alpha: number): string {
  const alphaHex = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, '0');

  return `${color}${alphaHex}`;
}
