import { makeVar, useReactiveVar } from '@apollo/client';
import { getHasUnreadNotifications } from 'src/restApi/getHasUnreadNotifications';

type HookOptions = {
  skip?: boolean;
};

const unreadNotificationsVar = makeVar<boolean | null>(null);

const init = async () => {
  if (unreadNotificationsVar() !== null) return;
  try {
    unreadNotificationsVar(await getHasUnreadNotifications());
  } catch (e) {
    console.log(e);
  }
};

export const clearUnreadNotificationsIndicator = () => {
  unreadNotificationsVar(null);
};

export const useHasUnreadNotifications = (options?: HookOptions) => {
  if (!options?.skip) {
    init();
  }
  return useReactiveVar(unreadNotificationsVar);
};
