/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box, Button, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { Enum_RiskName } from 'src/apollo';
import { PageTitle, trackChartAction } from 'src/utils';

import { addYears } from 'date-fns';
import { useAuth } from 'src/auth';
import { FullLoading } from 'src/components/layout';
import { useLogo } from 'src/hooks';
import { usePDF } from '@react-pdf/renderer';
import { download } from 'src/utils/download';
import { coercePositive } from './Chart/utils';
import { Filters } from './Filters';
import { RiskSummary } from './RiskSummary';
import { useStyles } from './styles';
import { DepositFrequency, ValueFilters } from './Filters/ValueFilters';
import { ProjectionsChart } from './ProjectionsChart';
import { ChartEvent, EventFilters } from './Filters/EventFilters';
import { ProjectedReturnsPdf } from './ChartPdf/ProjectedReturnsPdf';

const defaultValue = 500000;

interface Props {
  initialValue?: number;
  closeFlag?: boolean;
}

export const ProjectedReturnsChart: FC<Props> = ({
  initialValue,
  closeFlag,
}) => {
  const { classes, cx } = useStyles();
  const { attributes } = useAuth();
  const { src } = useLogo();
  const [instance, updateInstance] = usePDF({ document: <></> });
  const theme = useTheme();
  const dateNow = new Date();
  const tenYearsFromNow = addYears(dateNow, 10);
  const [startDate, setStartDate] = useState<Date>(dateNow);
  const [endDate, setEndDate] = useState<Date>(tenYearsFromNow);
  const [printing, setPrinting] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [renderFlag, setRenderFlag] = useState(0);
  const [lastUrl, setLastUrl] = useState('');
  const [contribution, setContribution] = useState(0);
  const [events, setEvents] = useState<ChartEvent[]>([]);
  const [showEvents, setShowEvents] = useState(false);
  const [selectedRisk, setSelectedRisk] = useState<Enum_RiskName>(
    Enum_RiskName.Medium,
  );
  const [contributionsFreq, setContributionsFreq] = useState(
    DepositFrequency.Month,
  );
  const [startingValue, setStartingValue] = useState(
    coercePositive(initialValue, defaultValue),
  );

  const { firstname, lastname } = attributes ?? {};

  const activeEvents = events.filter((e) => e.initialized);

  const downloadInstance = () => {
    if (!instance.url) return;
    download(
      instance.url,
      'Projected Returns Chart.pdf',
      PageTitle.ProjectedReturns,
    );
    setPrinting(false);
    setShowLoading(false);
  };

  useEffect(downloadInstance, [instance.url]);

  useEffect(() => {
    setStartDate(dateNow);
    setEndDate(tenYearsFromNow);
    setStartingValue(coercePositive(initialValue, defaultValue));
  }, [closeFlag]);

  useEffect(() => {
    if (typeof initialValue !== 'number') return;
    setStartingValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (events.length !== 0) return;
    setShowEvents(false);
  }, [events]);

  const selectRisk = (risk: Enum_RiskName) => {
    setSelectedRisk(risk);
    trackChartAction('Risk change');
  };

  const handleStartingValueChange = (value: number) => {
    setStartingValue(coercePositive(value));
  };

  const handleContributionChange = (value: number) => {
    setContribution(value);
  };

  const handleEventsChange = (evts: ChartEvent[]) => {
    setEvents(evts);
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
  };

  const handlePrint = () => {
    setRenderFlag(renderFlag + 1);
  };

  const onChartRender = (url: string) => {
    if (url === lastUrl) {
      downloadInstance();
      return;
    }
    updateInstance(
      <ProjectedReturnsPdf
        url={url}
        logo={src ?? ''}
        theme={theme}
        startingValue={startingValue}
        startDate={startDate}
        endDate={endDate}
        name={firstname ? `${firstname} ${lastname}` : undefined}
        contribution={contribution}
        contributionFreq={contributionsFreq}
        events={activeEvents}
      />,
    );
    setLastUrl(url);
  };

  return (
    <div className={classes.container} data-testid="historic-returns-chart">
      <div className={classes.contentWrapper}>
        <Typography ml="70px" mt={4}>
          Below is an indication of potential future returns, based off
          simulations run using historic data. It provides an illustration of
          expected returns, as well as a range of outcomes that are more or less
          likely. This illustration should be used as a guide only. Actual
          returns may vary considerably from those shown.
        </Typography>
        <Box mt={6}>
          <RiskSummary
            selectedRisk={selectedRisk}
            onChange={selectRisk}
            disableFold
          />
        </Box>
        <Box position="relative" overflow="hidden">
          <ProjectionsChart
            dateStart={startDate}
            dateEnd={endDate}
            selectedRisk={selectedRisk}
            startingValue={startingValue}
            contribution={contribution}
            events={activeEvents}
            contributionFreq={contributionsFreq}
            printing={printing}
            className={cx({ [classes.largeChart]: printing })}
            renderFlag={renderFlag}
            onChartRender={onChartRender}
            showGrid
          />
          <Button
            className={classes.printBtn}
            onClick={() => {
              setPrinting(true);
              setShowLoading(true);
              setTimeout(() => handlePrint(), 500);
            }}
          >
            Download PDF
          </Button>
          {showLoading && <FullLoading cover />}
        </Box>
        <Box className={classes.filtersWrapper}>
          <Filters
            minDate={dateNow}
            maxDate={addYears(dateNow, 30)}
            startDate={startDate}
            endDate={endDate}
            initialStart={dateNow}
            initialEnd={tenYearsFromNow}
            setStartDate={handleStartDateChange}
            setEndDate={handleEndDateChange}
            justifyContent="center"
            dropdownOptions={[
              { value: '5', name: '5 Years' },
              { value: '10', name: '10 Years' },
              { value: '20', name: '20 Years' },
              { value: '30', name: '30 Years' },
            ]}
          />
        </Box>
        <Box className={classes.filtersWrapper}>
          <ValueFilters
            startingValue={startingValue}
            eventsShown={showEvents}
            onShowEvents={() => setShowEvents(true)}
            onValueChange={handleStartingValueChange}
            onContributionChange={handleContributionChange}
            onFrequencyChange={setContributionsFreq}
            justifyContent="center"
          />
        </Box>
        {showEvents && (
          <Box mt={10}>
            <Box className={classes.eventsBadgeWrap}>
              <Box className={classes.eventsBadge}>EVENTS</Box>
            </Box>
            <Box className={classes.eventsDisclaimer}>
              Add up to 3 events, such as additional deposit (e.g. cash
              injection) or scheduled withdrawal (e.g. house purchase, gifting
              or lump sum pension withdrawal).
            </Box>
            <EventFilters
              minDate={startDate}
              maxDate={endDate}
              onChange={handleEventsChange}
            />
          </Box>
        )}
        <Typography variant="body2" className={classes.footerText}>
          Source: Dimensional Returns, Aspen. The composite indices are indices
          are made up of equity, bond and cash allocations, corresponding to
          risk profile. For more information on index construction, please
          contact your financial adviser. Advisers - please contact Aspen. All
          returns are total returns, net of 0.75% annual fee. Past performance
          is not a guide to future returns.{' '}
          <strong>
            Performance summary statistics are for information only. Losses may
            exceed those indicated.
          </strong>{' '}
          Any projections are for illustrative purposes only, based off historic
          data. Returns in the future may be lower or higher than those shown.
          <br />
          <br />
          The value of investments and the income from them can go down as well
          as up and investors may not recover the amount of their original
          investment. The information in this illustration is believed to be
          correct but cannot be guaranteed. No representation or warranty
          (express or otherwise) is given as to the accuracy or completeness of
          the information contained in this illustration. This document is for
          illustrative purposes only and does not constitute advice. It does not
          constitute an offer to sell or a solicitation of an offer to purchase
          any security or any other investment or product. Aspen will not accept
          any liability for the consequences of acting or not acting upon the
          information contained in this publication. Opinions expressed are
          solely the opinions of Aspen. ‘Aspen’ is a trading name of Aspen
          Advisers Limited, a company authorised and regulated by the FCA with
          FRN: 1007296, registered in England and Wales with company number
          14115281.
        </Typography>
      </div>
    </div>
  );
};
