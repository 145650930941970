/* eslint-disable @typescript-eslint/no-loss-of-precision */

import { Enum_RiskName } from 'src/apollo';

const standardNormalQuantile = (p: number) => {
  if (p < 0 || p > 1) {
    throw new Error('The probability must be between 0 and 1.');
  }

  const a1 = -39.6968302866538;
  const a2 = 220.946098424521;
  const a3 = -275.928510446969;
  const a4 = 138.357751867269;
  const a5 = -30.6647980661472;
  const a6 = 2.50662827745924;

  const b1 = -54.4760987982241;
  const b2 = 161.585836858041;
  const b3 = -155.698979859887;
  const b4 = 66.8013118877197;
  const b5 = -13.2806815528857;

  const c1 = -0.00778489400243029;
  const c2 = -0.322396458041136;
  const c3 = -2.40075827716184;
  const c4 = -2.54973253934373;
  const c5 = 4.37466414146497;
  const c6 = 2.93816398269878;

  const d1 = 0.00778469570904146;
  const d2 = 0.32246712907004;
  const d3 = 2.445134137143;
  const d4 = 3.75440866190742;

  if (p < 0.02425) {
    const q = Math.sqrt(-2 * Math.log(p));
    return (
      (((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
      ((((d1 * q + d2) * q + d3) * q + d4) * q + 1)
    );
  }
  if (p > 0.97575) {
    const q = Math.sqrt(-2 * Math.log(1 - p));
    return (
      -(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
      ((((d1 * q + d2) * q + d3) * q + d4) * q + 1)
    );
  }
  const q = p - 0.5;
  const r = q * q;
  return (
    ((((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q) /
    (((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1)
  );
};

export const cornishFisherExpansion = (
  p: number,
  mean: number,
  stdDev: number,
  skewness: number,
  kurtosis: number,
) => {
  const z = standardNormalQuantile(p);

  const zAdjusted =
    z +
    (skewness / 6) * (z ** 2 - 1) +
    (kurtosis / 24) * (z ** 3 - 3 * z) -
    ((skewness * skewness) / 36) * (2 * z ** 3 - 5 * z);

  return mean + zAdjusted * stdDev;
};

export const riskParams = {
  [Enum_RiskName.Low]: {
    mean: 0.0046,
    stdDev: 0.0111,
    skew: -0.0068,
    kurt: 0.310212143,
    legendBreakpoint: -45,
  },
  [Enum_RiskName.Lower]: {
    mean: 0.0051,
    stdDev: 0.0175,
    skew: -0.2722,
    kurt: 0.382630854,
    legendBreakpoint: -40,
  },
  [Enum_RiskName.Medium]: {
    mean: 0.0057,
    stdDev: 0.025,
    skew: -0.4233,
    kurt: 0.694699537,
    legendBreakpoint: -35,
  },
  [Enum_RiskName.Higher]: {
    mean: 0.0062,
    stdDev: 0.0328,
    skew: -0.5062,
    kurt: 0.906337262,
    legendBreakpoint: -30,
  },
  [Enum_RiskName.High]: {
    mean: 0.0066,
    stdDev: 0.0398,
    skew: -0.5233,
    kurt: 0.99647921,
    legendBreakpoint: -25,
  },
};

export const objectToStyles = (o: Record<string, string>) => {
  return Object.entries(o)
    .map(([key, val]) => `${key}:${val}`)
    .join(';');
};
