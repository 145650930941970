import { FC, ReactNode } from 'react';
import { Container } from '@mui/material';

import { ErrorBox } from 'src/components/ui';

interface Props {
  message: string;
  onTryAgain: () => void;
  children?: ReactNode;
}

export const ErrorMessage: FC<Props> = ({ message, onTryAgain, children }) => {
  return (
    <Container>
      {children}
      <ErrorBox onTryAgain={onTryAgain}>{message}</ErrorBox>
    </Container>
  );
};
