import { createIcon } from '../createIcon';

export const CapitalIcon = createIcon({
  svg: ({ palette }) => (
    <>
      <circle
        cx="36"
        cy="36"
        r="35.5"
        fill={palette.common.white}
        stroke="color-mix(in srgb, currentColor 20%, transparent)"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M51 51H21V21"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M25.36 43.849l6.454-6.628 2.79 2.093 4.884-5.233L42.28 36 51 27.628"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M46.29 27.628H51v4.71"
      />
    </>
  ),
  viewBox: '0 0 72 72',
});
