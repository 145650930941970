import { FC, Fragment, ReactElement } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  SxProps,
  Theme,
} from '@mui/material';

import { useStyles } from './styles';

export interface Option {
  value: string | number;
  label?: ReactElement;
}

interface Props {
  options: Option[];
  name?: string;
  readOnly?: boolean;
  value?: string | number | null;
  sx?: SxProps<Theme>;
  onChange?: (val: string) => void;
}

export const RiskInput: FC<Props> = ({
  name,
  options,
  readOnly,
  value,
  sx,
  onChange,
}) => {
  const { classes } = useStyles();

  const handleValueChange = readOnly
    ? undefined
    : (_: unknown, val: string) => onChange?.(val);

  return (
    <div className={classes.root}>
      <RadioGroup
        name={name}
        row
        value={value ?? null}
        onChange={handleValueChange}
        sx={sx}
      >
        {options.map((opt, i) => (
          <Fragment key={opt.value}>
            {i ? <div className={classes.line} /> : null}
            <FormControlLabel
              value={opt.value}
              control={<Radio />}
              disabled={readOnly}
              label={opt.label}
              labelPlacement="bottom"
              className={classes.label}
            />
          </Fragment>
        ))}
      </RadioGroup>
      <div className={classes.preloadFont}>.</div>
    </div>
  );
};
