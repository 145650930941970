import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  withCancel: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '& > a:first-of-type': {
      marginRight: theme.spacing(3),
    },
  },
  submitContainer: {
    width: '100%',
  },
  submit: {
    marginLeft: 'auto',
    width: 300,
  },
}));
