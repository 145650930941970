import { alpha, Components, lighten, Theme, ThemeOptions } from '@mui/material';

import { headerHeight } from 'src/utils';
import {
  checkedBox,
  checkedRadio,
  uncheckedBox,
  uncheckedRadio,
} from './checkboxIcons';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    compact: true;
  }
}

export const baseTheme: ThemeOptions = {
  palette: {
    error: {
      main: '#e35959',
    },
    text: {
      primary: '#272626',
      secondary: '#858585',
    },
    background: {
      paper: '#f6f6f6',
    },
    divider: '#d4d4d4',
    action: {
      disabled: '#d4d4d4',
      disabledBackground: undefined,
    },
  },
  spacing: 4,
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(),
    fontSize: 14,
    body1: {
      fontFamily: ['Poppins', 'sans-serif'].join(),
      fontSize: 14,
      fontWeight: 300,
      lineHeight: 1.5,
    },
    body2: {
      fontFamily: ['Poppins', 'sans-serif'].join(),
      fontSize: 14,
      fontWeight: 300,
      lineHeight: 1.75,
    },
    h1: {
      fontSize: 32,
      fontWeight: 300,
      lineHeight: 1.33,
    },
    h2: {
      fontSize: 32,
      fontWeight: 300,
      lineHeight: 1.22,
    },
    h3: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: 1.4,
    },
    h4: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: 1.75,
    },
    subtitle1: {
      fontFamily: ['Poppins', 'sans-serif'].join(),
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    button: {
      fontSize: 14,
      fontWeight: 300,
      lineHeight: 1.43,
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: { xs: 300, sm: 600, md: 900, lg: 1366, xl: 1500 },
  },
};

export const themeOverrides = (theme: Theme): Components => ({
  MuiAppBar: {
    defaultProps: {
      position: 'sticky',
    },
    styleOverrides: {
      root: {
        alignItems: 'center',
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.divider,
        boxShadow: 'none',
        height: theme.spacing(headerHeight),
        flexDirection: 'row',
        padding: theme.spacing(0, 7.5, 0, 10),
        backgroundColor: theme.palette.common.white,
        color: 'inherit',
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
      },
    },
  },
  MuiButton: {
    defaultProps: {
      color: 'primary',
      disableElevation: true,
      variant: 'contained',
    },
    variants: [
      {
        props: { size: 'compact' },
        style: {
          fontWeight: 600,
          fontSize: 11,
          padding: 2,
          maxHeight: 30,
          paddingInline: 20,
          minWidth: 150,
        },
      },
    ],

    styleOverrides: {
      root: {
        borderRadius: 5,
        height: 40,
        minWidth: 180,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: ((theme.typography.button.fontSize as number) * 9) / 7,
        textTransform: 'capitalize',
        '&.Mui-disabled': {
          opacity: 0.4,
        },
        '&.Mui-text': {
          minWidth: 'auto',
          padding: theme.spacing(2),
        },
        '&.shadow': {
          boxShadow: `2px 2px 6px 0px ${lighten(
            theme.palette.primary.main,
            0.7,
          )}`,
        },
      },
      containedPrimary: {
        '&:hover': {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
      outlined: {
        backgroundColor: 'white',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
      sizeSmall: {
        height: 44,
        minWidth: 135,
        padding: theme.spacing(1, 7),
        fontSize: theme.typography.button.fontSize,
      },
      sizeMedium: {
        height: 44,
        minWidth: 135,
        padding: theme.spacing(1, 7),
        fontSize: 16,
      },
      iconSizeMedium: {
        '& > *:first-of-type': {
          fontSize: undefined,
        },
      },
      startIcon: {
        marginRight: theme.spacing(3),
        '&$iconSizeSmall': {
          marginLeft: theme.spacing(-3),
          marginRight: theme.spacing(2),
        },
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'primary',
      checkedIcon: checkedBox,
      icon: uncheckedBox,
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(0, 4, 0, 0),
      },
      colorPrimary: {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      '@global': {
        body: {
          backgroundColor: theme.palette.common.white,
        },
        strong: {
          fontWeight: '600',
        },
      },
    },
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: 'sm',
    },
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'content-box',
      },
    },
  },
  MuiDialog: {
    defaultProps: {
      PaperProps: {
        elevation: 0,
      },
    },
    styleOverrides: {
      paper: {
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.spacing(2.5),
        padding: theme.spacing(8),
        width: '100%',
      },
      paperWidthSm: {
        maxWidth: 600,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        marginTop: theme.spacing(8),
        padding: 0,
      },
      spacing: {
        '& > :not(:first-of-type)': {
          marginLeft: theme.spacing(4),
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, -8),
        padding: theme.spacing(0, 8),
        ...theme.typography.body1,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        ...theme.typography.h1,
        padding: 0,
        paddingBottom: theme.spacing(11),
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        background: lighten(theme.palette.background.paper, 0.7),
      },
    },
  },
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: theme.spacing(2.5),
        borderTopLeftRadius: theme.spacing(2.5),
        borderTopRightRadius: theme.spacing(2.5),
        lineHeight: 1.375,
        overflow: 'hidden',
        transition: theme.transitions.create(['border-color']),
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
          backgroundColor: 'transparent',
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-error': {
          borderColor: theme.palette.error.main,
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          opacity: 0.5,
        },
      },
      adornedStart: {
        paddingLeft: undefined,
      },
      input: {
        height: 'auto',
        padding: theme.spacing(6, 6, 2.5),
      },
      inputSizeSmall: {
        // TODO: check this if inputMarginDense
        padding: theme.spacing(3),
        paddingTop: undefined,
        paddingBottom: undefined,
      },
      inputAdornedStart: {
        paddingLeft: theme.spacing(10),
      },
      multiline: {
        padding: theme.spacing(6),
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'flex-start',
        marginRight: 0,
      },
      label: {
        ...theme.typography.h4,
        fontSize: 16,
        marginTop: 10,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        fontSize: '0.625rem',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(6),
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: theme.palette.primary.main,
        fontSize: '1.5rem',
        padding: 12,
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        ...theme.typography.button,
        fontSize: (theme.typography.fontSize * 3) / 2,
        lineHeight: 2,
      },
      input: {
        height: 'auto',
        padding: theme.spacing(1, 0),
        textAlign: 'center',
      },
      underline: {
        '&:hover:not(.Mui-disabled)::before': {
          borderColor: `${theme.palette.primary.main} !important`,
          opacity: 0.2,
        },
        '&::before': {
          borderColor: theme.palette.primary.main,
          opacity: 0.2,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderColor: 'currentColor',
        },
      },
    },
  },
  MuiLink: {
    defaultProps: {
      color: 'inherit',
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        borderColor: theme.palette.divider,
        borderRadius: theme.spacing(2.5),
        borderTopLeftRadius: theme.spacing(2.5),
        borderTopRightRadius: theme.spacing(2.5),
        lineHeight: 1.375,
        overflow: 'hidden',
        transition: theme.transitions.create(['border-color']),
        '&:hover $notchedOutline': {
          borderColor: theme.palette.divider,
        },
        '&.Mui-focused': {
          backgroundColor: 'transparent',
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-error': {
          borderColor: theme.palette.error.main,
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          opacity: 0.5,
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      filled: {
        '&$positionStart:not($hiddenLabel)': {
          marginTop: undefined,
        },
      },
      positionStart: {
        position: 'absolute',
        left: theme.spacing(4),
        pointerEvents: 'none',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightRegular,
        '&.Mui-shrink': {
          color: 'currentColor',
          fontWeight: theme.typography.fontWeightLight,
        },
        '&.Mui-disabled': {
          color: 'currentColor',
          opacity: 0.5,
        },
      },
      filled: {
        transform: `translate(calc(${theme.spacing(
          6,
        )} + 1px), calc((0.375em + ${theme.spacing(8.5) + 2}px) / 2)) scale(1)`,
        '&.Mui-shrink': {
          transform: `translate(calc(${theme.spacing(
            6,
          )} + 1px), calc((-0.625em + ${
            theme.spacing(8.5) + 2
          }px) / 2)) scale(0.625)`,
        },
      },
      shrink: {
        '&.Mui-focused:not(.MuiInputLabel-outlined)': {
          fontSize: 12,
          fontWeight: 600,
          transform: 'scale(1) translate(10px, 6px)',
        },
      },
    },
  },
  MuiListItemText: {
    defaultProps: {
      disableTypography: true,
    },
    styleOverrides: {
      root: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
      },
    },
  },
  MuiMenu: {
    defaultProps: {
      elevation: 0,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      anchorEl: null,
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      MenuListProps: {
        disablePadding: true,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: theme.spacing(4),
        paddingTop: undefined,
        paddingBottom: undefined,
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      rounded: {
        borderRadius: theme.spacing(2),
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        marginTop: theme.spacing(1),
        minWidth: 300,
        backgroundColor: theme.palette.common.white,
        border: '2px solid',
        borderColor: theme.palette.divider,
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'primary',
      checkedIcon: checkedRadio,
      icon: uncheckedRadio,
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(3),
        '&.Mui-checked.Mui-disabled': {
          color: theme.palette.text.secondary,
        },
        '&.Mui-disabled': {
          color: theme.palette.divider,
        },
      },
      colorPrimary: {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        ...theme.typography.button,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        flexDirection: 'column',
        width: theme.breakpoints.values.sm,
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: 16,
      },
      fontSizeLarge: {
        fontSize: 32,
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      root: {
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
          backgroundColor: theme.palette.background.paper,
        },
      },
      track: {
        width: '40px',
        height: '20px',
        borderRadius: '10px',
        transform: 'translateY(-3px)',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
      },
      thumb: {
        height: '20px',
        width: '20px',
        boxShadow: 'none',
        border: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        height: theme.spacing(16),
        padding: 0,
        borderBottomColor: theme.palette.divider,
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: 1.75,
      },
      head: {
        ...theme.typography.button,
        fontSize: '16px',
        height: '64px',
        borderBottomWidth: 1,
        fontWeight: 700,
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: 40,
      },
      indicator: {
        display: 'none',
      },
      flexContainer: {
        columnGap: 2,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: 200,
        color: theme.palette.primary.main,
        fontSize: '16px !important',
        paddingTop: 8,
        paddingBottom: 8,
        minHeight: 'unset',
        '&.Mui-selected': {
          color: 'white',
          backgroundColor: theme.palette.primary.main,
        },
        '&:not(.Mui-selected)': {
          borderBottom: `1px solid ${lighten(theme.palette.primary.main, 0.8)}`,
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'filled',
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontWeight: theme.typography.fontWeightLight,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        border: 0,
        color: theme.palette.primary.main,
        font: '500 14px Poppins',
        borderRadius: 0,
        letterSpacing: '0.05em',
        paddingTop: 8,
        paddingBottom: 8,
        '&.Mui-selected': {
          background: theme.palette.primary.main,
          color: 'white',
          fontWeight: 600,
          '&:hover': {
            background: theme.palette.primary.main,
            color: 'white',
          },
        },
        '&:not(.Mui-selected)': {
          borderBottom: `1px solid ${lighten(theme.palette.primary.main, 0.8)}`,
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        fontWeight: 300,
      },
      h1: {
        [theme.breakpoints.down('lg')]: {
          fontSize: theme.typography.h2.fontSize,
        },
      },
    },
  },
});
