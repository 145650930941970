import { Theme } from '@mui/material';

const defaultBarWidth = 55;
const barNegativeColor = '#c62e2e';
const barPositiveColor = '#b8b8b8';

const seriesCfg = {
  barWidth: defaultBarWidth,
  type: 'bar',
  cursor: 'default',
};

const labelCfg = {
  show: true,
  formatter: ({ value }: { value: number }) => {
    return `${value < 0 ? '' : '+'}${value.toFixed(1)}%`;
  },
};

const prepareBlankSeries = (stack: string, barWidth = defaultBarWidth) => ({
  ...seriesCfg,
  stack,
  barWidth,
  data: [0],
});

const printLeftLabel = (theme: Theme, text: string) => ({
  type: 'text',
  left: 0,
  rotation: Math.PI / 2,
  style: {
    text,
    fill: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize * 0.625,
  },
});

const prepareSeries = (
  stack: string,
  negativeValue: number,
  positiveValue: number,
  tolerance: number,
  toleranceCapitalLoss: number,
  barWidth = defaultBarWidth,
) => {
  const opacity = toleranceCapitalLoss === tolerance ? 1 : 0.2;
  return [
    {
      ...seriesCfg,
      barWidth,
      stack,
      data: [negativeValue],
      itemStyle: {
        opacity,
        color: barNegativeColor,
      },
      label: {
        ...labelCfg,
        position: 'bottom',
        color: barNegativeColor,
      },
    },
    {
      ...seriesCfg,
      barWidth,
      stack,
      data: [positiveValue],
      itemStyle: {
        opacity,
        color: barPositiveColor,
      },
      label: {
        ...labelCfg,
        position: 'top',
      },
    },
  ];
};

export const getConfig = (
  theme: Theme,
  toleranceCapitalLoss: number,
  mobileWidth?: number,
) => {
  const barWidth = mobileWidth ? 45 : defaultBarWidth;
  const startPos = 0;
  const gap = mobileWidth
    ? (mobileWidth - 5 * barWidth) / 6 - 12
    : defaultBarWidth + 3;
  const chartConfig = {
    grid: {
      containLabel: false,
      left: 0,
      right: 0,
    },
    xAxis: {
      type: 'category',
      name: '',
      label: false,
      axisLine: {
        onZero: true,
        lineStyle: { color: theme.palette.text.secondary },
      },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: { show: false },
      zlevel: 1,
    },
    yAxis: {
      type: 'value',
      axisLine: { onZero: false },
      splitLine: { show: false },
      axisLabel: { show: false },
    },
    series: [
      prepareBlankSeries('0', startPos),
      ...prepareSeries('1', -8.86, 23.12, 1, toleranceCapitalLoss, barWidth),
      prepareBlankSeries('2', gap),
      ...prepareSeries('3', -9.93, 31.33, 2, toleranceCapitalLoss, barWidth),
      prepareBlankSeries('4', gap),
      ...prepareSeries('5', -16.59, 39.97, 3, toleranceCapitalLoss, barWidth),
      prepareBlankSeries('6', gap),
      ...prepareSeries('7', -24.21, 49.08, 4, toleranceCapitalLoss, barWidth),
      prepareBlankSeries('8', gap),
      ...prepareSeries('9', -30.69, 57.44, 5, toleranceCapitalLoss, barWidth),
    ],
    graphic: [
      {
        ...printLeftLabel(theme, '12 month possible gain'),
        top: mobileWidth ? 75 : 89,
      },
      {
        ...printLeftLabel(theme, '12 month possible loss'),
        bottom: mobileWidth ? 30 : 20,
      },
    ],
  };

  return chartConfig;
};
