import { createIcon } from '../createIcon';

export const AddIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        d="M16 8.5C16.2761 8.5 16.5 8.72386 16.5 9V15.5H23C23.2761 15.5 23.5 15.7239 23.5 16C23.5 16.2761 23.2761 16.5 23 16.5H16.5V23C16.5 23.2761 16.2761 23.5 16 23.5C15.7239 23.5 15.5 23.2761 15.5 23V16.5H9C8.72386 16.5 8.5 16.2761 8.5 16C8.5 15.7239 8.72386 15.5 9 15.5H15.5V9C15.5 8.72386 15.7239 8.5 16 8.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z"
        fill="currentColor"
      />
    </g>
  ),
  fontSize: 'small',
  viewBox: '0 0 32 32',
});
