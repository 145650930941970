import { Box, Button, DialogActions } from '@mui/material';
import { ReactElement } from 'react';
import { DialogSize, UseDialog } from 'src/hooks';

interface ConfirmOptions {
  content?: ReactElement;
  title?: string;
  size?: DialogSize;
  onCancel?: () => void;
  onConfirm?: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  hideCancel?: boolean;
}

export const confirmModal = (dialog: UseDialog, options: ConfirmOptions) => {
  dialog.show({
    content: (
      <>
        <Box py={5} px="80px">
          {options.content}
        </Box>
        <DialogActions>
          {!options.hideCancel && (
            <Button
              size="small"
              variant="outlined"
              sx={{ minWidth: '175px' }}
              onClick={() => {
                dialog.close();
                options.onCancel?.();
              }}
            >
              {options.cancelLabel ? options.cancelLabel : 'Cancel'}
            </Button>
          )}
          <Button
            size="small"
            sx={{ minWidth: '175px' }}
            onClick={() => {
              dialog.close();
              options.onConfirm?.();
            }}
          >
            {options.confirmLabel ? options.confirmLabel : 'Confirm'}
          </Button>
        </DialogActions>
      </>
    ),
    title: options.title ?? '',
    size: options.size,
  });
};

export const confirmation = (
  dialog: UseDialog,
  options: ConfirmOptions,
): Promise<boolean> => {
  return new Promise((resolve) => {
    confirmModal(dialog, {
      ...options,
      onConfirm: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });
};
