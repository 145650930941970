import { createIcon } from '../createIcon';

export const IncomeIcon = createIcon({
  svg: ({ palette }) => (
    <>
      <circle
        cx="36"
        cy="36"
        r="35.5"
        fill={palette.common.white}
        stroke="color-mix(in srgb, currentColor 20%, transparent)"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M50.827 51H21V21"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M28.803 34.006h-4.335v13.873h4.335V34.006zM35.74 29.67h-4.335v18.208h4.335V29.67zM42.676 25.336h-4.335v22.543h4.335V25.336zM49.613 21h-4.335v26.879h4.335V21z"
      />
    </>
  ),
  viewBox: '0 0 72 73',
});
