import { createIcon } from '../createIcon';

export const DuplicateJourneyIcon = createIcon({
  svg: () => (
    <path
      d="M13 12.5H14V10.5H16V9.5H14V7.5H13V9.5H11V10.5H13V12.5ZM8.625 18C8.15833 18 7.771 17.846 7.463 17.538C7.15433 17.2293 7 16.8417 7 16.375V3.625C7 3.15833 7.15433 2.77067 7.463 2.462C7.771 2.154 8.15833 2 8.625 2H15.5L20 6.5V16.375C20 16.8417 19.846 17.2293 19.538 17.538C19.2293 17.846 18.8417 18 18.375 18H8.625ZM8.625 17H18.375C18.5417 17 18.6873 16.9373 18.812 16.812C18.9373 16.6873 19 16.5417 19 16.375V7L15 3H8.625C8.45833 3 8.31267 3.06267 8.188 3.188C8.06267 3.31267 8 3.45833 8 3.625V16.375C8 16.5417 8.06267 16.6873 8.188 16.812C8.31267 16.9373 8.45833 17 8.625 17ZM4.625 22C4.15833 22 3.771 21.846 3.463 21.538C3.15433 21.2293 3 20.8417 3 20.375V8H4V20.375C4 20.5417 4.06267 20.6873 4.188 20.812C4.31267 20.9373 4.45833 21 4.625 21H14V22H4.625Z"
      fill="currentColor"
    />
  ),
  fontSize: 'large',
  viewBox: '0 0 24 24',
});
