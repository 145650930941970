import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useQueryParams } from 'src/hooks';
import { Routes } from 'src/routing';

interface Props {
  message?: string;
}

export const RedirectToLogin: FC<Props> = ({ message }) => {
  const queryParams = useQueryParams();
  return (
    <Navigate
      to={{
        pathname: Routes.Login,
        search: queryParams.toString(),
      }}
      state={{ message }}
      replace
    />
  );
};
