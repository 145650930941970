import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const indicatorColor = '#5D5FEF';

export const useStyles = makeStyles<void, 'unreadDot' | 'message'>()((
  theme,
  _,
  classes,
) => {
  return {
    root: {
      userSelect: 'none',
      position: 'relative',
      width: '100%',
      padding: '10px 20px',
      paddingRight: 40,
      borderRadius: 8,
      border: `1px solid ${lighten(theme.palette.primary.main, 0.8)}`,
      boxShadow: `0px 4px 5px 0px ${lighten(theme.palette.primary.main, 0.9)}`,
      cursor: 'pointer',
      '&.unread': {
        border: `1px solid ${lighten(indicatorColor, 0.5)}`,
      },
      [`&:not(.unread) .${classes.unreadDot}`]: {
        display: 'none',
      },
      [`&:not(.auto) .${classes.message}`]: {
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
      },
    },
    title: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      position: 'relative',
    },
    unreadDot: {
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translate(calc(-100% - 6px), -50%)',
      width: 5,
      height: 5,
      borderRadius: '50%',
      backgroundColor: indicatorColor,
    },
    message: {
      overflow: 'hidden',
    },
    clearIcon: {
      position: 'absolute',
      right: 6,
      top: 6,
      fontSize: 14,
      cursor: 'pointer',
    },
  };
});
