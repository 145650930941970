import { FC, ReactElement } from 'react';

import { useUserGroup } from 'src/hooks';

interface Props {
  ifaComponent: ReactElement;
  masterAdminComponent: ReactElement;
}

export const RoleSwitch: FC<Props> = ({
  ifaComponent,
  masterAdminComponent,
}) => {
  const { isMasterAdmin } = useUserGroup();

  if (isMasterAdmin) {
    return masterAdminComponent;
  }
  return ifaComponent;
};
