import { FC, useState } from 'react';
import { Box, Tooltip } from '@mui/material';

import { ChartIcon, SideButton } from 'src/components/ui';

import { InteractiveChart } from './InteractiveChart';
import { useStyles } from './styles';

interface Props {
  initialValue?: number;
}

export const InteractiveChartButton: FC<Props> = ({ initialValue }) => {
  const [isChartOpen, setIsChartOpen] = useState(false);
  const { classes } = useStyles();
  return (
    <>
      <Tooltip title="Historic Returns chart">
        <Box className={classes.wrapper}>
          <SideButton
            dataTestid="interactive-chart-btn"
            side="bottom"
            onClick={() => setIsChartOpen(true)}
          >
            <ChartIcon color="primary" />
          </SideButton>
        </Box>
      </Tooltip>
      <InteractiveChart
        open={isChartOpen}
        onClose={() => setIsChartOpen(false)}
        initialValue={initialValue}
      />
    </>
  );
};
