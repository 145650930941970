import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    '@media (orientation: portrait)': {
      display: 'none',
    },
  },
  snack: {
    display: 'none',
    position: 'fixed',
    background: theme.palette.common.white,
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1500,
    '& > div': {
      width: 518,
      alignSelf: 'center',
    },
    '@media (orientation: portrait)': {
      display: 'flex',
    },
  },
}));
