import { FC, ReactElement } from 'react';
import { ButtonBase } from '@mui/material';

import { NavLink } from 'react-router-dom';
import { useStyles } from './styles';

type Props = {
  icon: ReactElement;
  onClick?: () => void;
  to?: string;
};

export const RoundedIconButton: FC<Props> = ({ icon, onClick, to }) => {
  const { classes } = useStyles();

  return (
    <ButtonBase
      className={classes.button}
      onClick={onClick}
      component={NavLink}
      to={to ?? '#'}
    >
      {icon}
    </ButtonBase>
  );
};
