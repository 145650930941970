import { Box, Button, IconButton, TextField, useTheme } from '@mui/material';
import { ChangeEvent, CSSProperties, FC, useState } from 'react';
import { formatCurrency, parseInputNumber, selectOnFocus } from 'src/utils';
import { v4 as uuidv4 } from 'uuid';
import { DatePicker, TrashIcon } from 'src/components/ui';
import { Dropdown } from './Dropdown';
import { coercePositive } from '../Chart/utils';
import { useStyles } from './styles';

enum EventType {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
}

export type ChartEvent = {
  contribution: number;
  date: Date;
  type?: EventType;
  id?: string;
  initialized?: boolean;
};

interface EventProps {
  initialData: ChartEvent;
  minDate: Date;
  maxDate: Date;
  onChange?: (ev: ChartEvent) => void;
  onDelete?: () => void;
}

const Event: FC<EventProps> = ({
  initialData,
  minDate,
  maxDate,
  onChange,
  onDelete,
}) => {
  const [eventType, setEventType] = useState(initialData.type!);
  const [contribution, setContribution] = useState(
    formatCurrency(initialData.contribution),
  );
  const [isSaved, setIsSaved] = useState(false);
  const [date, setDate] = useState(initialData.date);
  const { classes } = useStyles();
  const theme = useTheme();

  const textLabelStyles = {
    style: {
      color: theme.palette.primary.main,
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 12,
      transform: 'none',
    } as CSSProperties,
  };

  const handleEventTypeChange = (e: string) => {
    setEventType(e as EventType);
    setIsSaved(false);
  };

  const handleValueBlur = () => {
    setContribution((val) =>
      formatCurrency(coercePositive(parseInputNumber(val)[1]!)),
    );
  };

  const handleValueChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const [val] = parseInputNumber(ev.currentTarget.value);
    setContribution(val);
    setIsSaved(false);
  };

  const handleDateChange = (d: Date) => {
    setDate(d);
    setIsSaved(false);
  };

  const handleSave = () => {
    const sign = eventType === EventType.Deposit ? 1 : -1;
    const val = parseInputNumber(contribution)[1]! * sign;
    onChange?.({ contribution: val, date });
    setIsSaved(true);
  };

  return (
    <Box
      display="flex"
      columnGap="15px"
      justifyContent="center"
      alignItems="flex-end"
      mb={6}
    >
      <Dropdown
        sx={{ width: 130 }}
        value={eventType}
        options={[
          { value: EventType.Deposit, name: 'Deposit' },
          { value: EventType.Withdrawal, name: 'Withdrawal' },
        ]}
        onChange={handleEventTypeChange}
      />
      <DatePicker
        value={date}
        label=""
        outlined
        fullWidth
        onDateChange={handleDateChange}
        minDate={minDate}
        maxDate={maxDate}
        takeFirstDayOfMonth
      />
      <TextField
        label=""
        autoComplete="off"
        variant="standard"
        value={contribution}
        onChange={handleValueChange}
        onBlur={handleValueBlur}
        inputProps={{
          ...selectOnFocus,
          style: {
            textAlign: 'left',
            fontSize: 16,
            fontWeight: 400,
            paddingBottom: 8,
            fontFamily: 'Montserrat',
          },
        }}
        InputLabelProps={textLabelStyles}
      />
      <Button
        className={classes.saveBtn}
        onClick={handleSave}
        disabled={isSaved}
      >
        Update
      </Button>
      <IconButton onClick={onDelete} sx={{ margin: '0px 0px -8px -6px' }}>
        <TrashIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

interface FilterProps {
  minDate: Date;
  maxDate: Date;
  onChange?: (ev: ChartEvent[]) => void;
}

export const EventFilters: FC<FilterProps> = ({
  minDate,
  maxDate,
  onChange,
}) => {
  const [events, setEvents] = useState<ChartEvent[]>([]);
  const { classes } = useStyles();

  const handleAddEvent = () => {
    setEvents([
      ...events,
      {
        contribution: 1000,
        date: new Date(),
        type: EventType.Deposit,
        id: uuidv4(),
        initialized: false,
      },
    ]);
  };

  return (
    <Box>
      {events.map((ev) => {
        return (
          <Event
            key={ev.id}
            initialData={{
              type: ev.type,
              date: ev.date,
              contribution: ev.contribution,
              initialized: ev.initialized,
            }}
            onChange={(newEv) => {
              const updated = { ...ev, ...newEv, id: ev.id, initialized: true };
              const evIdx = events.findIndex((e) => e.id === ev.id);
              const newEvents = [...events];
              newEvents[evIdx] = updated;
              setEvents(newEvents);
              onChange?.(newEvents);
            }}
            onDelete={() => {
              const newEvents = events.filter((e) => e.id !== ev.id);
              setEvents(newEvents);
              onChange?.(newEvents);
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
        );
      })}
      {events.length < 3 && (
        <Button className={classes.addEventBtn} onClick={handleAddEvent}>
          Add Event
        </Button>
      )}
    </Box>
  );
};
