import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { DialogClose } from 'src/components/ui/DialogClose';
import { hexToHsv, hsvToHex } from 'src/utils';

import { ColorBox } from './ColorBox';
import { ColorSlider } from './ColorSlider';
import { presetColors } from './presetColors';
import { useStyles } from './styles';
import { ColorDot } from '../ColorDot';

interface Props {
  onClose?: (value?: string) => void;
  value?: string | null;
}

const validateHex = (value: string) => {
  return /^#[0-9a-f]{6}$/.test(value.toLowerCase());
};

export const ColorDialog: FC<Props> = ({ onClose, value }) => {
  const { classes } = useStyles();
  const primaryColor = useTheme().palette.primary.main;
  const [inputValue, setInputValue] = useState(value ?? primaryColor);
  const [[hue, saturation, colorValue], setColor] = useState(() =>
    hexToHsv(inputValue),
  );

  const invalidInput = !validateHex(inputValue);
  const errorText = invalidInput ? 'Invalid value' : null;

  const handleBoxChange = useCallback(
    (s: number, v: number) => {
      const hsv: [number, number, number] = [hue, s, v];
      setColor(hsv);
      setInputValue(hsvToHex(hsv));
    },
    [hue],
  );
  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const rgb = e.currentTarget.value.toLowerCase();
    if (validateHex(rgb)) {
      setColor(hexToHsv(rgb));
    }
    setInputValue(rgb);
  }, []);
  const handlePresetChange = useCallback((rgb: string) => {
    setColor(hexToHsv(rgb));
    setInputValue(rgb);
  }, []);
  const handleSliderChange = useCallback(
    (h: number) => {
      const hsv: [number, number, number] = [h, saturation, colorValue];
      setColor(hsv);
      setInputValue(hsvToHex(hsv));
    },
    [saturation, colorValue],
  );
  const handleClose = () => onClose?.();
  const handleSave = () => onClose?.(inputValue);

  const presetList = useMemo(
    () =>
      [primaryColor]
        .concat(presetColors)
        .map((c) => (
          <ColorDot
            key={c}
            color={c}
            size={48}
            selected={c === inputValue}
            onClick={() => handlePresetChange(c)}
          />
        )),
    [primaryColor, inputValue, handlePresetChange],
  );

  return (
    <>
      <DialogTitle>
        Choose color
        <DialogClose onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h3">Preset colors</Typography>
        <div className={classes.presetContainer}>{presetList}</div>
        <ColorBox
          h={hue}
          s={saturation}
          v={colorValue}
          onChange={handleBoxChange}
        />
        <ColorSlider hue={hue} onChange={handleSliderChange} />
        <TextField
          id="hex-value"
          name="hex-value"
          label="Color HEX value"
          error={invalidInput}
          helperText={errorText}
          value={inputValue}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: invalidInput ? null : (
              <ColorDot color={inputValue} size={40} selected />
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleSave} disabled={invalidInput}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
