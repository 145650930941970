import { makeStyles } from 'tss-react/mui';

const size = 64;
const perpendicularSide = size - 3;

export const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 1,
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.spacing(2.5),
    background: theme.palette.common.white,
  },
  top: {
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: size,
    height: perpendicularSide,
    borderTopWidth: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  bottom: {
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: size,
    height: perpendicularSide,
    borderBottom: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  left: {
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: perpendicularSide,
    height: size,
    borderLeftWidth: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  right: {
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: perpendicularSide,
    height: size,
    borderRightWidth: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  disabled: {
    color: theme.palette.divider,
  },
}));
