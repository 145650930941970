import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  return {
    root: {
      position: 'relative',
      bottom: 8,
      height: 30,
      borderRadius: 5,
      color: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.main, 0.95),
      borderColor: lighten(theme.palette.primary.main, 0.9),
    },
  };
});
