import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<boolean>()((theme, withinInput) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: 'transparent',
    border: withinInput ? `1px solid ${theme.palette.primary.main}` : '',
    display: 'flex',
  },
  dragCommunicate: {
    alignSelf: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
