/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ECharts, EChartsCoreOption, init } from 'echarts';

import { useStyles } from './styles';
import { worldEvents } from '../InteractiveChart/InteractiveChart/worldEvents';

const getSeriesName = (params: Record<string, any> = {}) => {
  const topTarget = params.topTarget ?? {};
  for (const key of Object.keys(topTarget)) {
    if (!key.startsWith('__ec_inner_')) continue;
    const { seriesName } = topTarget[key]?.eventData ?? {};
    if (typeof seriesName === 'string') return seriesName;
  }
  return undefined;
};

const getSeriesIndex = (params: Record<string, any> = {}) => {
  const topTarget = params.topTarget ?? {};
  for (const key of Object.keys(topTarget)) {
    if (!key.startsWith('__ec_inner_')) continue;
    const { seriesIndex } = topTarget[key] ?? {};
    if (typeof seriesIndex === 'number') return seriesIndex;
  }
  return undefined;
};

type EChartItem = {
  style?: {
    text?: string;
    lineWidth?: number;
    stroke?: string;
  };
  shape?: {
    symbolType?: string;
  };
  id: number;
  __ecComponentInfo?: {
    mainType: string;
  };
  parent?: EChartItem;
  type?: string;
};

interface EChartsParams {
  topTarget?: EChartItem;
  target?: EChartItem;
}

export interface EChartProps {
  config: EChartsCoreOption;
  downturnEvents?: { [key: string]: { onClick: () => void } };
  resizeFlag?: boolean;
  height?: number | 'auto';
  renderFlag?: number;
  onLineEnter?: (color: string, series: number) => void;
  onLineLeave?: () => void;
  onFinished?: (val: string) => void;
  onZoom?: (start: number, end: number, isWheel?: boolean) => void;
  onSeriesClick?: (series: string) => void;
  onChartEnter?: () => void;
  onChartLeave?: () => void;
  onChartRender?: (url: string) => void;
}

let tooltipShown = false;

export const EChart: FC<EChartProps> = ({
  config,
  downturnEvents,
  resizeFlag,
  height,
  renderFlag,
  onLineEnter,
  onLineLeave,
  onFinished,
  onZoom,
  onSeriesClick,
  onChartEnter,
  onChartLeave,
  onChartRender,
}) => {
  const { classes } = useStyles();
  const chart = useRef<HTMLDivElement>(null);
  const [chartEl, setChartEl] = useState<ECharts>();

  const initChart = useCallback(() => {
    if (!chartEl && chart.current) {
      const initializedChart = init(chart.current, null, {
        devicePixelRatio: 2,
      });
      if (height) initializedChart.resize({ height });
      setChartEl(initializedChart);
    }
  }, [chartEl, height]);

  const handleResize = useCallback(() => {
    chartEl?.resize();
  }, [chartEl]);

  useEffect(() => {
    chartEl?.resize();
  }, [resizeFlag]);

  useEffect(() => {
    if (!chartEl || !renderFlag) return;
    const url = chartEl.getDataURL({ type: 'png', pixelRatio: 5 });
    onChartRender?.(url);
  }, [renderFlag, chartEl]);

  useEffect(() => {
    initChart();
    if (chartEl) {
      chartEl.setOption(config);
      window.addEventListener('resize', handleResize);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [chartEl, config, initChart, handleResize]);

  if (chartEl) {
    chartEl.on('dataZoom', () => {
      const option = chartEl.getOption().dataZoom as any;
      onZoom?.(option[0].startValue, option[0].endValue);
    });

    chartEl.on('showTip', () => {
      if (tooltipShown) return;
      onChartEnter?.();
      tooltipShown = true;
    });

    chartEl.on('hideTip', () => {
      if (!tooltipShown) return;
      onChartLeave?.();
      tooltipShown = false;
    });

    const zr = chartEl.getZr();
    zr.on('click', (params?: EChartsParams) => {
      const seriesName = getSeriesName(params);
      if (seriesName) {
        onSeriesClick?.(seriesName);
        return;
      }
      const topTarget = params?.topTarget;
      const parentText = topTarget?.parent?.style?.text;
      downturnEvents?.[parentText ?? '']?.onClick?.();
    });

    zr.on('mousemove', (params?: EChartsParams) => {
      const topTarget = params?.topTarget;
      const parentText = topTarget?.parent?.style?.text;
      if (parentText) {
        if (worldEvents.find((e) => e.name === parentText)) {
          zr.setCursorStyle('default');
        } else {
          zr.setCursorStyle('pointer');
        }
      } else {
        zr.setCursorStyle('default');
      }
    });

    zr.on('mouseover', (params?: EChartsParams) => {
      const topTarget = params?.topTarget;
      const seriesIndex = getSeriesIndex(params);
      if (seriesIndex === undefined) return;
      if (topTarget?.shape?.symbolType === 'circle') {
        onLineEnter?.('#000', seriesIndex);
        return;
      }
      if (topTarget?.type !== 'ec-polyline') return;
      onLineEnter?.(topTarget.style?.stroke ?? '', seriesIndex);
    });

    zr.on('mouseout', (params?: EChartsParams) => {
      if (params?.topTarget?.type !== 'ec-polyline') return;
      onLineLeave?.();
    });
  }

  useEffect(() => {
    if (!chartEl) return;
    chartEl.off('finished');
    chartEl.on('finished', () => onFinished?.(chartEl.getDataURL()));
  }, [chartEl, onFinished]);

  return <div ref={chart} className={classes.root} />;
};
