import { createIcon } from '../createIcon';

export const ReassignJourneyIcon = createIcon({
  svg: () => (
    <>
      <path
        d="M9 11.3847C8.175 11.3847 7.46875 11.0909 6.88125 10.5034C6.29375 9.91592 6 9.20967 6 8.38467C6 7.55965 6.29375 6.85339 6.88125 6.26589C7.46875 5.67839 8.175 5.38464 9 5.38464C9.825 5.38464 10.5313 5.67839 11.1188 6.26589C11.7063 6.85339 12 7.55965 12 8.38467C12 9.20967 11.7063 9.91592 11.1188 10.5034C10.5313 11.0909 9.825 11.3847 9 11.3847ZM2 18.6154V16.9693C2 16.5565 2.12019 16.1702 2.36057 15.8106C2.60096 15.451 2.92436 15.1718 3.33078 14.9731C4.27436 14.5206 5.21858 14.1811 6.16345 13.9548C7.10833 13.7286 8.05385 13.6154 9 13.6154C9.94615 13.6154 10.8917 13.7286 11.8366 13.9548C12.7814 14.1811 13.7256 14.5206 14.6692 14.9731C15.0756 15.1718 15.399 15.451 15.6394 15.8106C15.8798 16.1702 16 16.5565 16 16.9693V18.6154H2ZM3 17.6154H15V16.9693C15 16.7475 14.9285 16.5392 14.7856 16.3443C14.6426 16.1494 14.4449 15.9847 14.1923 15.85C13.3692 15.4513 12.5206 15.1459 11.6464 14.9337C10.7722 14.7215 9.89008 14.6154 9 14.6154C8.10992 14.6154 7.22778 14.7215 6.3536 14.9337C5.4794 15.1459 4.63077 15.4513 3.8077 15.85C3.55513 15.9847 3.35737 16.1494 3.21442 16.3443C3.07148 16.5392 3 16.7475 3 16.9693V17.6154ZM9 10.3847C9.55 10.3847 10.0208 10.1888 10.4125 9.79717C10.8042 9.4055 11 8.93467 11 8.38467C11 7.83467 10.8042 7.36384 10.4125 6.97217C10.0208 6.5805 9.55 6.38467 9 6.38467C8.45 6.38467 7.97917 6.5805 7.5875 6.97217C7.19583 7.36384 7 7.83467 7 8.38467C7 8.93467 7.19583 9.4055 7.5875 9.79717C7.97917 10.1888 8.45 10.3847 9 10.3847Z"
        fill="currentColor"
      />
      <path
        d="M18.4231 14.4337L17.7615 13.7817L20.8712 10.6721H13V9.74902H20.8712L17.774 6.65193L18.4356 6L22.6385 10.2154L18.4231 14.4337Z"
        fill="currentColor"
      />
    </>
  ),
  fontSize: 'large',
  viewBox: '0 0 24 24',
});
