import { lighten, Theme } from '@mui/material';
import { View, Text } from '@react-pdf/renderer';
import { FC } from 'react';

interface Props {
  theme: Theme;
  text?: string;
}

export const Input: FC<Props> = ({ theme, text }) => {
  return (
    <View
      style={{
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 8,
        color: theme.palette.primary.main,
        textAlign: 'center',
        borderBottom: `1px solid ${lighten(theme.palette.primary.main, 0.8)}`,
        width: 75,
        paddingBottom: 2,
      }}
    >
      <Text>{text}</Text>
    </View>
  );
};
