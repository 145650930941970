import { CSSProperties, ChangeEvent, FC, useCallback, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { formatCurrency, parseInputNumber, selectOnFocus } from 'src/utils';

import { useStyles } from './styles';
import { coercePositive } from '../Chart/utils';
import { Dropdown } from './Dropdown';

enum DepositsType {
  RegularDeposits = 'regular-deposits',
  FixedInvestment = 'fixed-investment',
  RegularWithdrawals = 'regular-withdrawals',
}

export enum DepositFrequency {
  Month = 'month',
  Year = 'year',
}

const depositSign = {
  [DepositsType.RegularWithdrawals]: -1,
  [DepositsType.RegularDeposits]: 1,
  [DepositsType.FixedInvestment]: 0,
};

interface Props {
  startingValue?: number;
  justifyContent?: string;
  eventsShown?: boolean;
  onShowEvents?: () => void;
  onValueChange?: (value: number) => void;
  onContributionChange?: (value: number) => void;
  onFrequencyChange?: (freq: DepositFrequency) => void;
}

export const ValueFilters: FC<Props> = ({
  startingValue,
  eventsShown,
  justifyContent = 'flex-start',
  onShowEvents,
  onValueChange,
  onContributionChange,
  onFrequencyChange,
}) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const [depositsType, setDepositsType] = useState(
    DepositsType.FixedInvestment,
  );
  const [depositsFrequency, setDepositsFrequency] = useState(
    DepositFrequency.Month,
  );
  const [startValue, setStartValue] = useState(
    formatCurrency(coercePositive(startingValue)),
  );
  const [contribution, setContribution] = useState(formatCurrency(0));

  const handleValueBlur = useCallback(() => {
    setStartValue((val) =>
      formatCurrency(coercePositive(parseInputNumber(val)[1]!)),
    );
  }, []);

  const handleContributionBlur = useCallback(() => {
    setContribution((val) =>
      formatCurrency(coercePositive(parseInputNumber(val)[1]!)),
    );
  }, []);

  const handleValueChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const [val] = parseInputNumber(ev.currentTarget.value);
    setStartValue(val);
  };

  const handleContributionChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const [val] = parseInputNumber(ev.currentTarget.value);
    setContribution(val);
  };

  const handleUpdate = (dType?: DepositsType) => {
    const [, valNumber] = parseInputNumber(startValue);
    const [, contributionNumber] = parseInputNumber(contribution);
    const sign = depositSign[dType ?? depositsType];
    const contributions = contributionNumber ?? 0;
    onValueChange?.(coercePositive(valNumber));
    onContributionChange?.(contributions * sign);
    onFrequencyChange?.(depositsFrequency);
  };

  const handleDepositsTypeChange = (newType: string) => {
    setDepositsType(newType as DepositsType);
  };

  const handleDepositsFrequencyChange = (frequency: string) => {
    setDepositsFrequency(frequency as DepositFrequency);
  };

  const textLabelStyles = {
    style: {
      color: theme.palette.primary.main,
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 12,
      transform: 'none',
    } as CSSProperties,
  };

  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const regularDeposits = depositsType === DepositsType.RegularDeposits;
  const regularWithdrawals = depositsType === DepositsType.RegularWithdrawals;

  return (
    <Box className={classes.root} sx={{ justifyContent }} mb={6}>
      <Dropdown
        value={depositsType}
        options={[
          { value: DepositsType.RegularDeposits, name: 'Regular Deposits' },
          { value: DepositsType.FixedInvestment, name: 'Fixed Investment' },
          {
            value: DepositsType.RegularWithdrawals,
            name: 'Regular Withdrawals',
          },
        ]}
        onChange={handleDepositsTypeChange}
      />
      {!isSmall && (
        <Typography className={classes.title}>Starting Value</Typography>
      )}
      <TextField
        className={classes.startingValueInput}
        id="starting-value"
        label={isSmall ? 'Starting Value:' : ''}
        autoComplete="off"
        variant="standard"
        value={startValue}
        onChange={handleValueChange}
        onBlur={handleValueBlur}
        inputProps={{
          ...selectOnFocus,
          style: {
            textAlign: 'left',
            fontSize: 16,
            fontWeight: 400,
            paddingBottom: 8,
            fontFamily: 'Montserrat',
          },
        }}
        InputLabelProps={textLabelStyles}
      />
      {(regularDeposits || regularWithdrawals) && (
        <>
          {!isSmall && (
            <Typography className={classes.title}>
              {regularDeposits ? 'Contribute every' : 'Withdrawal per'}
            </Typography>
          )}
          <Dropdown
            value={depositsFrequency}
            options={[
              { value: DepositFrequency.Month, name: 'Month' },
              { value: DepositFrequency.Year, name: 'Year' },
            ]}
            onChange={handleDepositsFrequencyChange}
            sx={{ width: 100 }}
          />
          <TextField
            id="contribution-value"
            autoComplete="off"
            variant="standard"
            value={contribution}
            onChange={handleContributionChange}
            onBlur={handleContributionBlur}
            inputProps={{
              ...selectOnFocus,
              style: {
                textAlign: 'left',
                fontSize: 16,
                fontWeight: 400,
                paddingBottom: 8,
                fontFamily: 'Montserrat',
              },
            }}
            InputLabelProps={textLabelStyles}
          />
        </>
      )}
      <Button className={classes.updateButton} onClick={() => handleUpdate()}>
        Update
      </Button>
      <Button
        className={classes.addEventsBtn}
        onClick={onShowEvents}
        disabled={eventsShown}
      >
        Add Events
      </Button>
    </Box>
  );
};
