import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { Notification } from 'src/components/shared/NotificationForm/types';
import { formatDate } from 'src/utils';
import ReactQuill from 'react-quill';
import { useStyles } from './styles';
import 'react-quill/dist/quill.snow.css';

interface Props {
  notification: Notification;
}

export const NotificationModal: FC<Props> = ({ notification }) => {
  const { classes } = useStyles();

  return (
    <Box py={10} px="80px" className={classes.root}>
      <Typography>
        {formatDate(notification.publishTime ?? '', false, false, {
          month: 'long',
        })}
      </Typography>
      <Typography fontSize={18} fontWeight={600} mt={5} mb={2}>
        {notification.title}
      </Typography>
      <ReactQuill
        value={notification.message}
        className={classes.message}
        readOnly
        modules={{ toolbar: [] }}
      />
    </Box>
  );
};
