import { createIcon } from '../createIcon';

export const GrowthIcon = createIcon({
  svg: ({ palette }) => (
    <>
      <circle
        cx="36"
        cy="36"
        r="35.5"
        fill={palette.common.white}
        stroke="color-mix(in srgb, currentColor 20%, transparent)"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M51 51H21V21M32.828 21h5.315v5.314"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M30.428 39.171h-6v8.057h6v-8.057zM40.371 31.457h-6V47.4h6V31.457zM50.143 21.686h-6V47.4h6V21.686zM38.143 21L24.257 35.057"
      />
    </>
  ),
  viewBox: '0 0 72 72',
});
