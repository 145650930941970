export interface Promotion {
  portfolioId: string;
  preferredFee: number;
}

export enum Feature {
  PortfolioChanges = 'portfolioChanges',
  MultiAssets = 'multiAssets',
}

export interface IfaCompany {
  ifaCompanyId?: string;
  address: string;
  name: string;
  fcaNumber: string;
  phoneNumber: string;
  brandColor: string;
  enabled: boolean;
  logo?: string;
  promotions?: Promotion[];
  disabledFeatures?: Feature[];
}

export interface Adviser {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  parentId?: string;
}
