import axios from 'axios';
import { getToken } from 'src/auth';
import { NOTIFICATIONS_LAMBDA_URL } from './lambda-routes';

export async function getHasUnreadNotifications() {
  const options = {
    headers: {
      Authorization: await getToken(),
      'Content-Type': 'application/json',
    },
  };
  const response = await axios
    .get(`${NOTIFICATIONS_LAMBDA_URL}/notifications/has-unread`, options)
    .catch((error) => {
      throw new Error(
        error.response?.data?.error ??
          'Unexpected error while fetching notifications.',
      );
    });

  return response.data.data.hasUnread as boolean;
}
