import { ChangeEvent, FC, useState } from 'react';
import {
  Box,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { Enum_RiskName } from 'src/apollo';

import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { useStyles } from './styles';
import { getRowTitle, riskRadioOptions, theadCells } from './utils';
import { RiskTable, riskLevels } from '../utils';

interface Props {
  data?: RiskTable;
  selectedRisk: Enum_RiskName;
  disableFold?: boolean;
  onChange: (risk: Enum_RiskName) => void;
}

export const RiskSummary: FC<Props> = ({
  data,
  selectedRisk,
  disableFold,
  onChange,
}) => {
  const { classes, cx } = useStyles();
  const [unfolded, setUnfolded] = useState(false);

  const handleRiskChange = (ev: ChangeEvent<HTMLInputElement>) => {
    onChange(ev.target.value as Enum_RiskName);
  };

  const printTableRow = (riskName: keyof RiskTable, isUnfolded?: boolean) => {
    return (
      <TableRow>
        <TableCell align="left" className={cx(classes.tcell, 'title')}>
          {getRowTitle(riskName)}
        </TableCell>
        {riskLevels.map((risk) => {
          const riskValue = data?.[riskName][risk];
          const formattedValue = Number.isNaN(riskValue)
            ? '-'
            : `${riskValue?.toFixed(1)}%`;
          const checked = risk === selectedRisk;
          return (
            <TableCell
              align="center"
              key={`${risk}-${riskName}`}
              className={cx(classes.tcell, { checked, unfolded: isUnfolded })}
              onClick={() => onChange(risk)}
            >
              {formattedValue}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <Box className={cx(classes.root, { unfolded, disableFold })}>
      <Table>
        <TableHead>
          <TableRow className={classes.tHeadRow}>
            <TableCell />
            {riskRadioOptions.map(({ value }) => {
              const checked = value === selectedRisk;
              return (
                <TableCell
                  width="121px"
                  align="center"
                  className={cx(classes.radioTCell, { checked })}
                  key={value}
                >
                  <Radio
                    className={classes.radio}
                    value={value}
                    onChange={handleRiskChange}
                    checked={checked}
                  />
                  <div className="divider" />
                  <div className={cx({ marker: checked })} />
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {theadCells.map(({ label, textInBrackets, riskLevel }, index) => {
              const checked = selectedRisk === riskLevel;
              const title = index === 0;
              return (
                <TableCell
                  align={title ? 'left' : 'center'}
                  key={label}
                  className={cx(classes.tcell, {
                    title,
                    checked,
                    unfolded,
                    firstRow: true,
                  })}
                  sx={{ paddingBottom: '10px' }}
                  onClick={() => {
                    if (title) return;
                    onChange(riskLevel!);
                  }}
                >
                  {checked || title ? <strong>{label}</strong> : <>{label}</>}
                  <br />
                  <em className="hidden">({textInBrackets})</em>
                  {title && unfolded && (
                    <Box className={classes.dashedDivider} />
                  )}
                </TableCell>
              );
            })}
          </TableRow>
          {unfolded && !disableFold && (
            <>
              {printTableRow('annualisedReturns', unfolded)}
              {printTableRow('bestOneYear', unfolded)}
              {printTableRow('worstOneYear', unfolded)}
              {printTableRow('maxDrawdown')}
            </>
          )}
        </TableBody>
      </Table>
      {!disableFold && (
        <Box className="arrowWrapper" onClick={() => setUnfolded(!unfolded)}>
          <IconButton className={classes.arrowIcon}>
            {unfolded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
