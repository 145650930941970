import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  legend: {
    position: 'absolute',
    left: 100,
    top: 50,
    border: '1px solid lightgray',
    borderRadius: 8,
    padding: 10,
    zIndex: 100,
    backgroundColor: 'white',
    minWidth: 200,
  },
  legendItem: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    '&:not(:last-child)': {
      marginBottom: 5,
    },
  },
  dot: {
    display: 'inline-block',
    width: 8,
    height: 8,
    marginRight: 7,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}));
