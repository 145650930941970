import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: 'flex',
      position: 'relative',
      gap: 10,
      alignItems: 'flex-end',
      width: '100%',
      '& > div > label': {
        whiteSpace: 'nowrap',
      },
    },
    startingValueInput: {
      maxWidth: 160,
    },
    title: {
      padding: theme.spacing(0, 2),
      paddingBottom: 13,
      fontFamily: theme.typography.fontFamily,
      whiteSpace: 'nowrap',
    },
    resetButton: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
      minWidth: 100,
      fontWeight: 400,
      color: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      fontSize: 12,
      height: 30,
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.9),
      },
    },
    updateButton: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
      minWidth: 100,
      fontWeight: 400,
      height: 30,
      fontSize: 12,
    },
    addEventsBtn: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
      minWidth: 100,
      fontWeight: 400,
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      color: theme.palette.primary.main,
      fontSize: 12,
      height: 30,
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.9),
      },
      '&.Mui-disabled': {
        color: 'black',
      },
    },
    datePickerWrapper: {
      position: 'relative',
    },
    icon: {
      position: 'absolute',
      left: theme.spacing(4),
      top: theme.spacing(5),
    },
    datePicker: {
      '& input': {
        padding: theme.spacing(6, 6, 2.5, 10),
      },
      '& label': {
        paddingLeft: theme.spacing(6.5),
      },
    },
    hidden: {
      opacity: 0,
      cursor: 'none',
      '&, & *': {
        pointerEvents: 'none',
      },
    },
    saveBtn: {
      minWidth: 'unset',
      width: 70,
      height: 30,
      fontSize: 12,
      padding: 0,
    },
    addEventBtn: {
      width: 175,
      height: 30,
      fontSize: 14,
      color: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      margin: 'auto',
      display: 'block',
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.9),
      },
    },
  };
});
