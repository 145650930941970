import { ReactElement, useContext, useEffect, useMemo, useRef } from 'react';

import { DialogContext, DialogSize } from './DialogController';

export interface UseDialog {
  close: () => void;
  show: (options: {
    title: string;
    content: ReactElement;
    backdropClose?: boolean;
    transition?: boolean;
    size?: DialogSize;
    onClose?: () => void;
  }) => void;
}

export const useDialog = (): UseDialog => {
  const closeRef = useRef<() => void>();
  const showDialog = useContext(DialogContext);
  useEffect(() => () => closeRef.current?.(), []);
  const ref: UseDialog = useMemo(
    () => ({
      close: () => {
        closeRef.current?.();
        closeRef.current = undefined;
      },
      show: (options) => {
        closeRef.current?.();
        const { close } = showDialog(options);
        closeRef.current = close;
      },
    }),
    [showDialog],
  );
  return ref;
};
