import { CSSProperties, ChangeEvent, FC } from 'react';
import { TextField } from '@mui/material';

import {
  getDecimalPlacesLength,
  parseInputNumber,
  selectOnFocus,
} from 'src/utils';

interface Props {
  value: string | number;
  label?: string;
  percentFormat?: boolean;
  allowEmpty?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  variant?: 'outlined' | 'filled';
  error?: boolean;
  precision?: number;
  charLimit?: number;
  style?: CSSProperties;
  onChange?: (value: string | number) => void;
  onBlur?: () => void;
}

export const NumberInput: FC<Props> = ({
  value,
  label,
  percentFormat,
  allowEmpty,
  className,
  disabled,
  id,
  variant = 'filled',
  error,
  precision = 1,
  charLimit,
  style,
  onChange,
  onBlur,
}) => {
  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const [val] = parseInputNumber(ev.currentTarget.value, true);
    onChange?.(val);
  };
  const handleBlur = () => {
    const parsedNumber = parseInputNumber(value.toString(), true)[1];
    if (parsedNumber === undefined && allowEmpty) {
      onChange?.('');
      return;
    }
    const val = parsedNumber ?? 0;
    const parsedVal = percentFormat
      ? `${getDecimalPlacesLength(val) < 1 ? val.toFixed(precision) : val}%`
      : val;
    onChange?.(parsedVal);
    onBlur?.();
  };

  return (
    <TextField
      id={id}
      autoComplete="off"
      variant={variant}
      label={label}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      inputProps={{
        ...selectOnFocus,
        maxLength: charLimit,
      }}
      className={className}
      disabled={disabled}
      error={error}
      style={style}
      fullWidth
    />
  );
};
