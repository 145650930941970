import { useTheme } from '@mui/material';
import { useCallback } from 'react';

export const useIntSpacing = () => {
  const { spacing } = useTheme();
  return useCallback(
    (value: number) => {
      return parseInt(spacing(value));
    },
    [spacing],
  );
};
