import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
  },
  content: {
    padding: '40px 76px',
  },
  requirementWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > p': {
      marginLeft: theme.spacing(2),
      font: '400 14px Poppins, sans-serif',
      lineHeight: 2.15,
      letterSpacing: 0.05,
    },
    '& > div': {
      color: '#c5e1de',
    },
  },
  headerText: {
    font: '600 14px Poppins, sans-serif',
    lineHeight: 3,
    letterSpacing: '0.05em',
  },
}));
