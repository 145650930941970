import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  textWrapper: {
    width: '100%',
  },
  charCounter: {
    fontSize: theme.typography.fontSize * 0.625,
  },
  textarea: {
    fontWeight: theme.typography.fontWeightLight,
  },
}));
