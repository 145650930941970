import { makeVar, useReactiveVar } from '@apollo/client';
import { getCurrentUser } from 'src/auth';
import { IfaCompany } from 'src/components/pages/CompanyPage/types';
import { getIfaCompanyInfo } from 'src/restApi/getIfaCompanyInfo';
import { identifyUser, initTracking, registerUser } from 'src/utils';

const companyVar = makeVar<IfaCompany | null>(null);

export const initCompany = async () => {
  if (companyVar() !== null) return;
  try {
    const info = await getIfaCompanyInfo();
    companyVar(info);
    const enabled = initTracking();
    if (enabled) {
      const currentUser = getCurrentUser();
      identifyUser(currentUser);
      registerUser(currentUser, info);
    }
  } catch (e) {
    console.log(e);
  }
};

export const destroyCompany = () => {
  companyVar(null);
};

export const useCompany = () => {
  return useReactiveVar(companyVar);
};
