export const formatCurrency = (
  value: number,
  maximumFractionDigits?: number,
) => {
  return Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits,
  }).format(value);
};

export const formatCurrencyInThousand = (
  value: number,
  maximumFractionDigits?: number,
) => {
  if (Math.abs(value) >= 1000) {
    return `${formatCurrency(value / 1000, maximumFractionDigits)}k`;
  }
  return formatCurrency(value, maximumFractionDigits);
};

export const formatCurrencyInMillions = (
  value: number,
  maximumFractionDigits?: number,
) => {
  if (Math.abs(value) >= 1000000) {
    return `${formatCurrency(value / 1000000, maximumFractionDigits)}m`;
  }
  return formatCurrencyInThousand(value, maximumFractionDigits);
};
