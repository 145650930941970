import axios from 'axios';
import { getToken } from 'src/auth';
import { NOTIFICATIONS_LAMBDA_URL } from './lambda-routes';

export async function readIfaNotifications(notificationIds: string[]) {
  const options = {
    headers: {
      Authorization: await getToken(),
      'Content-Type': 'application/json',
    },
  };
  const response = await axios
    .post(
      `${NOTIFICATIONS_LAMBDA_URL}/notifications/mark-read`,
      { notificationIds },
      options,
    )
    .catch((error) => {
      throw new Error(
        error.response?.data?.error ??
          'Unexpected error while marking notifications as read.',
      );
    });

  return response.data.data;
}
