import { FC, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  InputLabel,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';

import { ColorDialog } from './ColorDialog';
import { ColorDot } from './ColorDot';
import { useStyles } from './styles';
import { ArrowRightIcon } from '../icons';

interface Props {
  disabled?: boolean;
  onChange?: (value: string) => void;
  value?: string | null;
  sx?: SxProps<Theme>;
}

export const ColorInput: FC<Props> = ({ disabled, onChange, value, sx }) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const defaultColor = theme.palette.primary.main;
  const colorValue = value ?? defaultColor;
  const isDefaultColor = value === defaultColor || !value;
  const defaultLabel = isDefaultColor ? 'Default' : 'Custom';
  const handleClick = useCallback(() => setOpen((val) => !val), []);
  const handleClose = useCallback(
    (val?: string) => {
      setOpen(false);
      if (onChange && val) {
        onChange(val);
      }
    },
    [onChange],
  );

  return (
    <Box display="flex" flexDirection="column" sx={sx}>
      <Box ml={2}>
        <b>Brand color</b>
      </Box>
      <Button
        variant="text"
        onClick={handleClick}
        className={classes.root}
        disabled={disabled}
        disableRipple
      >
        <ColorDot color={colorValue} size={24} />
        <InputLabel className={classes.defaultLabel}>{defaultLabel}</InputLabel>
        <InputLabel sx={{ marginLeft: 'auto', fontSize: 10, height: 10 }}>
          <ArrowRightIcon color="primary" fontSize="inherit" />
        </InputLabel>
      </Button>
      <Dialog onClose={() => handleClose()} open={open}>
        <ColorDialog value={value} onClose={handleClose} />
      </Dialog>
    </Box>
  );
};
