import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  datePickerWrapper: {
    position: 'relative',
  },
  datePicker: {
    '& *': {
      cursor: 'pointer',
    },
    '&.fullWidth': {
      width: '100%',
    },
    '&.outlined .MuiFilledInput-root': {
      border: 0,
      borderBottom: `1px solid ${lighten(theme.palette.primary.main, 0.8)}`,
      borderRadius: 0,
      width: '100%',
      '& input': {
        textAlign: 'left',
        fontSize: 16,
        padding: theme.spacing(2.5, 6, 0, 0),
        height: 56,
        boxSizing: 'border-box',
      },
    },
    '& .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root': {
      padding: 0,
      position: 'absolute',
      right: theme.spacing(4),
      top: 15,
      color: theme.palette.primary.main,
      '& svg': {
        fontSize: '2.5rem',
      },
    },
    '&.outlined .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root':
      {
        padding: 0,
        position: 'absolute',
        right: 0,
        bottom: 0,
        transform: 'translateY(5px)',
        color: theme.palette.primary.main,
        '& svg': {
          fontSize: '42px',
        },
      },
    '&.outlined .MuiInputBase-adornedEnd': {
      paddingRight: 0,
    },
    '&.outlined .MuiFormLabel-root': {
      transform: 'none',
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
  },
}));
