import { Maybe } from '../apollo';

/**
 * Function to group an array of objects by a specified property.
 *
 * @template V - The type of objects in the array.
 * @template K - The type of the property to group by.
 * @param {V[]} arr - The array of objects to be grouped.
 * @param {K} prop - The property of the objects to group by.
 * @returns {Map<Maybe<K> | undefined, V[]>} - A Map that contains the groups where the key is the unique value of the
 * property and the value is an array of objects with the same property value.
 */
export const groupBy = <V extends Record<string, unknown>, K extends keyof V>(
  arr: V[],
  prop: K,
): Map<Maybe<K> | undefined, V[]> => {
  const groups = new Map<Maybe<K> | undefined, V[]>();
  arr?.forEach((element) => {
    const currentList = groups.get(element[prop] as K) ?? [];
    currentList.push(element);
    groups.set(element[prop] as K, currentList);
  });
  return groups;
};
