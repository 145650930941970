import mixpanel, { Dict } from 'mixpanel-browser';
import { Journey } from 'src/apollo';
import { getCognitoGroups } from 'src/auth';
import { IfaCompany } from 'src/components/pages/CompanyPage/types';
import { getRoleName } from 'src/hooks';
import { User } from '../auth/User';

export enum PageTitle {
  LoginPage = 'Login Page',
  RiskProfile = 'Risk Profile',
  QuestionnaireForm = 'Questionnaire Form',
  InteractiveChart = 'Interactive Chart',
  JourneyPage = 'Journey',
  MasterAdminDashboard = 'Master Admin Dashboard',
  IfaDashboard = 'IFA Dashboard',
  IfaAdminDashboard = 'IFA Admin Dashboard',
  ClientsList = 'Clients List',
  UnassignedJourneysList = 'Unassigned Journeys List',
  MarketUpdate = 'Market Update',
  IfaUsefulResources = 'IFA Useful Resources',
  MasterAdminUsefulResources = 'Master Admin Useful Resources',
  FactsheetsList = 'Factsheets List',
  SatellitesList = 'Satellites List',
  MultiAssetsList = 'Multi Assets List',
  MasterAdminMarketUpdatesList = 'Master Admin Market Updates List',
  IfaMarketUpdate = 'IFA Market Update',
  Satellites = 'Satellites',
  EditIfa = 'Edit IFA',
  AdvisersList = 'Advisers List',
  CompaniesList = 'Companies List',
  CompanyForm = 'Company Form',
  CompanyFormDemo = 'Company Form Demo',
  HistoricReturns = 'Historic Returns',
  ProjectedReturns = 'Projected Returns',
  FactsheetEdit = 'Factsheet Edit',
  SatelliteEdit = 'Satellite Edit',
  MultiAssetEdit = 'Multi Asset Edit',
  IfaAdminsList = 'IFA Admins List',
  AddIfaForCompany = 'Add IFA for Company',
  AddIfaPage = 'Add IFA Page',
  MonthlyMarketUpdate = 'Monthly Market Update',
  PulseMarketUpdate = 'Pulse Market Update',
  InsightsMarketUpdate = 'Insights Market Update',
}

let trackingEnabled = false;

export const initTracking = () => {
  const token = process.env.REACT_APP_MIXPANEL_TOKEN;
  if (!token) {
    trackingEnabled = false;
    return false;
  }
  if (trackingEnabled) return false;
  mixpanel.init(token);
  trackingEnabled = true;
  return true;
};

const trackEvent = (
  eventName: string,
  propOrTitle: Dict | string,
  pageTitle?: string,
) => {
  const noProps = typeof propOrTitle === 'string';
  const properties = {
    current_page_title: noProps ? propOrTitle : pageTitle,
  };
  if (!noProps) {
    Object.assign(properties, propOrTitle);
  }
  mixpanel.track(eventName, properties);
};

export const trackPage = (pageTitle: string, props?: Dict) => {
  if (!trackingEnabled) return;
  const properties = {
    ...props,
    current_page_title: pageTitle,
  };
  mixpanel.track_pageview(properties);
};

export const identifyUser = (user?: User | null) => {
  if (!trackingEnabled || !user || !user.attributes) return;
  mixpanel.identify(user.getUsername());
};

export const registerUser = (
  user: User | null | undefined,
  company: IfaCompany,
) => {
  if (!trackingEnabled || !user || !user.attributes) return;
  const groups = getCognitoGroups(user);

  const userData = {
    $email: user.attributes.email,
    role: getRoleName(groups),
    company: company.name,
    company_id: company.ifaCompanyId,
  };
  mixpanel.people.set(userData);
  mixpanel.register({
    user: { ...userData, $user_id: user.getUsername() },
  });
};

export const trackSignIn = () => {
  trackEvent('sign_in', PageTitle.LoginPage);
};

export const trackJourneyStarted = (
  journey: Journey,
  questionnaireId?: string,
  pageTitle?: string,
) => {
  if (!trackingEnabled) return;
  let journeyType;
  if (questionnaireId) {
    journeyType = 'questionnaire';
  } else if (journey?.isShort) {
    journeyType = 'selection';
  } else if (journey.isRiskProfile) {
    journeyType = 'risk_profile';
  } else {
    journeyType = 'discovery';
  }
  trackEvent(
    'journey_started',
    {
      journey_type: journeyType,
      journey_id: journey.journeyId,
      client_id: journey.clientId,
      questionnaire_id: questionnaireId,
    },
    pageTitle,
  );
};

export const trackJourneyCompleted = (
  journey: Journey,
  questionnaireId?: string,
  pageTitle?: string,
  afterEdit?: boolean,
) => {
  if (!trackingEnabled) return;
  let journeyType;
  if (questionnaireId) {
    journeyType = 'questionnaire';
  } else if (journey?.isShort) {
    journeyType = 'selection';
  } else if (journey.isRiskProfile) {
    journeyType = 'risk_profile';
  } else {
    journeyType = 'discovery';
  }

  const eventName = afterEdit ? 'journey_edit_completed' : 'journey_completed';

  trackEvent(
    eventName,
    {
      journey_type: journeyType,
      journey_id: journey.journeyId,
      client_id: journey.clientId,
      risk_profile: journey.riskProfile?.completeRiskProfile !== null, // true, false
      risk_mapping: journey.riskProfile?.completeRiskProfile === null, // true, false
      risk_profile_selection: journey.portfolioSelection?.riskProfileSelection, // eg. higher, lower
      portfolio_style: journey.portfolioSelection?.portfolioStyle?.selected, // eg. defensive, offensive, core, passive
      portfolio_value: journey.portfolioValue,
      portfolio_count:
        (journey.satellitePortfolios?.chosenSatellites?.length ?? 0) + 1,
      // tax_wrapper_count: journey., // TODO: Add when ready
      questionnaire_id: questionnaireId,
    },
    pageTitle,
  );
};

export const trackCompletedStep = (
  startTime: number,
  journeyId: string | undefined,
  step: string | null,
) => {
  if (!trackingEnabled) return;
  trackEvent(
    'step_time',
    {
      duration: +new Date() - startTime,
      journeyId,
      step,
    },
    `${PageTitle.JourneyPage} - ${step}`,
  );
};

export const trackSendRiskProfile = (
  clientId: string,
  questionnaireId: string,
) => {
  if (!trackingEnabled) return;
  trackEvent(
    'send_risk_profile',
    {
      client_id: clientId,
      questionnaire_id: questionnaireId,
    },
    PageTitle.RiskProfile,
  );
};

export const trackDocumentDownload = (
  documentTitle: string,
  pageTitle: string,
) => {
  if (!documentTitle) return;
  if (!trackingEnabled) return;
  trackEvent(
    'document_download',
    {
      document_title: documentTitle,
    },
    pageTitle,
  );
};

export const trackClientAdded = (clientId: string, pageTitle: string) => {
  if (!clientId) return;
  if (!trackingEnabled) return;
  trackEvent(
    'client_added',
    {
      client_id: clientId,
    },
    pageTitle,
  );
};

export const trackChartAction = (action: string) => {
  if (!action) return;
  if (!trackingEnabled) return;
  trackEvent('chart', { action }, PageTitle.InteractiveChart);
};

export const trackSignOut = () => {
  if (!trackingEnabled) return;
  mixpanel.reset();
};

export const reportURL = () => process.env.REACT_APP_MIXPANEL_URL;
