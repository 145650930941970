const getNavigatorInstance = () => {
  if (typeof window !== 'undefined') {
    if (window.navigator || navigator) {
      return window.navigator || navigator;
    }
  }

  return false;
};

export const isIOS = (): string | boolean => {
  const nav = getNavigatorInstance();
  return (
    nav &&
    nav.platform &&
    (nav.platform.indexOf('iPad') !== -1 ||
      (nav.platform === 'MacIntel' &&
        nav.maxTouchPoints > 1 &&
        !('MSStream' in window)))
  );
};

const iPadAdditionalHeight = 30;

export const fullScreenHeight = isIOS()
  ? `calc(100vh - ${iPadAdditionalHeight}px)`
  : '100vh';

export const headerHeight = 20;
export const strHeaderHeight = `${headerHeight * 4}px`;
