import { FC, useState } from 'react';

import { SimpleInput } from 'src/components/ui/SimpleInput';
import { validatePassword } from 'src/utils';

import { Typography } from '@mui/material';

interface Props {
  passwordValue: string;
  passwordConfirmValue: string;
  disabled: boolean;
  setPasswordValue: (val: string) => void;
  setPasswordConfirmValue: (val: string) => void;
  autofocus?: boolean;
}

export const PasswordWithConfirmation: FC<Props> = ({
  passwordValue,
  passwordConfirmValue,
  disabled,
  setPasswordValue,
  setPasswordConfirmValue,
  autofocus,
}) => {
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false);

  const { isPasswordValid } = validatePassword(passwordValue);
  const isConfirmPasswordValid = passwordValue === passwordConfirmValue;

  const passwordError = passwordDirty && !isPasswordValid ? '' : null;
  const confirmPasswordError =
    confirmPasswordDirty && !isConfirmPasswordValid ? '' : null;

  return (
    <>
      <Typography
        variant="h4"
        component="label"
        htmlFor="password"
        color="primary"
        style={{ fontWeight: 600, alignSelf: 'flex-start', marginTop: 32 }}
      >
        Enter password
      </Typography>
      <SimpleInput
        label=""
        id="password"
        type="password"
        autoComplete="new-password"
        disabled={disabled}
        value={passwordValue}
        onChange={setPasswordValue}
        onBlur={() => setPasswordDirty(true)}
        error={passwordError}
        autoFocus={autofocus}
        variant="standard"
        inputProps={{ style: { textAlign: 'left', marginTop: 0 } }}
      />
      <Typography
        variant="h4"
        component="label"
        htmlFor="confirm-password"
        color="primary"
        style={{ fontWeight: 600, alignSelf: 'flex-start', marginTop: 32 }}
      >
        Repeat password
      </Typography>
      <SimpleInput
        label=""
        id="confirm-password"
        type="password"
        autoComplete="new-password"
        disabled={disabled}
        value={passwordConfirmValue}
        onChange={setPasswordConfirmValue}
        onBlur={() => setConfirmPasswordDirty(true)}
        error={confirmPasswordError}
        variant="standard"
        inputProps={{ style: { textAlign: 'left', marginTop: 0 } }}
      />
    </>
  );
};
