import {
  createContext,
  FC,
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Box, Dialog, DialogTitle, Typography } from '@mui/material';

import { Transition } from 'src/components/shared';
import { DialogClose } from 'src/components/ui';
import { useStyles } from './styles';

type DialogValue = (config: { title: string; content: ReactElement }) => {
  close: () => void;
};

export const DialogContext = createContext<DialogValue>(() => ({
  close: () => {},
}));

let currentId = 0;

interface Props {
  children?: ReactNode;
}

export enum DialogSize {
  Regular,
  Medium,
  Big,
}

const dialogSizeLookup = {
  [DialogSize.Regular]: '',
  [DialogSize.Medium]: 'medium',
  [DialogSize.Big]: 'big',
} as const;

export const getDialogSizeClass = (size = DialogSize.Regular) => {
  return dialogSizeLookup[size] ?? '';
};

export const DialogController: FC<Props> = memo(({ children }) => {
  const { classes } = useStyles();
  const [dialogs, setDialogs] = useState<
    {
      id: number;
      open: boolean;
      content: ReactNode;
      title: string;
      backdropClose?: boolean;
      transition?: boolean;
      size?: DialogSize;
      onClose?: () => void;
    }[]
  >([]);

  const close = useCallback((id: number) => {
    setDialogs((v) => {
      const newV = v.slice();
      const i = v.findIndex((s) => s.id === id);
      const oldDialog = newV[i];
      if (oldDialog) {
        newV[i] = { ...oldDialog, open: false };
      }
      return newV;
    });
  }, []);

  const destroy = useCallback(
    (id: number) => setDialogs((v) => v.filter(({ id: vId }) => vId !== id)),
    [],
  );

  const show = useCallback(
    (config: {
      title: string;
      content: ReactElement;
      backdropClose?: boolean;
      size?: DialogSize;
      onClose?: () => void;
    }): { close: () => void } => {
      const id = currentId;
      setDialogs((v) => v.concat({ ...config, id, open: true }));
      currentId += 1;
      return { close: () => close(id) };
    },
    [close],
  );

  const dialogEls = useMemo(
    () =>
      dialogs.map((dialog) => {
        const sizeClass = getDialogSizeClass(dialog.size);
        return (
          <Dialog
            key={dialog.id}
            open={dialog.open}
            onClose={() => {
              if (!dialog.backdropClose) return;
              dialog.onClose?.();
              close(dialog.id);
            }}
            TransitionComponent={dialog.transition ? Transition : undefined}
            classes={{
              paper: `${classes.paper} ${sizeClass}`,
            }}
            TransitionProps={{
              onExited: () => destroy(dialog.id),
            }}
          >
            <DialogTitle classes={{ root: classes.dialogTitleContainer }}>
              <Box className={classes.dialogExit}>
                <DialogClose
                  onClose={() => {
                    dialog.onClose?.();
                    close(dialog.id);
                  }}
                />
              </Box>
              <Typography variant="h1" className={classes.dialogTitle}>
                {dialog.title}
              </Typography>
            </DialogTitle>
            {dialog.content}
          </Dialog>
        );
      }),
    [destroy, close, dialogs, classes],
  );

  return (
    <DialogContext.Provider value={show}>
      {children}
      {dialogEls}
    </DialogContext.Provider>
  );
});
