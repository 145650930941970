import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

interface Props {
  message: string;
  redirectTo: string;
  buttonText: string;
}

export const NoResults: FC<Props> = ({ message, redirectTo, buttonText }) => {
  return (
    <>
      <Typography variant="h3">{message}</Typography>
      <Box mt={10}>
        <Button component={NavLink} to={redirectTo}>
          {buttonText}
        </Button>
      </Box>
    </>
  );
};
