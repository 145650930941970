import { createIcon } from '../createIcon';

export const GiltsIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2806 0.586449L14.9995 0.395264L14.7183 0.586449L11.5933 2.71145L12.1556 3.53838L14.9995 1.60456L25.875 8.99991H4.12397L9.03064 5.66338L8.46833 4.83645L2.21833 9.08645L0.875 9.99991H2.49948H22.9995V21.9999H17.9995V11.9999H16.9995V21.9999H11.9995V13.9999H10.9995V21.9999H5.99948V15.9999H4.99948V21.9999H2.99948V22.9999V24.9999H0.999483V25.9999H2.99948H3.99948H28.9995V24.9999H25.9995V22.9999V21.9999H24.9995H23.9995V9.99991H27.4995H29.124L27.7806 9.08645L15.2806 0.586449ZM24.9995 22.9999V24.9999H3.99948V22.9999H24.9995Z"
        fill="currentColor"
      />
    </g>
  ),
  fontSize: 'inherit',
  viewBox: '0 0 30 26',
});
