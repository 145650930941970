import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  presetContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, auto)',
    justifyContent: 'space-between',
    rowGap: theme.spacing(6),
    marginTop: theme.spacing(6),
  },
}));
