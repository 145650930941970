import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';

import { useStyles } from './styles';

interface Props {
  image: ReactNode;
  label?: string;
  children?: ReactNode;
}

export const FactsheetSection: FC<Props> = ({ image, label, children }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.inputSection}>
      {image}
      <div className={classes.inputWrapper}>
        {label && (
          <Typography align="left" variant="h1">
            {label}
          </Typography>
        )}
        {children}
      </div>
    </div>
  );
};
