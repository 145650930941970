export const parseInputNumber = (
  value: string,
  allowNegative?: boolean,
): [string, number?] => {
  let stringRegex = /[^0-9., ]/g;
  let numberRegex = /[^0-9.]/g;
  if (allowNegative) {
    stringRegex = /[^0-9., -]/g;
    numberRegex = /[^0-9.-]/g;
  }
  const val = value
    .replace(stringRegex, '')
    .split('.')
    .map((v, i, { length }) => (v || i || length === 1 ? v : '0'))
    .slice(0, 2)
    .join('.');
  const number = val.replace(numberRegex, '');
  return [val, number ? +number : undefined];
};
