import { FC } from 'react';

import { SvgImage } from 'src/components/shared';

export const PortfolioUpdate: FC = () => (
  <SvgImage
    image={
      <svg
        width="132"
        height="180"
        viewBox="0 0 132 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.457265"
          y="0.457265"
          width="130.71"
          height="179.085"
          fill="white"
          stroke="#D4D4D4"
          strokeWidth="0.91453"
        />
        <path
          d="M1.125 1.125H104.625L96.75 25.875H1.125V1.125Z"
          fill="#D4D4D4"
        />
        <path d="M132 180L43 180L49.7717 168L132 168L132 180Z" fill="#D4D4D4" />
        <rect x="7" y="24" width="48" height="105" fill="#F6F6F6" />
        <circle cx="117" cy="13.5" r="4.5" fill="#D4D4D4" />
        <rect x="14" y="135" width="87.9" height="5.625" fill="#D4D4D4" />
        <rect x="14" y="145.125" width="109.875" height="2.25" fill="#D4D4D4" />
        <rect x="14" y="149.625" width="109.875" height="2.25" fill="#D4D4D4" />
        <rect x="14" y="154.125" width="109.875" height="2.25" fill="#D4D4D4" />
        <rect x="14" y="158.625" width="109.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="77" width="24" height="5" fill="#D4D4D4" />
        <rect x="62" y="84.125" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="88.625" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="93.125" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="101" width="24" height="5" fill="#D4D4D4" />
        <rect x="62" y="108.125" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="112.625" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="117.125" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="54" width="24" height="5" fill="#D4D4D4" />
        <rect x="62" y="61.125" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="65.625" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="70.125" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="31" width="24" height="5" fill="#D4D4D4" />
        <rect x="62" y="38.125" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="42.625" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="62" y="47.125" width="61.875" height="2.25" fill="#D4D4D4" />
        <rect x="14" y="32" width="18" height="6" fill="#D4D4D4" />
        <rect x="14" y="42" width="35" height="2" fill="#D4D4D4" />
        <rect x="14" y="46" width="35" height="2" fill="#D4D4D4" />
        <rect x="14" y="50" width="35" height="2" fill="#D4D4D4" />
        <rect x="14" y="54" width="35" height="2" fill="#D4D4D4" />
        <rect x="14" y="95" width="18" height="6" fill="#D4D4D4" />
        <path
          d="M14.1464 107.146C13.9512 107.342 13.9512 107.658 14.1464 107.854L17.3284 111.036C17.5237 111.231 17.8403 111.231 18.0355 111.036C18.2308 110.84 18.2308 110.524 18.0355 110.328L15.2071 107.5L18.0355 104.672C18.2308 104.476 18.2308 104.16 18.0355 103.964C17.8403 103.769 17.5237 103.769 17.3284 103.964L14.1464 107.146ZM48.8536 107.854C49.0488 107.658 49.0488 107.342 48.8536 107.146L45.6716 103.964C45.4763 103.769 45.1597 103.769 44.9645 103.964C44.7692 104.16 44.7692 104.476 44.9645 104.672L47.7929 107.5L44.9645 110.328C44.7692 110.524 44.7692 110.84 44.9645 111.036C45.1597 111.231 45.4763 111.231 45.6716 111.036L48.8536 107.854ZM14.5 108H48.5V107H14.5V108Z"
          fill="#D4D4D4"
        />
        <rect x="14" y="114" width="8" height="2" fill="#D4D4D4" />
        <rect x="23" y="114" width="8" height="2" fill="#D4D4D4" />
        <rect x="32" y="114" width="8" height="2" fill="#D4D4D4" />
        <rect x="41" y="114" width="8" height="2" fill="#D4D4D4" />
        <path
          d="M21 106V107.5V109M26 106V109M31 106V109M36 106V109M41 106V109"
          stroke="#D4D4D4"
        />
        <rect x="14" y="66" width="18" height="6" fill="#D4D4D4" />
        <rect x="14" y="76" width="35" height="2" fill="#D4D4D4" />
        <rect x="14" y="80" width="35" height="2" fill="#D4D4D4" />
        <rect x="14" y="84" width="35" height="2" fill="#D4D4D4" />
        <g style={{ mixBlendMode: 'multiply' }}>
          <rect
            x="126"
            y="165"
            width="116"
            height="21"
            transform="rotate(180 126 165)"
            fill="#EFF7F6"
          />
          <rect
            x="126"
            y="165"
            width="116"
            height="21"
            transform="rotate(180 126 165)"
            stroke="#1F5E4C"
            strokeWidth="2"
          />
        </g>
      </svg>
    }
  />
);
