import { Cache, MutationUpdaterFn } from '@apollo/client';

import { Mutation, Query, QueryData } from './graphql';

export const updateCacheData =
  <MN extends keyof Mutation, QN extends keyof Query, V>(
    mutationName: MN,
    queryData: QueryData<QN, V>,
    updateFn: (
      currentData: NonNullable<Query[QN]>,
      newData: NonNullable<Mutation[MN]>,
    ) => Query[QN],
  ): MutationUpdaterFn<Mutation> =>
  (cache, { data }) => {
    const newData = data?.[mutationName] as NonNullable<Mutation[MN]>;
    const options: Cache.ReadQueryOptions<Query, V> = queryData;
    const query = cache.readQuery(options);
    const currentData = query?.[queryData.name];
    if (currentData) {
      cache.writeQuery({
        ...options,
        data: {
          [queryData.name]: updateFn(currentData, newData),
        },
      });
    }
  };
