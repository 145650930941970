import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    margin: theme.spacing(6, 0),
    height: theme.spacing(4),
    flexShrink: 0,
  },
  slider: {
    display: 'block',
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
  dragger: {
    position: 'absolute',
    width: theme.spacing(2),
    height: theme.spacing(6),
    borderRadius: theme.spacing(0.5),
    top: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid',
    borderColor: 'rgba(71, 71, 71, 0.52)',
    background: alpha(theme.palette.common.white, 0.8),
    cursor: 'pointer',
  },
}));
