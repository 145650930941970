import {
  SimplePaletteColorOptions,
  Theme,
  ThemeOptions,
  unstable_createMuiStrictModeTheme,
} from '@mui/material';
import { baseTheme, themeOverrides } from './baseTheme';

const darkContrastText = '#444';

const isDark = (colorString: string) => {
  return !colorString.startsWith('#fff');
};

export const companyTheme = (
  primaryOptions: SimplePaletteColorOptions,
): Theme => {
  const themeConfig: ThemeOptions = {
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: primaryOptions,
      contrastThreshold: 1.5,
    },
  };

  // TODO: unstable_createMuiStrictModeTheme() is used to silence multiple errors in the app
  // We should consider switching to createTheme() and resolving these errors.
  let theme = unstable_createMuiStrictModeTheme(themeConfig);
  if (isDark(theme.palette.primary.contrastText)) {
    theme = unstable_createMuiStrictModeTheme({
      ...themeConfig,
      palette: {
        ...themeConfig.palette,
        primary: { ...primaryOptions, contrastText: darkContrastText },
      },
    });
  }
  theme.components = themeOverrides(theme);
  return theme;
};
