const BASE_URL = process.env.REACT_APP_REST_API_URL;
export const SATELLITE_LAMBDA_URL = `${BASE_URL}lmbd-satelite`;
export const MULTI_ASSETS_LAMBDA_URL = `${BASE_URL}lmbd-multiAssets`;
export const MARKET_UPDATES_LAMBDA_URL = `${BASE_URL}lmbd-marketUpdate`;
export const FILE_UPLOAD_LAMBDA_URL = `${BASE_URL}lmbd-fileUpload`;
export const USER_SESSION_DETAILS_LAMBDA_URL = `${BASE_URL}lmbd-userSessionDetails`;
export const CLIENT_PROFILE_LAMBDA_URL = `${BASE_URL}lmbd-clientProfile`;
export const INVESTOR_QUESTIONNAIRE_LAMBDA_URL = `${BASE_URL}lmbd-questionnaire`;
export const PUBLIC_QUESTIONNAIRE_LAMBDA_URL = `${BASE_URL}lmbd-questionnaire-public`;
export const USEFUL_RESOURCES_LAMBDA_URL = `${BASE_URL}lmbd-useful-resources`;
export const IFA_COMPANY_LAMBDA_URL = `${BASE_URL}lmbd-ifaCompany`;
export const NOTIFICATIONS_LAMBDA_URL = `${BASE_URL}lmbd-notifications`;
export const PORTFOLIO_POSITIONING_LAMBDA_URL = `${BASE_URL}lmbd-portfolio-positioning`;
export const PORTFOLIO_CHANGES_LAMBDA_URL = `${BASE_URL}lmbd-portfolioChanges`;
