import { createIcon } from '../createIcon';

export const ArrowLeftIcon = createIcon({
  svg: () => (
    <path
      d="M6.92115 12.5L12.7135 18.2923L12 19L5 12L12 5L12.7135 5.7077L6.92115 11.5H19V12.5H6.92115Z"
      fill="currentColor"
    />
  ),
  fontSize: 'large',
  viewBox: '0 0 24 24',
});
