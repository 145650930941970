import { createIcon } from '../createIcon';

export const BellIcon = createIcon({
  svg: () => (
    <path
      d="M5 18.7692V17.7692H6.61537V9.84615C6.61537 8.53975 7.02884 7.39072 7.85577 6.39905C8.68269 5.40737 9.73077 4.78973 11 4.54615V4C11 3.72222 11.097 3.4861 11.291 3.29165C11.485 3.09722 11.7206 3 11.9978 3C12.2749 3 12.5112 3.09722 12.7067 3.29165C12.9022 3.4861 13 3.72222 13 4V4.54615C14.2692 4.78973 15.3173 5.40737 16.1442 6.39905C16.9712 7.39072 17.3846 8.53975 17.3846 9.84615V17.7692H19V18.7692H5ZM11.9966 21.3846C11.5514 21.3846 11.1715 21.2265 10.8567 20.9101C10.542 20.5938 10.3846 20.2135 10.3846 19.7692H13.6154C13.6154 20.2167 13.4569 20.5978 13.1399 20.9125C12.8229 21.2273 12.4418 21.3846 11.9966 21.3846ZM7.61537 17.7692H16.3846V9.84615C16.3846 8.63077 15.9577 7.59615 15.1039 6.7423C14.25 5.88847 13.2154 5.46155 12 5.46155C10.7846 5.46155 9.75 5.88847 8.89615 6.7423C8.0423 7.59615 7.61537 8.63077 7.61537 9.84615V17.7692Z"
      fill="currentColor"
    />
  ),
  fontSize: 'large',
  viewBox: '0 0 24 24',
});
