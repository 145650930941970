import { createIcon } from '../createIcon';

export const EraseIcon = createIcon({
  svg: () => (
    <g fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
        d="M9.27 6.65h19.563c.746 0 1.35.604 1.35 1.35v16c0 .746-.604 1.35-1.35 1.35H9.27c-.4 0-.78-.178-1.037-.486l-6.667-8c-.417-.5-.417-1.228 0-1.728l6.667-8c.256-.308.636-.486 1.037-.486z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.3"
        d="M16.715 19.81l7.879-7.976M16.666 11.882l7.976 7.879"
      />
    </g>
  ),
  fontSize: 'large',
  viewBox: '0 0 32 32',
});
