import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
  },
  preloadFont: {
    fontWeight: theme.typography.fontWeightMedium,
    height: 0,
    overflow: 'hidden',
  },
  label: {
    width: '20%',
    marginLeft: 0,
    alignItems: 'center',
    '& .MuiFormControlLabel-label': {
      marginTop: theme.spacing(4),
      fontSize: (theme.typography.fontSize * 3) / 4,
      lineHeight: 1.25,
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    '& .Mui-checked + .MuiFormControlLabel-label': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  line: {
    margin: theme.spacing(0, -15),
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexGrow: 1,
    height: theme.spacing(6),
  },
}));
