import { createIcon } from '../createIcon';

export const PassiveIcon = createIcon({
  svg: ({ palette }) => (
    <>
      <circle
        cx="36"
        cy="36"
        r="35.5"
        fill={palette.common.white}
        stroke="color-mix(in srgb, currentColor 20%, transparent)"
      />
      <path
        fill="currentColor"
        d="M52 23.467v2.134c0 .32-.213.533-.533.533h-.534v17.6c0 1.493-1.173 2.666-2.666 2.666H23.733c-1.493 0-2.666-1.173-2.666-2.666V26.08c0-.32.213-.533.533-.533.32 0 .533.213.533.534v17.653c0 .907.694 1.6 1.6 1.6h24.534c.906 0 1.6-.694 1.6-1.6V25.6c0-.32.213-.533.533-.533h.533V24H21.067v1.067h29.28c.32 0 .533.213.533.534 0 .32-.213.533-.533.533H20.533c-.32 0-.533-.213-.533-.534v-2.133c0-.32.213-.533.533-.533h14.934V22.4c0-.32.213-.533.533-.533.32 0 .533.213.533.534v.533h14.934c.32 0 .533.213.533.533zm-16 24c-.32 0-.533.214-.533.534v1.386l-3.574 3.574c-.213.213-.213.533 0 .746.107.107.267.16.374.16.106 0 .266-.053.373-.16l3.733-3.733c.107-.107.16-.213.16-.373V48c0-.32-.213-.534-.533-.534zm.48 1.92c-.213-.213-.533-.213-.747 0-.213.213-.213.533 0 .747l3.68 3.573c.107.107.267.16.374.16.106 0 .266-.053.373-.16.213-.213.213-.533 0-.746l-3.68-3.574zm-3.84-14.56c-.213-.213-.533-.213-.747 0l-5.333 5.334c-.213.213-.213.533 0 .746.107.107.267.16.373.16.107 0 .267-.053.374-.16l4.96-4.96 3.893 3.893c.213.214.533.214.747 0l7.626-7.626v1.92c0 .32.214.533.534.533.32 0 .533-.213.533-.533v-3.2c0-.053 0-.16-.053-.213-.054-.107-.16-.214-.267-.267-.053-.053-.16-.053-.213-.053h-3.2c-.32 0-.534.213-.534.533 0 .32.214.533.534.533h1.92l-7.254 7.254-3.893-3.894z"
      />
    </>
  ),
  viewBox: '0 0 72 73',
});
