import { createIcon } from '../createIcon';

export const PlusIcon = createIcon({
  svg: ({ palette }) => (
    <g fill="none" fillRule="evenodd">
      <line
        x1="1.5"
        y1="8"
        x2="15.5"
        y2="8"
        stroke={palette.primary.main}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="8.5"
        y1="15"
        x2="8.5"
        y2="1"
        stroke={palette.primary.main}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  fontSize: 'small',
  viewBox: '0 0 16 16',
});
