import { ElementType, FC, ReactElement, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, IconButton, Tooltip } from '@mui/material';

interface CommonProps {
  icon: ReactElement;
  title: string;
  testId?: string;
  disabled?: boolean;
}

interface LinkProps {
  external?: boolean;
  to: string;
  onClick?: never;
}

interface ButtonProps {
  onClick: () => void;
  external?: never;
  to?: never;
}

type Props = CommonProps & (LinkProps | ButtonProps);

export const TableButton: FC<Props> = ({
  external,
  icon,
  title,
  to,
  disabled,
  onClick,
  testId,
}) => {
  const renderIconButton = (
    props: { to?: string; href?: string; onClick?: () => void },
    component: ElementType = 'button',
  ) => (
    <IconButton
      component={component}
      color="primary"
      size="medium"
      data-testid={testId}
      disabled={disabled}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      {icon}
    </IconButton>
  );

  let content: ReactNode;
  if (onClick) {
    content = renderIconButton({ onClick });
  } else if (external) {
    content = renderIconButton({ href: to }, 'a');
  } else {
    content = renderIconButton({ to }, NavLink);
  }

  return (
    <Box component="span">
      <Tooltip title={title}>{content}</Tooltip>
    </Box>
  );
};
