export const formatPercent = (
  value: number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
): string =>
  Intl.NumberFormat('en-GB', {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
