import {
  Button,
  ButtonProps,
  CircularProgress,
  IconButton,
  IconButtonProps,
  Link,
  useTheme,
} from '@mui/material';
import {
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Journey } from 'src/apollo';
import { useLogo } from 'src/hooks';
import { getPdf, trackDocumentDownload } from 'src/utils';

import { useStyles } from './styles';

type Props = {
  children?: ReactNode;
  filename: string;
  download?: boolean;
  downloadName?: string;
  journey?: Journey;
  icon?: boolean;
  shadow?: boolean;
  displayLoadingSpinner?: boolean;
  fetchImmediately?: boolean;
  fetchDirectly?: boolean;
  onClick?: () => void;
  buttonText?: ReactNode;
  style?: React.CSSProperties;
  pageTitle?: string;
  circularSpinnerOptions?: {
    color?: 'primary' | 'secondary' | 'inherit';
    size?: number;
  };
  skipDownloadCaching?: boolean;
  className?: string;
} & Pick<
  ButtonProps,
  'color' | 'startIcon' | 'title' | 'variant' | 'disabled' | 'size'
>;

export const ReportButton: FC<Props> = ({
  filename,
  color,
  children,
  download,
  downloadName,
  icon,
  shadow,
  journey,
  size,
  startIcon,
  title,
  style,
  variant,
  disabled,
  displayLoadingSpinner,
  fetchImmediately,
  fetchDirectly,
  onClick,
  buttonText,
  circularSpinnerOptions,
  pageTitle,
  skipDownloadCaching,
  className,
}) => {
  const { classes, cx } = useStyles();
  const { src: logoSrc } = useLogo();
  const theme = useTheme();
  const [clicked, setClicked] = useState(false);
  const [href, setHref] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const ref = useRef<HTMLAnchorElement>(null);
  const target = download ? undefined : '_blank';

  const fetchPdfFile = useCallback(async () => {
    if (fetchDirectly) {
      setHref(filename);
      return;
    }
    setLoading(true);
    const url = await getPdf(filename, download);
    setHref(url);
    setLoading(false);
  }, [download, filename]);

  useEffect(() => {
    if (fetchImmediately && !href) {
      fetchPdfFile();
    }
  }, [fetchImmediately, href, fetchPdfFile]);

  useEffect(() => {
    if (!href) return;

    if (download) {
      ref.current?.click();
      trackDocumentDownload(
        downloadName as string,
        pageTitle ?? 'Report Button',
      );
      if (skipDownloadCaching) {
        setClicked(false);
        setHref('');
      }
    } else {
      window.open(href, '_blank');
    }
  }, [href, download]);

  useEffect(() => {
    if (clicked && logoSrc && filename && !href) {
      fetchPdfFile();
    }
  }, [clicked, journey, logoSrc, theme, filename, href, fetchPdfFile]);

  const handleClick = useCallback(
    (ev: MouseEvent) => {
      if (onClick) {
        onClick();
      }
      if (clicked && download) return;
      ev.preventDefault();
      if (href) {
        window.open(href, '_blank');
      } else {
        setClicked(true);
      }
    },
    [clicked, href, download, onClick],
  );

  const loadingSpinner = (
    <CircularProgress
      color={circularSpinnerOptions?.color ?? 'inherit'}
      size={circularSpinnerOptions?.size || (size === 'compact' && 20) || 30}
      style={{
        verticalAlign: 'middle',
        margin: 'auto 0',
        flexGrow: 0,
        flexShrink: 0,
      }}
    />
  );
  const displayLoading =
    loading || displayLoadingSpinner ? loadingSpinner : children;
  const isButtonDisabled = loading || displayLoadingSpinner || disabled;
  if (buttonText) {
    return (
      <>
        <Link
          component="button"
          onClick={handleClick}
          // href={href} TODO: check if not needed anymore
          // download={download}
          underline="hover"
          color="primary"
          variant="body1"
          // rel="noopener"
          style={style}
          className={classes.linkButton}
        >
          {buttonText}
        </Link>
        {displayLoading}
      </>
    );
  }
  if (icon) {
    return (
      <IconButton
        id="1234"
        ref={ref}
        href={href}
        onClick={handleClick}
        color={color}
        download={downloadName}
        size={
          (size === 'compact' && 'medium') || (size as IconButtonProps['size'])
        }
        target={target}
        title={title}
        disabled={isButtonDisabled}
      >
        {displayLoading}
      </IconButton>
    );
  }
  return (
    <Button
      className={cx({ shadow }, className)}
      ref={ref}
      href={href}
      onClick={handleClick}
      color={color}
      download={downloadName}
      size={size}
      startIcon={loading ? undefined : startIcon}
      target={target}
      title={title}
      variant={variant}
      disabled={isButtonDisabled}
      style={{
        minWidth: size !== 'compact' ? 180 : undefined,
        ...style,
      }}
    >
      {displayLoading}
    </Button>
  );
};
